import React from "react";

const Burj = ({ width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 323 389" fill="none">
      <path
        d="M316.086 388.76H6.91406C2.96719 388.76 0.335938 386.13 0.335938 382.185C0.335938 378.24 2.96719 375.61 6.91406 375.61H316.086C320.033 375.61 322.664 378.24 322.664 382.185C322.664 386.13 319.375 388.76 316.086 388.76Z"
        fill="black"
      />
      <path
        d="M174.657 136.937C174.657 140.882 177.288 143.512 181.235 143.512H182.551V153.375C181.235 173.5 185.182 207 189.129 207C193.076 207 195.707 177.445 195.707 173.5V136.937C195.707 132.992 193.076 130.362 189.129 130.362H187.813V113.267C187.813 109.322 185.182 106.692 181.235 106.692H179.92V65.9275C179.92 61.9825 177.288 59.3525 173.341 59.3525H171.368L166.763 2.14996C166.763 1.49246 166.105 0.834961 165.448 0.834961C164.79 0.834961 164.132 1.49246 164.132 2.14996L159.527 58.695H157.554C153.607 58.695 150.976 61.325 150.976 65.27V98.145H149.66C145.713 98.145 143.082 100.775 143.082 104.72V177.045H133.873C129.926 177.045 127.295 179.675 127.295 183.62V232.275H125.979C122.032 232.275 119.401 234.905 119.401 238.85V295.395H110.191C106.245 295.395 103.613 298.025 103.613 301.97V380.87C103.613 384.815 106.245 387.445 110.191 387.445C114.138 387.445 116.77 384.815 116.77 380.87V308.545H131.899V380.87C131.899 384.815 134.53 387.445 138.477 387.445C142.424 387.445 145.055 384.815 145.055 380.87V301.97C145.055 298.025 142.424 295.395 138.477 295.395H132.557V245.425H148.345V381.527C148.345 385.473 150.976 388.102 154.923 388.102C158.87 388.102 161.501 385.473 161.501 381.527V239.507C161.501 235.562 158.87 232.932 154.923 232.932H140.451V190.852H149.66C153.607 190.852 156.238 188.222 156.238 184.277V111.952H157.554C161.501 111.952 164.132 109.322 164.132 105.377V72.5025H166.763V113.267C166.763 117.212 169.395 119.842 173.341 119.842H174.657V136.937Z"
        fill="black"
      />
      <path
        d="M228.597 320.38H219.387V263.835C219.387 259.89 216.756 257.26 212.809 257.26H203.6V208.605C203.6 204.66 200.969 202.03 197.022 202.03H195.706V173.1C195.706 169.155 193.075 166.525 189.128 166.525C185.181 166.525 182.55 169.155 182.55 173.1V207.947C182.55 211.892 185.181 214.522 189.128 214.522H190.444V256.602H181.234C177.287 256.602 174.656 259.232 174.656 263.177V381.527C174.656 385.472 177.287 388.102 181.234 388.102C185.181 388.102 187.812 385.472 187.812 381.527V269.752H206.231V319.722H197.68C193.733 319.722 191.102 322.352 191.102 326.297V381.527C191.102 385.472 193.733 388.102 197.68 388.102C201.627 388.102 204.258 385.472 204.258 381.527V332.872H222.019V381.527C222.019 385.472 224.65 388.102 228.597 388.102C232.544 388.102 235.175 385.472 235.175 381.527V326.297C235.175 323.01 232.544 320.38 228.597 320.38Z"
        fill="black"
      />
      <path
        d="M229.255 59.3524C229.912 57.3799 231.886 56.0649 233.859 54.7499L239.122 52.1199L233.859 49.4899C231.886 48.8324 230.57 46.8599 229.255 44.8874L226.623 39.6274L223.992 44.8874C223.334 46.8599 221.361 48.1749 219.387 49.4899L214.125 52.1199L219.387 54.7499C221.361 55.4074 222.677 57.3799 223.992 59.3524L226.623 64.6124L229.255 59.3524Z"
        fill="black"
      />
      <path
        d="M90.4559 202.03C91.7715 200.057 93.0871 198.085 95.0605 197.427L100.323 194.797L95.0605 192.167C93.0871 190.852 91.1137 189.537 90.4559 187.565L87.8246 182.305L85.1934 187.565C83.8777 189.537 82.5621 191.51 80.5887 192.167L75.3262 194.797L80.5887 197.427C82.5621 198.742 84.5355 200.057 85.1934 202.03L87.8246 207.29L90.4559 202.03Z"
        fill="black"
      />
      <path d="M163 106V71.5H170L172 74.5L170 101.5V116.5H177.5V125L181 137.5L185.5 142V150V185L189 208.5L193.5 214V258.5H189L177.5 262V377H163L156.5 373.5V234.5H140V185H153V110L163 106Z" fill="black" stroke="black" />
      <path d="M130.5 294V243L133.5 241L157 243L191 266H212.5V329H228.5V376.5H113V304L142.5 300L130.5 294Z" fill="black" stroke="black" />
    </svg>
  );
};

export default Burj;
