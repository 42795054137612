import { useEffect, useState, useRef } from "react";
import Layout from "../Components/Layout";
import axios from "axios";
import { config, url } from "../Common/Common";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import ProjectForm from "../Components/Layout/ProjectForm";
import Loading from "../Components/UI/Loading";
import Title from "../Components/UI/Title";

const BlogDetails = () => {
    const [blog, setBlog] = useState([]);
    const [blogPosts, setBlogPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const { slug } = useParams();
    useEffect(() => {
        axios
          .get(url + `blog/${slug}`, config)
          .then(function (response) {
            // Extract the data from the Axios response
            const responseData = response.data;
            setBlog(responseData.data); // Set the data using setBlogPosts
            // setIsLoading(false);
            // setTotal(responseData.total);
            // setLastPage(responseData.last_page);
            setIsLoading(false)
          })
          .catch(function (error) {
            console.log(error);
          });

          axios
          .get(url + `blog`, config)
          .then(function (response) {
            // Extract the data from the Axios response
            const responseData = response.data;
            setBlogPosts(responseData.data); 
          })
          .catch(function (error) {
            console.log(error);
          });
      }, []);
    return (
        <Layout>
            { isLoading ? 
            <div className="h-screen flex justify-center items-center">
                <Loading />
            </div> : (

            <div className="bg-gray-100 min-h-screen mt-20 lg:mt-28">
                {/* Header */}
                <div className="bg-blue-500 text-black py-6">
                    <div className="container mx-auto">
                        <div className="flex flex-col items-center">

                            <h1 className="lg:text-4xl font-bold mb-6">{blog.title}</h1>
                            <div className="flex justify-center items-center space-x-2 divide-x font-bold mb-5">
                                {blog.categories.map(category => (
                                    <p key={category.id} className="text-lg px-4 py-px">
                                        {category.name_en}
                                    </p>
                                ))}
                            </div>
                            <p className="text-sm font-bold">{blog.last_updated_date}</p>
                        </div>
                    </div>
                </div>

                {/* Main Content */}
                <section className="container mx-auto py-8">
                <div className="flex flex-col justify-center items-center -mx-4">
                        
                        {/* Blog Content */}
                        <div className="w-full px-4 flex flex-col items-center">
                            {/* Image (visible on larger screens) */}
                        <div className="w-full max-w-4xl px-4">
                            <img className="aspect-video max-w-full h-auto rounded-3xl" src={blog.img_url} alt={blog.title} />
                        </div>
                            <div className="bg-white mt-6 p-6 lg:p-12 rounded-lg">
                            <div className="text-gray-700 blogContent font-medium" dangerouslySetInnerHTML={{ __html: blog.content }} />
                            </div>
                        </div>

                    </div>

                </section>

                <ProjectForm project_name={`blog: ${blog.title}`} heading="Get in touch" subHeading="Let us help you find your dream house" topSpace={0} phoneAg={'+971505720423'} />

                <section className="container mx-auto ">
                <Title title={"Latest News "} />
                        <div className="grid gap-12 row-gap-5 md:grid-cols-2 lg:grid-cols-4 py-20 sm:py-10 lg:py-12">
                        {
                            blogPosts.slice(0, 4).map((blog, index) => (
                                <div class="inline-block bg-white rounded ">
                                    <div class="flex flex-col h-full">
                                        <div className=" relative">
                                        <img src={blog.thumb_img} class="object-cover rounded-3xl w-full h-44 lg:h-56" alt="blog.title" />
                                        {/* {project.other_payment_plan ? (
                                            <div className="absolute top-2 left-4">
                                            <p className="bg-[#D4B071] px-4 py-2 rounded-full font-bold">{project.other_payment_plan} Payment Plan</p>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        {project.project_type && (
                                            <div className="absolute bottom-14 left-4">
                                            <p className=" bg-white px-4 py-2 rounded-full font-bold">{project.project_type.name}</p>
                                            </div>
                                        )} */}
                                    </div>

                                        <div class="flex-grow border-2 border-[#D4B071] rounded-3xl mx-3 -mt-12 bg-white z-10 relative">
                                        <div class="p-7 text-left flex flex-col justify-center items-center">
                                            <p>{blog.last_updated_date}</p>
                                            {blog.title && <h3 class="mb-2 text-center font-bold text-lg lg:text-xl">{blog.title}</h3>}
                                            <div className="flex flex-wrap items-center justify-between">
                                            {/* <p class="text-sm text-gray-500">Payment Plan</p>
                                <h3 class="mb-2 font-bold leading-5 text-lg md:text-xl">
                                {`${Number(project.on_booking) + Number(project.during_construction)}/${project.on_completion}`}
                                </h3> */}
                                            {/* {project.post_handover ? (
                                                <div>
                                                <p class="text-sm text-gray-500">Post Hand Over</p>
                                                <h3 class="mb-2 font-bold leading-5 text-lg lg:text-xl">{project.post_handover}% <span className="text-sm lg:text-md">in {project.post_handover_period} {project.post_handover_type}</span></h3>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {project.delivery_date && (
                                                <div>
                                                <p class="text-sm text-gray-500">Completion Year</p>
                                                <h3 class="mb-2 font-bold leading-5 text-lg md:text-xl">{project.delivery_date.substring(0, project.delivery_date.indexOf("-"))}</h3>
                                                </div>
                                            )} */}
                                            <Link to={blog.slug} className="w-[60%] text-center font-black absolute left-1/2 -translate-x-1/2 -bottom-4 bg-white px-4 py-px border-2 border-[#D4B071] rounded-full hover:bg-black hover:text-white transition-all duration-2000 ease-in-out">
                                            <p >Read More</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                        {/* <LazyLoadImage src={project.image} alt="" className=' h-full w-full object-cover rounded-lg' style={{ height: '25rem' }} effect='blur'/> */}
                                    </div>

                                    {/* <LazyLoadImage src={project.image} alt="" className=' h-full w-full object-cover rounded-lg' style={{ height: '25rem' }} effect='blur'/> */}
                                    </div>
                            ))
                        }

                        </div>
                </section>
            </div>

            )}

        </Layout>
    )
}

export default BlogDetails