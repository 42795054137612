const url_production = "https://backend.keymaxrealestate.com/api/v1/";
const url_stg = "https://stg.backend.keymaxrealestate.com/api/v1/";
const url_local = "http://127.0.0.1:8000/api/v1/";
export const url = url_stg;

export const recaptcha_key = "6Lf2EzcpAAAAAFKA-oC8IVCVeOHH-Y10_lCuNNaa";
export const recaptcha__secret_key = "6Lf2EzcpAAAAAKVl4aeKuvt21q3VKTj2Rvuy6smz";
export const phonRigex = /[1-9][0-9 \-\(\)\.]{10,11}$/;

// set the token and user from the Local storage
export const setUserSession = (token, name) => {
  localStorage.setItem("KeyMaxToken", token);
  localStorage.setItem("name", name);
};

export const removeUserSession = () => {
  localStorage.clear();
  window.location.href = "/";
};

//  the configs  with  AUTH
export const config = {
  headers: {
    "content-type": "multipart/form-data",
    Accept: "application/json",
    lang: localStorage.getItem("KeyMaxLang")
      ? localStorage.getItem("KeyMaxLang")
      : "en",
    "Accept-Language": localStorage.getItem("KeyMaxLang")
      ? localStorage.getItem("KeyMaxLang")
      : "en",
    Authorization: `Bearer ${localStorage.KeyMaxToken}`,
  },
};

export const chechPhone = (phone) => {
  if (phone == null) return false;

  if (phone.includes("971")) {
    const startNumber = phone[3];
    if (startNumber == "5") {
      if (phone.length == 12) {
        return true;
      } else {
        return false;
      }
    } else {
      if (phone.length == 11) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    if (phonRigex.test(phone)) {
      return true;
    } else {
      return false;
    }
  }
};
