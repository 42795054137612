import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GrLinkedinOption } from "react-icons/gr";
import { BiPhoneCall } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail, AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { BsPinterest, BsFillSuitHeartFill } from "react-icons/bs";
import { FiDollarSign, FiInstagram, FiLinkedin } from "react-icons/fi";
import { FaBusinessTime, FaTiktok, FaFacebookF } from "react-icons/fa";
import { TbCurrencyDirham } from "react-icons/tb";
import logo from "../../img/logo_keymax.png";
import Lang from "../lang/Lang";
import "./ScreenNav.scss";
import { config, removeUserSession, url } from "../../Common/Common";
import Modal from "../Modal/Modal";
import { t } from "i18next";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import axios from "axios";
import FavoritesContext from "../../store/store";
import LangSvgIcons from "../SvgIcons/LangSvgIcons";
// import { WhatsappIcon } from "react-share";

const ScreenNav = (props) => {
  const { HomePage } = props;
  const [modal, setModal] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [servicesDropDown, setServicesDropDown] = useState(false);
  const [companyProfile, setCompanyProfile] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ctx = useContext(FavoritesContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 500);
    });
  }, []);

  const handleLogout = () => {
    removeUserSession();
  };

  const ChangeCurrancy = () => {
    window.location.reload();
    if (localStorage.price == "USD") {
      localStorage.setItem("price", "AED");
    } else {
      localStorage.setItem("price", "USD");
    }
  };

  useEffect(() => {
    axios
      .get(url + "company-profile", config)
      .then(function (response) {
        // handle success
        setCompanyProfile(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const [stickyNav, setStickyNav] = useState(false);

  const handleScroll = () => {
    window.pageYOffset >= 150 ? setStickyNav(true) : setStickyNav(false);
    window.pageYOffset <= 150 ? setStickyNav(false) : setStickyNav(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.addEventListener("scroll", handleScroll);
  });

  // const textColorClass = stickyNav ? "text-" : "text-";
  const textColorClass = HomePage ? (stickyNav ? "text-black" : "text-white") : stickyNav ? "text-black" : "text-black";
  const borderColor = HomePage ? (stickyNav ? "border-black" : "border-white") : stickyNav ? "border-black" : "border-black";
  return (
    <>
      {modal && (
        <>
          <Modal title={t(" Take Appontment")} btnText={t("Send Message")} closeModal={() => setModal(false)} />
        </>
      )}
      <nav>
        <div className="bg-black z-50 relative">
          <div className="container mx-auto flex w-full">
            <div className="flex flex-row justify-end items-center py-2 space-x-5 rtl:space-x-reverse w-full">
              <a href="tel:971505720423" target="_blank" rel="noreferrer" className="flex justify-center items-center text-white items-center text-base ">
                <BiPhoneCall className="rtl:ml-2 ltr:mr-2 " /> <span className="ltr"> +971 50 572 0423 </span>{" "}
              </a>
              <a href="https://api.whatsapp.com/send?phone=971505720423" target="_blank" rel="noreferrer" className="flex justify-center items-center text-white items-center text-base ">
                <BsWhatsapp className="rtl:ml-2 ltr:mr-2 " /> <span className="ltr">whatsApp</span>{" "}
              </a>
            </div>
          </div>
        </div>
        <div
          className={`${textColorClass} ${HomePage ? "bg-white/[.14] backdrop-blur-sm" : "bg-white shadow-lg"} ${
            stickyNav ? "activeNav top-5 bg-white shadow text-white " : ""
          }  flex items-center justify-between absolute w-full z-50 transition-all transition duration-200`}
        >
          <div class={`container mx-auto`}>
            <div class="relative flex items-center justify-between py-2 ">
              <div class="flex items-center w-10/12">
                <Link to="/" aria-label="Keymax Real Estate" title="Keymax Real Estate" class="inline-flex items-center ltr:mr-6 rtl:ml-6">
                  <img src={logo} alt="" className="w-40" />
                </Link>
                <div className="w-full flex flex-col justify-center items-start">
                  <p className={`${textColorClass} transition-colors duration-200 capitalize font-medium py-2 text-sm xl:text-base hover:cursor-pointer`} onClick={() => setModal(true)}>
                    Book your free consultation
                  </p>
                  <ul class={`flex items-center hidden space-x-4 lg:space-x-8 rtl:space-x-reverse py-2 lg:flex w-full border-t transition duration-200 text-sm xl:text-base font-medium ${borderColor}`}>
                    <li>
                      <Link to="/property-for-sale-in-dubai" aria-label="property for sale in dubai" title="property for sale in dubai" class={`tracking-wide ${textColorClass} transition-colors duration-200 hover:opacity-60`}>
                        {t("Buy")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/property-for-rent-in-dubai" aria-label="property for rent in dubai" title="property for rent in dubai" class={`tracking-wide ${textColorClass} transition-colors duration-200 hover:opacity-60`}>
                        {t("Rent")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/off-plan-properties-dubai" aria-label="Off Plan Properties Dubai" title="Off Plan Properties Dubai" class={`tracking-wide ${textColorClass} transition-colors duration-200 hover:opacity-60`}>
                        {t("Off Plan")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/projects" aria-lapel="Projects" title="Projects" class={`tracking-wide ${textColorClass} transition-colors duration-200 hover:opacity-60`}>
                        {t("Projects")}
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/list-your-property-in-dubai" aria-label="List your Property in Dubai" title="List your Property in Dubai" class={`tracking-wide ${textColorClass} transition-colors duration-200 hover:opacity-60`}>
                        {t("List your Property")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/area" aria-label="Areas in Dubai" title="Areas in Dubai" class={`tracking-wide ${textColorClass} transition-colors duration-200 hover:opacity-60`}>
                        {t("Areas")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/developers" aria-label="Developers in Dubai" title="Developers in Dubai" class={`tracking-wide ${textColorClass} transition-colors duration-200 hover:opacity-60`}>
                        {t("Developers")}
                      </Link>
                    </li>

                    <div className="relative">
                      <button
                        className={`flex items-center gap-1 ${textColorClass}`}
                        onClick={() => {
                          setDropDown(false);
                          setServicesDropDown(!servicesDropDown);
                        }}
                      >
                        {t("Services")}

                        {servicesDropDown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                      </button>
                      <div
                        className={`absolute top-full rtl:left-0 ltr:right-0 z-10 ltr:-mr-36 rtl:-ml-36 w-64 bg-white rounded divide-y divide-gray-100 shadow ${servicesDropDown ? "flex" : "hidden"} `}
                        onMouseLeave={() => {
                          setServicesDropDown(false);
                        }}
                      >
                        <ul className={`w-full text-sm max-h-[14rem] overflow-y-auto mt-4 text-black`}>
                          <li className="hover:bg-opacity-10 hover:bg-black" onClick={() => setServicesDropDown(false)}>
                            <Link to="/property-management-dubai" className="py-2 px-2 flex items-center md:text-sm xl:text-lg">
                              {t("Property Management")}{" "}
                            </Link>
                          </li>
                          <li className="hover:bg-opacity-10 hover:bg-black" onClick={() => setServicesDropDown(false)}>
                            <Link to="/sell-property-in-dubai" className="py-2 px-2 flex items-center md:text-sm xl:text-lg">
                              {t("Sell with Us")}{" "}
                            </Link>
                          </li>
                          {/* <li className='hover:bg-opacity-10 hover:bg-black' onClick={() => setServicesDropDown(false)}>
                                                      <Link to='/SingleService/Property Valuation' className='py-2 px-2 flex items-center md:text-sm xl:text-lg'>{t('Property Valuation')} </Link>
                                                 </li>
                                                 <li className='hover:bg-opacity-10 hover:bg-black' onClick={() => setServicesDropDown(false)}>
                                                      <Link to='/SingleService/Property Investment' className='py-2 px-2 flex items-center md:text-sm xl:text-lg'>{t('Property Investment')} </Link>
                                                 </li> */}
                          <li className="hover:bg-opacity-10 hover:bg-black" onClick={() => setServicesDropDown(false)}>
                            <Link to="/renting-property-in-dubai" className="py-2 px-2 flex items-center md:text-sm xl:text-lg">
                              {t("Rent with Us")}{" "}
                            </Link>
                          </li>
                          <li className="hover:bg-opacity-10 hover:bg-black" onClick={() => setServicesDropDown(false)}>
                            <Link to="/buying-property-dubai" className="py-2 px-2 flex items-center md:text-sm xl:text-lg">
                              {t("Buy with Us")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <li className="relative">
                      <button
                        aria-label="Others"
                        title="Others"
                        class={`flex flex-row items-center gap-x-2 tracking-wide ${textColorClass} transition-colors duration-200 hover:opacity-60`}
                        onClick={() => {
                          setServicesDropDown(false);
                          setDropDown(!dropDown);
                        }}
                      >
                        {t("Others")}
                        {dropDown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                      </button>
                      <div
                        className={`absolute top-8 rtl:left-0 ltr:right-0 z-10 ltr:-mr-40 rtl:-ml-48 w-64 bg-white rounded divide-y divide-gray-100 shadow ${dropDown ? "flex" : "hidden"} `}
                        onMouseLeave={() => {
                          setIsOpen(false);
                          setDropDown(false);
                        }}
                      >
                        <ul className="w-full py-1 text-sm text-black">
                          <li className="hover:bg-opacity-10 hover:bg-black">
                            <a href={companyProfile.data} target="_blank" rel="noreferrer" download className="py-2 px-4  flex items-center md:text-sm xl:text-lg">
                              {t("Download Profile Company")}{" "}
                            </a>
                          </li>
                          <li className="hover:bg-opacity-10 hover:bg-black">
                            <Link to="/about" className="py-2 px-4  flex items-center md:text-sm xl:text-lg">
                              {t("About Us")}{" "}
                            </Link>
                          </li>
                          <li className="hover:bg-opacity-10 hover:bg-black">
                            <Link to="/meet-the-team" className="py-2 px-4  flex items-center md:text-sm xl:text-lg">
                              {t("Meet the Team")}{" "}
                            </Link>
                          </li>
                          <li className="relative overflow-hidden">
                            <button className="py-2 px-4 flex w-full items-center md:text-sm xl:text-lg justify-between hover:bg-opacity-10 hover:bg-black" onClick={() => setIsOpen(!isOpen)}>
                              {" "}
                              {t("Guides")}
                              {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                            </button>
                            <ul className={`opacity-75 relative overflow-hidden ${isOpen ? "h-auto" : "h-0"}`}>
                              <li className="hover:bg-opacity-10 hover:bg-black">
                                <Link to="/buying-off-plan-guide" className="py-2 ltr:pl-6 rtl:pr-6 flex items-center md:text-[1rem]">
                                  {t("Buying Off Plan Guide")}{" "}
                                </Link>
                              </li>
                              <li className="hover:bg-opacity-10 hover:bg-black">
                                <Link to="/selling-guide" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                                  {t("Selling Guide")}{" "}
                                </Link>
                              </li>
                              <li className="hover:bg-opacity-10 hover:bg-black">
                                <Link to="/buying-guide" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                                  {t("Buying Guide")}{" "}
                                </Link>
                              </li>
                              <li className="hover:bg-opacity-10 hover:bg-black">
                                <Link to="/renting-guide" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                                  {t("Renting Guide")}{" "}
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="hover:bg-opacity-10 hover:bg-black">
                            <Link to="/faqs" className="py-2 px-4  flex items-center md:text-sm xl:text-lg">
                              {t(`FAQ's`)}{" "}
                            </Link>
                          </li>
                          <li className="hover:bg-opacity-10 hover:bg-black">
                          <Link to="/blogs" className="py-2 px-4  flex items-center md:text-sm xl:text-lg">
                            {t("Blog")}{" "}
                          </Link>
                          </li>
                          <li className="hover:bg-opacity-10 hover:bg-black">
                            <Link to="/careers" className="py-2 px-4  flex items-center md:text-sm xl:text-lg">
                              {t("Careers")}{" "}
                            </Link>
                          </li>
                          <li className="hover:bg-opacity-10 hover:bg-black">
                            <Link to="/contact" className="py-2 px-4  flex items-center md:text-sm xl:text-lg">
                              {t("Contact Us")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <ul class={`flex items-center justify-end hidden ${!localStorage.KeyMaxToken ? "space-x-4" : "space-x-2"} rtl:space-x-reverse lg:flex w-2/12 h-full`}>
                <li className="relative langBtns h-full">
                  <div className="w-full h-full ltr:ml-2 rtl:mr-2">
                    <LangSvgIcons HomePage={HomePage} stickyNav={stickyNav} />
                  </div>
                  <div className="btn z-10 absolute top-0  opacity-0 h-full w-full flex flex-col items-center justify-center mx-2">
                    <Lang HomePage={HomePage} stickyNav={stickyNav} />
                  </div>
                </li>

                {!localStorage.KeyMaxToken ? (
                  <div className="relative authBtns h-full w-full">
                    <Link to="/login" className={`flex h-full w-full z-30 relative items-center justify-center ${borderColor} ${textColorClass} border rounded-full font-medium px-3 xl:px-5 py-2 rounded-lg`}>
                      {" "}
                      {t("Login")}{" "}
                    </Link>
                    <Link
                      to="/sign-up"
                      className={`btn z-10 absolute top-0  opacity-0 mr-2 w-full flex items-center justify-center mx-2  ${
                        HomePage ? (stickyNav ? "bg-white shadow-lg" : "bg-white/[.14] backdrop-blur-sm") : "bg-white shadow-lg"
                      } font-medium px-2 py-2 rounded-full`}
                    >
                      {" "}
                      {t("Sign up")}{" "}
                    </Link>
                  </div>
                ) : (
                  <div className="relative authBtns cursor-pointer">
                    <p className={`font-medium ${textColorClass} transition duration-200   text-center w-full z-30 mx-2 md:text-sm xl:text-lg overflow-hidden`}>{localStorage.name}</p>
                    <button
                      onClick={handleLogout}
                      className={`btn z-10 absolute top-2  opacity-0 w-full flex items-center justify-center mx-2  ${stickyNav ? "text-black border-black  bg-white" : ""} ${
                        stickyNav ? "" : "text-white bg-white/[.14] backdrop-blur-sm border-white"
                      } font-medium px-2 py-2 rounded-full`}
                    >
                      {" "}
                      {t("Log out ")}{" "}
                    </button>
                  </div>
                )}
              </ul>
              <div class="lg:hidden">
                <button aria-label="Open Menu" title="Open Menu" class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50" onClick={() => setIsMenuOpen(true)}>
                  <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z" />
                    <path fill="currentColor" d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z" />
                    <path fill="currentColor" d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z" />
                  </svg>
                </button>
                {isMenuOpen && (
                  <div class="absolute top-0 left-0 w-full">
                    <div class="p-5 bg-white border rounded shadow-sm">
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default ScreenNav;
