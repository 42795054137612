import { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import "../Style/Auth.scss";
import axios from "axios";
import { setUserSession, url } from "../Common/Common";
// import SocialMedia from '../Components/SocialMedia/SocialMedia'
import ErrorAlert from "../Components/UI/ErrorAlert";
import SuccessAlert from "../Components/UI/SuccessAlert";
import bgImage from "../img/Hero.webp";
import { t } from "i18next";

const Login = () => {
  const inputRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [forgetEmail, setForgetEmail] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showResetForm, setShowResetForm] = useState(false);
  const [applyPass, setApplyPass] = useState("");
  const [number1, setnumber1] = useState("");
  const [number2, setnumber2] = useState("");
  const [number3, setnumber3] = useState("");
  const [number4, setnumber4] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const opt = [number1, number2, number3, number4];
  // const otpString = opt.join('')
  const navigate = useNavigate();
  const [otpString, setotpString] = useState("");

  useEffect(() => {
    // inputRef.current.focus()
  }, []);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const Login = () => {
    if (email.trim() == " " || email.trim().length < 6 || pass.trim() == " " || pass.trim().length < 6) {
      setError(`Please check your data !! `);
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    axios
      .post(url + "auth/login", {
        email: email,
        password: pass,
      })
      .then((response) => {
        setEmail("");
        setPass("");
        setUserSession(response.data.data.token, response.data.data.user.name);
        navigate(-1);
        setTimeout(() => {
          window.location.reload(true);
        }, 500);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const ResetPassword = () => {
    axios
      .post(url + "auth/reset-password", {
        identity: forgetEmail,
        otp: otpString,
        password: pass,
        confirm_password: applyPass,
      })
      .then((response) => {
        setApplyPass("");
        setPass("");
        setnumber1("");
        setnumber2("");
        setnumber3("");
        setnumber4("");
        setShowLoginForm(false);
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data);
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const forgetPasswordHandler = () => {
    axios
      .post(url + "auth/forgot-password", {
        identity: forgetEmail,
      })
      .then((response) => {
        setShowResetForm(true);
        setShowLoginForm(false);
        setForgetPassword(false);
        ref_input1.current.focus();
        setSuccess(response.data.message);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
      })
      .catch((error) => {
        console.log(error.response);
        setError(error.response.data.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const ref_input1 = useRef();
  const ref_input2 = useRef();
  const ref_input3 = useRef();
  const ref_input4 = useRef();
  const ref_input5 = useRef();
  const bgStyles = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "80vh",
  };

  return (
    <Layout>
      <div style={bgStyles} className={`SignUp bg-no-repeat bg-coverauth flex items-center justify-center px-6 md:px-0 mt-12 md:mt-24 -mb-16 md:-mb-24`}>
        <div className="container mx-auto">
          <div className="row flex items-center justify-center text-center">
            <div className="w-full sm:w-1/2 md:w-1/3 ">
              <div className=" flex justify-center items-center gap-2 pb-3 md:pb-8  text-2xl text-white md:text-4xl font-black">
                {t(`Asalam, hello!`)} <br />
                {t(`Welcome back!`)}
              </div>
              {success && <SuccessAlert success={success} />}
              {error && <ErrorAlert error={error} />}
              {showLoginForm ? (
                <form>
                  {/* <div className="signSocialMedia pb-8 border-mainColor">
                    <button className="w-full p-4 mb-2 bg-white rounded-lg flex items-center text-mainColor justify-center border border-mainColor">
                      {" "}
                      <FcGoogle className="text-2xl mx-2" /> Log in with Google{" "}
                    </button>
                    <SocialMedia />
                    <button className="w-full p-4 mb-0 bg-white rounded-lg flex items-center text-mainColor justify-center border border-mainColor">
                      <FaFacebookF className="text-2xl mx-2 text-blue" /> Log in with Facebook{" "}
                    </button>
                  </div> */}
                  {/* <p className="or relative">
                    <span className="relative z-10 px-4 bg-white">{t("OR")}</span>
                  </p> */}

                  <div className="mt-6">
                    <input
                      required
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      name="email"
                      autoComplete="true"
                      className="w-full p-4 text-mainColor bg-white rounded-full opacity-70 placeholder:text-black"
                      placeholder={t("Address Email")}
                    />
                  </div>
                  <div className="mt-6 relative">
                    <input
                      onCut={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      required
                      onChange={(e) => setPass(e.target.value)}
                      value={pass}
                      type={showPassword ? "text" : "password"}
                      maxLength="20"
                      autoComplete="true"
                      className="w-full p-4 text-mainColor bg-white rounded-full opacity-70 placeholder:text-black"
                      placeholder={t("Password")}
                    />
                    <span className="absolute ltr:right-2 ltr:left-auto rtl:right-auto rtl:left-2 top-1/2 -translate-y-1/2 cursor-pointer" onClick={handleShowPassword}>
                      {showPassword ? <AiOutlineEye className="text-2xl text-mainColor" /> : <AiOutlineEyeInvisible className="text-2xl" />}
                    </span>
                  </div>
                  <button
                    className="text-white font-bold text-md md:text-lg text-center mt-2 w-full block"
                    onClick={() => {
                      setShowLoginForm(false);
                      setForgetPassword(true);
                    }}
                  >
                    {t("Forget Password?")}
                  </button>
                  <div className="mt-6">
                    <button onClick={Login} className=" bg-white px-12 py-2 my-5 text-lg font-bold md:text-xl rounded-full text-center cursor-pointer bg-whtie border-4 border-[#D4B071] text-black" type="button">
                      {" "}
                      {t("Log in")}{" "}
                    </button>
                    <br />
                    <br />
                  </div>
                  <Link to="/sign-up" className="text-white font-bold text-md md:text-lg text-center mt-2 w-full block">
                    {" "}
                    . . . . {t("Sign Up")} . . . .{" "}
                  </Link>
                </form>
              ) : null}
              {forgetPassword ? (
                <form>
                  <div className="mt-6">
                    <input
                      onChange={(e) => setForgetEmail(e.target.value)}
                      value={forgetEmail}
                      ref={inputRef}
                      type="email"
                      name="email"
                      autoComplete="true"
                      className="w-full p-4 text-mainColor bg-white border border-mainColor  rounded-md "
                      placeholder={t("Address Email")}
                    />
                  </div>
                  <div className="mt-6">
                    <button onClick={forgetPasswordHandler} className="w-full p-4 text-xl rounded-lg text-center cursor-pointer bg-mainColor text-white" type="button">
                      {" "}
                      {t("Send")}{" "}
                    </button>
                  </div>
                  <button
                    to="/Login"
                    className="text-mainColor font-bold text-center mt-2 w-full block"
                    onClick={() => {
                      setShowLoginForm(true);
                      setForgetPassword(false);
                    }}
                  >
                    {" "}
                    . . . . {t("Back To Login")} . . . .{" "}
                  </button>
                </form>
              ) : null}

              {showResetForm ? (
                <form>
                  {/* <div className="mt-6 relative flex items-center justify-between gap-4 text-center" >
                      <input type="text" ref={ref_input1} onChange={(event) => { setnumber1(event.target.value); ref_input2.current.focus() }} length='1' maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 text-mainColor border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                      <input type="text" ref={ref_input2} onChange={(event) => { setnumber2(event.target.value); ref_input3.current.focus() }} length='1' maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 text-mainColor border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                      <input type="text" ref={ref_input3} onChange={(event) => { setnumber3(event.target.value); ref_input4.current.focus() }} length='1' maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 text-mainColor border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                      <input type="text" ref={ref_input4} onChange={(event) => { setnumber4(event.target.value); ref_input5.current.focus() }} length='1' maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 text-mainColor border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                    </div> */}
                  <div className="mt-6 relative">
                    <input
                      onChange={(e) => setotpString(e.target.value)}
                      value={otpString}
                      type="text"
                      maxLength="4"
                      autoComplete="true"
                      className="w-full p-4 text-mainColor bg-white border-mainColor border  rounded-md "
                      placeholder={t("OTP")}
                    />
                  </div>
                  <div className="mt-6 relative">
                    <input
                      ref={ref_input5}
                      onChange={(e) => setPass(e.target.value)}
                      value={pass}
                      type={showPassword ? "text" : "password"}
                      maxLength="20"
                      autoComplete="true"
                      className="w-full p-4 text-mainColor bg-white border-mainColor border  rounded-md "
                      placeholder={t("Password")}
                    />
                    <span className="absolute ltr:right-2 ltr:left-auto rtl:right-auto rtl:left-2 top-1/2 -translate-y-1/2 cursor-pointer" onClick={handleShowPassword}>
                      {showPassword ? <AiOutlineEye className="text-2xl text-mainColor" /> : <AiOutlineEyeInvisible className="text-2xl" />}
                    </span>
                  </div>
                  <div className="mt-6 relative">
                    <input
                      onChange={(e) => setApplyPass(e.target.value)}
                      value={applyPass}
                      type={showPassword ? "text" : "password"}
                      maxLength="20"
                      autoComplete="true"
                      className="w-full p-4 text-mainColor bg-white border-mainColor border  rounded-md "
                      placeholder={t("Apply Password")}
                    />
                    <span className="absolute ltr:right-2 ltr:left-auto rtl:right-auto rtl:left-2 top-1/2 -translate-y-1/2 cursor-pointer" onClick={handleShowPassword}>
                      {showPassword ? <AiOutlineEye className="text-2xl text-mainColor" /> : <AiOutlineEyeInvisible className="text-2xl" />}
                    </span>
                    {/* <span className='flex text-right items-center justify-end w-full text-xs'>{t('At least 8 characters, 1 uppercase letter, 1 number, 1 symbol')}</span> */}
                  </div>
                  <div className="mt-6">
                    <button onClick={ResetPassword} className="w-full p-4 text-xl rounded-lg text-center cursor-pointer bg-mainColor text-white" type="button">
                      {" "}
                      {t("Reset Password")}{" "}
                    </button>
                  </div>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
