import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { config, url } from '../Common/Common';
import Layout from '../Components/Layout'
import { BsCheck } from 'react-icons/bs'
import Loading from '../Components/UI/Loading';
import { t } from 'i18next';
const Services = () => {
          const [service, setService] = useState()
          const [isLoading, setLoading] = useState(true)

          useEffect(() => {
                    setLoading(true)
                    axios.get(url + 'services', config)
                              .then(function (response) {
                                        // handle success
                                        setService(response.data.data);
                                        setLoading(false)
                              })
                              .catch(function (error) {
                                        // handle error
                                        console.log(error);
                              })
                              .then(function () {
                                        // always executed
                              });
          }, [])

          return (
                    <Layout>

                              <div className="container mx-auto">
                              <h3 className='font-bold text-2xl text-center md:text-4xl w-full mt-8 text-mainColor '>  <span className='text-[#CDAC62]'> {t('Services')} </span> </h3>

                                        {isLoading ?
                                                  <div className='absolute top-0 left-0 h-screen w-screen bg-white z-40 flex items-center justify-center'><Loading /></div>
                                                  :
                                                  <>
                                                            {
                                                                      service.map((item, index) => (
                                                                                <div key={index} className="serviceCard flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center">
                                                                                          <div className="flex items-center justify-center w-full h-96 lg:w-1/2 md:p-4">
                                                                                                    <img className="object-cover w-full h-full max-w-2xl rounded-md ml-0 mr-auto" src={item.img_url} alt="glasses " />
                                                                                          </div>
                                                                                          <div className="w-full lg:w-1/2">
                                                                                                    <h1 className="text-2xl font-medium tracking-wide text-gray-800 lg:text-3xl">{item.name}</h1>
                                                                                                    <div style={{ overflowWrap: 'anywhere' }} dangerouslySetInnerHTML={{ __html: item.content }} />

                                                                                          </div>


                                                                                </div>
                                                                      ))
                                                            }
                                                  </>
                                        }
                              </div>



                    </Layout>
          )
}

export default Services