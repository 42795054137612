import React from "react";
import "./Footer.scss";
import logo from "../../img/white-logo.png";
import { Link } from "react-router-dom";
import { GoLocation } from "react-icons/go";
import { BiPhoneCall } from "react-icons/bi";
import Lottie from "react-lottie";
import whatsapp from "../Lottie/whatsapp.json";
import { t } from "i18next";
import { BsPinterest, BsSnapchat } from "react-icons/bs";
import { AiOutlineMail, AiFillInstagram, AiFillYoutube } from "react-icons/ai";
import { FiTwitter, FiLinkedin } from "react-icons/fi";
import { FaFacebookF, FaTiktok } from "react-icons/fa";
// import TownhouseSvgIcons from '../SvgIcons/TownhouseSvgIcons'
// import ApartmentSvgIcons from '../SvgIcons/ApartmentSvgIcons'
// import VillaSvgIcons from '../SvgIcons/VillaSvgIcons'
// import OfficeSvgIcons from '../SvgIcons/OfficeSvgIcons'
import Support from "../Sections/Support";

import { GrLinkedin } from "react-icons/gr";
import { useEffect } from "react";
const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: whatsapp,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <footer className="mt-16 pt-16 bg-black text-white">
      {/* <div className="container mx-auto">
                    <Support />
               </div> */}
      <section className="container mx-auto py-16">
        <div className="px-4 mx-auto lg:px-8">
          <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
            <div className="md:max-w-md lg:col-span-2">
              <Link to="/" aria-label="Go home" title="Keymax Real Estate" className="inline-flex items-center">
                <img src={logo} alt="" className="mx-auto my-4" width="150" />

                {/* <svg
                              className="w-8 text-deep-purple-accent-400"
                              viewBox="0 0 24 24"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeMiterlimit="10"
                              stroke="currentColor"
                              fill="none"
                              >
                              <rect x="3" y="1" width="7" height="12" />
                              <rect x="3" y="17" width="7" height="6" />
                              <rect x="14" y="1" width="7" height="6" />
                              <rect x="14" y="11" width="7" height="12" />
                              </svg> */}
              </Link>
              <div className="mt-4 lg:max-w-sm">
                <p className="footerDetails text-md font-bold text-gray-800">
                  <a
                    href="https://www.google.com/maps/place/Key+Max+Real+Estate+Brokers/@25.1941874,55.2609457,15z/data=!4m5!3m4!1s0x0:0x7197e1467d539d59!8m2!3d25.1941874!4d55.2609457"
                    className="flex items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {/* <GoLocation className='rtl:ml-2 ltr:mr-2' />   */}
                    {t("Office 107, \n Sheikh Zayed Rd, Rawdat Al Wasl Building, Dubai, UAE")}
                  </a>
                  <a href="tel:97146052400 " className="flex items-center" target="_blank" rel="noreferrer">
                    {/* <BiPhoneCall className='rtl:ml-2 ltr:mr-2' />  */}
                    <span className="ltr"> +971 4 605 2400 </span>{" "}
                  </a>
                  <a href="mailto:info@keymaxrealestate.com" className="flex items-center" target="_blank" rel="noreferrer">
                    {/* <AiOutlineMail className='rtl:ml-2 ltr:mr-2' /> */}
                    info@keymaxrealestate.com
                  </a>
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4">
              <div>
                <ul className="mt-2 space-y-2">
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:white/80" to="/SubProperties/category[]=1">
                      {t("Apartment")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:white/80" to="/SubProperties/category[]=5">
                      {" "}
                      {t("Villas")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:white/80" to="/SubProperties/category[]=7">
                      {" "}
                      {t("Office")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:white/80" to="/SubProperties/category[]=2">
                      {" "}
                      {t("Commercial full building")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                {/* <p className="font-semibold tracking-wide text-gray-800">
                              Business
                              </p> */}
                <ul className="mt-2 space-y-2">
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/property-for-sale-in-dubai" aria-label="property for sale in dubai" title="property for sale in dubai">
                      {t("Buy")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/property-for-rent-in-dubai">
                      {t("Rent")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/off-plan-properties-dubai">
                      {t("Off Plan")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/Projects">
                      {t("Projects")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/Developers">
                      {t("Developers")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/Area">
                      {t("Areas")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                {/* <p className="font-semibold tracking-wide text-gray-800">Apples</p> */}
                <ul className="mt-2 space-y-2">
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/About">
                      {t("About Us")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/faqs">
                      {t("FAQs")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/Contact">
                      {t("Contact Us")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/Careers">
                      {t("Careers")}
                    </Link>
                  </li>
                  <li>
                    <Link className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400" to="/privacy-policy">
                      {t("privacy policy")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <p className="font-semibold tracking-wide text-gray-800">Connect with us</p>
                <ul className="mt-2 space-y-2">
                  <li>
                    <a className="flex items-center" href="https://www.linkedin.com/company/keymax-realestate/" target="_blank" rel="noreferrer">
                      <GrLinkedin className="rtl:ml-2 ltr:mr-2 rounded-sm" /> LinkedIn
                    </a>
                  </li>
                  <li>
                    <a className="flex items-center" href="https://www.instagram.com/keymaxrealestate/" target="_blank" rel="noreferrer">
                      <AiFillInstagram className="rtl:ml-2 ltr:mr-2" /> Instagram
                    </a>
                  </li>
                  <li>
                    <a className="flex items-center" href="https://www.facebook.com/keymaxrealestatedubai" target="_blank" rel="noreferrer">
                      <FaFacebookF className=" rtl:ml-2 ltr:mr-2" /> Facebook
                    </a>
                  </li>
                  <li>
                    <a className="flex items-center " href="https://www.youtube.com/channel/UCSvQVypbBeQ18yegnWJPYWQ" target="_blank" rel="noreferrer">
                      <AiFillYoutube className="rtl:ml-2 ltr:mr-2" /> Youtube
                    </a>
                  </li>
                  <li>
                    <a className="flex items-center" href="https://twitter.com/Keymaxdubai" target="_blank" rel="noreferrer">
                      <FiTwitter className="rtl:ml-2 ltr:mr-2" /> Twitter
                    </a>
                  </li>
                  <li>
                    <a className="flex items-center" href="https://www.tiktok.com/@keymaxrealestate" target="_blank" rel="noreferrer">
                      <FaTiktok className="rtl:ml-2 ltr:mr-2" /> TikTok
                    </a>
                  </li>
                  {/* <li>
                    <a className="flex items-center" href="https://twitter.com/KeymaxRE" target="_blank" rel="noreferrer">
                      <BsSnapchat className="rtl:ml-2 ltr:mr-2" /> Snapchat
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between pt-5 pb-10 border-t sm:flex-row">
            <p className="text-sm text-gray-600">We manage your expectations.</p>
            <p className="text-sm text-gray-600">All Rights Reserved 2023</p>
          </div>
        </div>
      </section>

      {/* <section className='glassCard py-16'>
                    <div className="container mx-auto">
                         <div className='row flex items-center justify-center flex-wrap w-full'>
                              <div className="w-full lg:w-3/12">
                                   <Link to='/'>
                                        <img src={logo} alt="" className='mx-auto my-4' width='250' />
                                   </Link>
                              </div>
                              <div className="w-full lg:w-9/12">
                                   <div className="row flex item-start justify-between flex-wrap md:gap-4 border-b border-white pb-4 mx-4 xl:mx-8">
                                        <ul className='w-1/2 mb-6 md:mb-0 md:w-auto text-xl text-white leading-9'>
                                             <li>
                                                  <Link to='/SubProperties/1'>{t('Apartment')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/SubProperties/5'> {t('Villas')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/SubProperties/7'> {t('Office')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/SubProperties/2'> {t('Commercial full building')}</Link>
                                             </li>
                                        </ul>
                                        <ul className='w-1/2 mb-6 md:mb-0 md:w-auto text-xl text-white leading-9'>
                                             <li>
                                                  <Link to='/Rent'>{t('Rent')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/Buy'>{t('Buy')} </Link>
                                             </li>
                                             <li>
                                                  <Link to='/Developers'>{t('Developers')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/Projects'>{t('Projects')}</Link>
                                             </li>
                                        </ul>
                                        <ul className='w-1/2 mb-6 md:mb-0 md:w-auto  text-xl text-white leading-9'>
                                             <li>
                                                  <Link to='/About'>{t('About Us')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/faqs'>{t('FAQs')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/Contact'>{t('Contact Us')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/Careers'>{t('Careers')}</Link>
                                             </li>
                                             <li>
                                                  <Link to='/privacy-policy'>{t('privacy policy')}</Link>
                                             </li>

                                        </ul>
                                        <ul className='w-1/2 mb-6 md:mb-0 md:w-auto  text-xl text-white leading-9 capitalize'>
                                             <a className='flex items-center' href="https://www.facebook.com/keymaxrealestatedubai" target='_blank' rel="noreferrer"><FaFacebookF className='text-2xl rtl:ml-2 ltr:mr-2' /> facebook</a>
                                             <a className='flex items-center' href="https://www.instagram.com/keymax.realestate/" target='_blank' rel="noreferrer"><AiFillInstagram className='rtl:ml-2 ltr:mr-2' /> Instagram</a>
                                             <a className='flex items-center' href="https://twitter.com/KeymaxRE" target='_blank' rel="noreferrer"><FiTwitter className='rtl:ml-2 ltr:mr-2' /> Twitter</a>
                                             <a className='flex items-center' href="https://www.linkedin.com/company/company/28702313/" target='_blank' rel="noreferrer"><GrLinkedinOption className='rtl:ml-2 ltr:mr-2' /> LinkedIn</a>
                                             <a className='flex items-center ' href='https://www.tiktok.com/@keymaxrealestate' target='_blank' rel="noreferrer"><FaTiktok className='rtl:ml-2 ltr:mr-2' /> tiktok </a>
                                             <a className='flex items-center ' href='https://www.linkedin.com/company/keymaxrealestatedubai/' target='_blank' rel="noreferrer"><FiLinkedin className='rtl:ml-2 ltr:mr-2' /> LinkedIn</a>
                                             <a className='flex items-center ' href='https://www.youtube.com/channel/UCSvQVypbBeQ18yegnWJPYWQ' target='_blank' rel="noreferrer"><AiFillYoutube className='rtl:ml-2 ltr:mr-2' /> Youtube</a>
                                             <a className='flex items-center ' href='https://www.pinterest.com/keymaxrealestate/' target='_blank' rel="noreferrer"><BsPinterest className='rtl:ml-2 ltr:mr-2' /> Pinterest</a>

                                        </ul>

                                   </div>
                                   <ul className='text-md md:text-xl text-white leading-9 flex item-start justify-between flex-wrap gap-4 mx-4 py-4 xl:mx-8'>
                                        <a href="https://www.google.com/maps/place/Key+Max+Real+Estate+Brokers/@25.1941874,55.2609457,15z/data=!4m5!3m4!1s0x0:0x7197e1467d539d59!8m2!3d25.1941874!4d55.2609457" className='flex items-center' target='_blank' rel="noreferrer"><GoLocation className='rtl:ml-2 ltr:mr-2' />  {t('Office 107 - Sheikh Zayed Rd - Rawdat Al Wasl - Dubai')}</a>
                                        <div className="flex flex-col gap-2">
                                             <a href='tel:97143330833 ' className='flex items-center'><BiPhoneCall className='rtl:ml-2 ltr:mr-2' /> <span className="ltr"> +97143330833 </span> </a>
                                             <a href='tel:97146052400 ' className='flex items-center' target='_blank' rel="noreferrer"><BiPhoneCall className='rtl:ml-2 ltr:mr-2' /> <span className="ltr"> +971 4 605 2400 </span> </a>
                                        </div>
                                        <a href='mailto:info@keymaxrealestate.com' className='flex items-center' target='_blank' rel="noreferrer"><AiOutlineMail className='rtl:ml-2 ltr:mr-2' /> info@keymaxrealestate.com</a>
                                   </ul>
                              </div>
                              <div className="w-full lg:w-3/12">
                                   <iframe className='rounded-xl' title='Google map' width="100%" height="220" id="gmap_canvas" src="https://maps.google.com/maps?q=key%20max&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                              </div>
                         </div>
                    </div>

               </section> */}
      {/* <div className="text-center bg-white copyRight p-4 mt-8">
                    <p className="text-sm text-gray-900">©  {t('All rights reserved')} <b> {t('KeyMax')} </ b> </p>
               </div> */}
      <a href="https://bit.ly/keymax_whatsapp" target="_blank" rel="noreferrer" className="whatsapp fixed bottom-2 right-2">
        <Lottie options={defaultOptions} height={100} width={100} />
      </a>
    </footer>
  );
};

export default Footer;
