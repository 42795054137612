import { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Filter from "../Components/Sections/Filter";
// import WidthCard from "../Components/Sections/WidthCard";
import Card from "../Components/UI/Card";
import axios from "axios";
import { config, url } from "../Common/Common";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { HiOutlineAdjustments, HiOutlineLocationMarker } from "react-icons/hi";
import { BsWhatsapp } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { HiLocationMarker } from "react-icons/hi";
import map from "../img/map.png";
import List from "../Components/UI/List";
import Loading from "../Components/UI/Loading";
import Nothing from "../Components/UI/Nothing";
import { Link, useLocation } from "react-router-dom";
import FilterMobile from "../Components/Sections/FilterMobile";
import { t } from "i18next";
import { Helmet } from "react-helmet-async";
// import CEO from "../img/CEO.webp";
const BuyType = ({ apartments, villa, hotelApartment, townhouse, title, meta }) => {
  const [rentType, setRentType] = useState([]);
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("featured");
  const [isActive, setIsActive] = useState(false);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(12);
  const [filters, setFilters] = useState();
  const [lastPage, setLastPage] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page') || 1;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    setRentType([]);
    axios
      .get(url + `properties?target=rent&page=${page}&category[]=${apartments || villa || hotelApartment || townhouse}&sort_by=${sortBy}`, config)
      .then(function (response) {
        setRentType(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
        setCurrentPage(+response.data.page.current_page);
        setLastPage(response.data.page.last_page);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [sortBy, page]);

  const handleFilter = (filterVal, filterType) => {
    setLoading(true);
    setFilters(filterVal);
    const val = filterVal;
    if (!val) {
      const val = "";
      axios
        .get(url + `properties${val}?target=buy&sort_by=${sortBy}&limit=${limit}`, config)
        .then(function (response) {
          setRentType(response.data.data);
          setLoading(false);
          setTotal(response.data.total);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      axios
        .get(url + `properties${val}&target=buy&sort_by=${sortBy}&limit=${limit}`, config)
        .then(function (response) {
          setRentType(response.data.data);
          setLoading(false);
          setTotal(response.data.total);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  };

  const displayPageCount = 5; // Number of page numbers to display

  let startPage = Math.max(1, currentPage - 2); // Display 2 pages before the current page
  const endPage = Math.min(startPage + displayPageCount - 1, lastPage);

  // Adjust the start page if there are not enough pages on the right
  if (endPage - startPage < displayPageCount - 1) {
    startPage = Math.max(1, lastPage - displayPageCount + 1);
  }

  const pages = [];
  for (let page = startPage; page <= endPage; page++) {
    pages.push(page);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
  };

  return (
    <div>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <link href={`https://keymaxrealestate.com/${meta.link}`} rel="canonical" />
      </Helmet>

      <Layout>
        {window.innerWidth < 768 ? <FilterMobile onFilterChange={handleFilter} handleCloseFilter={() => setIsActive(false)}  typeChange={"rent"}/> : ""}
        <div className="propertiesList container mx-auto pt-8 md:mt-24 relative">
          <div className="row flex items-start justify-start text-center flex-wrap">
            <div className="w-full">{window.innerWidth < 768 ? "" : <Filter buyclassName="hidden" onFilterChange={handleFilter} filterType={"buy"} />}</div>
            <div className="w-full flex items-center justify-between flex-wrap border-b border-mainColor pb-4">
              <div className="btns flex items-center justify-between md:justify-start mb-4 md:mb-0 w-full md:w-1/2">
                <Link to="/MapView" className="text-sm md:text-lg w-1/3 md:w-auto px-1 md:px-4 py-2 rounded-full mx-1 md:mx-2  flex items-center relative overflow-hidden cursor-pointer  border-2 font-black border-[#D4B071]">
                  <HiOutlineLocationMarker className="mx-1 md:mx-2 text-mainColor" /> {t("Map View")}{" "}
                </Link>
                <button
                  onClick={() => setIsActive(true)}
                  className="w-1/3 md:w-auto  px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex md:hidden items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-mainColor text-gray"
                >
                  <HiOutlineAdjustments className="mx-1 md:mx-2 text-gray" /> {t("Filter")}{" "}
                </button>
              </div>
              <div className="btns flex justify-between md:justify-end items-center  w-full md:w-1/2">
                <label className="mx-4" htmlFor="SortBy">
                  {t("Sort By:")}
                </label>
                <select id="SortBy" className="border border-gray py-2 px-4 rounded-full shadow-lg" onClick={(e) => setSortBy(e.target.value)}>
                  {/*<option value="featured">{t("Featured")}</option>*/}
                  <option value="newest">{t("Newest")}</option>
                  <option value="price_low">{t("Price (low)")}</option>
                  <option value="price_high">{t("Price (high)")}</option>
                  <option value="beds_low">{t("Beds (least)")}</option>
                  <option value="beds_high">{t("Beds (most)")}</option>
                  <option value="delivery_date_earliest">{t("Delivery date (earliest)")}</option>
                  <option value="delivery_date_latest">{t("Delivery date (latest)")}</option>
                </select>
              </div>
            </div>
            <h1 className="font-bold text-2xl text-center md:text-4xl w-full my-6 md:mt-8">
              <span className="border-b-2 border-[#CDAC62] text-black px-4 md:px-4">{title}</span>
            </h1>

            {isLoading ? (
              <div className="h-screen w-screen bg-white z-40 flex items-center justify-center">
                <Loading />
              </div>
            ) : (
              <>
                <div className="flex flex-col md:flex-row w-full">
                  <div className=" w-full flex flex-col justify-center items-center">
                    <div className="relative grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 md:pt-8">
                      {rentType.map((item, index) => (
                        <>
                          <Card
                            id={item.id}
                            slug={item.slug}
                            name={item.title}
                            location={item.address}
                            area={item.size}
                            bedroom={item.bedrooms_number}
                            bathroom={item.bathrooms_number}
                            property_type={item.property_type}
                            price={item.price}
                            img={item.image}
                            imgs={item.images}
                            status={item.launch}
                            buy_or_sell={item.buy_or_sell}
                            price_year={item.price_year}
                            price_hidden={item.price_hidden}
                            description={item.description}
                            height={256}
                            width={380}
                          />
                        </>
                      ))}
                    </div>
                  {/* Pagination */}
                <nav className="pagination flex items-center justify-center space-x-2 w-full">
                  <Link to={`/${meta.link}?page=1`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
                    &lt;&lt; {/* First Page */}
                  </Link>
                  <Link to={`/${meta.link}?page=${currentPage - 1}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
                    &lt; {/* Previous Page */}
                  </Link>
                  {pages.map((page) => (
                    <Link
                      to={`/${meta.link}?page=${page}`}
                      onClick={scrollToTop}
                      key={page}
                      className={`rounded-full black-bg px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 ${page === currentPage ? 'text-white bg-black' : 'text-black'}`}
                    >
                      {page}
                    </Link>
                  ))}
                  <Link to={`/${meta.link}?page=${currentPage + 1}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
                    &gt; {/* Next Page */}
                  </Link>
                  <Link to={`/${meta.link}?page=${lastPage}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
                    &gt;&gt; {/* Last Page */}
                  </Link>
                </nav>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default BuyType;
