import { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Filter from "../Components/Sections/Filter";
import axios from "axios";
import { config, url } from "../Common/Common";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import Loading from "../Components/UI/Loading";
import { Link, useParams } from "react-router-dom";
import WidthCard from "../Components/Sections/WidthCard";
import Nothing from "../Components/UI/Nothing";
import { t } from "i18next";
import Card from "../Components/UI/Card";
// import { GoogleMap, LoadScript, Circle, Marker } from "@react-google-maps/api";
// import AreaMap from "./AreaMap";
import Airport from "../Components/Icon/Airport";
import Burj from "../Components/Icon/Burj";
import Plam from "../Components/Icon/Plam";
import Expo from "../Components/Icon/Expo";
import Museum from "../Components/Icon/Museum";
import Title from "../Components/UI/Title";
import ProjectList from "./ProjectList";


const AreaProperties = () => {
  const { name } = useParams();
  const [projects, setProjects] = useState([]);
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState();
  const [start, setStart] = useState(1);
  const [areaDetails, setAreaDetails] = useState("");
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(4);

  useEffect(() => {
    // axios.get(url + `properties?start=0&area_id=${id}`, config)

    axios
      .get(url + `areas/${name}`, config)
      .then(function (response) {
        // handle success
        setAreaDetails(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  useEffect(() => {
    axios
      .get(url + `properties?area_id=${areaDetails.id}`)
      .then(function (response) {
        // handle success
        setProperties(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });

    axios
      .get(url + `projects?area_id=${areaDetails.id}`, config)
      .then(function (response) {
        // handle success
        setProjects(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, [areaDetails.id, sortBy, limit]);

  const favChange = (id) => {
    const item = projects.find((item) => {
      return item.id === id;
    });
    item.is_fav = !item.is_fav;
    setProjects([...projects]);
  };
  const styles = {
    backgroundImage: `url(${areaDetails.img_url})`,
  };

  return (
    <Layout>
      <div className="container mx-auto pt-8 lg:pt-0 px-4 lg:px-0 lg:mt-28 mt-20 ">
        <section className="bg-white dark:bg-gray-900 flex flex-col justify-center items-center">
          <div className="relative w-full h-[50vh] lg:h-[75vh] ">

          <div className="brightness-50 h-full bg-cover rounded-3xl lg:rounded-[3.25rem] bg-top" style={styles}>
          </div>
            <h1 className="absolute absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl lg:text-6xl text-white font-black inline-block drop-shadow-lg">{areaDetails.name}</h1>
          </div>
          <div className="p-4 md:p-14 space-y-10">

            <div class="mb-10 md:mx-auto md:mb-12">
              <p class="text-base text-gray-50 md:text-xl text-justify" dangerouslySetInnerHTML={{ __html: areaDetails.content }} />
            </div>

            <div class="mb-10 md:mx-auto md:mb-12">
              <h2 class="mb-6 font-sans text-3xl font-bold leading-none text-center tracking-tight text-gray-50 sm:text-4xl md:mx-auto">
              {t("Lifestyle")}
              </h2>
              <p class="text-base text-gray-50 md:text-xl text-justify" dangerouslySetInnerHTML={{ __html: areaDetails.life_style }} />
            </div>


            <div>
              <div className="flex flex-col lg:flex-row justify-center items-center md:space-x-12 space-y-7 md:space-y-0">
                <div className="w-full lg:w-1/2 ">
                  <h1 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-50 sm:text-4xl md:mx-auto">{t("Location")}</h1>
                  <p className="text-base text-gray-50 md:text-xl text-justify" dangerouslySetInnerHTML={{ __html: areaDetails.location }} />
                </div>
                <div className="w-full max-w-md lg:max-w-full lg:w-1/2 relative grid gap-5 row-gap-5 mb-8 grid-cols-2 lg:grid-cols-3 ">
                  <div className=" p-5 md:p-8 h-full flex flex-col justify-center items-center text-center rounded-xl space-y-5 shadow-lg">
                    <div className="flex justify-center items-center p-2 border-2 rounded-2xl w-20 h-20">
                      <Airport width={50} />
                    </div>
                    <p className="grow text-xl">
                      <span className="font-bold">{areaDetails.DXD} min</span> to the <br />
                      DXB Airport
                    </p>
                  </div>
                  <div className=" p-5 md:p-8 h-full flex flex-col justify-center items-center text-center rounded-xl space-y-5 shadow-lg">
                    <div className="flex justify-center items-center p-2 border-2 rounded-2xl w-20 h-20">
                      <Burj width={50} />
                    </div>
                    <p className="grow text-xl">
                      <span className="font-bold">{areaDetails.Downtown} min</span> to the <br />
                      Burj Khalifa{" "}
                    </p>
                  </div>
                  <div className=" p-5 md:p-8 h-full flex flex-col justify-center items-center text-center rounded-xl space-y-5 shadow-lg">
                    <div className="flex justify-center items-center p-2 border-2 rounded-2xl w-20 h-20">
                      <Plam width={50} />
                    </div>
                    <p className="grow text-xl">
                      <span className="font-bold">{areaDetails.Plam} min</span> to the <br />
                      Palm Jumeirah
                    </p>
                  </div>
                  <div className=" p-5 md:p-8 h-full flex flex-col justify-center items-center text-center rounded-xl space-y-5 shadow-lg">
                    <div className="flex justify-center items-center p-2 border-2 rounded-2xl w-20 h-20">
                      <Expo width={50} />
                    </div>
                    <p className="grow text-xl">
                      <span className="font-bold">{areaDetails.Expo} min</span> to the <br />
                      Expo City
                    </p>
                  </div>
                  <div className=" p-5 md:p-8 h-full flex flex-col justify-center items-center text-center rounded-xl space-y-5 shadow-lg">
                    <div className="flex justify-center items-center p-2 border-2 rounded-2xl w-20 h-20">
                      <Museum width={70} />
                    </div>
                    <p className="grow text-xl">
                      <span className="font-bold">{areaDetails.Museum} min</span> to the <br />
                      Museum of the Future
                    </p>
                  </div>
                  <div className=" p-5 md:p-8 h-full flex flex-col justify-center items-center text-center rounded-xl space-y-5 shadow-lg">
                    <div className="flex justify-center items-center p-2 border-2 rounded-2xl w-20 h-20">
                      <Airport width={50} />
                    </div>
                    <p className="grow text-xl">
                      <span className="font-bold">{areaDetails.DWC} min</span> to the <br />
                      DWC Airport
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              <AreaMap />
            </div> */}
          </div>
        </section>

        <div className="row flex items-start justify-start text-center flex-wrap">
          <Title title={`Properties in ${areaDetails.name}`} />
          <div className="flex flex-col w-full">
            <div className=" w-full flex flex-col justify-center items-center">
                {isLoading ? 
                <div className="h-[50vh]">
                <Loading /> 
                </div>
                : (
                  <div className="relative grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 md:pt-8">
                  { properties.slice(0, 4).map((item, index) => (
                    <Card
                      id={item.id}
                      slug={item.slug}
                      name={item.title}
                      location={item.address}
                      area={item.size}
                      bedroom={item.bedrooms_number}
                      bathroom={item.bathrooms_number}
                      property_type={item.property_type}
                      price={item.price}
                      img={item.image}
                      imgs={item.images}
                      status={item.launch}
                      buy_or_sell={item.buy_or_sell}
                      price_year={item.price_year}
                      price_month={item.price_month}
                      price_hidden={item.price_hidden}
                      description={item.description}
                      height={256}
                      width={380}
                    />
                  ))}
                  </div>) 
                }
                {!isLoading && properties.length === 0 && <Nothing />}
            </div>

            {!(properties.length === 0)  &&  
          <div class="flex gap-4 my-8">
            <Link to={`/SubProperties/${areaDetails.id}`} class="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl">View All Properties in {areaDetails.name}</Link>
          </div> }
          </div>
        </div>

       

          <div className="row flex flex-col items-start justify-start w-full">
            <Title title={`Off Plan Projects in ${areaDetails.name}`} />
            <div className="flex flex-col w-full">
              <div className=" w-full flex flex-col justify-center items-center">
                {projects.length === 0 ? (
                  <div className="w-full flex items-center">

                    <Nothing />
                  </div>
                ) : (
                  <>
                  
                  <div className="grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1">
                    {projects.slice(0, 4).map((project) => (
                      <ProjectList project={project} key={project.id} />
                    ))}
                  </div>
                  <div class="flex gap-4 mt-8"><Link to={`/off-plan-projects/${areaDetails.id}`} class="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl">View All Projects in {areaDetails.name}</Link></div>
                  </>
                )}
              </div>
              <div>
            </div>
              </div>
          </div>
      </div>
    </Layout>
  );
};

export default AreaProperties;
