import { useEffect, useState } from 'react';
import Layout from '../Components/Layout'
import axios from "axios";
import { config, url } from '../Common/Common';
import Filter from '../Components/Sections/Filter';
import WidthCard from '../Components/Sections/WidthCard';
import { BsFillSuitHeartFill } from 'react-icons/bs'
import { HiOutlineAdjustments, HiOutlineLocationMarker } from 'react-icons/hi'
import List from '../Components/UI/List';
import Nothing from '../Components/UI/Nothing';
import Loading from '../Components/UI/Loading';
import { Link } from 'react-router-dom';
import FilterMobile from '../Components/Sections/FilterMobile';
import { t } from 'i18next';


const Hot = () => {
     const [hot, setHot] = useState([])
     const [list, setList] = useState([])
     const [isLoading, setLoading] = useState(true)
     const [sortBy, setSortBy] = useState('featured')
     const [isActive, setIsActive] = useState(false)
     const [total, setTotal] = useState()
     const [limit, setLimit] = useState(10)

     useEffect(() => {
          axios.get(url + `properties?hot=1&sort_by=${sortBy}&limit=${limit}`, config)
               .then(function (response) {
                    // handle success
                    setHot(response.data.data);
                    setLoading(false)
                    setTotal(response.data.total);
               })
               .catch(function (error) {
                    // handle error
                    console.log(error);
               })
               .then(function () {
                    // always executed
               });
     }, [sortBy, limit]);
     useEffect(() => {
          axios.get(url + 'categories?hot=1', config)
               .then(function (response) {
                    // handle success
                    setList(response.data.data);
               })
               .catch(function (error) {
                    // handle error
                    console.log(error);
               })
               .then(function () {
                    // always executed
               });
     }, []);
     const favChange = (id) => {
          const item = hot.find(item => {
               return item.id === id;
          })
          item.is_fav = !item.is_fav
          setHot([...hot]);
     }


     // const handleFilter = (filterVal, filterType) => {
     //           // filterVal.find('filterVal')
     //           // const filter = filterVal.replace("undefined", "buy")
     //           // console.log(filter)
     //           axios.get(url + `properties${filterVal}&target='hot'&sort_by=${sortBy}`, config)
     //                     .then(function (response) {
     //                               // handle success
     //                               setHot(response.data.data);
     //                               setLoading(false)
     //                               setIsActive(false)
     //                               console.log(response);

     //                     })
     //                     .catch(function (error) {
     //                               // handle error
     //                               console.log(error);
     //                     })
     // }


     return (
          <Layout>
               {/* {window.innerWidth < 768 ? <FilterMobile  hotclassName="hidden" buyclassName="hidden"  onFilterChange={handleFilter} handleCloseFilter={() => setIsActive(false)} className={isActive && 'active'} /> : ''} */}

               <div className="container mx-auto pt-8 md:pt-8  px-4 md:px-0">
                    <div className="row flex items-start justify-start text-center flex-wrap">
                         {/* <div className="w-full">
                                                            {window.innerWidth < 768 ? '' : <Filter buyclassName="hidden" onFilterChange={handleFilter} />}
                                                  </div> */}
                         <div className="w-full flex items-center justify-between flex-wrap border-b border-mainColor pb-4">
                              <div className="btns flex items-center justify-between md:justify-start mb-4 md:mb-0 w-full md:w-1/2">
                                   <Link to='/MapView' className='w-1/3 md:w-auto px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex items-center relative overflow-hidden cursor-pointer  border border-mainColor bg-gray' ><HiOutlineLocationMarker className='mx-1 md:mx-2 text-mainColor' /> Map View </Link>
                                   <Link to='/WishList' className='w-1/3 md:w-auto px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-gray' ><BsFillSuitHeartFill className='mx-1 md:mx-2 text-mainColor' /> Whishlist </Link>
                                   <button onClick={() => setIsActive(true)} className='w-1/3 md:w-auto  px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex md:hidden items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-mainColor text-gray' ><HiOutlineAdjustments className='mx-1 md:mx-2 text-gray' /> Filter </button>
                              </div>
                              <div className="btns flex justify-between md:justify-end items-center  w-full md:w-1/2">
                                   <label className='mx-4' htmlFor="SortBy">Sort By:</label>
                                   <select id='SortBy' className='border border-gray py-2 px-4' onChange={(e) => setSortBy(e.target.value)}>
                                        { /*   <option value="featured">featured</option> */}
                                        <option value="newest">Newest</option>
                                        <option value="price_low">Price (low)</option>
                                        <option value="price_high">Price (high)</option>
                                        <option value="beds_low">Beds (least)</option>
                                        <option value="beds_high">Beds (most)</option>
                                        <option value="delivery_date_earliest">Delivery date (earliest)</option>
                                        <option value="delivery_date_latest">Delivery date (latest)</option>
                                   </select>
                              </div>
                         </div>
                         <h3 className='font-bold text-2xl text-center md:text-4xl w-full my-8'>  <span className='border-b-2 border-[#CDAC62] text-black px-4 md:px-4'> {t('Hot Properties')} </span> </h3>

                         <div className="w-full md:w-9/12 pt-8">
                              {/* {isLoading && <div className='absolute top-0 left-0 h-screen w-screen bg-white z-40 flex items-center justify-center'><Loading /></div>} */}
                              {!isLoading && hot.length === 0 && <Nothing />}
                              {
                                   hot.map((item, index) => (
                                        <WidthCard
                                             fav={item.is_fav}
                                             id={item.id}
                                             developer={item.developer.icon}
                                             key={item.id}
                                             name={item.title}
                                             description={item.description}
                                             location={item.location_area.name + ' , ' + item.location_area.city}
                                             image={item.image}
                                             images={item.images}
                                             area={item.size}
                                             bedrooms={item.bedrooms_number}
                                             bathrooms={item.bathrooms_number}
                                             rent_term={item.rent_term}
                                             status={item.buy_or_sell}
                                             url={item.developer_pic}
                                             whatsApp={item.whatsapp}
                                             phone={item.phone}
                                             email={item.email}
                                             price={item.price} />
                                   ))
                              }
                              <div className="w-full flex justify-center items-center">
                                   {
                                        total > 10 &&
                                        <div className="flex gap-4 mt-8">
                                             {total > 10 && total > limit &&
                                                  <button onClick={() => setLimit(limit + 10)} className='flex mx-auto relative items-center justify-center   bg-mainColor text-white font-bold px-2 xl:px-6 py-2 rounded-lg'>{t('Show more')}</button>
                                             }

                                             {limit > 10 &&
                                                  <button onClick={() => setLimit(limit - 10)} className='flex mx-auto relative items-center justify-center   bg-mainColor text-white font-bold px-2 xl:px-6 py-2 rounded-lg'>{t('Show Less')}</button>
                                             }
                                        </div>
                                   }
                              </div>
                         </div>
                         <div className="w-full md:w-3/12 mt-12 px-4">
                              <div className="border border-mainColor px-4 py-4 rounded-lg">
                                   {
                                        list.map((item, index) => (
                                             <List key={index} id={item.id} status={'hot=1'} name={item.name} total={item.total} />
                                        ))
                                   }
                              </div>
                         </div>
                    </div>
               </div>
          </Layout>
     )
}

export default Hot