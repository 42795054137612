import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import banner from "../../img/Hero.webp";
import Filter from "./Filter";
import { useState } from "react";
import { HiOutlineAdjustments } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import FilterMobile from "./FilterMobile";
import { t } from "i18next";
import Lottie from "react-lottie";
import "./Banner.scss";
import { animationOptionsArabic, animationOptionsEnglish, defaultOptions } from "../../Common/LottiOptions";
import HeroProject from "./HeroProject";

const Banner = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [search, setSearch] = useState();

  const handleFilter = (filterVal) => {
    let path = `/Properties`;
    navigate(path + filterVal);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate(`/Properties?&term=${search}`);
    }
  };

  const handleOpenFilter = () => {
    setIsActive(true)
  }

  return (
    <div className="Banner relative">
      <div className="mainBanner flex items-center justify-center w-full h-full flex-wrap relative z-10">
        <div className=" flex flex-col items-center w-full xl:w-8/12 h-5/6 md:h-full justify-center py-0 md:py-10 pt-20 md:pt-28">
          <h2 className="text-2xl md:text-4xl font-black text-center mb-3 md:mb-10 text-white z-10 relative">
            Let us find <br className="md:hidden" />a property for you
          </h2>
          {window.innerWidth < 1024 && <FilterMobile onFilterChange={handleFilter} rentclassName="hidden" buyclassName="hidden" handleOpenFilter={() => setIsActive(true)} handleCloseFilter={() => setIsActive(false)} className={isActive && 'active'} />}
          <HeroProject />
          {window.innerWidth > 1024 && (
            <>
              {/* <div className="lottiText h-52 w-full relative -mt-32  z-40 flex hover:cursor-default">
                <Lottie options={localStorage.KeyMaxLang === 'ar' ? animationOptionsArabic : animationOptionsEnglish} />
              </div> */}

              <Filter onFilterChange={handleFilter} homepage={true} typeChange={"buy"} />
            </>
          )}
          {/* {window.innerWidth > 768 ?
                              <div style={{ zIndex: '9' }} className='mt-12 bg-white h-36 hover:scale-105 transition-all transition-1000 ease-in-out flex flex-col items-center justify-center  cursor-pointer absolute rounded-lg left-1/2 overflow-hidden z-0 bottom-2 xl:top-3/4  -translate-y-1/2 -translate-x-1/2'>
                                   <Link to='/MapView' > <Lottie options={defaultOptions} height={100} width={200} /> </Link>
                                   <input onKeyDown={handleKeyDown} autoComplete='true' type="text" className='w-11/12 px-2 h-8 text-center border border-black/40 rounded-lg' placeholder={t('Search here')} onChange={(e) => setSearch(e.target.value)} value={search} />
                              </div>
                              :
                              <div className=" absolute top-1/2 left-1/2 z-50 -translate-x-1/2 -translate-y-1/2 w-10/12 ">
                                   <h3 className="w-full relative z-10 font-black text-center text-3xl xl:text-4xl text-white mb-4 -mt-16">{t('THE BEST WAY TO FIND YOUR HOME')}</h3>
                                   <Link to='/MapView' style={{ zIndex: '9' }} className='flex flex-wrap items-center justify-center border-4 bg-white border-white  mt-4  h-36 overflow-hidden rounded-lg'>
                                        <Lottie options={defaultOptions} height={100} width={200} />
                                        <h3 className="text-mainColor font-bold capitalize w-full text-center"> {t('Search by map')}</h3>
                                   </Link>
                                   <button onClick={() => setIsActive(true)} className='flex items-center justify-center flex-wrap bg-mainColor w-full  py-2 mt-4 rounded-xl text-white text-xl font-bold gap-4'><HiOutlineAdjustments className='text-2xl text-white' />   </button>

                              </div>
                         } */}
        </div>
        <img src={banner} alt="" className="absolute w-full h-full object-cover buttom-0 left-0 brightness-60" />
      </div>
    </div>
  );
};

export default Banner;
