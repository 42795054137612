import React from "react";

const Expo = ({ width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 1819 1818" fill="none">
      <path
        d="M1392.03 950.666L1371.74 970.64C1365.19 977.194 1363.63 985.933 1368 994.048L1381.11 1019.33C1385.79 1028.38 1396.4 1035.25 1408.88 1037.43C1411.69 1038.05 1414.5 1038.05 1417.31 1038.05C1418.25 1038.05 1419.18 1038.05 1420.12 1038.05C1421.99 1038.05 1423.87 1037.74 1425.43 1037.12C1426.36 1037.12 1427.3 1036.81 1428.23 1036.49C1430.11 1035.87 1431.67 1035.25 1433.23 1034.62C1433.85 1034.31 1434.79 1034 1435.41 1033.68C1437.6 1032.44 1439.47 1031.19 1441.34 1029.32L1461.63 1009.34C1468.19 1002.79 1469.75 994.048 1465.38 985.933L1452.27 960.653C1447.59 951.29 1436.66 944.424 1424.18 942.551C1421.68 942.239 1419.5 941.927 1417 941.927C1407.01 941.927 1397.65 945.048 1392.03 950.978"
        fill="#000"
      />
      <path
        d="M1410.13 781.194C1410.13 781.194 1409.82 781.194 1409.51 781.194C1397.02 783.066 1386.1 789.933 1381.42 799.296L1368.31 824.576C1363.94 832.691 1365.5 841.43 1372.05 847.984L1392.34 867.958C1399.52 875.137 1411.69 878.57 1424.49 876.697C1437.29 874.825 1447.9 867.958 1452.89 858.595L1466 833.315C1469.12 827.073 1469.43 820.831 1466.31 815.213C1465.38 813.34 1464.13 811.468 1462.26 809.907L1441.97 789.933C1440.41 788.372 1438.22 786.812 1436.04 785.563C1435.41 785.251 1434.79 784.939 1434.17 784.627C1432.61 783.691 1430.73 783.066 1428.86 782.442C1428.24 782.442 1427.3 782.13 1426.67 781.818C1424.8 781.506 1422.93 780.882 1420.74 780.882C1419.81 780.882 1419.18 780.882 1418.25 780.882C1417.62 780.882 1417 780.882 1416.38 780.882C1414.5 780.882 1412.32 780.882 1410.44 781.194"
        fill="#000"
      />
      <path
        d="M400.443 780.258C400.443 780.258 399.195 780.258 398.259 780.258C396.074 780.258 393.889 780.57 392.016 781.194C391.392 781.194 390.456 781.194 389.832 781.818C387.959 782.442 386.086 783.067 384.214 784.003C383.589 784.003 382.965 784.627 382.341 784.939C380.156 786.188 377.971 787.436 376.411 789.309L356.123 809.283C349.569 815.837 348.008 824.576 352.378 832.691L365.487 857.971C366.735 860.156 368.296 862.341 370.168 864.525C370.793 865.15 371.729 865.774 372.665 866.398C373.914 867.646 375.162 868.895 376.723 869.831C377.659 870.455 378.908 870.767 379.844 871.392C381.404 872.328 382.965 873.264 384.526 873.888C385.462 874.201 386.71 874.513 387.647 874.825C389.519 875.449 391.392 876.073 393.577 876.385C406.374 878.57 418.858 875.137 426.037 867.959L446.324 847.984C452.879 841.43 454.439 832.691 450.07 824.576L436.961 799.296C432.279 790.245 421.355 783.067 408.871 781.194C408.871 781.194 408.558 781.194 408.246 781.194C406.061 780.882 404.189 780.57 402.004 780.57C401.38 780.57 400.756 780.57 400.443 780.57"
        fill="#000"
      />
      <path
        d="M393.889 941.303C381.092 943.175 370.48 950.354 365.487 959.405L352.378 984.685C348.008 992.8 349.569 1001.54 356.123 1008.09L376.411 1028.07C377.971 1029.63 380.156 1031.19 382.341 1032.44C382.965 1032.75 383.589 1033.06 384.214 1033.37C385.774 1034.31 387.647 1034.93 389.519 1035.56C390.456 1035.56 391.08 1035.87 392.016 1036.18C393.889 1036.49 395.762 1036.81 397.634 1037.12C398.571 1037.12 399.507 1037.12 400.443 1037.12C402.94 1037.12 405.437 1037.12 408.246 1036.81H408.871C421.355 1034.93 432.279 1028.07 436.961 1018.7L450.07 993.424C454.439 985.309 452.879 976.57 446.324 970.016L426.037 950.041C420.107 944.112 410.743 940.99 400.756 940.99C398.571 940.99 396.386 940.99 393.889 941.615"
        fill="#000"
      />
      <path
        d="M1363 628.263C1357.07 628.575 1351.14 630.136 1345.52 632.945C1334.29 638.563 1325.86 648.55 1324.3 658.849L1319.62 686.939C1318.06 695.99 1322.12 704.104 1330.23 708.162L1355.51 720.958C1364.56 725.64 1377.67 724.703 1388.91 719.085C1400.14 713.468 1408.57 703.48 1410.13 693.181L1414.81 665.092C1416.37 656.041 1412.32 647.926 1404.2 643.868L1378.92 631.072C1376.74 629.824 1374.24 629.2 1371.74 628.575C1371.43 628.575 1370.81 628.575 1370.49 628.575C1368.62 628.263 1366.44 627.951 1364.56 627.951C1364.25 627.951 1363.63 627.951 1363.31 627.951"
        fill="#000"
      />
      <path
        d="M659.81 402.925C659.81 402.925 658.25 402.925 657.313 403.237C656.065 403.237 655.129 403.861 653.88 404.173C653.256 404.173 652.632 404.798 651.695 405.11C650.447 405.734 649.511 406.67 648.574 407.294C648.262 407.607 647.638 407.919 647.014 408.543C645.765 410.103 644.517 411.664 643.58 413.536L630.784 438.817C626.102 448.18 626.726 460.976 632.656 472.212C638.587 483.448 648.262 491.562 658.562 493.435L686.652 498.116C695.704 499.677 703.819 495.62 707.876 487.505L720.673 462.225C725.354 453.174 724.73 440.377 718.8 428.83C714.43 420.403 707.876 413.537 700.385 410.103C697.888 408.855 695.391 408.231 692.895 407.607L664.804 402.925C664.804 402.925 662.931 402.925 661.995 402.925C661.059 402.925 660.435 402.925 659.498 402.925"
        fill="#000"
      />
      <path
        d="M823.981 1367.64L798.7 1380.74C789.649 1385.43 782.47 1396.04 780.597 1408.83C778.725 1421.63 781.846 1433.8 789.025 1441.29L809 1461.58C809 1461.58 812.121 1464.39 813.682 1465.32C813.682 1465.32 814.306 1465.32 814.306 1465.64C816.179 1466.57 817.739 1467.2 819.612 1467.51C819.612 1467.51 819.612 1467.51 819.924 1467.51C821.484 1467.51 823.357 1467.51 824.918 1467.51C825.23 1467.51 825.854 1467.51 826.166 1467.51C828.039 1467.2 829.912 1466.57 831.784 1465.64L857.065 1452.53C866.429 1447.85 873.295 1436.92 875.48 1424.44C877.353 1411.95 874.232 1399.47 867.053 1392.29L847.078 1372C843.02 1367.95 838.027 1365.76 833.033 1365.76C829.912 1365.76 826.79 1366.7 823.669 1368.26"
        fill="#000"
      />
      <path
        d="M549.009 499.053L520.919 503.11C511.867 504.358 505.625 510.6 504.065 519.964L499.695 548.053C499.383 550.55 499.383 553.359 499.695 555.855C499.695 556.48 500.007 556.792 500.007 557.416C500.319 559.601 500.943 561.785 501.568 563.97C501.568 564.594 502.192 565.218 502.504 565.843C503.44 568.027 504.377 569.9 505.625 571.773C506.249 572.709 506.874 573.333 507.498 573.957C508.746 575.518 509.995 577.391 511.555 578.951C520.295 588.002 532.467 592.684 542.767 591.123L570.857 587.066C579.908 585.817 586.151 579.575 587.711 570.212L592.081 542.123C593.641 531.824 588.96 519.964 579.908 510.913C577.724 509.04 575.851 507.167 573.354 505.607C572.73 504.983 571.793 504.671 571.169 504.358C569.609 503.422 567.736 502.486 565.863 501.549C564.927 501.237 563.991 500.925 563.366 500.613C561.806 499.989 559.933 499.677 558.372 499.365C557.436 499.365 556.5 499.365 555.563 499.053C554.315 499.053 553.067 499.053 551.818 499.053C550.882 499.053 549.945 499.053 549.321 499.053"
        fill="#000"
      />
      <path
        d="M511.242 1237.8C511.242 1237.8 508.433 1240.92 507.185 1242.79C506.561 1243.73 505.937 1244.35 505.312 1244.98C504.064 1246.85 503.44 1248.72 502.503 1250.6C502.191 1251.53 501.567 1252.16 501.255 1253.09C500.318 1255.28 500.006 1257.15 499.694 1259.34C499.694 1259.96 499.382 1260.58 499.07 1261.21C498.758 1264.02 498.758 1266.51 499.07 1269.01L503.128 1297.1C504.376 1306.15 510.618 1312.39 519.982 1313.95L548.072 1318.32C548.072 1318.32 550.881 1318.64 552.129 1318.64C559.62 1318.64 567.735 1315.51 574.602 1310.21C576.162 1308.96 577.723 1307.71 579.283 1306.15C588.335 1297.1 593.016 1285.24 591.456 1274.94L587.398 1246.85C586.15 1237.8 579.908 1231.56 570.544 1230L542.454 1225.63C542.454 1225.63 539.645 1225.32 538.396 1225.32C529.033 1225.32 519.045 1230 511.242 1237.8Z"
        fill="#000"
      />
      <path
        d="M1109.88 1329.87L1097.08 1355.15C1092.4 1364.2 1093.03 1377 1098.64 1388.55C1104.26 1400.09 1114.25 1408.21 1124.55 1409.77L1152.64 1414.45C1161.69 1416.01 1169.49 1411.95 1173.86 1403.84L1186.66 1378.56C1191.34 1369.2 1190.72 1356.4 1184.79 1345.16C1178.86 1333.93 1169.18 1325.81 1158.88 1323.94L1130.79 1319.26C1130.79 1319.26 1128.29 1318.95 1127.05 1318.95C1119.55 1318.95 1113.31 1323 1109.57 1329.87"
        fill="#000"
      />
      <path
        d="M1275.3 1226.56L1247.21 1230.62C1238.16 1231.87 1231.92 1238.11 1230.36 1247.48L1225.99 1275.56C1224.43 1285.86 1229.11 1297.72 1238.16 1306.78C1239.72 1308.34 1241.28 1309.58 1243.15 1310.83C1243.78 1311.46 1244.4 1312.08 1245.34 1312.71C1247.52 1313.95 1249.71 1315.2 1251.89 1316.14C1252.52 1316.14 1252.83 1316.76 1253.14 1316.76C1255.32 1317.7 1257.2 1318.01 1259.38 1318.32C1260.01 1318.32 1260.63 1318.64 1261.25 1318.95C1264.06 1319.26 1266.56 1319.26 1269.37 1318.95L1297.46 1314.89C1306.51 1313.64 1312.75 1307.4 1314.31 1298.04L1318.68 1269.95C1320.24 1259.65 1315.56 1247.79 1306.51 1238.74C1298.71 1230.93 1288.72 1226.25 1279.36 1226.25C1278.11 1226.25 1276.86 1226.25 1275.3 1226.25"
        fill="#000"
      />
      <path
        d="M970.051 1371.69L950.076 1391.98C942.897 1399.16 939.464 1411.64 941.337 1424.44C941.337 1426 941.961 1427.56 942.585 1428.81C942.897 1430.37 943.209 1431.93 943.834 1433.49C944.458 1434.74 945.082 1435.99 945.706 1437.23C946.33 1438.48 946.955 1440.04 947.891 1441.29C948.827 1442.54 949.764 1443.48 950.7 1444.72C951.636 1445.66 952.261 1446.91 953.197 1447.85C955.07 1449.72 957.255 1451.28 959.751 1452.53L985.033 1465.64C986.905 1466.57 988.778 1467.2 990.651 1467.51C991.275 1467.51 991.587 1467.51 991.899 1467.51C993.46 1467.51 995.332 1467.82 996.893 1467.51C996.893 1467.51 996.893 1467.51 997.205 1467.51C999.078 1467.51 1000.95 1466.57 1002.51 1465.64C1002.51 1465.64 1002.82 1465.64 1003.14 1465.32C1004.7 1464.39 1006.57 1463.14 1007.82 1461.58L1027.79 1441.29C1029.67 1439.42 1031.23 1437.23 1032.47 1435.05C1033.1 1434.11 1033.41 1432.86 1033.72 1431.62C1034.35 1430.06 1034.97 1428.81 1035.6 1427.25C1035.91 1425.69 1036.22 1424.13 1036.22 1422.57C1036.22 1421.32 1036.84 1420.07 1036.84 1418.51C1036.84 1416.95 1036.84 1415.08 1036.84 1413.51C1036.84 1411.95 1036.84 1410.71 1036.84 1409.15C1034.97 1396.66 1028.1 1385.74 1018.74 1381.06L993.46 1367.95C990.339 1366.39 987.217 1365.45 983.784 1365.45C978.79 1365.45 973.797 1367.64 970.051 1371.69Z"
        fill="#000"
      />
      <path
        d="M686.339 1318.95L658.249 1323.63C647.949 1325.19 638.274 1333.3 632.343 1344.54C631.719 1346.1 631.095 1347.66 630.471 1349.22C629.847 1350.47 629.222 1351.72 628.91 1353.28C628.598 1354.84 628.286 1356.4 627.974 1358.27C627.974 1359.52 627.35 1361.08 627.35 1362.33C627.35 1364.2 627.35 1365.76 627.35 1367.64C627.35 1368.57 627.35 1369.82 627.35 1370.76C627.974 1373.25 628.598 1376.06 629.847 1378.25L642.331 1403.84C643.267 1405.71 644.516 1407.58 645.764 1408.83C646.076 1409.15 646.701 1409.46 647.013 1410.08C647.949 1411.02 649.198 1411.95 650.134 1412.58C650.758 1412.89 651.382 1413.2 652.007 1413.51C653.255 1414.14 654.191 1414.45 655.44 1414.76C656.064 1414.76 657 1414.76 657.625 1415.08C659.185 1415.08 661.058 1415.08 662.931 1415.08L691.021 1410.39C701.321 1408.83 711.308 1400.72 716.926 1389.17C722.544 1377.93 723.481 1365.14 718.799 1355.78L706.314 1330.18C702.881 1323 696.327 1318.95 688.836 1318.95C687.588 1318.95 686.339 1318.95 685.091 1318.95"
        fill="#000"
      />
      <path
        d="M821.484 350.18C821.484 350.18 821.172 350.18 820.86 350.18C818.987 350.18 817.115 351.116 815.554 352.052L814.93 352.364C813.057 353.301 811.497 354.549 809.936 355.797L789.961 376.084C782.782 383.262 779.349 395.747 781.221 408.543C783.094 421.027 789.961 431.951 799.324 436.632L824.605 449.74C832.72 454.11 841.459 452.549 848.014 445.995L867.989 425.708C875.168 418.218 878.601 406.046 876.728 393.25C876.728 391.377 875.792 389.817 875.48 387.944C875.168 386.696 874.856 385.447 874.544 384.199C873.919 382.326 872.983 380.766 872.047 379.205C871.422 378.269 871.11 377.02 870.486 376.084C869.862 374.836 868.613 373.899 867.989 372.963C867.053 371.715 866.429 370.778 865.492 369.842C863.62 367.969 861.435 366.409 859.25 365.16L833.969 352.052C832.096 351.116 830.223 350.492 828.351 350.18C827.726 350.18 827.414 350.18 826.79 350.18C825.854 350.18 824.917 350.18 823.981 350.18C823.357 350.18 822.733 350.18 821.796 350.18"
        fill="#000"
      />
      <path
        d="M1355.51 1097.67L1329.92 1110.15C1321.49 1114.21 1317.75 1122.01 1318.99 1131.37L1323.68 1159.46C1325.24 1169.76 1333.35 1179.44 1344.59 1185.37C1350.21 1188.18 1356.14 1190.05 1362.07 1190.36C1364.88 1190.36 1367.68 1190.36 1370.49 1190.05C1373.3 1189.74 1375.8 1188.8 1377.98 1187.55L1403.58 1175.07C1412 1171.01 1416.06 1163.21 1414.5 1153.84L1409.82 1125.76C1409.51 1123.26 1408.57 1120.76 1407.32 1118.27C1407.01 1117.33 1406.39 1116.7 1406.07 1116.08C1405.14 1114.52 1404.2 1112.65 1402.95 1111.09C1402.02 1109.84 1400.77 1108.9 1399.83 1107.97C1398.9 1106.72 1397.65 1105.78 1396.71 1104.84C1395.46 1103.91 1393.9 1102.97 1392.65 1102.35C1391.4 1101.41 1390.16 1100.48 1388.91 1099.85C1382.67 1096.73 1375.49 1094.86 1368.93 1094.86C1363.94 1094.86 1359.26 1095.79 1355.51 1097.67Z"
        fill="#000"
      />
      <path
        d="M1263.75 499.365C1263.75 499.365 1261.57 499.365 1260.32 499.677C1258.76 499.677 1257.51 500.301 1255.95 500.925C1254.7 500.925 1253.76 501.549 1252.52 502.174C1250.96 502.798 1249.39 503.734 1247.83 504.67C1246.9 505.295 1245.96 505.607 1245.02 506.231C1242.53 507.792 1240.34 509.664 1238.16 511.849C1229.11 520.9 1224.43 532.76 1225.99 543.059L1230.04 571.148C1231.29 580.199 1237.53 586.441 1246.9 588.002L1274.99 592.371C1285.29 593.932 1297.15 589.25 1306.2 580.512C1307.76 578.951 1308.7 577.391 1309.94 575.83C1310.57 574.894 1311.51 574.269 1312.13 573.333C1313.07 571.773 1314 569.9 1314.63 568.339C1315.25 567.403 1315.87 566.467 1316.19 565.218C1316.81 563.658 1317.12 562.097 1317.44 560.537C1317.75 559.288 1318.06 558.352 1318.37 557.104C1318.68 554.295 1318.68 551.798 1318.37 549.301L1314.31 521.212C1313.07 512.161 1306.82 505.607 1297.46 504.358L1269.37 499.989C1269.37 499.989 1267.5 499.989 1266.56 499.989C1265.31 499.989 1264.38 499.989 1263.13 499.989"
        fill="#000"
      />
      <path
        d="M992.523 350.492C992.523 350.492 991.587 350.492 991.275 350.492C989.402 350.804 987.529 351.428 985.657 352.364L960.375 365.473C951.012 370.154 944.146 381.078 942.273 393.562C940.088 406.046 943.521 418.53 950.7 425.708L970.675 445.995C977.23 452.549 985.969 454.11 994.084 449.74L1019.37 436.632C1028.42 431.951 1035.6 421.027 1037.47 408.543C1039.65 396.059 1036.22 383.575 1029.04 376.084L1009.07 355.797C1009.07 355.797 1005.94 352.988 1004.38 352.052C1004.38 352.052 1003.76 352.052 1003.76 351.74C1001.89 350.804 1000.33 350.18 998.453 349.867C997.517 349.867 996.893 349.867 995.956 349.867C995.02 349.867 994.084 349.867 993.147 349.867"
        fill="#000"
      />
      <path
        d="M1153.89 403.549L1125.8 408.231C1115.5 409.791 1105.51 417.906 1099.89 429.454C1093.96 440.689 1093.34 453.486 1098.02 462.849L1110.5 488.441C1114.56 496.556 1122.68 500.925 1131.73 499.365L1159.82 494.683C1170.12 493.123 1180.1 485.008 1185.72 473.772C1186.66 472.212 1186.97 470.651 1187.59 469.091C1188.22 467.842 1188.84 466.594 1189.16 465.346C1189.78 463.473 1189.78 461.6 1190.09 459.728C1190.09 458.791 1190.72 457.543 1190.72 456.607C1190.72 454.734 1190.72 452.861 1190.72 450.989C1190.72 450.053 1190.72 449.116 1190.72 448.18C1190.09 445.683 1189.47 442.874 1188.22 440.689L1175.73 415.097C1174.8 413.224 1173.55 411.664 1172.3 410.103C1171.99 409.791 1171.36 409.479 1171.05 408.855C1170.12 407.919 1168.87 406.982 1167.93 406.358C1167.31 406.046 1166.68 405.734 1166.06 405.422C1164.81 404.798 1163.87 404.485 1162.63 404.173C1162 404.173 1161.07 404.173 1160.44 403.861C1159.5 403.861 1158.88 403.861 1157.94 403.861C1157.01 403.861 1156.07 403.861 1155.13 403.861"
        fill="#000"
      />
      <path
        d="M448.509 627.951C448.509 627.951 447.573 627.951 446.948 627.951C444.451 628.575 441.955 629.2 439.77 630.136L414.176 642.62C405.749 646.677 401.692 654.48 403.252 663.843L407.934 691.932C408.246 694.429 409.183 696.926 410.431 699.423C410.431 700.047 411.055 700.359 411.367 700.983C412.304 702.856 413.552 704.729 414.801 706.601C415.425 707.225 416.049 707.85 416.673 708.474C418.234 710.034 419.482 711.595 421.355 712.843C421.979 713.467 422.916 713.78 423.852 714.404C425.725 715.652 427.285 716.901 429.158 717.837C440.394 723.455 453.503 724.391 462.554 720.022L488.147 707.538C496.262 703.48 500.32 695.678 499.071 686.315L494.39 658.225C492.829 647.926 484.714 638.251 473.166 632.321C473.166 632.321 473.166 632.321 472.854 632.321C470.357 631.072 467.548 629.824 464.739 629.2C464.739 629.2 464.739 629.2 464.427 629.2C461.618 628.575 458.809 627.951 455.688 627.951C455.063 627.951 454.751 627.951 454.127 627.951C452.254 627.951 450.382 627.951 448.509 628.263"
        fill="#000"
      />
      <path
        d="M429.157 1098.91C417.609 1104.53 409.494 1114.52 407.933 1124.82L403.251 1152.91C401.691 1161.96 405.748 1170.07 413.863 1174.13L439.144 1186.93C441.329 1188.18 443.826 1188.8 446.635 1189.42C446.947 1189.42 447.259 1189.42 447.884 1189.42C450.381 1189.74 452.565 1190.05 455.062 1189.74C455.999 1189.74 457.247 1189.74 458.183 1189.42C460.056 1189.42 461.929 1189.11 463.801 1188.49C465.05 1188.18 466.298 1187.55 467.547 1186.93C469.107 1186.3 470.98 1185.99 472.541 1185.06C483.777 1179.44 492.204 1169.45 493.764 1159.15L498.446 1131.06C500.007 1122.01 495.949 1113.9 487.834 1109.84L462.553 1097.04C458.496 1095.17 453.814 1093.92 448.82 1093.92C442.266 1093.92 435.711 1095.48 429.469 1098.6"
        fill="#000"
      />
      <path
        d="M1660.76 1101.41C1660.76 1101.41 1658.58 1102.97 1657.64 1103.91C1637.04 1118.58 1613.01 1126.07 1587.73 1126.07C1583.36 1126.07 1578.99 1125.76 1574.62 1125.44C1600.21 1140.11 1619.25 1162.58 1628.61 1189.74C1629.86 1192.86 1630.8 1196.29 1631.73 1199.41C1640.47 1223.44 1659.82 1242.48 1685.11 1250.6C1705.39 1257.15 1723.81 1254.65 1739.73 1242.79C1754.4 1231.87 1766.26 1213.77 1774.06 1190.05C1780.3 1171.32 1795.59 1107.97 1737.23 1089.24C1728.8 1086.43 1720.06 1085.18 1711.32 1085.18C1693.53 1085.18 1675.74 1090.8 1661.07 1101.72"
        fill="#000"
      />
      <path
        d="M1684.48 568.028C1659.51 576.142 1640.16 595.18 1631.11 620.149C1630.49 622.333 1629.55 625.454 1628.61 628.263C1618.94 655.728 1599.59 678.2 1574.3 692.869C1603.33 689.748 1632.05 696.614 1655.45 712.843C1658.58 715.028 1661.38 717.213 1664.19 719.397C1685.42 733.442 1712.26 737.187 1736.92 729.073C1757.2 722.518 1770.62 709.722 1776.55 690.684C1782.17 673.206 1780.92 651.359 1773.43 627.951C1765.63 604.544 1753.77 586.129 1739.1 575.518C1729.11 568.028 1717.88 564.594 1706.02 564.594C1699.15 564.594 1691.97 565.843 1684.79 568.34"
        fill="#000"
      />
      <path
        d="M1540.91 345.186C1520.31 360.167 1507.51 383.575 1506.26 409.167C1506.26 409.167 1506.26 409.167 1506.26 409.479C1506.26 413.537 1506.58 417.594 1506.26 421.027V421.963C1505.02 450.053 1493.78 476.581 1474.74 497.804C1500.65 486.257 1530.3 483.76 1556.83 491.25H1557.76C1557.76 491.25 1559.01 492.186 1559.64 492.186C1562.44 493.123 1565.25 494.059 1568.37 495.307C1593.34 501.862 1619.56 497.18 1640.16 481.887C1689.79 445.995 1655.45 390.753 1643.59 374.524C1634.85 362.664 1609.26 332.078 1576.49 332.078C1565.25 332.078 1553.08 335.823 1540.28 344.874"
        fill="#000"
      />
      <path
        d="M1335.85 177.587C1320.56 198.81 1315.87 225.026 1323.05 250.307C1324.3 252.803 1325.55 256.549 1326.49 260.294C1334.29 288.071 1331.79 316.785 1320.24 343.001C1341.15 323.963 1367.68 312.727 1395.46 311.479H1396.09H1396.71C1401.08 311.479 1405.45 311.479 1410.13 311.479C1435.41 309.918 1458.51 297.122 1473.49 276.523C1485.98 259.045 1489.41 240.943 1483.17 222.217C1477.55 204.74 1463.82 187.886 1443.53 173.217C1433.85 166.039 1409.82 150.746 1384.23 150.746C1367.68 150.746 1350.52 157.3 1336.16 176.962"
        fill="#000"
      />
      <path
        d="M222.537 335.511C205.058 341.441 188.204 354.861 173.535 374.836C158.865 394.81 151.375 415.097 151.062 433.511C151.062 453.174 159.802 469.403 176.968 482.199C198.192 497.492 225.034 502.174 250.315 494.995C252.5 494.059 255.621 493.123 258.43 492.186C287.144 483.76 316.171 486.569 342.389 498.116C322.725 476.581 311.489 449.428 310.553 421.027C310.553 417.282 310.553 413.536 310.553 409.791C309.617 384.511 296.82 360.479 275.596 345.186C264.048 336.759 251.875 332.39 239.391 332.39C233.461 332.39 227.218 333.326 221.288 335.511"
        fill="#000"
      />
      <path
        d="M1567.44 1323C1565.25 1323.94 1562.13 1324.88 1559.01 1325.81C1547.78 1329.25 1536.54 1330.81 1525.3 1330.81C1508.14 1330.81 1490.97 1326.75 1475.05 1319.88C1494.72 1341.42 1505.95 1368.57 1506.89 1397.29C1506.89 1401.03 1506.89 1404.78 1506.89 1408.52C1507.82 1433.8 1520.62 1457.83 1541.85 1473.13C1559.01 1485.61 1577.43 1489.04 1596.15 1482.8C1613.63 1477.18 1630.49 1463.45 1645.16 1443.16C1656.7 1427.25 1691.04 1372 1641.41 1335.8C1626.74 1325.19 1609.57 1319.88 1592.1 1319.88C1583.98 1319.88 1576.18 1321.13 1568.06 1323.32"
        fill="#000"
      />
      <path
        d="M1325.55 1560.52C1325.55 1560.52 1324.61 1563.64 1323.99 1565.2C1315.87 1590.79 1320.24 1618.88 1336.16 1640.73C1372.05 1690.04 1427.3 1655.71 1443.53 1644.16C1459.76 1632.61 1509.07 1590.48 1473.18 1541.16C1457.57 1519.63 1433.54 1507.15 1407.01 1506.52C1407.01 1506.52 1404.2 1506.52 1402.64 1506.52H1399.52C1370.49 1506.21 1342.72 1494.97 1320.56 1475C1333.04 1502.46 1334.91 1532.74 1325.86 1560.83"
        fill="#000"
      />
      <path
        d="M44.6321 628.263C37.1413 651.671 35.8929 673.518 41.511 690.996C47.4411 709.722 60.862 722.519 81.1495 729.073C106.119 737.187 132.648 733.442 154.496 718.773C156.681 716.901 159.49 714.716 162.611 712.843C186.644 696.614 215.047 690.06 243.761 692.869C218.168 678.2 198.817 655.416 189.453 627.951C188.517 625.454 187.893 622.958 186.956 620.461V619.837C178.217 595.493 158.554 576.454 133.585 568.34C125.782 565.843 118.603 564.906 112.049 564.906C68.977 564.906 49.938 612.346 44.6321 628.888"
        fill="#000"
      />
      <path
        d="M177.282 1335.8C127.967 1371.69 162.3 1426.93 173.848 1443.16C185.397 1459.08 227.532 1508.71 276.846 1472.81C298.07 1457.21 310.866 1433.18 311.491 1406.65C311.491 1404.46 311.491 1401.65 311.491 1398.53C312.115 1369.2 323.351 1341.73 343.326 1319.57C327.409 1326.75 310.242 1330.81 293.076 1330.81C281.84 1330.81 270.916 1329.25 260.304 1326.13C256.559 1325.19 252.813 1323.94 249.38 1322.38C242.201 1320.2 234.711 1319.26 227.22 1319.26C209.741 1319.26 192.263 1324.88 177.282 1335.49"
        fill="#000"
      />
      <path
        d="M81.1492 1089.24C23.0959 1108.28 38.7016 1171.32 44.6318 1190.36C52.4347 1213.77 64.295 1231.87 78.9644 1242.79C94.8822 1254.34 113.297 1256.84 133.584 1250.28C158.554 1242.17 177.905 1223.44 186.956 1198.79C187.58 1196.29 188.516 1192.86 189.765 1189.11C199.753 1161.96 218.479 1139.8 243.761 1125.44C239.391 1125.76 235.022 1126.38 230.652 1126.38C205.059 1126.38 181.026 1118.58 160.738 1104.22C160.114 1103.6 159.49 1103.28 159.178 1102.97C158.241 1102.35 157.617 1101.72 156.681 1101.1C142.324 1090.8 124.845 1085.18 107.367 1085.18C98.6276 1085.18 89.8884 1086.43 81.1492 1089.24Z"
        fill="#000"
      />
      <path
        d="M417.609 1506.52H414.488H413.864C386.71 1506.52 361.116 1519.63 345.199 1541.48C309.305 1591.1 358.932 1632.92 375.162 1644.47C395.137 1659.14 415.424 1666.63 433.839 1666.94C453.502 1666.94 469.732 1658.2 482.217 1641.04C497.822 1619.5 502.504 1592.35 494.389 1566.44C493.765 1564.88 493.141 1562.39 492.204 1560.2C483.465 1531.8 485.65 1502.15 497.822 1475C475.35 1495.29 447.26 1506.52 417.297 1506.52"
        fill="#000"
      />
      <path
        d="M627.038 1628.87C625.477 1629.49 623.917 1629.8 622.044 1630.43C622.044 1630.43 621.732 1630.43 621.42 1630.43C596.138 1638.85 576.475 1658.83 568.36 1684.42C549.633 1742.47 609.871 1767.13 628.598 1773.37C647.637 1779.61 710.684 1794.9 729.411 1736.85C737.214 1712.51 733.781 1686.29 720.048 1665.07C719.111 1663.82 718.175 1662.88 717.239 1661.64C716.615 1661.01 715.99 1660.39 715.678 1659.76C714.43 1657.89 712.869 1656.02 711.933 1654.15C696.639 1631.05 690.085 1602.65 692.894 1574.25C677.912 1600.15 654.816 1619.5 627.038 1628.87Z"
        fill="#000"
      />
      <path
        d="M627.974 44.6307C604.565 52.4333 586.151 64.2932 575.539 78.9621C563.991 94.8793 561.494 113.293 568.048 133.58C576.163 158.548 595.202 178.211 619.859 186.95C622.668 187.886 625.165 188.822 627.35 189.447C655.128 199.122 677.912 218.472 692.582 244.065C689.46 215.039 696.327 186.325 712.557 162.918C714.742 159.797 716.927 156.988 719.111 154.179C733.156 132.956 736.902 106.427 728.787 81.4589C722.232 61.1722 709.436 47.7518 690.709 41.8218C683.53 39.325 675.415 38.3887 666.676 38.3887C654.816 38.3887 641.707 40.5734 627.974 44.9428"
        fill="#000"
      />
      <path
        d="M864.244 1680.05C862.059 1681.61 859.874 1683.17 857.69 1684.73C857.69 1684.73 857.377 1685.04 857.065 1685.36C836.778 1701.27 824.605 1725.62 824.605 1752.15C824.605 1773.37 832.72 1790.22 848.638 1801.77C863.62 1812.38 884.531 1818 909.188 1818C929.164 1818 993.771 1813.01 993.771 1751.83C993.771 1723.74 979.726 1697.53 956.63 1681.61C932.909 1664.13 916.055 1637.92 909.813 1608.58C903.57 1637.6 887.965 1662.88 864.868 1680.36"
        fill="#000"
      />
      <path
        d="M65.8561 824.576C4.68171 824.576 0 889.494 0 909.468C0 929.443 4.68171 994.048 65.8561 994.048C92.3858 994.048 117.043 981.876 132.961 960.653C133.897 959.093 135.458 956.908 137.33 954.723C155.121 931.315 180.402 915.398 209.429 909.468C179.778 903.226 154.184 886.997 136.706 863.277L134.833 860.468C134.833 860.468 134.833 860.156 134.521 860.156C118.603 838.309 93.0101 825.2 65.8561 825.2V824.576Z"
        fill="#000"
      />
      <path
        d="M1105.51 1655.39C1103.32 1658.52 1101.14 1661.32 1098.95 1664.13C1084.91 1685.36 1080.85 1712.2 1088.97 1736.85C1095.52 1757.14 1108.63 1770.56 1127.36 1776.49C1144.83 1782.11 1166.68 1780.86 1190.09 1773.37C1209.13 1767.13 1269.06 1742.47 1250.02 1684.42C1241.9 1659.45 1222.86 1640.1 1198.21 1631.05C1196.02 1630.43 1192.9 1629.49 1190.09 1628.55C1162.31 1618.88 1140.15 1599.53 1125.48 1574.25C1128.61 1603.27 1121.74 1631.99 1105.51 1655.39Z"
        fill="#000"
      />
      <path
        d="M1751.9 824.264C1725.37 824.264 1701.02 836.436 1684.79 857.659C1683.86 859.22 1682.3 861.404 1680.74 863.277C1662.94 886.685 1637.66 902.602 1608.64 908.844C1638.29 915.086 1663.88 931.315 1681.36 955.035C1681.36 955.347 1681.67 955.659 1681.98 955.971C1682.61 956.908 1683.23 957.532 1683.54 958.468C1699.46 980.316 1725.37 993.424 1752.21 993.424C1773.43 993.424 1790.29 985.309 1801.84 969.392C1812.45 954.411 1818.38 933.5 1818.07 908.844C1818.07 888.869 1813.07 824.264 1752.21 824.264H1751.9Z"
        fill="#000"
      />
      <path
        d="M1126.73 41.5097C1108.01 47.4397 1095.21 60.8601 1088.66 81.1468C1080.54 106.115 1084.29 132.644 1098.96 154.491C1100.83 156.676 1103.01 159.485 1104.89 162.294C1121.12 186.325 1127.67 214.727 1124.86 243.44C1139.53 217.848 1162.31 198.498 1189.78 189.134C1192.28 188.198 1194.77 187.574 1197.27 186.95H1197.9C1222.24 178.211 1241.28 158.86 1249.39 133.58C1255.95 113.293 1253.45 94.8793 1241.9 78.9621C1230.98 64.2932 1212.88 52.4333 1189.16 44.6307C1175.74 40.2613 1162.63 38.0766 1150.77 38.0766C1142.03 38.0766 1133.91 39.325 1126.42 41.5097"
        fill="#000"
      />
      <path
        d="M908.876 0C888.901 0 824.293 4.68154 824.293 66.1658C824.293 92.6946 836.465 117.039 857.689 133.268C859.25 134.204 861.435 135.765 863.619 137.637C887.34 155.427 902.946 180.708 909.188 209.733C915.43 180.083 931.66 154.491 955.381 137.013C956.317 136.389 957.254 135.765 958.19 135.141C958.19 135.141 958.19 135.141 958.19 134.829C980.038 118.911 993.459 93.3188 993.147 66.1658C993.147 44.6307 985.032 28.0893 969.114 16.5415C954.133 5.92996 933.221 0.312103 908.564 0.312103L908.876 0Z"
        fill="#000"
      />
      <path
        d="M374.849 173.841C358.619 185.701 309.305 227.523 345.199 277.147C360.804 298.37 384.837 311.167 411.367 311.791C411.367 311.791 414.176 311.791 415.736 311.791H419.17C448.508 312.415 475.974 323.651 497.822 343.313C485.962 316.785 483.465 287.447 491.58 260.294C492.516 256.549 494.077 252.803 495.325 249.37C502.192 225.026 497.51 198.497 482.217 177.274C467.859 157.924 451.005 151.37 434.151 151.37C408.558 151.37 384.525 166.663 374.849 173.841Z"
        fill="#000"
      />
      <path
        d="M1512.51 1140.11C1482.23 1140.11 1455.39 1155.72 1442.59 1180.69C1433.23 1198.79 1432.29 1220.01 1439.78 1240.3C1447.27 1261.21 1463.19 1278.37 1483.79 1288.67C1496.9 1295.23 1511.26 1298.66 1525.3 1298.66C1533.73 1298.66 1541.84 1297.41 1549.65 1295.23C1552.14 1294.29 1555.27 1293.35 1558.07 1292.42C1571.18 1286.8 1579.61 1271.82 1588.35 1254.34C1597.4 1236.24 1604.27 1221.57 1600.83 1207.21C1599.9 1204.41 1598.96 1201.28 1598.02 1198.16C1590.53 1177.56 1574.93 1160.4 1554.02 1149.79C1540.91 1143.23 1526.55 1139.8 1512.51 1139.8"
        fill="#000"
      />
      <path
        d="M1587.41 723.767C1582.42 723.767 1577.43 724.079 1572.43 725.015C1549.65 728.761 1529.36 740.308 1515.63 757.786C1502.21 774.64 1496.9 795.238 1500.02 815.525C1505.95 852.978 1540.28 880.131 1581.8 880.131C1586.79 880.131 1591.78 879.818 1596.78 878.882C1618.94 875.449 1638.29 864.525 1652.02 847.984V847.048L1654.83 843.927C1654.83 843.927 1657.01 840.806 1658.58 838.621C1666.38 826.137 1664.19 808.347 1661.38 789.621C1658.26 769.646 1654.83 753.729 1643.91 744.054C1641.41 742.493 1638.6 740.308 1635.79 738.124C1622.06 729.073 1604.89 723.767 1587.1 723.767"
        fill="#000"
      />
      <path
        d="M1208.19 1600.46C1210.38 1600.78 1212.88 1601.09 1215.37 1601.09C1227.86 1601.09 1241.28 1594.85 1255.01 1587.67C1278.73 1575.5 1289.34 1566.44 1293.71 1554.27C1293.71 1554.27 1293.71 1554.27 1293.71 1553.96C1293.71 1553.96 1293.71 1553.65 1293.71 1553.34C1294.03 1552.09 1294.65 1550.84 1294.96 1549.9C1301.83 1528.06 1299.64 1504.34 1288.72 1482.8C1273.74 1453.15 1244.09 1434.11 1213.19 1434.11C1201.64 1434.11 1190.72 1436.61 1180.42 1441.92C1162.31 1451.28 1148.89 1467.82 1142.96 1488.42C1137.03 1509.64 1139.53 1532.74 1150.14 1553.65C1160.75 1574.56 1178.23 1590.16 1199.45 1597.66C1201.95 1598.28 1204.76 1599.22 1207.57 1600.15"
        fill="#000"
      />
      <path
        d="M1483.17 529.015C1441.03 550.55 1422.93 599.238 1442.28 637.314C1455.08 662.283 1481.92 677.888 1512.19 677.888C1526.55 677.888 1540.91 674.455 1554.02 667.588C1574.93 656.977 1590.54 639.499 1598.03 618.276C1598.96 615.467 1599.9 612.346 1600.52 609.849C1603.64 595.805 1596.78 580.199 1587.73 562.722C1578.36 544.62 1570.87 531.199 1558.08 525.269C1554.33 524.333 1550.58 523.085 1546.84 521.524C1539.66 519.651 1532.48 518.715 1524.99 518.715C1510.63 518.715 1496.28 522.148 1483.17 529.015Z"
        fill="#000"
      />
      <path
        d="M179.777 1078.32C197.88 1091.11 222.225 1096.42 245.321 1092.98C268.106 1089.24 288.393 1077.69 302.126 1060.21C315.547 1043.36 320.853 1022.76 317.732 1002.47C311.802 965.023 277.469 937.87 235.958 937.87C230.964 937.87 225.97 938.182 220.976 939.118C198.816 942.551 179.465 953.475 165.732 970.016V970.953L162.923 974.074C162.923 974.074 160.738 977.507 159.178 979.379C151.375 991.863 153.56 1009.65 156.681 1028.38C160.738 1054.6 166.044 1067.39 176.344 1075.51C177.593 1076.44 178.841 1077.38 180.089 1078.32"
        fill="#000"
      />
      <path
        d="M220.041 618.588C227.531 639.499 243.137 656.977 264.049 667.588C277.157 674.142 291.515 677.576 305.56 677.576C336.147 677.576 362.677 661.97 375.473 637.002C394.825 598.614 376.41 550.238 334.274 528.702C321.166 522.148 306.808 518.715 292.763 518.715C284.648 518.715 276.533 519.964 268.73 522.148C266.233 523.085 263.112 524.333 260.303 524.957C247.194 530.575 238.767 545.244 230.028 562.722C221.289 579.887 214.735 594.556 217.232 608.289L218.168 609.537V612.034C218.792 613.907 219.416 616.091 220.041 617.964"
        fill="#000"
      />
      <path
        d="M270.603 1296.48C277.469 1298.35 285.272 1299.28 293.075 1299.28C307.432 1299.28 321.79 1295.85 334.898 1288.99C355.498 1278.37 371.104 1261.21 378.595 1240.3C386.085 1220.01 384.837 1198.79 375.473 1180.69C362.677 1155.72 335.835 1140.11 305.56 1140.11C291.202 1140.11 276.845 1143.55 263.736 1150.41C243.137 1161.02 227.531 1178.19 220.04 1199.1C219.416 1201.91 218.48 1205.34 217.231 1208.77C214.11 1222.2 221.289 1237.8 230.028 1254.97C238.767 1272.13 247.194 1287.11 260.615 1292.73C263.736 1293.67 267.17 1294.92 270.603 1296.16"
        fill="#000"
      />
      <path
        d="M1500.33 1002.79C1497.21 1023.07 1502.83 1043.67 1516.25 1060.53C1529.98 1077.69 1549.96 1089.24 1572.74 1092.98C1595.84 1096.42 1619.56 1091.42 1637.98 1078.63L1638.6 1078C1639.22 1077.38 1640.16 1076.76 1641.1 1076.13C1652.02 1067.39 1657.33 1054.91 1661.7 1028.07C1666.07 1001.54 1664.82 987.806 1657.33 976.882L1656.08 975.01C1656.08 975.01 1655.45 974.074 1655.14 973.761C1641.41 955.347 1620.81 943.175 1597.09 939.43C1592.1 938.806 1587.41 938.182 1582.42 938.182C1540.91 938.182 1506.26 965.335 1500.33 1002.79Z"
        fill="#000"
      />
      <path
        d="M162.923 844.239C176.656 862.653 197.256 875.137 220.977 878.882C225.971 879.506 230.652 880.13 235.646 880.13C277.157 880.13 311.802 852.977 317.732 815.525C320.853 795.238 315.235 774.952 302.126 758.098C288.393 740.62 268.106 729.073 245.322 725.639C240.328 724.703 235.646 724.391 230.652 724.391C212.862 724.391 195.695 729.385 181.338 739.06C179.153 740.933 176.344 743.117 173.223 745.302C162.923 754.353 159.49 771.207 156.369 790.557C152.312 817.086 153.56 831.13 161.051 842.054V842.678C161.051 842.678 161.675 842.99 161.675 843.302C161.675 843.302 162.299 844.551 162.299 844.863L162.923 844.239Z"
        fill="#000"
      />
      <path
        d="M745.329 1645.41C754.693 1655.39 769.674 1658.52 789.649 1661.64C814.931 1665.69 828.664 1664.76 838.651 1658.83C840.836 1657.27 843.021 1655.39 845.518 1653.83C862.996 1640.41 874.857 1620.13 878.602 1596.72C885.781 1550.22 857.378 1506.83 814.931 1499.97C811.497 1499.34 807.44 1499.03 803.694 1499.03C765.304 1499.03 731.284 1530.55 724.73 1572.38C721.296 1594.53 725.666 1616.69 737.214 1634.8C738.775 1636.36 740.023 1637.92 740.96 1639.48L741.584 1640.41C742.832 1642.29 744.081 1643.53 744.705 1645.09"
        fill="#000"
      />
      <path
        d="M524.976 1556.46C530.594 1570.19 546.2 1579.24 563.366 1587.98C578.972 1595.78 591.769 1601.09 603.317 1601.09C606.438 1601.09 609.559 1600.78 612.68 1599.84C614.241 1599.22 615.802 1598.9 617.05 1598.59H617.674C639.21 1591.1 657.001 1575.5 667.925 1553.96C678.537 1533.36 680.721 1510.27 674.791 1488.73C668.861 1468.13 655.752 1451.59 637.338 1442.23C627.038 1436.92 616.114 1434.43 604.878 1434.43C573.978 1434.43 544.327 1453.46 529.346 1483.11C518.422 1504.34 516.237 1528.06 523.104 1549.9C523.728 1551.78 524.352 1553.96 525.289 1556.15"
        fill="#000"
      />
      <path
        d="M414.488 1474.69H417.609C440.706 1474.69 463.178 1465.32 480.344 1448.16C513.741 1414.76 516.238 1362.95 485.65 1332.68C471.917 1318.95 453.19 1311.46 432.903 1311.46C409.807 1311.46 387.022 1321.13 369.856 1338.3C353.314 1354.84 343.95 1376.37 343.326 1399.16C343.326 1401.97 343.326 1405.09 343.326 1407.27C344.575 1421.63 356.123 1434.43 370.168 1448.16C389.207 1467.2 401.067 1474.37 414.488 1474.69Z"
        fill="#000"
      />
      <path
        d="M1337.72 369.842C1321.49 386.071 1312.13 407.294 1311.19 429.454C1310.26 450.989 1317.75 470.963 1332.42 485.632C1346.15 499.365 1364.88 506.855 1385.16 506.855C1407.95 506.855 1431.04 497.18 1448.21 480.014C1464.13 464.097 1473.8 443.186 1474.74 421.339C1474.74 417.282 1474.43 413.536 1474.74 410.103C1473.18 396.059 1462.88 384.511 1448.21 369.842C1433.85 355.485 1422.62 345.186 1409.2 343.625C1404.83 343.625 1400.46 343.625 1396.71 343.313H1396.09C1374.55 344.562 1354.26 354.237 1338.35 369.842"
        fill="#000"
      />
      <path
        d="M1385.16 1311.14C1364.88 1311.14 1346.15 1318.64 1332.42 1332.37C1317.75 1347.04 1310.26 1366.7 1311.19 1388.55C1312.13 1410.71 1321.49 1431.62 1338.03 1448.16C1354.58 1464.7 1376.11 1474.06 1398.9 1474.69C1400.46 1474.69 1402.33 1474.69 1404.2 1474.69H1407.01C1421.37 1473.75 1434.16 1461.89 1448.21 1447.85C1462.57 1433.49 1473.49 1421.63 1474.74 1406.96C1474.74 1403.22 1474.74 1399.16 1474.74 1396.66C1473.8 1375.13 1464.44 1354.21 1447.9 1337.67C1430.73 1320.82 1407.95 1311.14 1385.16 1311.14Z"
        fill="#000"
      />
      <path
        d="M1013.75 1499.34C1010 1499.34 1006.26 1499.34 1002.51 1500.28C960.063 1507.15 931.661 1550.53 939.152 1597.03C942.897 1620.75 955.694 1641.97 974.421 1655.71C982.223 1661.32 991.275 1663.82 1004.07 1663.82C1010.63 1663.82 1018.43 1663.2 1028.42 1661.64C1048.39 1658.52 1064.31 1655.08 1073.99 1644.16C1075.55 1641.66 1077.73 1638.85 1079.92 1636.04C1092.09 1617.94 1096.46 1595.47 1093.02 1572.69C1086.47 1530.87 1052.45 1499.34 1014.06 1499.34"
        fill="#000"
      />
      <path
        d="M973.485 162.918C955.07 176.338 942.897 197.249 939.152 220.969C935.407 243.752 940.4 266.536 952.885 284.95C965.057 302.74 982.536 314.288 1002.82 317.409C1006.57 318.033 1010.31 318.345 1013.75 318.345C1052.14 318.345 1086.16 286.823 1092.71 245.001C1096.15 222.217 1091.46 199.434 1079.29 181.02C1077.42 178.835 1075.23 176.026 1073.05 172.905C1064 162.606 1047.14 159.173 1027.79 156.052C1020.61 154.803 1011.56 153.555 1002.82 153.555C991.587 153.555 983.472 155.739 976.294 160.421C974.733 161.357 973.797 161.981 973.172 162.606"
        fill="#000"
      />
      <path
        d="M1199.46 220.033C1178.54 227.523 1161.07 243.128 1150.45 264.039C1139.84 284.638 1137.66 307.734 1143.59 328.957C1149.52 349.867 1162.63 366.409 1181.04 375.772C1191.03 381.078 1201.95 383.575 1213.5 383.575C1244.4 383.575 1274.05 364.536 1289.03 334.574C1299.33 313.976 1301.83 291.192 1295.9 269.969C1294.65 267.16 1293.4 263.415 1292.47 259.67C1286.85 246.873 1272.18 238.759 1254.7 229.708C1241.28 222.842 1227.23 216.287 1215.06 216.287C1214.12 216.287 1213.19 216.287 1212.25 216.287L1211.63 217.848H1205.39C1203.51 218.472 1201.33 219.096 1199.46 219.721"
        fill="#000"
      />
      <path
        d="M343.326 421.339C344.263 442.874 353.626 463.785 370.168 480.014C387.334 496.868 410.119 506.855 432.903 506.855C453.19 506.855 471.917 499.365 485.65 485.632C500.008 471.276 507.498 451.301 506.874 429.766C505.938 407.607 496.574 386.384 480.032 370.154C463.49 353.613 441.954 344.25 419.17 343.625C418.234 343.625 416.673 343.625 413.864 343.625H411.055C396.698 344.562 383.901 356.422 369.856 370.154C355.811 384.511 344.575 396.371 343.326 411.04C343.326 414.161 343.326 417.594 343.326 421.339Z"
        fill="#000"
      />
      <path
        d="M738.151 181.956C726.29 199.746 721.609 222.842 725.042 245.313C731.596 287.135 765.617 318.657 804.007 318.657C807.752 318.657 811.497 318.657 815.243 317.721C835.53 314.6 853.009 302.74 865.181 284.95C877.354 266.536 882.347 243.752 878.602 220.969C874.857 197.873 863.308 177.899 845.83 164.166L842.709 162.918L842.085 161.357C842.085 161.357 839.9 159.797 838.964 159.173C833.033 155.739 825.543 153.867 815.867 153.867C807.752 153.867 798.389 155.115 789.649 156.364C769.674 159.485 753.756 162.918 744.081 173.841C742.52 176.338 740.335 179.147 737.838 181.956"
        fill="#000"
      />
      <path
        d="M521.544 270.593C515.926 291.192 518.735 314.288 529.035 334.574C544.016 364.224 573.667 383.262 604.566 383.262C616.115 383.262 627.039 380.766 637.338 375.46C655.753 366.097 668.862 349.555 674.792 328.644C680.722 307.421 678.225 284.326 667.613 263.727C657.002 242.816 639.523 227.211 618.299 219.721C615.178 218.784 612.369 217.848 609.872 217.224C607.688 216.912 605.191 216.599 602.694 216.599C590.209 216.599 576.788 223.154 563.055 230.02C545.889 238.759 531.219 247.186 525.289 260.294C524.353 263.415 523.104 266.848 521.856 270.593"
        fill="#000"
      />
    </svg>
  );
};

export default Expo;
