import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { config, url } from "../../Common/Common";

const HeroProject = () => {
  const [projects, setProjects] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // When the component mounts, restore the scroll position
    const savedScrollPosition = JSON.parse(localStorage.getItem('scrollPosition'));
    if (savedScrollPosition) {
      window.scrollTo(savedScrollPosition.x, savedScrollPosition.y);
    }
  }, []);

  // Add an event listener to save scroll position before unloading the page
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem(
        'scrollPosition',
        JSON.stringify({ x: window.scrollX, y: window.scrollY })
      );
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(url + `projects?limit=1`, config)
      .then(function (response) {
        const data = response.data.data;
        setProjects(data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const skeletonHeight = window.innerWidth > 768 ? 250 : 200;

  return (
    <div className="mx-auto max-w-sm md:max-w-2xl z-10 mb-[-4rem] md:mb-14 mx-5">
      <SkeletonTheme baseColor="#202121" highlightColor="#777">
        <div className="grid grid-cols-1 md:grid-cols-3 relative ">
          <div className="row-span-2 md:row-span-1 col-span-1 md:col-span-2 w-full">
            <div className=" md:hidden relative z-30 px-10 py-px -mb-5 w-full">
              <div className="bg-black text-md w-full md:text-lg border-[3px] border-[#D4B071] px-2 py-px rounded-full text-white text-center">Latest Launch</div>
            </div>
            {isLoading || !projects?.[0]?.image ? (
              <Skeleton width="100%" height={skeletonHeight} style={{ backgroundColor: "#202121", borderRadius: "2rem", border: "4px solid #D4B071" }} />
            ) : (
              <img className="aspect-[12/8] border-4 z-20 relative border-[#D4B071] rounded-[2rem] object-cover" src={projects[0].image} alt="best real estate agency in Dubai" />
            )}
          </div>
          <div className="flex flex-col flex-wrap bg-black/50 px-2 py-8 md:p-10 md:pb-4 border-4 border-[#D4B071] rounded-[2rem] w-full h-full mt-[-1.5rem] md:mt-0 ml-0 md:ml-[-2rem] text-white font-black text-center space-y-2">
            <div className="hidden md:block static z-20 px-10 py-px md:px-0 md:py-px mt-[-3.7rem] w-full">
              <div className="bg-black text-sm w-full md:text-md border-[3px] border-[#D4B071] px-2 py-px rounded-full">Latest Launch</div>
            </div>
            <div className="flex flex-col flex-grow justify-between items-center space-y-3">
              <p className="text-xl md:text-2xl font-Butler ">{isLoading || !projects?.[0]?.name ? <Skeleton width={150} count={3} /> : `${projects[0].name} by ${projects[0].developer_name}`}</p>
              {/* <p className="text-sm md:text-lg font-base">Starting from </p> */}
              <p className="text-sm md:text-lg">{isLoading || !projects?.[0]?.start_price ? <Skeleton width={150} count={2} /> : projects[0].start_price === "0" ? `Starting from Update Soon` : `Starting from AED ${projects[0].start_price}`}</p>
              {isLoading || !projects?.[0]?.slug ? (
                <Skeleton width={50} height={30} />
              ) : (
                <Link to={`/Projects/${projects[0].slug}`}>
                  <button className="bg-white text-md text-black border-[3px] border-[#D4B071] rounded-full px-3 py-px">Enquire Now</button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default HeroProject;
