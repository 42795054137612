import { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Filter from "../Components/Sections/Filter";
import axios from "axios";
import { config, url } from "../Common/Common";
import { BsFillSuitHeartFill, BsWhatsapp } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { BiTimeFive } from "react-icons/bi";
import Loading from "../Components/UI/Loading";
import { Link, useParams } from "react-router-dom";
import WidthCard from "../Components/Sections/WidthCard";
import Nothing from "../Components/UI/Nothing";
import FilterMobile from "../Components/Sections/FilterMobile";
import { t } from "i18next";
import Modal from "../Components/Modal/Modal";
import ImgSliderAmenities from "../Components/UI/ImgSliderAmenities";
import Card from "../Components/UI/Card";
import Title from "../Components/UI/Title";
import ProjectList from "./ProjectList";

const Developer = () => {
  const { name } = useParams();
  const [properties, setProperties] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("featured");
  const [isActive, setIsActive] = useState(false);
  const [developerDetails, setDeveloperDetails] = useState("");
  const [modal, setModal] = useState(false);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    axios
      .get(url + `developers/${name}`, config)
      .then(function (response) {
        // handle success
        setDeveloperDetails(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  useEffect(() => {
    axios
      .get(url + `properties?developer_id=${developerDetails.id}`, config)
      .then(function (response) {
        // handle success
        setProperties(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    
    axios
      .get(url + `projects?developer_id=${developerDetails.id}`, config)
      .then(function (response) {
        // handle success
        setProjects(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, [developerDetails.id, sortBy, limit]);



  // const favChange = (id) => {
  //         const item = developers.find(item => {
  //                 return item.id === id;
  //         })
  //         item.is_fav = !item.is_fav
  //         setDevelopers([...developers, item]);
  // }

  // const handleFilter = (filterVal) => {
  //   // filterVal.find('filterVal')
  //   const filter = filterVal.replace("undefined", "buy");
  //   axios
  //     .get(url + `properties${filter}&sort_by=${sortBy}`, config)
  //     .then(function (response) {
  //       // handle success
  //       setDevelopers(response.data.data);
  //       setLoading(false);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // };

  return (
    <Layout>
      {/* {window.innerWidth < 768 ? <FilterMobile onFilterChange={handleFilter} rentclassName="hidden" buyclassName="hidden" handleCloseFilter={() => setIsActive(false)} className={isActive && "active"} /> : ""} */}
      {modal && (
        <>
          <Modal closeModal={() => setModal(false)} />
        </>
      )}
      <div className="container mx-auto px-4 md:px-0  mt-20 md:mt-28">
        {developerDetails.images && (
          <div className=" relative flex jusity-center items-center">
            <ImgSliderAmenities imgs={developerDetails.images} developer={true} />
            <p className="absolute absolute left-1/2 transform -translate-x-1/2  z-10 md:text-4xl font-bold text-white">{developerDetails.name}</p>
          </div>
        )}
        <div className="flex justify-center items-center max-w-2xl py-6 space-x-4 mx-auto">
          <a href="#area" className="w-full py-2 text-center font-bold text-2xl bg-black text-[#D4B071] rounded-full border-2 border-[#D4B071]">
            Areas
          </a>
          <a href="#projects" className="w-full py-2 text-center font-bold text-2xl bg-black text-[#D4B071] rounded-full border-2 border-[#D4B071]">
            Projects
          </a>
          <a href="#properties" className="w-full py-2 text-center font-bold text-2xl bg-black text-[#D4B071] rounded-full border-2 border-[#D4B071]">
            Properties
          </a>
        </div>
        <div className="row flex items-start justify-center ltr:text-left rtl:text-right flex-wrap mt-12 lg:p-4 ">
          <div className="w-full md:w-2/3 md:pr-4 xl:pr-12">
            {developerDetails.description && (
              <>
                <div className="text-xl" style={{ overflowWrap: "anywhere" }} dangerouslySetInnerHTML={{ __html: developerDetails.content }} />
              </>
            )}

            {developerDetails.content && (
              <>
                <h1 id="area" className="ltr:text-left rtl:text-right  font-bold mt-12 text-2xl">
                  {t("Areas: ")}
                </h1>
                <div className="text-xl devList" style={{ overflowWrap: "anywhere" }} dangerouslySetInnerHTML={{ __html: developerDetails.description }} />
              </>
            )}
          </div>
          {/* <div className="w-full md:w-1/3 sticky top-20 z-50">
            <img src={developerDetails.icon} alt="" className="mx-auto mb-4" />
            <a href="tel:971505720423" className="w-full md:w-8/12 mx-auto my-2 p-2 py-6 text-md lg:text-lg bg-mainColor text-white rounded-lg h-10 gap-4  flex justify-start items-center text-center">
              {" "}
              <FiPhoneCall /> {t("Callback Request")}{" "}
            </a>
            <a
              href={`https://api.whatsapp.com/send?phone=971505720423`}
              rel="noreferrer"
              target="_blank"
              className="w-full md:w-8/12 mx-auto my-2 p-2 py-6 text-md lg:text-lg bg-mainColor text-white rounded-lg h-10  gap-4 flex justify-start items-center text-center"
            >
              {" "}
              <BsWhatsapp /> {t("Whatsapp Contact")}{" "}
            </a>
            <button className="w-full md:w-8/12 mx-auto my-2 p-2 py-6 text-md lg:text-lg bg-mainColor text-white rounded-lg h-10  gap-4 flex justify-start items-center text-center" onClick={() => setModal(true)}>
              {" "}
              <BiTimeFive /> {t("Request  Meeting")}{" "}
            </button>
          </div> */}
        </div>
        <div className="row flex flex-col items-start justify-start text-center">
          {/* <div className="w-full">
                                                {window.innerWidth < 768 ? '' : <Filter rentclassName="hidden" onFilterChange={handleFilter} />}
                                        </div> */}
          {/* <div className="w-full flex items-center justify-between flex-wrap border-b border-mainColor pb-4">
            <div className="btns flex items-center  justify-between md:justify-start mb-4 md:mb-0 w-full md:w-1/2">
              <Link to="/MapView" className="px-4 py-2 rounded-md mx-2  flex items-center relative overflow-hidden cursor-pointer  border border-mainColor bg-gray">
                <HiOutlineLocationMarker className="mx-2 text-mainColor" /> {t("Map View ")}
              </Link>
              <Link to="/WishList" className="px-4 py-2 rounded-md mx-2  flex items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-gray">
                <BsFillSuitHeartFill className="mx-2 text-mainColor" /> {t("Save Search")}{" "}
              </Link>{" "}
            </div>
            <div className="btns flex justify-between md:justify-end items-center  w-full md:w-1/2">
              <label className="mx-4" htmlFor="SortBy">
                {t("Sort By:")}
              </label>
              <select id="SortBy" className="border border-gray py-2 px-4" onChange={(e) => setSortBy(e.target.value)}>
                <option value="featured">{t("Featured")}</option>
                <option value="newest">{t("Newest")}</option>
                <option value="price_low">{t("Price (low)")}</option>
                <option value="price_high">{t("Price (high)")}</option>
                <option value="beds_low">{t("Beds (least)")}</option>
                <option value="beds_high">{t("Beds (most)")}</option>
                <option value="delivery_date_earliest">{t("Delivery date (earliest)")}</option>
                <option value="delivery_date_latest">{t("Delivery date (latest)")}</option>
              </select>
            </div>
          </div> */}
          <div className="row flex flex-col items-start justify-start text-center w-full">
            <Title title={t("Properties")} link={t("view all")} url={`/SubProperties/developer_id=${developerDetails.id}`} />
            <div className="flex flex-col md:flex-row w-full">
              <div className=" w-full flex flex-col justify-center items-center">
                {properties.length === 0 ? (
                  <Nothing />
                ) : (
                  <div className="relative grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 md:pt-8">
                    {properties.slice(0, 4).map((item, index) => (
                      <Card
                        id={item.id}
                        slug={item.slug}
                        name={item.title}
                        location={item.address}
                        area={item.size}
                        bedroom={item.bedrooms_number}
                        bathroom={item.bathrooms_number}
                        property_type={item.property_type}
                        price={item.price}
                        img={item.image}
                        imgs={item.images}
                        status={item.launch}
                        buy_or_sell={item.buy_or_sell}
                        price_year={item.price_year}
                        price_hidden={item.price_hidden}
                        description={item.description}
                        height={256}
                        width={380}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        {projects &&

          <div className="row flex flex-col items-start justify-start w-full">
            <Title title={t("Projects")} link={t("view all")} url={`/SubProperties/developer_id=${developerDetails.id}`} />
            <div className="flex flex-col md:flex-row">
              <div className=" w-full flex flex-col justify-center items-center">
                {projects.length === 0 ? (
                  <Nothing />
                ) : (
                  <div className="grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1">
                    {projects.slice(0, 4).map((project) => (
                      <ProjectList project={project} key={project.id} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        }
        </div>
      </div>
    </Layout>
  );
};

export default Developer;
