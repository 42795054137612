import { t } from 'i18next'
import { GiElectric } from 'react-icons/gi'

const ErrorAlert = (props) => {
          return (
                    <div className="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md  mb-8">
                              <div className="flex items-center justify-center w-12 bg-red">
                                        <GiElectric className='text-3xl text-white' />
                              </div>

                              <div className="px-4 py-2 -mx-3">
                                        <div className="mx-3 rtl:text-right ltr:text-left">
                                                  <span className="font-semibold text-red ">{t('Error')}</span>
                                                  <p className="text-sm text-gray-600  ">{props.error}</p>
                                        </div>
                              </div>
                    </div>
          )
}

export default ErrorAlert