import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import axios from "axios";
import { config, url } from "../Common/Common";

const MeetTeam = () => {
  const [Agents, setAgents] = useState([]);
  const [Team, setTeam] = useState([]);

  useEffect(() => {
    axios
      .get(url + `teams?limit=50&department=external`, config)
      .then(function (response) {
        const data = response.data.data;
        setAgents(data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(url + `teams?limit=50&department=Digital%20Marketing`, config)
      .then(function (response) {
        const data = response.data.data;
        setTeam(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <Layout>
      <section className="container mx-auto mt-16 md:mt-28">
        <div className="flex flex-col justify-center items-center">
          <p className=" px-6 py-3 bg-black rounded-full text-[#D4B071] text-xl md:text-3xl font-semibold">Meet The Team</p>
          <p className=" px-6 py-3 my-8 md:my-14 text-xl md:text-2xl font-semibold text-center">
            Keymax Real Estate have a team of highly qualified and trained professional
            <br />
            consultants constantly study the changed, dynamics and market trends in
            <br />
            Dubai Real Estate market in order to provide our clients
            <br />
            with the knowledge based information to assist them in their decisions.
          </p>
        </div>
        <div class="grid gap-5 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-3 grid-cols-1">
          {Agents.map((item, index) => (
            <div key={item.id} className={`${window.innerWidth > 1024 && index === 0 ? "col-span-3" : "col-span-1"} flex flex-col justify-center items-center bg-gray-200 p-4 flex justify-center items-center`}>
              <div className="shrink rounded-full overflow-hidden border-4 border-[#D4B071]">
                <img className="aspect-square object-cover w-64" src={item.img_url} alt={item.name} />
              </div>
              <div className="grow py-4 grid grid-col-1 gap-y-4 w-full md:w-2/3">
                <div className="shrink flex flex-col">
                  <p className="shrink font-bold text-lg text-center w-full">{item.name}</p>
                  <p className="grow font-medium text-lg text-center w-full">{item.position}</p>
                </div>
                <div className="grow grid grid-col-1 gap-y-2 w-full">
                  <p className="text-md text-black text-center w-full">Speaks: {item.languages}</p>
                  <div className={`flex items-center w-full ${window.innerWidth > 1024 && index === 0 ? "justify-center" : "justify-between"}  space-x-3`}>
                    <a
                      href={`tel:+${item.phone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`text-center ${window.innerWidth > 1024 && index === 0 ? "w-1/5" : "w-full"} border-2 border-[#D4B071] text-xl font-bold shadow-lg rounded-full`}
                    >
                      Call
                    </a>
                    <a
                      href={`https://api.whatsapp.com/send?phone=${item.whatsapp}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`text-center ${window.innerWidth > 1024 && index === 0 ? "w-1/5" : "w-full"}  border-2 border-[#D4B071] text-xl font-bold shadow-lg rounded-full`}
                    >
                      WhatsApp
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justiy-center items-center relative w-full">
          <div className="h-[1px] bg-black w-full" />
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white text-xl md:text-3xl inline-block font-Butler font-bold px-3">The Heart of Keymax</div>
        </div>

        <div class="grid grid-cols-3grid gap-5 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 mt-12 md:mt-24">
          {Team.map((item, index) => (
            <div key={item.id} className={`flex flex-col justify-center items-center bg-gray-200 p-4 flex justify-center items-center`}>
              <div className="shrink rounded-full overflow-hidden border-4 border-[#D4B071] bg-black">
                <img className="aspect-square object-cover w-64" src={item.img_url} alt={item.name} />
              </div>
              <div className="grow py-4 flex flex-col w-2/3">
                <div className="shrink flex flex-col">
                  <p className="shrink font-bold text-lg text-center w-full">{item.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default MeetTeam;
