import React, { useEffect, useState, useRef } from "react";
import { LoadScript, GoogleMap, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import Layout from "../Components/Layout";
import axios from "axios";
import { config, url } from "../Common/Common";
import Card from "../Components/UI/Card";
import Loading from "../Components/UI/Loading";
import { t } from "i18next";
import Lottie from "react-lottie";
import loading from "../Components/Lottie/Loading.json";
import marker from "../img/marker.png";
import { Link } from "react-router-dom";
import "./MapView.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRulerCombined } from "react-icons/fa";
import { IoIosBed } from "react-icons/io";
import { FaBath } from "react-icons/fa";

const MAP_OPTIONS = {
  scrollwheel: true,
  minZoom: 8,
  maxZoom: 18,
  mapTypeControl: false,
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        { visibility: "off" }, // Hide points of interest labels
      ],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [
        { visibility: "off" }, // Hide transit labels
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        { visibility: "off" }, // Hide road labels
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [
        { visibility: "off" }, // Hide administrative labels
      ],
    },
  ],
};

const MAP_THEME = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        color: "#878787",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f9f5ed",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#aee0f4",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const containerStyle = {
  width: "100%",
  height: "85vh",
  position: "fixed",
  top: "0",
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
};

const MapView = (props) => {
  const [defaultLocation, setDefaultLocation] = useState({ lat: 25.200012722064614, lng: 55.258894247149705 });
  const [mapref, setMapRef] = useState(null);
  const [lat, setLat] = useState(24.4241);
  const [lng, setLng] = useState(53.8474);
  const [zoom, setZoom] = useState(12);
  const [recomend, setRecomend] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [markerData, setMarkerData] = useState("");

  useEffect(() => {
    setIsLoading(true);
    if (mapref) {
      const newCenter = mapref.getCenter();
      const zoomLevel = mapref.getZoom();

      setLat(newCenter.lat());
      setLng(newCenter.lng());
      setZoom(zoomLevel);

      const bounds = mapref.getBounds();
      const updatedMarkers = ranges.filter((marker) => marker.lat >= bounds.getSouthWest().lat() && marker.lat <= bounds.getNorthEast().lat() && marker.lng >= bounds.getSouthWest().lng() && marker.lng <= bounds.getNorthEast().lng());

      setRecomend(updatedMarkers);
      setIsLoading(false);
    }
  }, [zoom, lat, lng, mapref]);

  const handleOnLoad = (map) => {
    setMapRef(map);
    handleCenterChanged();
    handleNewProperties();
  };
  const handleCenterChanged = () => {
    axios
      .get(url + `properties/map/rangs`, config)
      .then(function (response) {
        const data = response.data.data;
        setRanges(data);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .get(url + `properties/map/properties?zoom=12&selected_lat=24.4241&selected_lng=53.8474`, config)
      .then(function (response) {
        // const data = response.data.data;
        setRecomend(response.data.data);
      })
      .catch(function (error) {
        console.log("Error fetching data:", error);
        // Additional error handling if needed
      });
  };

  // const center = mapref.getCenter();
  const handleNewProperties = () => {
    setIsLoading(true);
    if (mapref) {
      const newCenter = mapref.getCenter();
      const zoomLevel = mapref.getZoom();

      setLat(newCenter.lat());
      setLng(newCenter.lng());
      setZoom(zoomLevel);

      const bounds = mapref.getBounds();
      const updatedMarkers = ranges.filter((marker) => marker.lat >= bounds.getSouthWest().lat() && marker.lat <= bounds.getNorthEast().lat() && marker.lng >= bounds.getSouthWest().lng() && marker.lng <= bounds.getNorthEast().lng());

      setRecomend(updatedMarkers);
      setIsLoading(false);
    }
  };

  // const handleBoundsChange = (bounds) => {
  //   // Perform additional actions based on the new bounds
  // };

  let selectedPrice = null;
  let priceSuffix = "";

  if (markerData.price_month !== "0" && markerData.price_month) {
    selectedPrice = markerData.price_month;
    priceSuffix = " / Month";
  } else if (markerData.price_year !== "0" && markerData.price_year) {
    selectedPrice = markerData.price_year;
    priceSuffix = " / Year";
  } else {
    selectedPrice = markerData.price;
  }

  return (
    <Layout>
      <div className="MapView flex items-start justify-center mt-20 md:mt-28 ">
        <div className="row flex items-start justify-center flex-wrap-reverse md:flex-wrap w-full">
          <div className="w-full sm:w-1/2 md:w-5/12 flex items-start flex-wrap justify-center relative overflow-y-auto h-auto md:h-[85vh] noScrollBarr">
            {isLoading ? (
              <div className="w-full flex items-center justify-center h-full flex-col">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <h3 className="font-bold mt-12 ">{t("Loading")} . . . . </h3>
              </div>
            ) : (
              <div className="relative grid gap-5 row-gap-5 mb-8 md:grid-cols-2 grid-cols-1 md:pt-5 mx-4">
                {recomend &&
                  recomend.length > 0 &&
                  recomend
                    .slice(0, 12)
                    .map((item, index) => (
                      <Card
                        map={true}
                        key={index}
                        id={item.id}
                        slug={item.slug}
                        name={item.title}
                        location={item.Location}
                        area={item.area}
                        bedroom={item.bedroom}
                        bathroom={item.bathroom}
                        property_type={item.buy_or_sell}
                        price={item.price}
                        imgs={item.images}
                        buy_or_sell={item.buy_or_sell}
                        price_year={item.price_year}
                        price_month={item.price_month}
                        price_hidden={item.price_hidden}
                      />
                    ))}
              </div>
            )}
          </div>

          <div className="w-full sm:w-1/2 md:w-7/12 rounded-xl relative overflow-hidden">
            <div className="mb-8 md:mb-0">
              <LoadScript googleMapsApiKey="AIzaSyCSu32tihJqyOGNpcNdUnjBbNBoACV2R_M">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  onZoomChanged={handleNewProperties}
                  onDragEnd={handleNewProperties}
                  onLoad={handleOnLoad}
                  center={defaultLocation}
                  zoom={zoom}
                  options={{
                    ...MAP_OPTIONS,
                    styles: MAP_THEME,
                  }}
                >
                  <MarkerClusterer
                    gridSize={60}
                    options={{
                      clusterClass: "custom-cluster",
                      averageCenter: true,
                      minimumClusterSize: 2,
                      styles: [
                        {
                          width: 40,
                          height: 40,
                        },
                      ],
                    }}
                    calculator={(markers, numStyles) => ({
                      text: markers.length.toString(),
                      index: numStyles,
                      title: markers.map((marker) => marker.getTitle()),
                    })}
                  >
                    {(clusterer) =>
                      ranges.map((item, index) => (
                        <Marker
                          key={index}
                          position={{ lat: +item.lat, lng: +item.lng }}
                          onClick={() => setMarkerData(item)}
                          clusterer={clusterer} // Pass the clusterer to the Marker component
                        />
                      ))
                    }
                  </MarkerClusterer>

                  {markerData && (
                    <InfoWindow position={{ lat: +markerData.lat, lng: +markerData.lng }}>
                      <div className="relative">
                        <button onClick={() => setMarkerData("")} className="absolute top-2 right-2 z-[1]">
                          <AiOutlineCloseCircle className="text-white text-xl font-bold" />
                        </button>
                        <Link to={`/PropertyDetails/${markerData.slug}`}>
                          <div className="relative">
                            <img className="w-full mx-auto" src={markerData.images[0].medium} />
                            {markerData.buy_or_sell && (
                              <div class="absolute bottom-2 left-4 z-[2]">
                                <p class=" bg-white px-4 py-2 rounded-full font-bold capitalize">{markerData.buy_or_sell}</p>
                              </div>
                            )}
                          </div>
                          <h1 className="text-xl font-bold font-Butler p-3">{markerData.title}</h1>

                          {(markerData.price || markerData.price_year || markerData.price_month) && markerData.price_hidden === "0" && <div className="text-lg font-bold px-3">AED {`${selectedPrice} ${priceSuffix}`}</div>}
                          {markerData.price_hidden === "1" && (
                            <button href="" className="text-lg font-bold px-3">
                              {" "}
                              {t("Ask for price")}{" "}
                            </button>
                          )}

                          <div className="flex-1 w-full flex items-center flex-wrap justify-start ltr:ml-5 rtl:mr-5 space-x-4 md:space-x-2 lg:space-x-4 rtl:space-x-reverse text-center">
                            <div className="flex items-center justify-center py-2 text-overflow ">
                              <div className="w-4">
                                <FaRulerCombined className="text-lg text-black" />
                              </div>
                              <span className="rtl:mr-2 ltr:ml-2 text-overflow text-bold">
                                {markerData.area} {t(".sqft")}
                              </span>
                            </div>
                            <div className="flex items-center justify-center py-2 text-overflow">
                              <div className="w-4">
                                <IoIosBed className="text-xl text-black" />
                              </div>
                              <span className="rtl:mr-2 ltr:ml-2 text-bold">{markerData.bedroom == 0 ? "Studio" : markerData.bedroom}</span>
                            </div>
                            <div className="flex items-center justify-center py-2 text-overflow ">
                              <div className="w-4">
                                <FaBath className="text-lg text-black" />
                              </div>
                              <span className="rtl:mr-2 ltr:ml-2 text-bold">{markerData.bathroom}</span>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MapView;
