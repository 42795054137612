import Layout from "../Components/Layout";
import Buy from "../Components/Sections/Buy";
import Hot from "../Components/Sections/Hot";
import Sell from "../Components/Sections/Sell";
import Banner from "../Components/Sections/Banner";
import Recomend from "../Components/Sections/Recomend";
import Projects from "../Components/Sections/Projects";
import Developers from "../Components/Sections/Developers";
import ListSellYourProperty from "../Components/Sections/ListSellYourProperty/ListSellYourProperty";
import OffPlan from "../Components/Sections/OffPlan";
import ReactRecaptcha3 from "react-google-recaptcha3";

import { Helmet } from "react-helmet-async";

// Home Page
const Home = () => {
  return (
    <>
      <Helmet>
        <title>Real estate agency dubai, uae | Dubai real estate broker | Keymax real estate</title>
        <meta name="description" content="Find your ideal Dubai property! Top agency, best choices for investment or living. Expert advice for buying/renting in Dubai's competitive market." />
        <link href="https://keymaxrealestate.com/Buy" rel="canonical" />
      </Helmet>
      <Layout HomePage={true}>
        <section className="Recomend">
          <Banner />
        </section>

        <section className="About bg-black py-8">
          <div className="container mx-auto">
            <div className="text-base lg:text-lg py-10 text-white">
              <span className="block mb-4">
                Keymax Real Estate is a Dubai-based company that was founded in 2014 by Dubai Land Department accredited consultants and industry-savvy directors. We take immense pride in being recognised as the{" "}
                <h1 className="inline">best real estate agency in Dubai</h1>. With years of experience and a deep understanding of the local property market, we offer unparalleled services to our valued clients.
              </span>{" "}
              <span className="block mb-4">
                Our team of dedicated professionals are committed to providing top-notch solutions, whether you are buying, selling, or renting properties in Dubai. As the{" "}
                <h2 className="inline">best real estate brokers in dubai</h2>, we strive to exceed expectations, offering expert guidance and personalised assistance to ensure your real estate journey is smooth and successful.
              </span>
              <span className="block">Trust Keymax Real Estate for all your property needs in Dubai and experience the difference that sets us apart as the premier choice in the industry.</span>
            </div>
          </div>
        </section>
        <div className="container mx-auto ">
          {/* <section className="Recomend relative z-0">
          <Hot />
        </section>
        <section className="Recomend relative z-0">
          <Recomend />
        </section> */}
          <section className="Projects">
            <Projects />
          </section>
          <section className="Buy">
            <OffPlan />
          </section>
          <section className="Rent">
            <Sell />
            {/* Any word Sell means rent  component */}
          </section>
          <section className="Buy">
            <Buy />
          </section>

          <section className="Developers">
            <Developers />
          </section>
          <section className="ListSellYourProperty">
            <ListSellYourProperty />
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Home;
// Home Page
