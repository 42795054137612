import React from "react";

const Museum = ({ width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 2051 1025" fill="none">
      <path
        d="M1332.79 768.587C1049.82 768.587 820.42 653.88 820.42 512.391C820.42 370.903 1049.82 256.196 1332.79 256.196C1615.75 256.196 1845.16 370.903 1845.16 512.391C1845.16 653.88 1615.75 768.587 1332.79 768.587ZM1025.37 0C459.406 0 0.630859 229.415 0.630859 512.391C0.630859 795.368 459.406 1024.78 1025.37 1024.78C1591.33 1024.78 2050.1 795.368 2050.1 512.391C2050.1 229.415 1591.33 0 1025.37 0Z"
        fill="#000"
      />
    </svg>
  );
};

export default Museum;
