import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout/index.jsx'
import axios from 'axios'
import { config, url } from '../Common/Common.jsx'
import { t } from 'i18next'

const Faqs = () => {
  const [faq, setFaq] = useState([])
  const [id, setID] = useState()

  useEffect(() => {
    axios.get(url + `faqs`, config)
      // Response
      .then(response => {
        const data = response.data.data
        setFaq(data)
        console.log(data)
      })
      // Check Error
      .catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <Layout>
      <div className="container mx-auto mt-24 px-4 md:px-0">
        <div className="row flex items-start justify-start text-center flex-wrap">
          <h3 className='font-bold text-2xl text-center md:text-4xl w-full my-8'>  <span className='border-b-2 border-[#CDAC62] text-black px-4 md:px-4'>{t(`FAQ's`)} </span> </h3>

          {
            faq &&
            <>
              {
                faq.map((item, index) => (
                  <div className="w-full border border-black bg-[#D4B071] rounded-lg mt-4 cursor-pointer" onClick={() => setID(item.id)} key={index}>
                    <div className="flex items-center justify-between w-full p-8">
                      <h1 className="font-medium text-black text-lg lg:text-xl">{item.question}</h1>
                      {id == item.id ?
                        <span className="text-gray-400 bg-light rounded-full">
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 12H6" />
                          </svg>
                        </span>
                        :
                        <span className=" bg-light rounded-full">
                          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                        </span>
                      }
                    </div>

                    <div className={`bg-white rounded-b-lg mt-4 ${id == item.id ? 'flex ltr:text-left rtl:text-right' : 'hidden'}`}>
                      <p className="p-2 text-md leading-9 text-black text-md lg:text-lg">{item.answer}</p>
                    </div>
                  </div>
                ))
              }
            </>
          }
        </div>
      </div>
    </Layout>
  )
}

export default Faqs

