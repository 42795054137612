import { Link } from "react-router-dom";

export function Pagination(props) {
  const scrollToTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
    props.onClick(
      isNaN(e.target.text)
        ? isNaN(e.target.getAttribute("selectedNum"))
          ? 1
          : e.target.getAttribute("selectedNum")
        : e.target.text
    );
  };

  const Nothing = () => {
    return;
  };

  return (
    <>
      {props.total == 0 ? (
        <p className="pagination flex items-center justify-center space-x-2 w-full">
          .. No properties found for selected filters ..
        </p>
      ) : (
        ""
      )}
      <nav className="pagination flex items-center justify-center space-x-2 w-full">
        <Link
          selectedNum={1}
          to={`${
            props.endPage > 1 && props.currentPage != 1
              ? "/" + props.title + "?page=1"
              : "javascript:void(0)"
          }`}
          onClick={
            props.endPage > 1 && props.currentPage != 1 ? scrollToTop : Nothing
          }
          className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600"
        >
          &lt;&lt; {/* First Page */}
        </Link>
        <Link
          selectedNum={props.currentPage > 1 ? props.currentPage - 1 : 1}
          to={
            props.currentPage > 1
              ? `/${props.title}?page=${props.currentPage - 1}`
              : "javascript:void(0)"
          }
          onClick={props.currentPage > 1 ? scrollToTop : Nothing}
          className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600"
        >
          &lt; {/* Previous Page */}
        </Link>
        {props.pages.map((page) => (
          <Link
            to={`/${props.title}?page=${page}`}
            onClick={scrollToTop}
            key={page}
            className={`rounded-full black-bg px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 ${
              page === props.currentPage ? "text-white bg-black" : "text-black"
            }`}
          >
            {page}
          </Link>
        ))}
        <Link
          selectedNum={
            props.currentPage < props.endPage ? props.currentPage + 1 : 1
          }
          to={
            props.currentPage < props.endPage
              ? `/${props.title}?page=${props.currentPage + 1}`
              : "javascript:void(0)"
          }
          onClick={props.currentPage < props.endPage ? scrollToTop : Nothing}
          className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600"
        >
          &gt; {/* Next Page */}
        </Link>
        <Link
          selectedNum={props.endPage}
          to={`${
            props.endPage > 1 && props.currentPage != props.endPage
              ? "/" + props.title + "?page=" + props.endPage
              : "javascript:void(0)"
          }`}
          onClick={
            props.endPage > 1 && props.currentPage != props.endPage
              ? scrollToTop
              : Nothing
          }
          className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600"
        >
          &gt;&gt; {/* Last Page */}
        </Link>
      </nav>
    </>
  );
}
