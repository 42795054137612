import { useEffect, useState } from 'react';
import Layout from '../Components/Layout'
import Filter from '../Components/Sections/Filter'
import WidthCard from '../Components/Sections/WidthCard'
import axios from "axios";
import { config, url } from '../Common/Common';
import { BsFillSuitHeartFill } from 'react-icons/bs'
import { HiOutlineAdjustments, HiOutlineLocationMarker } from 'react-icons/hi'
import List from '../Components/UI/List';
import Loading from '../Components/UI/Loading';
import Nothing from '../Components/UI/Nothing';
import { Link, useParams } from 'react-router-dom';
import FilterMobile from '../Components/Sections/FilterMobile';
import { t } from 'i18next';


const LongestPayment = () => {
     const { id } = useParams();
     const [buy, setBuy] = useState([])
     const [list, setList] = useState([])
     const [isLoading, setLoading] = useState(true)
     const [sortBy, setSortBy] = useState('featured')
     const [urlPage, setUrlPage] = useState(window.location.search)
     const [isActive, setIsActive] = useState(false)

     useEffect(() => {
          axios.get(url + `properties?long_term=1&sort_by=${sortBy}`, config)
               .then(function (response) {
                    // handle success
                    setBuy(response.data.data);
                    setLoading(false)
               })
               .catch(function (error) {
                    // handle error
                    console.log(error);
               })
     }, [sortBy])

     useEffect(() => {
          axios.get(url + 'categories', config)
               .then(function (response) {
                    // handle success
                    setList(response.data.data);
               })
               .catch(function (error) {
                    // handle error
                    console.log(error);
               })
               .then(function () {
                    // always executed
               });
     }, [])



     return (
          <Layout>
               <div className="container mx-auto pt-8 md:pt-16">
                    <div className="row flex items-start justify-start text-center flex-wrap">
                         <div className="w-full flex items-center justify-between flex-wrap border-b border-mainColor pb-4">
                              <div className="btns flex items-center justify-between md:justify-start mb-4 md:mb-0 w-full md:w-1/2">
                                   <Link to='/MapView' className='w-1/3 md:w-auto px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex items-center relative overflow-hidden cursor-pointer  border border-mainColor bg-gray' ><HiOutlineLocationMarker className='mx-1 md:mx-2 text-mainColor' /> {t('Map View')} </Link>
                                   <Link to='/WishList' className='w-1/3 md:w-auto px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-gray' ><BsFillSuitHeartFill className='mx-1 md:mx-2 text-mainColor' /> {t('Whishlist')} </Link>
                                   <button onClick={() => setIsActive(true)} className='w-1/3 md:w-auto  px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex md:hidden items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-mainColor text-gray' ><HiOutlineAdjustments className='mx-1 md:mx-2 text-gray' /> {t('Filter')} </button>
                              </div>
                              <div className="btns flex justify-between md:justify-end items-center  w-full md:w-1/2">
                                   <label className='mx-4' htmlFor="SortBy">{t('Sort By:')}</label>
                                   <select id='SortBy' className='border border-gray py-2 px-4' onChange={(e) => setSortBy(e.target.value)}>
                                       {/*  <option value="featured">{t('Featured')}</option>*/}
                                        <option value="newest">{t('Newest')}</option>
                                        <option value="price_low">{t('Price (low)')}</option>
                                        <option value="price_high">{t('Price (high)')}</option>
                                        <option value="beds_low">{t('Beds (least)')}</option>
                                        <option value="beds_high">{t('Beds (most)')}</option>
                                        <option value="delivery_date_earliest">{t('Delivery date (earliest)')}</option>
                                        <option value="delivery_date_latest">{t('Delivery date (latest)')}</option>
                                   </select>
                              </div>
                         </div>
                         <h3 className='font-bold text-2xl text-center md:text-4xl w-full my-8'>  <span className='border-b-2 border-[#CDAC62] text-black px-4 md:px-4'> {t('Properties with longest payment plan')} </span> </h3>
                         <div className="w-full md:w-9/12 pt-8">
                              {/* {isLoading && <div className='absolute top-0 left-0 h-screen w-screen bg-white z-40 flex items-center justify-center'><Loading /></div>} */}
                              {buy.length === 0 && <Nothing />}
                              {
                                   buy.map((item, index) => (
                                        <WidthCard
                                             id={item.id}
                                             developer={item.developer_pic}
                                             key={index}
                                             name={item.title}
                                             description={item.description}
                                             location={item.address}
                                             image={item.image}
                                             area={item.size}
                                             bedrooms={item.bedrooms_number}
                                             bathrooms={item.bathrooms_number}
                                             rent_term={item.rent_term}
                                             price={item.price}
                                             whatsApp={item.whatsapp}
                                             phone={item.phone}
                                             email={item.email}
                                        />
                                   ))
                              }
                         </div>
                         <div className="w-full md:w-3/12 mt-12 px-4">
                              <div className="border border-mainColor px-4 py-4 rounded-lg">
                                   {
                                        list.map((item, index) => (
                                             <List key={index} id={item.id} name={item.name} total={item.total} />
                                        ))
                                   }
                              </div>
                         </div>
                    </div>
               </div>
          </Layout>
     )
}

export default LongestPayment