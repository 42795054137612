import React, { useEffect } from "react";
import Layout from "../Components/Layout";
import { config, url } from "../Common/Common";
import axios from "axios";
import { useState } from "react";
import Title from "../Components/UI/Title";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import Nothing from "../Components/UI/Nothing";
import Loading from "../Components/UI/Loading";
import { Helmet } from "react-helmet-async";
import FormOptions from "../Components/Sections/FormOptions";
import FormOptionsBuy from "../Components/Sections/FormOptionsBuy";
import FormOptionsManagement from "../Components/Sections/FormOptionsManagement";

const SingleService = ({ id, meta, details, buy, property_type }) => {
  const [singleService, setSingleService] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // const { id } = useParams();
  useEffect(() => {
    axios
      .get(url + `services/${id}`, config)
      .then(function (response) {
        const data = response.data.data;
        setSingleService(data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <link href={`https://keymaxrealestate.com/${meta.link}`} rel="canonical" />
      </Helmet>
      <Layout>
        <div className="">
          {isLoading ? (
            <Loading />
          ) : singleService ? (
            <>
              <div className="container mx-auto mt-20 md:mt-28 px-4 md:px-0 flex-col flex items-start justify-start text-center">
                <div className="w-full p-4 md:p-8 rounded-[3rem] bg-cover aspect-[18/9] object-cover" style={{ backgroundImage: `linear-gradient(to right, rgba(14 14 14 / 77%), rgba(0, 0, 0, 0) 60%), url(${singleService.img_url})` }}>
                  {/* <img src={singleService.img_url} alt="" className="rounded-[3rem] relative w-full bg-cover aspect-[18/9] object-cover" /> */}
                  <div className="flex flex-row justify-start items-center h-full px-6 lg:px-8">
                    <div className="w-full py-24 lg:py-0 drop-shadow-lg lg:w-2/5 font-base text-center lg:text-left">
                      <p className="text-[#D4B071] font-black text-4xl font-Butler mb-4">{details.title}</p>
                      <p className=" text-xl text-white drop-shadow-xl">{details.titleDescription}</p>
                    </div>
                  </div>
                </div>
                <div className="w-full rtl:text-right ltr:text-left p-4 md:p-8">
                  <div className="flex flex-col justify-center items-center w-full ">
                    <h1 className="text-3xl lg:text-4xl font-black py-5 max-w-4xl text-center text-[#D4B071] mb-4 lg:mb-8">{details.subHeading}</h1>
                    <div className="grid grid-cols-1 lg:grid-cols-2 text-lg text-center gap-8 md:text-xl">
                      {details.subHeadingDescription.map((el) => (
                        <p className="max-w-md">{el}</p>
                      ))}
                    </div>
                  </div>
                  {/* <div style={{ overflowWrap: "anywhere" }} dangerouslySetInnerHTML={{ __html: singleService.content }} /> */}
                </div>
              </div>
              <div className="h-[70vh] bg-center bg-cover w-full" style={{ backgroundImage: `url(${singleService.img2_url})` }}></div>
              <div className="container mx-auto mt-20 md:mt-28 px-4 md:px-0 bg-gray-300">
                <p className="text-center text-3xl lg:text-4xl font-black max-w-5xl mx-auto pb-8">{details.about}</p>
                <div className={`px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20`}>
                <div className="relative mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl h-full border-4 border-[#D4B071] rounded-[3rem]  bg-white">
                  <div className="w-full absolute left-1/2 transform -translate-x-1/2 top-[-4.2rem] lg:top-[-4.5rem] flex justify-center items-center my-10 z-10">
                    <p className="text-white bg-black rounded-full inline-block text-center text-xl md:text-3xl px-5 py-2 font-bold font-Butler border-4 border-[#D4B071]">List your Property</p>
                  </div>
                  {/* <p className="font-bold text-xl md:text-2xl text-center py-5" > </p> */}
                  {
                    details.title === "Buy with Us" ? <FormOptionsBuy /> : (details.title === "Property Management" ? <FormOptionsManagement /> :  <FormOptions property_type={property_type+ "?"} />)
                    
                  }

                  {/* <FormOptions service={true} buy={details.title === "Buy with Us" ? true : false} property_management={details.title === "Property Management" ? true : false}/> */}
              </div>
              </div></div>
            </>
          ) : (
            <div className="row flex items-center justify-center text-center flex-wrap min-h-[50vh]">
              <div className="w-full">
                <Nothing />
                <h3 className="font-bold text-3xl ">{t("Not Available")}</h3>
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default SingleService;
