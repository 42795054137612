import { useRef, useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { t } from "i18next";
import SuccessAlert from "../UI/SuccessAlert";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { recaptcha__secret_key, recaptcha_key, url, chechPhone } from "../../Common/Common";

import ReactRecaptcha3 from "react-google-recaptcha3";
import { AiOutlineCloseCircle } from "react-icons/ai";

const ProjectForm = ({
  heading,
  subHeading,
  topSpace,
  project_name,
  agent_id,
  phoneAg,
  slug,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("971");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState();
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef(null);


  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key)    
  }, [])

  const contactHandler = (e) => {
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    e.preventDefault();

    if (name.trim() == "") {
      setNameError(true);
      return;
    }
    if (email.trim() == "" || !email.includes("@")) {
      setEmailError(true);
      return;
    }

    if(!chechPhone(phone)){
      setPhoneError(true);
        return;
    }

    setLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + 'verifyRecaptcha',
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            axios
              .post(url + "contact-us", {
                name: name,
                email: email,
                phone: phone,
                message: `None`,
                agent_id: agent_id,
                source: `Project Name: ${project_name} / ${heading}`,
              })
              .then((response) => {
                setLoading(false);
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                Swal.fire({
                  timer: 3000,
                  title: `${t("Success")}`,
                  icon: "success",
                  html: `${t("Your message has been sent")}`,
                  confirmButtonText: `${t("ok")}`,
                  showCloseButton: true,
                  timerProgressBar: true,
                });
              })
              .catch((error) => {
                setErrToken(true);
                setLoading(false);
              });
          })
          .catch(() => {
            setErrToken(true);
            setLoading(false);
          });
      },
      (error) => {
        setErrToken(true);
        setLoading(false);
      }
    );
  };

  return (
    <>
      {success && (
        <div className="fixed bottom-4 left-4 z-50">
          <SuccessAlert success={success} />
        </div>
      )}
      <section
        className={`${
          topSpace ? `mt-[${topSpace}rem]` : ""
        } px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20`}
      >
        <div className="relative p-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-2 lg:px-8 lg:py-8 h-full border-4 border-[#D4B071] rounded-[3rem]  bg-white">
          <div className="w-full absolute left-1/2 transform -translate-x-1/2 top-[-4.2rem] lg:top-[-4.5rem] flex justify-center items-center my-10 ">
            <p className="text-white bg-black rounded-full inline-block text-center text-xl md:text-3xl px-5 py-2 font-bold font-Butler border-4 border-[#D4B071]">
              {heading}
            </p>
          </div>
          <p
            className="font-bold text-xl md:text-2xl text-center py-5"
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
          <form>
            <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 gap-x-0 md:gap-x-2 lg:gap-x-20 justify-center items-center">
              <div className="relative w-full">
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Name"
                  required
                  type="text"
                  className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border-b-2 border-black appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                />
                {nameError && (
                  <p className=" text-red text-sm">Please enter your name</p>
                )}
              </div>
              <div className="relative w-full">
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Email"
                  required
                  type="email"
                  className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                />
                {emailError && (
                  <p className=" text-red text-sm">Please enter your email</p>
                )}
              </div>
              <div className="relative w-full">
                <PhoneInput
                  placeholder="Enter phone number"
                  country={"ae"}
                  value={phone}
                  onChange={(value) => setPhone(value)}
                  className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-b-black focus:outline-none focus:shadow-outline"
                />
                {phoneError && (<p className=" text-red text-sm"> Please enter valid phone number </p> )}
              </div>
              
              <button
                disabled={loading}
                onClick={contactHandler}
                className="inline-flex items-center justify-center h-12 px-6 text-lg md:text-2xl mr-0 md:mr-6 font-medium tracking-wide text-black font-bold transition duration-200 rounded-full shadow-md bg-[#D4B071] hover:bg-blue-700 focus:shadow-outline focus:outline-none"
              >
                {loading ? (
                  <>
                    <span className="inline-flex items-center justify-center h-12 px-6">
                      <span className="lds-dual-ring"></span>
                    </span>
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
            {errToken && (
              <p className="text-red text-sm error-token right">
                please verify you are a human
              </p>
            )}
            <div className="flex justify-center pt-2 md:pt-10">
            
              <p className="text-md lg:text-xl">
                Or contact via{" "}
                <a
                  target="_blank"
                  href={`https://wa.me/${phoneAg?.replace(
                    "+",
                    ""
                  )}?text=Hi, I am interested in your property on Keymax  https://keymaxrealestate.com/PropertyDetails/${slug}`}
                >
                  <b>WhatsApp</b>
                </a>
              </p>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ProjectForm;
