import { t } from "i18next";
import React, { useRef } from "react";
import { useState } from "react";
import Layout from "../Components/Layout";
import { AiOutlineCloudUpload, AiOutlineCloseCircle } from "react-icons/ai";

import Loading from "../Components/UI/Loading";
import axios from "axios";
import {
  config,
  recaptcha_key,
  url,
  recaptcha__secret_key,
  chechPhone
} from "../Common/Common";
import { useEffect } from "react";
import ErrorAlert from "../Components/UI/ErrorAlert";
import SuccessAlert from "../Components/UI/SuccessAlert";
import BG from "../img/career_bg.webp";
import { HiLocationMarker } from "react-icons/hi";
import { BiTime } from "react-icons/bi";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import ReactRecaptcha3 from "react-google-recaptcha3";
import Swal from "sweetalert2/dist/sweetalert2.js";

const Careers = () => {
  const [job, setJob] = useState([]);
  const [upload, setUpload] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("971");
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [jobId, setJob_id] = useState();
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [loadingBook, setLoadingBook] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);


  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key);
  }, []);

  const jobHandler = (e) => {
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    e.preventDefault();

    if (name.trim() == "") {
      setNameError(true);
      return;
    }
    if (email.trim() == "" || !email.includes("@")) {
      setEmailError(true);
      return;
    }
    if(!chechPhone(phone)){
      setPhoneError(true);
        return;
    }

    setSellLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + "verifyRecaptcha",
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            let data = new FormData();
            data.append("email", email);
            data.append("phone", phone);
            data.append("name", name);
            data.append("job_id", jobId ? jobId : -1);
            data.append("cv", upload);
            const config = {
              headers: { "content-type": "multipart/form-data" },
            };
            axios
              .post(url + "request-jobs", data, config)
              .then((response) => {
                setUpload();
                setName("");
                setPhone("971");
                setEmail("");
                setJob_id("");
                setLoadingBook(false);
                // setSuccess(response.data.message);

                Swal.fire({
                  timer: 3000,
                  title: `${t("Success")}`,
                  icon: "success",
                  html: `${t(response.data.message)}`,
                  confirmButtonText: `${t("ok")}`,
                  showCloseButton: true,
                  timerProgressBar: true,
                });
              })
              .catch((error) => {
                setSellLoading(false);
                setErrToken(true);
              });
          })
          .catch(() => {
            setSellLoading(false);
            setErrToken(true);
          });
      },
      (error) => {
        setSellLoading(false);
        setErrToken(true);
      }
    );
  };

  // const jobHandler = () => {
  //   let data = new FormData();
  //   data.append("email", email);
  //   data.append("phone", phone);
  //   data.append("name", name);
  //   data.append("job_id", jobId);
  //   data.append("cv", upload);
  //   const config = {
  //     headers: { "content-type": "multipart/form-data" },
  //   };

  //   axios
  //     .post(url + "request-jobs", data, config)
  //     .then((response) => {
  //       setUpload();
  //       setName("");
  //       setPhone("");
  //       setEmail("");
  //       setJob_id("");
  //       setSuccess(response.data.message);
  //       setTimeout(() => {
  //         setSuccess();
  //       }, 3000);
  //     })
  //     .catch((error) => {
  //       console.log("Error:", error);
  //       setError(error.response.data);
  //       setTimeout(() => {
  //         setError();
  //       }, 3000);
  //     });
  // };

  useEffect(() => {
    axios(url + "jobs", config)
      .then(function (response) {
        const data = response.data.data;
        setJob(data);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const styles = {
    backgroundImage: `url(${BG})`,
  };

  const handleButtonClick = (item) => {
    setJob_id(item);
    setModal(true);
  };

  return (
    <Layout>
      {modal && (
        <>
          <div
            className="fixed top-0 left-0 bg-black opacity-75 w-screen h-screen z-50 bkDrop"
            onClick={() => setModal(false)}
          ></div>
          <div className="Modal w-5/6 md:w-2/6  h-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-3xl flex flex-col justify-center items-center relaive py-12 px-10">
            <button
              className="font-bold absolute rtl:left-4 ltr:right-4 top-4 text-2xl"
              onClick={() => setModal(false)}
            >
              {" "}
              <AiOutlineCloseCircle />{" "}
            </button>
            <h3 className="font-bold text-2xl">{t("Apply this Job")}</h3>
            <div className="mt-6 w-full">
              <input
                required
                onChange={(e) => setName(e.target.value)}
                pattern="^\w+([\w ]*\w)*$"
                value={name}
                minLength="4"
                type="text"
                name="Name"
                autoComplete="true"
                className="w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full"
                placeholder={t("Name")}
              />
            </div>
            <div className="mt-6 w-full">
              <PhoneInput
                placeholder="Enter phone number"
                country={"ae"}
                value={phone}
                onChange={(value) => setPhone(value)}
                className="w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full"
              />
            </div>
            <div className="mt-6 w-full">
              <input
                required
                onChange={(e) => setEmail(e.target.value)}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                value={email}
                type="email"
                name="email"
                autoComplete="true"
                className="w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full"
                placeholder={t("E-mail")}
                title="Please enter a valid email address"
              />
            </div>
            <div className="w-full my-6  px-6 py-3 text-mainColor bg-white shadow-lg rounded-full flex flex-wrap items-center">
              <input
                type="file"
                id="upload"
                hidden
                onChange={(e) => {
                  setUploadError(false);
                  setUpload(e.target.files[0]);
                }}
                accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
              />
              <label
                htmlFor="upload"
                className={` w-full cursor-pointer font-bold flex items-center justify-between gap-2 ${
                  uploadError ? "text-red-500" : "text-gray-500"
                }`}
              >
                {" "}
                {t(
                  "Upload CV"
                )} <AiOutlineCloudUpload className="text-2xl" />{" "}
              </label>
              {upload && (
                <p className="text-sm text-right flex items-center justify-end w-full text-[#555]">
                  {t("uploaded")}
                </p>
              )}
            </div>
            <button
            disabled={sellLoading}
              onClick={jobHandler}
              className=" px-6 py-2 text-xl rounded-full text-center cursor-pointer bg-black text-[#D4B071] font-bold mt-4"
              type="button"
            >
              {" "}
              {t("submit")}{" "}
              
            </button>
            {error && (
              <div className="mt-8">
                <ErrorAlert error={error} />
              </div>
            )}
            {success && (
              <div className="mt-8">
                <SuccessAlert success={success} />
              </div>
            )}
          </div>
        </>
      )}
      <div className="container mx-auto md:pt-0 px-4 md:px-0 mt-20  md:mt-28">
        <div className="mx-auto w-full relative flex justify-center items-center">
          <div
            className="w-full h-[50vh] md:h-[75vh] bg-cover rounded-3xl md:rounded-[3.25rem] bg-bottom brightness-[0.65]"
            style={styles}
          ></div>
          <p className="absolute text-4xl md:text-6xl text-white font-black text-center">
            Careers at <br />
            Keymax Real Estate
          </p>
          {/* <div className="row flex items-start justify-start text-center flex-wrap relative">
            <div className="w-full md:w-1/2 xl:w-1/3">
              <div className="">
                <h3 className="font-bold text-xl">{t("Careers")}</h3>
                <p>
                  {t(`Didn't Find A Suitable And Available Vacancy? Send Your CV Using The Form Below Or Email To`)}{" "}
                  <a href="mailto:info@keymaxrealestate.com" className="text-blue">
                    info@keymaxrealestate.com
                  </a>{" "}
                </p>
                <div className="mt-6">
                  <input
                    required
                    onChange={(e) => setName(e.target.value)}
                    pattern="^\w+([\w ]*\w)*$"
                    value={name}
                    minLength="4"
                    type="text"
                    name="Name"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white border border-mainColor  rounded-md "
                    placeholder={t("Name")}
                  />
                </div>
                <div className="mt-6">
                  <input
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    value={phone}
                    minLength="4"
                    type="tel"
                    name="Phone"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white border border-mainColor  rounded-md "
                    placeholder={t("Phone")}
                  />
                </div>
                <div className="mt-6">
                  <input
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                    value={email}
                    type="email"
                    name="email"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white border border-mainColor rounded-md"
                    placeholder={t("E-mail")}
                    title="Please enter a valid email address"
                  />
                </div>
                <div className="mt-6 w-full">
                  <select className="w-full p-4 text-mainColor bg-white border border-mainColor  rounded-md " value={jobId} onChange={(e) => setJob_id(e.target.value)}>
                    <option> {t("Choose your position")} </option>
                    {job.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.position}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full my-3  text-gray-500 flex flex-wrap items-center">
                  <input
                    type="file"
                    id="upload"
                    hidden
                    onChange={(e) => {
                      setUploadError(false);
                      setUpload(e.target.files[0]);
                    }}
                    accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
                  />
                  <label htmlFor="upload" className={` text-[0.9rem] xl:text-xl w-full cursor-pointer font-bold flex items-center justify-between gap-2 mt-6  ${uploadError ? "text-red-500" : "text-gray-500"}`}>
                    {" "}
                    {t("Upload CV")} <AiOutlineCloudUpload className="text-3xl" />{" "}
                  </label>
                  {upload && <p className="text-sm text-right flex items-center justify-end w-full text-[#555]">{t("uploaded")}</p>}
                </div>
                <button onClick={jobHandler} className=" p-4 text-xl rounded-lg text-center cursor-pointer bg-mainColor text-white mt-4" type="button">
                  {" "}
                  {t("Submit")}{" "}
                </button>
                {error && (
                  <div className="mt-8">
                    <ErrorAlert error={error} />
                  </div>
                )}
                {success && (
                  <div className="mt-8">
                    <SuccessAlert success={success} />
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>
        <div className="mx-auto">
          <div className="w-full mt-16 flex flex-col justify-between items-center gap-y-5">
            <div>
              <h3 className="font-bold mb-4 text-xl md:text-3xl text-center">
                {t("Current Job Opportunities!")}
              </h3>
              <p className="text-center text-lg max-w-2xl mx-auto">
                {t(
                  `We are seeking motivated individuals who excel in dynamic, results-oriented environments. Our organization offers ambitious candidates an exceptional opportunity to earn substantial, tax-free income.`
                )}
              </p>
            </div>
            {/* <div className="row flex items-center justify-center font-bold capitalize text-xl border border-mainColor py-2 bg-mainColor rounded-t-xl  text-white">
              <div className="w-1/3 p-2">{t("title")}</div>
              <div className="w-1/3 p-2">{t("position")}</div>
              <div className="w-1/3 p-2">{t("Date")}</div>
            </div> */}
            {isLoading ? (
              <div className="h-screen w-screen bg-white z-40 flex items-center justify-center">
                <Loading />
              </div>
            ) : (
              job.map((item, index) => (
                <div
                  className="w-full flex flex-col md:flex-row items-center justify-center py-4 bg-[#fbfbfb] rounded-2xl md:rounded-full shadow-xl px-10"
                  key={index}
                >
                  <div className="w-full text-center md:text-left md:w-2/5 p-2 font-bold text-xl">
                    {item.title}
                  </div>
                  <div className="w-full md:w-1/5 p-2 flex justify-center items-center gap-x-2">
                    <BiTime className="w-5 h-5 text-black" /> Full Time
                  </div>
                  <div className="w-full md:w-1/5 p-2 flex justify-center items-center gap-x-2">
                    <HiLocationMarker className="w-5 h-5 text-black" />
                    Dubai
                  </div>
                  <div
                    className="w-auto p-2 text-center px-6 py-2 bg-black text-[#D4B071] font-black rounded-full flex justify-center items-center gap-x-2 hover:cursor-pointer"
                    onClick={() => handleButtonClick(item.id)}
                  >
                    Apply <BsFillArrowRightCircleFill />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="mx-auto mt-14 flex flex-col md:flex-row justify-center items-center">
          <div>
            <h3 className="font-bold mb-4 text-2xl md:text-3xl text-center">
              {t("Future Job Opportunities!")}
            </h3>
            <p className="text-center text-md max-w-2xl mx-auto">
              {t(
                `If you haven't come across a suitable and currently available job opportunity, we kindly request you to submit your curriculum vitae (CV) using the form provided below or send it via email to `
              )}{" "}
              <a href="mailto:info@keymaxrealestate.com" className="text-blue">
                info@keymaxrealestate.com.
              </a>
              {t(
                ` Your CV will be carefully reviewed and considered for any potential openings that match your qualifications and experience. Thank you for your interest in our company, and we look forward to reviewing your application.`
              )}
            </p>
          </div>

          <div className="mt-8 md:mt-0 w-full md:w-1/2 flex flex-col justify-center items-center relaive p-0 md:py-12 md:px-10 mx-auto">
            <h3 className="font-bold text-2xl text-center">
              {t("Find your next job with")}
            </h3>
            <h3 className="font-bold text-2xl text-center">{t("Keymax")}</h3>
            <div className="mt-6 w-full">
              <input
                required
                onChange={(e) => setName(e.target.value)}
                pattern="^\w+([\w ]*\w)*$"
                value={name}
                minLength="4"
                type="text"
                name="Name"
                autoComplete="true"
                className="w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full"
                placeholder={t("Name")}
              />
              {nameError && (
                <p className=" text-red text-sm">Please enter your name</p>
              )}
            </div>
            <div className="mt-6 w-full">
              <PhoneInput
                placeholder="Enter phone number"
                country={"ae"}
                value={phone}
                onChange={(value) => setPhone(value)}
                className="w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full"
              />
              {phoneError &&
                (phone.length <= 3 ? (
                  <p className=" text-red text-sm">
                    Please enter your phone number
                  </p>
                ) : (
                  <p className=" text-red text-sm">
                    Please enter valid phone number
                  </p>
                ))}
            </div>
            <div className="mt-6 w-full">
              <input
                required
                onChange={(e) => setEmail(e.target.value)}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                value={email}
                type="email"
                name="email"
                autoComplete="true"
                className="w-full px-6 py-3 text-mainColor bg-white shadow-lg rounded-full"
                placeholder={t("E-mail")}
                title="Please enter a valid email address"
              />
              {emailError && (
                <p className=" text-red text-sm">Please enter your email</p>
              )}
            </div>
            <div className="w-full my-6  px-6 py-3 text-mainColor bg-white shadow-lg rounded-full flex flex-wrap items-center">
              <input
                type="file"
                id="upload"
                hidden
                onChange={(e) => {
                  setUploadError(false);
                  setUpload(e.target.files[0]);
                }}
                accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
              />
              <label
                htmlFor="upload"
                className={` w-full cursor-pointer font-bold flex items-center justify-between gap-2 ${
                  uploadError ? "text-red-500" : "text-gray-500"
                }`}
              >
                {" "}
                {t(
                  "Upload CV"
                )} <AiOutlineCloudUpload className="text-2xl" />{" "}
              </label>
              {upload && (
                <p className="text-sm text-right flex items-center justify-end w-full text-[#555]">
                  {t("uploaded")}
                </p>
              )}
            </div>
            {errToken && (
              <p className="text-red text-sm">please verify you are a human</p>
            )}
            <button
              disabled={loadingBook}
              onClick={jobHandler}
              className=" px-6 py-2 text-xl rounded-full text-center cursor-pointer bg-black text-[#D4B071] font-bold mt-4"
              type="button"
            >
              {loadingBook ? (
                <>
                  <span className="inline-flex items-center justify-center h-8 px-6">
                    <span className="lds-dual-ring"></span>
                  </span>
                </>
              ) : (
                t("submit")
              )}
            </button>
            {error && (
              <div className="mt-8">
                <ErrorAlert error={error} />
              </div>
            )}
            {success && (
              <div className="mt-8">
                <SuccessAlert success={success} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Careers;
