import React from "react";
import Lottie from "react-lottie";
import nothing from "../Lottie/nothing.json";
import logo from "../../img/logo_keymax.png";

const Nothing = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: nothing,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="h-[50vh] w-full flex items-center justify-center capitalize font-bold flex-col gap-4 ">
      <img src={logo} alt="" className="w-48" />
      <p>No Results Found</p>
    </div>
  );
};

export default Nothing;
