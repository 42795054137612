import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "./DateForm.scss";
import "swiper/css";
import "swiper/css/navigation";

const DateForm = ({ mobile, onUpdateDate }) => {
  const [checkedIndex, setCheckedIndex] = useState(0);
  const [checkedName, setCheckedName] = useState(null);

  // useEffect(() => {
  //   const generatedName = `${nextSevenDays[0].getUTCDate()} ${Days[nextSevenDays[0].getDay()]} ${Months[nextSevenDays[0].getMonth()]}`;

  //   onUpdateDate ? onUpdateDate(generatedName) : "";
  // }, []);

  const handleInputChange = (event, index) => {
    if (event.target.checked) {
      setCheckedIndex(index);
      const selectedDate = event.target.name;
      onUpdateDate(selectedDate); // Pass the selected date back to the parent component
    } else {
      setCheckedIndex(-1);
    }
  };

  // Get the current date
  const today = new Date();

  // Create an array to hold the next 7 days
  const nextSevenDays = [];

  // Loop through the next 7 days starting from tomorrow
  for (let i = 1; i <= 7; i++) {
    // Create a new date object for the current day in the loop
    const day = new Date(today);
    day.setDate(today.getDate() + i);

    // Add the day to the nextSevenDays array
    nextSevenDays.push(day);
  }

  const monthsNum = nextSevenDays.map((el) => el.getMonth());
  const dayNum = nextSevenDays.map((el) => el.getDay());
  const date = nextSevenDays.map((el) => el.getUTCDate());

  const Months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return (
    <div className="max-w-sm md:max-w-lg mx-auto">
      <div className="flex flex-row space-x-5 justify-center w-full">
        <Swiper
          slidesPerView={mobile ? 3 : 4}
          spaceBetween={10}
          className="mySwiperCarasole"
          modules={[Navigation]}
          navigation={true}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: mobile ? 3 : 4,
              spaceBetween: 20,
            },
          }}
        >
          {nextSevenDays.map((day, index) => (
            <SwiperSlide key={index}>
              <div className={`flex flex-col justify-center text-center cursor-pointer w-full checkbox-item ${index === checkedIndex ? "text-black" : "text-black/30"}`}>
                <p className="font-black md:text-sm lg:text-md">{Months[day.getMonth()]}</p>
                <input
                  type="checkbox"
                  onChange={(event) => handleInputChange(event, index)}
                  checked={index === checkedIndex}
                  name={`${day.getUTCDate()} ${Days[day.getDay()]} ${Months[day.getMonth()]}`}
                  id={day.getUTCDate()}
                  className="hidden"
                />
                <label className="font-black text-xl md:text-3xl lg:text-5xl cursor-pointer" htmlFor={day.getUTCDate()}>
                  {day.getUTCDate()}
                </label>
                <p className="font-black md:text-sm lg:text-md">{Days[day.getDay()]}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
    </div>
  );
};

export default DateForm;
