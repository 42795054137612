import { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import axios from "axios";
import { config, url } from "../Common/Common";
import Loading from "../Components/UI/Loading";
import ProjectDetails from "../Components/ProjectDetails/ProjectDetails";
import { useParams } from "react-router-dom";



const Project = () => {
  const { slug } = useParams();
  const [projectDetails, setProjectDetails] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch project details and set loading state
    axios
      .get(url + `projects/${slug}`, config)
      .then(function (response) {
        setProjectDetails(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [slug]);

  return (
    <Layout>
      {isLoading ? (
        <div className="h-screen w-full flex justify-center items-center">
          <Loading />
        </div>
      ) : projectDetails ? (
        <ProjectDetails
          videoUrl={projectDetails?.Video?.url}
          youtubeVideo={projectDetails?.Video?.youtube}
          videoDescription={projectDetails?.Video?.description}
          imageBanner={projectDetails?.Banner?.image}
          project_name={projectDetails?.Banner?.project_name}
          developer_name={projectDetails?.Banner?.developer_name}
          completion_date={projectDetails?.About?.completion_date}
          location={projectDetails?.Location}
          About={projectDetails?.About}
          Banner={projectDetails?.Banner}
          about_bref={projectDetails?.About?.about_bref}
          floor_plan={projectDetails?.Floor?.floor_plan}
          starting_price={projectDetails?.Starting_Price}
          delivery_in={projectDetails?.Payment?.delivery_in}
          Starting={projectDetails?.Starting}
          Gallery={projectDetails?.Gallery}
          Payments={projectDetails?.Payments}
          Payment={projectDetails?.Payment}
          Amenities={projectDetails?.Amenities}
          AmenitiesDesc={projectDetails?.AmenitiesDesc}
          LocationDescription={projectDetails?.Location?.description}
          lat={projectDetails?.Location?.lat}
          lng={projectDetails?.Location?.lng}
          Full={projectDetails?.Full}
          floor_plans={projectDetails?.floor_plans}
        />
      ) : (
        <div className="h-screen w-full flex flex-col space-y-5 justify-center items-center">
          {/* <img src={logo} alt="" className="w-48" /> */}
          <p>This property is not available anymore.</p>
        </div>
      )}
    </Layout>
  );
};

export default Project;

