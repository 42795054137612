import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";
import { config, url } from "../../Common/Common";
import Loading from "../UI/Loading";
import Nothing from "../UI/Nothing";
import Title from "../UI/Title";
import { t } from "i18next";
import ProjectList from "../../Pages/ProjectList";
import Skeleton from "react-loading-skeleton";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(url + "projects?limit=8", config)
      .then(function (response) {
        // handle success
        setProjects(response.data.data.slice(0, 8));
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }

  return (
    <div className="Projects mt-10">
      {projects.length > 0 && (
        <>
          <Title title={t("Projects")} link={t("view all")} url="/Projects" />
          {isLoading ? (
            <div className="grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1">
              {Array.from({ length: 4 }).map((_, index) => (
                <div className="flex flex-col h-full">
                  <div className="relative">
                    <div className="rounded-3xl overflow-hidden">
                      <Skeleton height={350} className="object-cover rounded-3xl w-full h-64 lg:h-80" />
                    </div>
                    <div className="absolute top-2 left-4">
                      <Skeleton width={200} height={24} className="bg-[#D4B071] px-4 py-2 rounded-full font-bold" />
                    </div>
                    <div className="absolute bottom-14 left-4">
                      <Skeleton width={150} height={24} className="bg-white px-4 py-2 rounded-full font-bold" />
                    </div>
                  </div>
                  <div className="flex-grow border-2 border-[#D4B071] rounded-3xl mx-3 -mt-12 bg-white z-10">
                    <div className="p-5">
                      <Skeleton width={200} height={32} className="mb-2 font-bold leading-5 text-2xl md:text-3xl" />
                      <Skeleton width={150} height={16} className="text-sm text-gray-500" />
                      <Skeleton width={200} height={32} className="mb-2 font-bold leading-5 text-lg md:text-xl" />
                      <Skeleton width={150} height={16} className="text-sm text-gray-500" />
                      <Skeleton width={200} height={32} className="mb-2 font-bold leading-5 text-lg md:text-xl" />
                      <div className="flex flex-wrap items-center justify-between">
                        <div>
                          <Skeleton width={100} height={16} className="text-sm text-gray-500" />
                          <Skeleton width={200} height={32} className="mb-2 font-bold leading-5 text-lg md:text-xl" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1">
              {projects.slice(0, 8).map((project) => (
                <ProjectList project={project} key={project.id} />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Projects;
