import axios from "axios";
import { t } from "i18next";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { url, recaptcha__secret_key,
  recaptcha_key,
  config,
  chechPhone } from "../../Common/Common";
import Button from "../UI/Button";
import ErrorAlert from "../UI/ErrorAlert";
import SuccessAlert from "../UI/SuccessAlert";
import { AiOutlineCloseCircle } from "react-icons/ai";
import modalBg from "../../img/modal_bg.webp";
import DateForm from "../Layout/DateForm";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"
import ReactRecaptcha3 from "react-google-recaptcha3";



const Modal = (props) => {
  const [phone, setPhone] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);
  const [errorSelectedDate, setErrorSelectedDate] = useState(false);

  const formatDate = (inputDate) => {
    // Split the input date string into day, weekday, and month parts
    const [day, weekday, month] = inputDate.split(" ");

    // Map month names to month numbers
    const monthMapping = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      June: "06",
      July: "07",
      Aug: "08",
      Sept: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
   
  
    // Get the current year
    const currentYear = new Date().getFullYear();

    // Construct the final date string in YYYY-MM-DD format
    const formattedDate = `${currentYear}-${monthMapping[month]}-${day.padStart(2, "0")}`;

    return formattedDate;
  };

  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key);
  }, []);


  const contactHandler = (e) => {
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    setErrorSelectedDate(false);
    e.preventDefault();


    if (selectedDate.trim() == "") {
      setErrorSelectedDate(true);
      return;
    }
    if (name.trim() == "") {
      setNameError(true);
      return;
    }
    if (email.trim() == "" || !email.includes("@")) {
      setEmailError(true);
      return;
    }
    if(!chechPhone(phone)){
      setPhoneError(true);
        return;
    }

    setSellLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + "verifyRecaptcha",
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            axios
            .post(url + "save-meeting", {
              date: formatDate(selectedDate),
              phone: phone,
              name: name,
              email: email,
              message: msg
            })
            .then((response) => {
              setSelectedDate("");
              setPhone("");
              setName("");
              setEmail("");
              setSuccess(response.data.message);
              setTimeout(() => {
                props.closeModal();
                setSuccess("");
              }, 2000);
            })
            .catch((error) => {
              setError(error.response.data);
              setTimeout(() => {
                setError("");
              }, 2000);
            });
          })
          .catch(() => {
            setSellLoading(false);
            setErrToken(true);
          });
      },
      () => {
        setSellLoading(false);
        setErrToken(true);
      }
    );
  
  };
  const styles = {
    backgroundImage: `url(${modalBg})`,
  };
  const handleStateUpdate = (selectedDate) => {
    setSelectedDate(selectedDate);
  };
  return (
    <>
      <div className="fixed top-0 left-0 bg-black opacity-75 w-screen h-screen z-50 bkDrop" onClick={() => props.closeModal()}></div>
      <div className="Modal w-5/6 md:w-auto  h-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-3xl flex flex-col justify-center items-center relaive">
        <button className="font-bold absolute rtl:left-4 ltr:right-4 top-4 text-2xl" onClick={() => props.closeModal()}>
          {" "}
          <AiOutlineCloseCircle />{" "}
        </button>
        {/* Row  */}
        <div className="w-full flex flex-col md:flex-row ">
          {/* Col  */}
          <div className="md:flex md:w-[32rem] justify-center items-start w-full bg-bottom md:h-auto bg-gray-400 bg-cover rounded-3xl hidden" style={styles}>
            <p className="text-center font-bold text-white text-xl md:text-2xl pt-20">Book your free consultation</p>
          </div>
          {/* Col  */}
          <div className="w-full md:w-1/2  bg-white p-5 rounded-3xl md:h-auto ">
            <p className="text-center font-bold text-black text-3xl block md:hidden">Book your free consultation</p>
            {success && (
              <div className=" z-50">
                <SuccessAlert success={success} />
              </div>
            )}
            {error && (
              <div className=" z-50">
                <ErrorAlert error={error} />
              </div>
            )}
            <h3 className="pt-4 text-lg text-center">Select a Date</h3>
            <div className="mt-4 pt-4 ">
              <div className="relative p-2 w-full h-auto">
                <form className="relative">
                  <div className="mx-auto flex flex-col justify-center items-center">
                    <div className="w-5/6">
                      <DateForm mobile={true} selectedDate={selectedDate} onUpdateDate={handleStateUpdate} />
                      {errorSelectedDate && (
                        <span className="text-sm text-red">
                          {" "}
                          Please select date
                        </span>
                      )}
                    </div>
                    <div className={`flex flex-col gap-x-0 md:gap-x-5 w-full relative pt-5`}>
                      <input
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        required
                        type="text"
                        className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white rounded-full drop-shadow-md appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      />
                      {nameError && (
                        <span className="text-sm text-red">
                          {" "}
                          Please enter your name
                        </span>
                      )}

                      <input
                        placeholder="Email"
                        required
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white rounded-full drop-shadow-md appearance-none  focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      />
                      {emailError && (
                        <span className="text-sm text-red">
                          {" "}
                          Enter a valid Email
                        </span>
                      )}

                      <PhoneInput
                        placeholder="Enter phone number"
                        country={"ae"}
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                        className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 appearance-none rounded-full shadow-md focus:border-b-black focus:outline-none focus:shadow-outline"
                      />
                      {phoneError && (<p className=" text-red text-sm"> Please enter valid phone number </p> )}

                    </div>
                    <textarea
                      onChange={(e) => setMsg(e.target.value)}
                      value={msg}
                      rows="5"
                      placeholder="Message"
                      className="flex-grow w-full px-4 transition duration-200 bg-white rounded-2xl drop-shadow-md appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline mb-10"
                    ></textarea>
                    {errToken && (
                      <p className="text-red text-sm error-token">
                        please verify you are a human
                      </p>
                    )}
                    <button
                      type="submit"
                      disabled={sellLoading}
                      onClick={contactHandler}
                      className="placeholder-center inline-flex items-center justify-center  px-7 py-2 text-md tracking-wide text-black font-bold transition duration-200 rounded-full shadow-md bg-[#D4B071] hover:bg-blue-700 focus:shadow-outline focus:outline-none"
                    >
                    {sellLoading ? (
                      <>
                        <span className="inline-flex items-center justify-center h-12 px-6">
                          <span className="lds-dual-ring"></span>
                        </span>
                      </>
                    ) : (
                      "Book now"
                    )}
                    </button>
                  </div>
                </form>
              </div>
              {/* <Slider {...alsoHave} >
                        {
                          propertyDetails.more_same_area.map((item, index) => (
                            <Card key={index}
                              id={item.id}
                              name={item.title}
                              location={item.address}
                              whatsapp={item.whatsapp}
                              area={item.size}
                              bedroom={item.bedrooms_number}
                              bathroom={item.bathrooms_number}
                              price={item.price}
                              img={item.image}
                              imgs={item.images}
                              status={item.launch}
                              buy_or_sell={item.buy_or_sell}
                              price_year={item.price_year}
                              price_hidden={item.price_hidden}
                              description={item.description}
                              width='100%'
                              height='100%' />
                          ))
                        }
                      </Slider> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="fixed top-0 left-0 bg-black opacity-75 w-screen h-screen z-50 bkDrop" onClick={() => props.closeModal()}></div>
      <div className="Modal w-96 h-auto fixed top-1/2 left-1/2 bg-white -translate-x-1/2 -translate-y-1/2 p-4 rounded-lg">
        {success && (
          <div className=" z-50">
            <SuccessAlert success={success} />
          </div>
        )}
        {error && (
          <div className=" z-50">
            <ErrorAlert error={error} />
          </div>
        )}
        <h3 className="text-black font-bold text-xl mb-4"> {props.title} </h3>
        <button className="font-bold absolute rtl:left-4 ltr:right-4 top-4 text-2xl" onClick={() => props.closeModal()}>
          {" "}
          <AiOutlineCloseCircle />{" "}
        </button>
        <div className="form-group mb-4">
          <label htmlFor="">{t("Your Name")} </label>
          <input onChange={(e) => setName(e.target.value)} value={name} className="w-full mb-2 border border-mainColor rounded-lg p-2" type="text" maxLength="12" placeholder={t("Your Name")} name="name" required />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="">{t("Your phone")} </label>
          <input
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            className="w-full mb-2 border border-mainColor rounded-lg p-2"
            type="tel"
            pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
            maxLength="12"
            placeholder={t("Your Phone")}
            name="Phone"
            required
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="">{t("Your Email")} </label>
          <input onChange={(e) => setEmail(e.target.value)} value={email} className="w-full mb-2 border border-mainColor rounded-lg p-2" type="email" placeholder={t("Your Email")} name="Email" required />
        </div>
        <div className="form-group mb-4 relative overflow-hidden">
          <label htmlFor="">{t("Select date")} </label>
          <input onChange={(e) => setDate(e.target.value)} value={date} id="datePickerId" className="w-full mb-2 border border-mainColor rounded-lg p-2" type="date" placeholder="Your date" name="date" required />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="">{t("Select time ")}</label>
          <input
            onChange={(e) => {
              setTime(e.target.value);
            }}
            value={time}
            className="w-full mb-2 border border-mainColor rounded-lg p-2"
            type="time"
            placeholder="Your time"
            name="time"
            required
          />
          <select
            className="w-full mb-2 border border-mainColor rounded-lg p-2"
            onChange={(e) => {
              setTime(e.target.value);
            }}
          >
            <option value="08:00">08:00 AM</option>
            <option value="08:30">08:30 AM</option>
            <option value="09:00">09:00 AM</option>
            <option value="09:30">09:30 AM</option>
            <option value="10:00">10:00 AM</option>
            <option value="10:30">10:30 AM</option>
            <option value="11:00">11:00 AM</option>
            <option value="11:30">11:30 AM</option>
            <option value="12:00">12:00 PM</option>
            <option value="12:30">12:30 PM</option>
            <option value="13:00">01:00 PM</option>
            <option value="13:30">01:30 PM</option>
            <option value="14:00">02:00 PM</option>
            <option value="14:30">02:30 PM</option>
            <option value="15:00">03:00 PM</option>
            <option value="15:30">03:30 PM</option>
            <option value="16:00">04:00 PM</option>
            <option value="16:30">04:30 PM</option>
            <option value="17:00">05:00 PM</option>
            <option value="17:30">05:30 PM</option>
            <option value="18:00">06:00 PM</option>
            <option value="18:30">06:30 PM</option>
            <option value="19:00">07:00 PM</option>
            <option value="19:30">07:30 PM</option>
            <option value="20:00">08:00 PM</option>
            <option value="20:30">08:30 PM</option>
            <option value="21:00">09:00 PM</option>
            <option value="21:30">09:30 PM</option>
            <option value="22:00">10:00 PM</option>
          </select>
        </div>
        <div className="flex gap-4">
          <Button primary className="text-md" onClick={contactHandler}>
            {" "}
            {props.btnText}
          </Button>
          <Button onClick={() => props.closeModal()}>{t("close")}</Button>
        </div>
      </div> */}
    </>
  );
};

export default Modal;
