import { useEffect, useState, useRef } from "react";
import Layout from "../Components/Layout";
import axios from "axios";
import { config, url } from "../Common/Common";
import { Link } from "react-router-dom";

const Blog = () => {

    const [blogPosts, setBlogPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState();
    const [lastPage, setLastPage] = useState();

    useEffect(() => {
        axios
          .get(url + `blog`, config)
          .then(function (response) {
            // Extract the data from the Axios response
            const responseData = response.data;
            setBlogPosts(responseData.data); // Set the data using setBlogPosts
            setIsLoading(false);
            setTotal(responseData.total);
            setLastPage(responseData.last_page);
          })
          .catch(function (error) {
            console.log(error);
          });
      }, []);

    return(
        <Layout Blog={true}>
            <section>
            {isLoading ? (
            <div className="flex items-center justify-center h-screen bg-cover bg-center xl:bg-top" style={{ backgroundImage: `url('https://blogs.keymaxrealestate.com/wp-content/uploads/2023/04/zq-lee-DcyL0IoCY0A-unsplash-scaled.jpg')` }}>
                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
            </div>
            ) : (
            <>
                <section className="bg-cover bg-center xl:bg-top relative h-[80vh] mt-[4rem] xl:mt-24" style={{ backgroundImage: `url('https://blogs.keymaxrealestate.com/wp-content/uploads/2023/04/zq-lee-DcyL0IoCY0A-unsplash-scaled.jpg')` }}>

                <div className="absolute inset-0 bg-black opacity-50"></div>
                <div className="container mx-auto text-center relative z-10 px-4 sm:px-6 lg:px-8 py-20 sm:py-32 md:py-40 lg:py-64 xl:py-96 h-full">
                    <div className="flex justify-center items-center h-full flex-col">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-white">
                            Keymax Blog
                        </h1>
                        <p className="mt-4 text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-white">
                            The latest news about Dubai Real Estate
                        </p>
                    </div>
                </div>
                </section>

                <section className="container mx-auto">
                    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-20">
                        <div className="grid gap-8 lg:grid-cols-2">
                            <div className="flex justify-center items-center relative">
                                <img
                                    className="object-cover rounded-3xl w-full h-full col-span-2 shadow-lg"
                                    src={blogPosts[0].thumb_img}
                                    alt={blogPosts[0].title}
                                />
                                <div className="absolute bottom-4 left-4 flex justify-center items-center space-x-2">
                                    {blogPosts[0].categories.map(category => (
                                        <p key={category.id} className="bg-white text-md px-4 py-px rounded-full font-bold">
                                            {category.name_en}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-col justify-center items-center lg:items-start">
                                    <div>
                                        <h3 className="mb-2 text-center lg:text-left font-bold text-2xl md:text-3xl">
                                        {blogPosts[0].title}
                                        </h3>
                                        <p className="text-center lg:text-left">{blogPosts[0].last_updated_date}</p>
                                    </div>
                                    <p className="text-sm text-gray-900">
                                    {blogPosts[0].meta_description}
                                    </p>
                                    <Link to={blogPosts[0].slug} className="font-black w-fit mt-5 bg-white px-4 py-px border-2 border-[#D4B071] rounded-full hover:bg-black hover:text-white transition-all duration-2000 ease-in-out">
                                    <p>Read More</p></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container mx-auto ">
                    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                        <div className="grid gap-12 row-gap-5 lg:grid-cols-3">
                        {
                            blogPosts.slice(1, 7).map((blog, index) => (
                                <div class="inline-block bg-white rounded ">
                                    <div class="flex flex-col h-full">
                                        <div className=" relative">
                                        <img src={blog.thumb_img} class="object-cover rounded-3xl w-full h-64 lg:h-80" alt="blog.title" />
                                        {/* {project.other_payment_plan ? (
                                            <div className="absolute top-2 left-4">
                                            <p className="bg-[#D4B071] px-4 py-2 rounded-full font-bold">{project.other_payment_plan} Payment Plan</p>
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                        */}

                                        {blog.categories && (
                                            <div className="absolute bottom-14 left-4 flex justify-center items-center space-x-2">
                                                {blog.categories.map(category => (
                                                    <p key={category.id} className="bg-white text-md px-4 py-px rounded-full font-bold">
                                                        {category.name_en}
                                                    </p>
                                                ))}
                                            </div>
                                        )}

                                        </div>

                                        <div class="flex-grow border-2 border-[#D4B071] rounded-3xl mx-3 -mt-12 bg-white z-10 relative">
                                        <div class="p-7 text-left flex flex-col justify-center items-center">
                                            <p>{blog.last_updated_date}</p>
                                            {blog.title && <h3 class="mb-2 text-center font-bold text-2xl md:text-3xl">{blog.title}</h3>}
                                            <div className="flex flex-wrap items-center justify-between">
                                            {/* <p class="text-sm text-gray-500">Payment Plan</p>
                                <h3 class="mb-2 font-bold leading-5 text-lg md:text-xl">
                                {`${Number(project.on_booking) + Number(project.during_construction)}/${project.on_completion}`}
                                </h3> */}
                                            {/* {project.post_handover ? (
                                                <div>
                                                <p class="text-sm text-gray-500">Post Hand Over</p>
                                                <h3 class="mb-2 font-bold leading-5 text-lg lg:text-xl">{project.post_handover}% <span className="text-sm lg:text-md">in {project.post_handover_period} {project.post_handover_type}</span></h3>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                            {project.delivery_date && (
                                                <div>
                                                <p class="text-sm text-gray-500">Completion Year</p>
                                                <h3 class="mb-2 font-bold leading-5 text-lg md:text-xl">{project.delivery_date.substring(0, project.delivery_date.indexOf("-"))}</h3>
                                                </div>
                                            )} */}
                                            <Link to={blog.slug} className="font-black absolute left-1/2 -translate-x-1/2 -bottom-4 bg-white px-4 py-px border-2 border-[#D4B071] rounded-full hover:bg-black hover:text-white transition-all duration-2000 ease-in-out">
                                            <p >Read More</p></Link>
                                            </div>
                                        </div>
                                        </div>
                                        {/* <LazyLoadImage src={project.image} alt="" className=' h-full w-full object-cover rounded-lg' style={{ height: '25rem' }} effect='blur'/> */}
                                    </div>

                                    {/* <LazyLoadImage src={project.image} alt="" className=' h-full w-full object-cover rounded-lg' style={{ height: '25rem' }} effect='blur'/> */}
                                    </div>
                            ))
                        }

                        </div>
                    </div>
                </section>
            </>
            )}
            </section>
        </Layout>
    )
}

export default Blog;