import React from 'react'
import styled from 'styled-components'

const ButtonStyle = styled.button`
           background: ${props => (props.primary ? '#CDAC62' : '#000000')};
           padding:0.5rem  1rem  ;
           font-weight: 600;
           color: ${props => (props.primary ? '#222' : '#fff')}; 
           border : 4px solid  ${props => (props.primary ? '#fff' : '#CDAC62')}; 
           border-radius: 4rem;
           font-size: 1.5rem;
           z-index: 20`

const Button = (props) => {
     return (
          <ButtonStyle type={props.type} className={`btn ${props.className}`} onClick={props.onClick} primary={props.primary}>
               {props.children}
          </ButtonStyle>
     )
}

export default Button