import React, { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Title from "../Components/UI/Title";
import { config, url } from "../Common/Common";
import axios from "axios";

const PrivacyPolicy = () => {
  const [page, setPages] = useState();

  useEffect(() => {
    axios
      .get(url + `pages/privacy-policy`, config)
      .then(function (response) {
        const data = response.data.data;
        setPages(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <Layout>
      <div className="container mx-auto mt-24 px-4 md:px-0">
        {page && (
          <div className="row flex items-start justify-start  flex-wrap">
            <div className="w-full md:w-1/2">
              <Title title={page.title} />
              <div style={{ overflowWrap: "anywhere" }} dangerouslySetInnerHTML={{ __html: page.contents }} />
            </div>
            <div className="w-full md:w-1/2 md:p-8">
              <div className="relative overflow-hidden rounded-xl">
                <img src={page.img_url} alt={page.title} title={page.title} className="w-full h-full object-cover" />
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
