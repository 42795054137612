import { useContext, useEffect, useState } from 'react';
import Layout from '../Components/Layout'
import axios from "axios";
import { config, url } from '../Common/Common';
import { BsFillSuitHeartFill } from 'react-icons/bs'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import Title from '../Components/UI/Title';
import Nothing from '../Components/UI/Nothing';
import Loading from '../Components/UI/Loading';
import WidthCard from '../Components/Sections/WidthCard';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import FavoritesContext from '../store/store';
import List from '../Components/UI/List';



const WishList = () => {

     const cartCtx = useContext(FavoritesContext)

     return (
          <Layout>
               <div className="container mx-auto px-4 pt-8 md:pt-8 md:px-0 ">
                    <div className="row flex items-start justify-start text-center flex-wrap ">
                         <div className="w-full flex items-center justify-between flex-wrap ">
                              <div className="btns flex items-center  justify-between md:justify-start mb-4 md:mb-0 w-full md:w-1/2">
                                   <Link to='/MapView' className='px-4 py-2 rounded-md mx-2  flex items-center relative overflow-hidden cursor-pointer  border border-mainColor bg-gray' ><HiOutlineLocationMarker className='mx-2 text-mainColor' /> {t('Map View ')}</Link>
                                   <Link to='/WishList' className='px-4 py-2 rounded-md mx-2  flex items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-gray' ><BsFillSuitHeartFill className='mx-2 text-mainColor' /> {t('Save Search')} </Link>
                              </div>
                              {/* <div className="btns flex justify-between md:justify-end items-center  w-full md:w-1/2">
                                   <label className='mx-4' htmlFor="SortBy">{t('Sort By:')}</label>
                                   <select id='SortBy' className='border border-gray py-2 px-4' onChange={(e) => setSortBy(e.target.value)}>
                                        <option value="featured">{t('Featured')}</option>
                                        <option value="newest">{t('Newest')}</option>
                                        <option value="price_low">{t('Price (low)')}</option>
                                        <option value="price_high">{t('Price (high)')}</option>
                                        <option value="beds_low">{t('Beds (least)')}</option>
                                        <option value="beds_high">{t('Beds (most)')}</option>
                                        <option value="delivery_date_earliest">{t('Delivery date (earliest)')}</option>
                                        <option value="delivery_date_latest">{t('Delivery date (latest)')}</option>
                                   </select>
                              </div> */}
                         </div>
                    </div>
                    <Title title={t('Saved Properties')} />
                    <div className="row flex items-start justify-start text-center flex-wrap">
                    <div className="w-full md:w-9/12 pt-4">
                         {/* {isLoading && <div className='absolute top-0 left-0 h-screen w-screen bg-white z-40 flex items-center justify-center'><Loading /></div>} */}
                         {cartCtx.favorites.length === 0 && <Nothing />}
                         {
                              cartCtx.favorites.map((item, index) => (
                                   <WidthCard
                                        fav={item.is_fav}
                                        id={item.id}
                                        developer={item.developer.icon}
                                        key={item.id}
                                        name={item.title}
                                        description={item.description}
                                        location={item.location_area.name + ' , ' + item.location_area.city}
                                        image={item.image}
                                        images={item.images}
                                        area={item.size}
                                        bedrooms={item.bedrooms_number}
                                        bathrooms={item.bathrooms_number}
                                        status={item.buy_or_sell}
                                        url={item.developer_pic}
                                        rent_term={item.rent_term}
                                        whatsApp={item.whatsapp}
                                        phone={item.phone}
                                        email={item.email}
                                        price={item.price}
                                        buy_or_sell={item.buy_or_sell}
                                        price_year={item.price_year} />

                              ))
                         }
                    </div>
                         </div>
               </div>
          </Layout>
     )
}

export default WishList