import axios from "axios";
import { useEffect } from "react";
import { createContext, useState } from "react";
import { config, url } from "../Common/Common";

const FavoritesContext = createContext({
  favorites: [],
  totalFavorites: 0,
  addFavorite: (favoriteMeetup) => {},
  removeFavorite: (id) => {},
  itemIsFavorite: (id) => {},
});

export const FavoritesContextProvider = (props) => {
  const [userFavorites, setUserFavorites] = useState([]);

  useEffect(() => {
    axios(url + "properties?is_fav=1", config)
      // Response
      .then((response) => {
        setUserFavorites(response.data.data);
        // console.log(response.data.data)
      })
      // Check Error
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function addFavoriteHandler(id) {
    // console.log(slug)
    axios
      .post(
        url + `add-wishlist`,
        {
          property_id: id,
        },
        config
      )
      .then(function (response) {
        // handle success
        setUserFavorites((prevUserCart) => {
          return prevUserCart.concat(response.data.data);
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function removeFavoriteHandler(id) {
    axios
      .post(
        url + "add-wishlist",
        {
          property_id: id,
        },
        config
      )
      // Response
      .then((response) => {
        console.log(response);
        setUserFavorites((prevUserFavorites) => {
          return prevUserFavorites.filter((meetup) => meetup.id !== id);
        });
      })
      // Check Error
      .catch((error) => {
        console.log(error);
      });
  }

  function itemIsFavoriteHandler(id) {
    return userFavorites.some((favItem) => favItem.id == id);
  }

  const context = {
    favorites: userFavorites,
    totalFavorites: userFavorites.length,
    addFavorite: addFavoriteHandler,
    removeFavorite: removeFavoriteHandler,
    itemIsFavorite: itemIsFavoriteHandler,
  };

  return <FavoritesContext.Provider value={context}>{props.children}</FavoritesContext.Provider>;
};

export default FavoritesContext;
