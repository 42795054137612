import React, { useEffect, useState } from 'react'
import Layout from '../Components/Layout'
import Loading from '../Components/UI/Loading';
import { t } from 'i18next';
import ListSellYourProperty from '../Components/Sections/ListSellYourProperty/ListSellYourProperty';
import Dubai from '../img/Dubai.jpg'
import img1 from '../img/Asset.png'
import img2 from '../img/calender.png'
import img3 from '../img/community.png'
import img4 from '../img/Eye.png'
import img5 from '../img/house.png'
import img6 from '../img/money.png'
import img7 from '../img/network.png'
import img8 from '../img/setting.png'
import { config, url } from '../Common/Common';
import axios from 'axios';
import FormOptions from '../Components/Sections/FormOptions';
const ListYourPropertyInDubai = () => {
     const [listed, setListed] = useState()
     const [isLoading, setIsLoading] = useState(true);
     useEffect(() => {
          axios.get(url + `listed-properties-info`, config)
               .then(function (response) {
                    // handle success
                    setListed(response.data.data);
                    setIsLoading(false)
               })
               .catch(function (error) {
                    // handle error
                    console.log(error);
               })
               .then(function () {
                    // always executed
               });
     }, []);
     return (
          <Layout>
               {isLoading ?
               (
                    <div className='h-screen flex justify-center items-center w-full'>
                         <Loading />
                    </div>
               )
               :
                    (<>
                         <div className="min-h-[90vh] w-auto lg:w-screen relative overflow-hidden">
                              <img src={Dubai} className="h-full w-full object-cover bg-center absolute bg-fixed " alt="" />
                              <div className="container mx-auto relative xl:overflow-hidden min-h-[90vh] flex justify-center items-center">
                                   <div className="flex justify-center items-center w-full">
                                        {/* <h3 className='font-bold w-full my-2 text-2xl text-[#CDAC62]'>{t('List Property in Dubai')}</h3> */}
                                        {/* <h3 className='font-bold w-full my-2 text-2xl text-[#CDAC62]'>{t('Save Thousands')}</h3> */}
                                        {/* <p className='leading-loose text-md md:text-xl overflow-hidden'> {listed.sell_faster} </p> */}
                                        {/* <a href='#register' className='bg-[#CDAC62]  text-black font-bold px-2 py-2 rounded-lg my-4 inline-block'>{t('Register your Interest')}</a> */}
                                        <FormOptions />
                                   </div>
                              </div>
                         </div>
                         <div className="bg-white">
                              <div className="container mx-auto mt-12 px-4 md:px-0">
                                   <div className="row flex items-start justify-start text-center flex-wrap">
                                        <div className="w-full mb-16">
                                             <h3 className='font-bold text-2xl text-center md:text-4xl w-full my-8 text-[#d4b071] '>  {t('How it works ?')} </h3>
                                             <div className="row flex items-start justify-start text-center flex-wrap mb-12">
                                                  <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                                                       <div className="flex items-center justify-center flex-wrap shadow-xl rounded-lg p-2 md:p-4 border border-gray">
                                                            <h3 className='font-bold w-full my-2 text-xl'>{listed.paragraph_1_title}</h3>
                                                            <p className='leading-loose text-lg lines lines-3 overflow-hidden'>{listed.paragraph_1_description}</p>
                                                       </div>
                                                  </div>
                                                  <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                                                       <div className="flex items-center justify-center flex-wrap shadow-xl rounded-lg p-2 md:p-4 border border-gray">
                                                            <h3 className='font-bold w-full my-2 text-xl'>{listed.paragraph_2_title}</h3>
                                                            <p className='leading-loose text-lg lines lines-3 overflow-hidden'> {listed.paragraph_2_description}</p>
                                                       </div>
                                                  </div>
                                                  <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                                                       <div className="flex items-center justify-center flex-wrap shadow-xl rounded-lg p-2 md:p-4 border border-gray">
                                                            <h3 className='font-bold w-full my-2 text-xl'>{listed.paragraph_3_title}</h3>
                                                            <p className='leading-loose text-lg lines lines-3 overflow-hidden'>{listed.paragraph_3_description}</p>
                                                       </div>
                                                  </div>
                                                  <div className="w-full md:w-1/2 lg:w-1/4 p-2">
                                                       <div className="flex items-center justify-center flex-wrap shadow-xl rounded-lg p-2 md:p-4 border border-gray">
                                                            <h3 className='font-bold w-full my-2 text-xl'>{listed.paragraph_4_title}</h3>
                                                            <p className='leading-loose text-lg lines lines-3 overflow-hidden'>{listed.paragraph_4_description}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="w-full bg-gray rounded-2xl md:px-4 mb-16">
                                             <div className="row flex items-center justify-center text-center flex-wrap mb-12">
                                                  <div className="w-full md:w-1/2 p-2 rtl:text-right ltr:text-left">
                                                       <h3 className='font-bold text-2xl rtl:text-right ltr:text-left md:text-4xl w-full my-8 text-[#d4b071] '>  {t(`What's included ?`)} </h3>
                                                       <p className='leading-loose text-lg overflow-hidden'>  {listed.included}</p>
                                                  </div>
                                                  <div className="w-full md:w-1/2 p-2">
                                                       <h3 className='font-bold text-2xl text-center md:text-4xl w-full my-8 text-mainColor'>  {t(`WE WILL PROVIDE`)} </h3>
                                                       <ul className='flex flex-wrap items-stary justify-center'>

                                                            <li className='w-1/2 md:w-1/3 mb-8'>
                                                                 <div className="flex flex-wrap justify-center">
                                                                      <span className='w-24 h-24 bg-[#ccc2] p-2 rounded-full mb-3 flex items-center justify-center'>
                                                                           <img src={img3} alt="" className='h-16' />
                                                                      </span>
                                                                      <h3 className='w-full text-lg lines three overflow-hidden'>{listed.provide_1}</h3>
                                                                 </div>
                                                            </li>
                                                            <li className='w-1/2 md:w-1/3 mb-8'>
                                                                 <div className="flex flex-wrap justify-center">
                                                                      <span className='w-24 h-24 bg-[#ccc2] p-2 rounded-full mb-3 flex items-center justify-center'>
                                                                           <img src={img4} alt="" className='h-16' />
                                                                      </span>
                                                                      <h3 className='w-full text-lg lines three overflow-hidden'>{listed.provide_2}</h3>
                                                                 </div>
                                                            </li>
                                                            <li className='w-1/2 md:w-1/3 mb-8'>
                                                                 <div className="flex flex-wrap justify-center">
                                                                      <span className='w-24 h-24 bg-[#ccc2] p-2 rounded-full mb-3 flex items-center justify-center'>
                                                                           <img src={img2} alt="" className='h-16' />
                                                                      </span>
                                                                      <h3 className='w-full text-lg lines three overflow-hidden'>{listed.provide_3}</h3>
                                                                 </div>
                                                            </li>
                                                            <li className='w-1/2 md:w-1/3 mb-8'>
                                                                 <div className="flex flex-wrap justify-center">
                                                                      <span className='w-24 h-24 bg-[#ccc2] p-2 rounded-full mb-3 flex items-center justify-center'>
                                                                           <img src={img1} alt="" className='h-16' />
                                                                      </span>
                                                                      <h3 className='w-full text-lg lines three overflow-hidden'>{listed.provide_4}</h3>
                                                                 </div>
                                                            </li>
                                                            <li className='w-1/2 md:w-1/3 mb-8'>
                                                                 <div className="flex flex-wrap justify-center">
                                                                      <span className='w-24 h-24 bg-[#ccc2] p-2 rounded-full mb-3 flex items-center justify-center'>
                                                                           <img src={img8} alt="" className='h-16' />
                                                                      </span>
                                                                      <h3 className='w-full text-lg lines three overflow-hidden'>{listed.provide_5}</h3>
                                                                 </div>
                                                            </li>
                                                            <li className='w-1/2 md:w-1/3 mb-8'>
                                                                 <div className="flex flex-wrap justify-center">
                                                                      <span className='w-24 h-24 bg-[#ccc2] p-2 rounded-full mb-3 flex items-center justify-center'>
                                                                           <img src={img7} alt="" className='h-16' />
                                                                      </span>
                                                                      <h3 className='w-full text-lg lines three overflow-hidden'>{listed.provide_6}</h3>
                                                                 </div>
                                                            </li>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   {/* <div className="w-full pt-16" id='register'>
                                        <h3 className='font-bold text-2xl text-center md:text-4xl w-full my-8 text-mainColor '>  <span className='text-[#d4b071]'> {t('list your property in dubai')} </span> </h3>
                                        <ListSellYourProperty />
                                   </div> */}
                              </div>
                         </div>
                    </>)
               }

          </Layout >

     )
}

export default ListYourPropertyInDubai