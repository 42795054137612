import React from "react";

const MapTriangle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-7 h-7 absolute left-1/2 transform -translate-x-1/2 -mt-2" viewBox="0 0 16 16" version="1.1" fill="#ffffff">
      <g id="SVGRepo_bgCarrier" stroke-width="0" />

      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <rect width="16" height="16" id="icon-bound" fill="none" /> <polygon points="8,13 0,5 16,5" />{" "}
      </g>
    </svg>
  );
};

export default MapTriangle;
