import "./Filter.scss";
import { IoIosArrowDown } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../Common/Common";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { HiOutlineAdjustments } from "react-icons/hi";

const FilterMobile = (props) => {
  const navigate = useNavigate();

  // Location
  const [location, setLocation] = useState("");
  //  Buy -- Rent
  const [filterType, setFilterType] = useState("");
  // Type Apartment
  const [filterKind, setFilterKind] = useState("");
  //  BathRoom Number
  const [bathRoom, setBathRoom] = useState("");
  //  Bed Number
  const [bedrooms, setBedrooms] = useState("");
  // keywords input
  const [keywords, setkeywords] = useState("");
  //  From Api 'Categories'
  const [list, setList] = useState([]);
  //  From Api 'Aminities'
  const [aminities, setAminities] = useState([]);
  // Rental Period
  const [Period, setPeriod] = useState("");

  // Min Price
  const [MinPrice, setMinPrice] = useState("");

  // Max Price
  const [MaxPrice, setMaxPrice] = useState("");

  // Min Area
  const [MinArea, setMinArea] = useState("");

  // Max Area
  const [MaxArea, setMaxArea] = useState("");

  const [aminitiesValue, setAminitiesValue] = useState([]);

  // Show more search options
  const [dataFilter, setDataFilter] = useState(false);

  // Price dropdown

  // Area dropdown
  const [areaDrop, setAreaDrop] = useState(false);

  const [AminitiesDrop, setAminitiesDrop] = useState(false);

  // bath and bedroom dropdown

  const [status, setStatus] = useState("");

  const [isActive, setIsActive] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (searchTerm) => {
    // Perform search logic with the search term
  };

  const handleMobileFilter = () => {
    // Perform filter logic
  };

  const handleFilter = (event) => {
    // event.preventDefault()
    //?min_price_aed=5000&max_price_aed=50000000&min_area_ft=200&max_area_ft=30000&class[]=Luxury&category[]=1&category[]=2&category[]=3&category[]=4&category[]=5&category[]=6&bedrooms[]=1&bedrooms[]=2&location=Al%20Furjan&type=sell&aminities[]=5&aminities[]=6&aminities[]=7&start=0
    const filterVal = `?term=${location}&category[]=${filterKind}&bedrooms[]=${bedrooms}&bathroms[]=${bathRoom}&min_price_aed=${MinPrice}&max_price_aed=${MaxPrice}&status=${status}&min_area_ft=${MinArea}&max_area_ft=${MaxArea}&${aminitiesValue}&keywords=${keywords}`;
    // let path = `/Properties`;
    // navigate(path + filterVal)
    props.handleCloseFilter();
    props.onFilterChange(filterVal, filterType);
  };

  useEffect(() => {
    axios
    .get(url + "categories" + (props.typeChange ? "?target=" + props.typeChange : "") )
    .then(function (response) {
      // handle success
      setList(response.data.data);
    })
    .catch(function (error) {
      // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);
  useEffect(() => {
    axios
      .get(url + "aminities")
      .then(function (response) {
        // handle success
        setAminities(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  return (
    <>
      <div className="z-10 w-full mb-4 md:mb-14 px-5">
        {/* <h3 className="w-full relative z-10 font-black text-center text-3xl xl:text-4xl text-white mb-4 -mt-16">{t('THE BEST WAY TO FIND YOUR HOME')}</h3>
                    <Link to='/MapView' style={{ zIndex: '9' }} className='flex flex-wrap items-center justify-center border-4 bg-white border-white  mt-4  h-36 overflow-hidden rounded-lg'>
                         <Lottie options={defaultOptions} height={100} width={200} />
                         <h3 className="text-mainColor font-bold capitalize w-full text-center"> {t('Search by map')}</h3>
                    </Link> */}
        <div>
          <div className="flex justify-center items-center">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter search term"
              className="max-w-md flex-1 p-4 text-base rounded-full opacity-30 placeholder:text-black placeholder:font-black"
            />
            <button
              onClick={props.handleOpenFilter}
              className=" ml-[-3rem] py-4 px-4 text-base border-0 rounded-full cursor-pointer z-10 bg-black text-white"
            >
              <HiOutlineAdjustments className="text-2xl text-white" />
            </button>
            {/* <button
              onClick={() => handleSearch(searchTerm)}
              className="ml-2 py-3.5 px-3.5 text-base rounded-full cursor-pointer z-10 bg-black text-white border-4 border-[#D4B071]"
              // style={{
              //      marginLeft: '.2rem',
              //      padding: '8px 16px',
              //      fontSize: '16px',
              //      backgroundColor: '#007bff',
              //      color: '#fff',
              //      border: 'none',
              //      borderRadius: '4px',
              //      cursor: 'pointer',
              // }}
            >
              <BsSearch className="text-2xl text-white" />
            </button> */}
          </div>
        </div>
        {/* <button onClick={() => setIsActive(true)} className='flex items-center justify-center flex-wrap bg-mainColor w-full  py-2 mt-4 rounded-xl text-white text-xl font-bold gap-4'><HiOutlineAdjustments className='text-2xl text-white' /></button> */}
      </div>
      <div className="overlay fixed h-screen w-screen bg-black opacity-60 left-0 z-20"></div>
      <div className={`MobileFilter filter fixed w-full z-50 bg-white rounded-xl p-2 lg:p-4 pb-8 shadow-lg capitalize ${props.className} ${isActive && 'active'} `}>
        <div className="relative w-full flex items-center justify-between font-bold py-4 gap-2 " o>
          <button className="bg-gray text-black py-3 px-4 rounded-md flex items-center gap-2 mt-4 w-full" onClick={props.handleCloseFilter}>
            {" "}
            <IoIosArrowDown /> {t("Close Filter")}{" "}
          </button>
          <button className="bg-mainColor text-white py-3 px-4 rounded-md flex items-center gap-2 mt-4 w-full" onClick={handleFilter}>
            {" "}
            <BsSearch className="mx-1" /> {t("Search")}
          </button>
        </div>
        <div className="flex items-center justify-between my-2">
          <div className="tags flex items-center flex-wrap">
            <div className="w-full flex justify-between">
              <span className={`px-4 py-2 rounded-md    inline-flex relative overflow-hidden cursor-pointer ${props.rentClass} ${filterType === "rent" ? "bg-mainColor text-white" : "bg-gray"}`}>
                <input onChange={(e) => setFilterType(e.target.value)} value="rent" type="radio" name="type" id="" className="absolute w-full h-full left-0 top-0 opacity-0" />
                {t("rent")}
              </span>
              <span className={`px-4 py-2 rounded-md mx-2  inline-flex relative overflow-hidden cursor-pointer ${props.buyClass} ${filterType === "buy" ? "bg-mainColor text-white" : "bg-gray"}`}>
                <input onChange={(e) => setFilterType(e.target.value)} value="buy" type="radio" name="type" id="" className="absolute w-full h-full left-0 top-0 opacity-0" />
                {t("buy")}
              </span>
              <Link to={`/Properties?target=${"offplan"}`} className="capitalize px-4 py-2 rounded-md mx-2  inline-flex relative overflow-hidden cursor-pointer font-bold text-lg  text-[#000] bg-gray">
                {" "}
                {t("Off Plan")}{" "}
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 flex-col my-2">
          <div className="form-group w-full relative">
            <BsSearch className="absolute top-1/2 left-2 -translate-y-1/2" />
            <input type="text" className="border border-gray w-full py-2 px-4 pl-8" placeholder={t("City, Community or building")} />
          </div>
          <div className="flex justify-between w-full items-center flex-col gap-2">
            <div className="form-group w-full ">
              <select className="border border-gray w-full py-2 px-2" onChange={(e) => setFilterKind(e.target.value)}>
                <option> {t("Type")} </option>
                {list.map((item, index) => (
                  <option key={index} value={item.nam}>
                    {" "}
                    {item.name}{" "}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group w-full">
              <select className="border border-gray w-full py-2 px-4" onChange={(e) => setStatus(e.target.value)}>
                <option value="">{t("Payment Plan")}</option>
                <option value="1">{t("Year")} </option>
                <option value="2">{t("twoYears")} </option>
                <option value="3"> 3 {t("Years")} </option>
                <option value="4"> 4 {t("Years")} </option>
                <option value="5"> 5 {t("Years")} </option>
                <option value="6"> 6 {t("Years")} </option>
                <option value="7"> 7 {t("Years")} </option>
                <option value="8"> 8 {t("Years")} </option>
                <option value="9"> 9 {t("Years")} </option>
                <option value="10"> +10 {t("Years")} </option>
              </select>
            </div>
            <div className="form-group w-full">
              <select className="border border-gray w-full py-2 px-4" onChange={(e) => setStatus(e.target.value)}>
                <option value="">{t("Off Plan")}</option>
                <option value="1"> {t("Year")} </option>
                <option value="2"> {t("twoYears")} </option>
                <option value="3"> 3 {t("Years")} </option>
                <option value="4"> 4 {t("Years")} </option>
                <option value="5"> 5 {t("Years")} </option>
                <option value="6"> 6 {t("Years")} </option>
                <option value="7"> 7 {t("Years")} </option>
                <option value="8"> 8 {t("Years")} </option>
                <option value="9"> 9 {t("Years")} </option>
                <option value="10"> +10 {t("Years")} </option>
              </select>
            </div>
            <div className="form-group w-full relative">
              <h3 className="font-bold w-full py-2 cursor-pointer"> {t("Bed & Baths")} </h3>
              <div className=" bg-white rounded-lg text-dark z-30 leading-7">
                <div className="flex w-full flex-wrap">
                  <h3 className="">{t("Bedrooms")}</h3>
                  <ul className="w-full flex items-center justify-start">
                    <li
                      className={`rounded-md my-1 mr-2 w-auto px-2 cursor-pointer  ${bedrooms === "studio" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="studio"
                      onClick={() => {
                        setBedrooms("studio");
                      }}
                    >
                      {" "}
                      {t("Studio")}{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "1" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="1"
                      onClick={() => {
                        setBedrooms("1");
                      }}
                    >
                      {" "}
                      1{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "2" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="2"
                      onClick={() => {
                        setBedrooms("2");
                      }}
                    >
                      {" "}
                      2{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "3" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="3"
                      onClick={() => {
                        setBedrooms("3");
                      }}
                    >
                      {" "}
                      3{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "4" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="4"
                      onClick={() => {
                        setBedrooms("4");
                      }}
                    >
                      {" "}
                      4{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "5" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="5"
                      onClick={() => {
                        setBedrooms("5");
                      }}
                    >
                      {" "}
                      5
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "6" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="6"
                      onClick={() => {
                        setBedrooms("6");
                      }}
                    >
                      {" "}
                      6{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "7" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="7"
                      onClick={() => {
                        setBedrooms("7");
                      }}
                    >
                      {" "}
                      7{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "7" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="8"
                      onClick={() => {
                        setBedrooms("8");
                      }}
                    >
                      {" "}
                      8{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "7" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="9"
                      onClick={() => {
                        setBedrooms("9");
                      }}
                    >
                      {" "}
                      9+{" "}
                    </li>
                  </ul>
                </div>
                <div className="flex w-full flex-wrap mt-4">
                  <h3 className="">{t("Bathrooms")}</h3>
                  <ul className="w-full flex items-center justify-start">
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bathRoom === "1" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="1"
                      onClick={() => {
                        setBathRoom("1");
                      }}
                    >
                      {" "}
                      1{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bathRoom === "2" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="2"
                      onClick={() => {
                        setBathRoom("2");
                      }}
                    >
                      {" "}
                      2{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bathRoom === "3" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="3"
                      onClick={() => {
                        setBathRoom("3");
                      }}
                    >
                      {" "}
                      3{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bathRoom === "4" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="4"
                      onClick={() => {
                        setBathRoom("4");
                      }}
                    >
                      {" "}
                      4{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bathRoom === "5" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="5"
                      onClick={() => {
                        setBathRoom("5");
                      }}
                    >
                      {" "}
                      5
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bathRoom === "6" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="6"
                      onClick={() => {
                        setBathRoom("6");
                      }}
                    >
                      {" "}
                      6{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bathRoom === "7" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="7"
                      onClick={() => {
                        setBathRoom("7");
                      }}
                    >
                      {" "}
                      7{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "7" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="8"
                      onClick={() => {
                        setBedrooms("8");
                      }}
                    >
                      {" "}
                      8{" "}
                    </li>
                    <li
                      className={`rounded-md my-1 mx-1 text-center w-8 px-2 cursor-pointer  ${bedrooms === "7" ? "bg-mainColor text-white" : "text-dark bg-gray"}`}
                      value="9"
                      onClick={() => {
                        setBedrooms("9");
                      }}
                    >
                      {" "}
                      9+{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="form-group w-full relative">
              <h3 className="font-bold w-full py-2 cursor-pointer"> {t("Price")} </h3>
              <div className="w-full bg-white rounded-lg text-dark leading-7">
                <div className="flex w-full gap-2 items-center ">
                  <input className="w-1/2 rounded-lg border border-mainColor p-2 " pattern="[0-9]{3} [0-9]{3} [0-9]{4}" type="tel" onChange={(e) => setMinPrice(e.target.value)} placeholder={t("Min. Price (AED) ")} />
                  <span> - </span>
                  <input className="w-1/2 rounded-lg border border-mainColor p-2 " pattern="[0-9]{3} [0-9]{3} [0-9]{4}" type="tel" onChange={(e) => setMaxPrice(e.target.value)} placeholder={t("Max. Price (AED)")} />
                </div>
                <div className="flex w-full flex-wrap mt-4 ">
                  <h3 className="font-bold w-full">{t("Rental Period")}</h3>
                  <span className={`rounded-md my-1 mr-2 w-auto px-3 cursor-pointer text-overflow  ${Period === "Yearly" ? "bg-mainColor text-white" : "text-dark bg-gray"}`} onClick={() => setPeriod("Yearly")}>
                    {t("Yearly")}
                  </span>
                  <span className={`rounded-md my-1 mr-2 w-auto px-3 cursor-pointer text-overflow  ${Period === "Monthly" ? "bg-mainColor text-white" : "text-dark bg-gray"}`} onClick={() => setPeriod("Monthly")}>
                    {t("Monthly")}
                  </span>
                  <span className={`rounded-md my-1 mr-2 w-auto px-3 cursor-pointer text-overflow  ${Period === "Weekly" ? "bg-mainColor text-white" : "text-dark bg-gray"}`} onClick={() => setPeriod("Weekly")}>
                    {t("Weekly")}
                  </span>
                  <span className={`rounded-md my-1 mr-2 w-auto px-3 cursor-pointer text-overflow  ${Period === "Daily" ? "bg-mainColor text-white" : "text-dark bg-gray"}`} onClick={() => setPeriod("Daily")}>
                    {t("Daily")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between flex-col gap-2 relative">
          <div className="flex justify-between w-full flex-col items-center gap-2">
            <div className="form-group w-full">
              <select className="border border-gray w-full py-2 px-1" onChange={(e) => setStatus(e.target.value)}>
                <option value="completion-status">{t("Completion Status")}</option>
                <option value="offplan ">{t("Off-plan")} </option>
                <option value="ready">{t("Ready")} </option>
              </select>
            </div>
            <div className="form-group w-full relative">
              <h3 className="font-bold w-full py-2 cursor-pointer"> {t("Area")} </h3>
              <div className="w-auto bg-white rounded-lg text-dark z-20 leading-7">
                <div className="flex w-full gap-2 items-center ">
                  <input className="w-1/2 rounded-lg border border-mainColor p-2 " pattern="[0-9]{3} [0-9]{3} [0-9]{4}" type="tel" onChange={(e) => setMinArea(e.target.value)} placeholder={t("Min. Area (Sqft)")} />
                  <span> - </span>
                  <input className="w-1/2 rounded-lg border border-mainColor p-2 " pattern="[0-9]{3} [0-9]{3} [0-9]{4}" type="tel" onChange={(e) => setMaxArea(e.target.value)} placeholder={t("Max. Area (Sqft)")} />
                </div>
              </div>
            </div>
            <div className="form-group w-full relative">
              <h3 className="w-full py-2 px-2 cursor-pointer font-bold"> {t("Aminities")} </h3>
              <div className="aminities px-2 w-auto bg-white rounded-lg text-dark z-30 leading-7 ">
                <div className=" flex flex-wrap w-full items-center ">
                  {aminities.map((item, index) => (
                    <label
                      className="checkBox aminitiesCheckbox mb-4 w-1/2 flex items-center relative cursor-pointer rtl:pr-6 ltr:pl-6 select-none text-md h-4 text-overflow"
                      key={index}
                      onChange={(e) => {
                        aminitiesValue.push("aminities[]=" + e.target.value);
                      }}
                    >
                      {item.name}
                      <input type="checkbox" className="absolute opacity-0 cursor-pointer h-0 w-0" value={item.id} />
                      <span className="checkmark absolute top-0 ltr:left-0 ltr:right-auto rtl:right-0 rtl:left-auto h-4 w-4 bg-gray"></span>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="form-group w-full relative">
            <BsSearch className="absolute top-1/2 left-2 -translate-y-1/2" />
            <input onChange={(e) => setkeywords(e.target.value)} type="text" className="border border-gray w-full py-2 pl-8 px-4" placeholder={t("Keywords: e.g. beach, city")} />
          </div>
        </div>
        <div className="flex justify-between items-center gap-4">
          <button className="bg-gray text-dark py-3 border-2 border-gray px-4 rounded-md flex items-center gap-2 mt-4 w-1/2" onClick={props.handleCloseFilter}>
            {" "}
            <IoIosArrowDown /> {t("Close Filter")}{" "}
          </button>
          <button className="bg-mainColor text-white py-3 px-4 rounded-md flex items-center gap-2 mt-4 w-1/2" onClick={handleFilter}>
            {" "}
            <BsSearch className="mx-1" /> {t("Search")}
          </button>
        </div>
      </div>
    </>
  );
};

export default FilterMobile;
