import React, { useEffect } from "react";
import Layout from "../Components/Layout";
import { BiPhoneCall } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { config, url } from "../Common/Common";
import axios from "axios";
import { useState } from "react";
import { t } from "i18next";
import Loading from "../Components/UI/Loading";
import BG from "../img/about-bg.webp";

const About = () => {
  const [about, setAbout] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(url + `about-us`, config)
      .then(function (response) {
        const data = response.data.data;
        setAbout(data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = {
    backgroundImage: `url(${BG})`,
  };

  return (
    <Layout>
      {isLoading ? (
        <div className="pt-8 md:pt-0 md:mt-28 container mx-auto flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <section>
          <div className="relative">
            <img src={BG} className="absolute inset-0 object-cover w-full h-full bg-top brightness-[0.60]" style={{ objectPosition: "top" }} alt="About-Us" />

            <div className="relative bg-gray-900 h-[80vh]">
              <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 h-full">
                <div className="flex flex-col items-center justify-center xl:flex-row h-full">
                  <div className="flex flex-col items-start justify-center w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12 h-full">
                    <h2 className="max-w-lg mb-6 font-sans text-3xl md:leading-10 font-bold tracking-tight text-white sm:text-4xl leading-none " style={{ textShadow: "0px 5px 10px rgba(0, 0, 0, 0.47)" }}>
                      Keymax Real Estate is a <br className="hidden md:block" />
                      Dubai-based company <br className="hidden md:block" /> founded in 2014 by <br className="hidden md:block" />
                      Dubai Land Department accredited consultants and industry savvy Directors.
                    </h2>
                  </div>
                  <div className="w-full max-w-xl xl:px-8 xl:w-5/12"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mx-auto mt-20 md:mt-28 md:px-0">
            {about.about_us && (
              <>
                <section>
                  <div className="container px-6 py-10 mx-auto">
                    <div className="row flex items-center justify-center flex-wrap">
                      <div className="w-full xl:w-8/12 relative">
                        <p className="text-lg md:text-2xl ltr:text-left rtl:text-right relative leading-loose font-black" dangerouslySetInnerHTML={{ __html: about.about_us.content }} />
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
            {about.ceo && (
              <div className="flex justify-center my-20 md:justify-end w-full md:max-w-5xl mx-auto relative drop-shadow-lg">
                {/* <div className="w-full xl:w-10/12 mt-8">
                  <section className="Manger bg-[#E5E5E5] relative">
                    <div className="container mx-auto">
                      <div className="row flex items-center justify-center flex-wrap">
                        <div className="w-full md:w-10/12 py-16 px-4">
                          <div className="round">
                            <img src={about.ceo.image} alt="" className="mx-auto h-full" />
                          </div>
                          <h1 className="text-3xl font-bold my-2"> {about.ceo.name} </h1>
                          <p className="flex items-center gap-2 font-bold">
                            <span className="w-32 h-2 bg-gold text-gold ">.</span>
                            {about.ceo.position}
                          </p>
                          <b className="my-4 flex"> {t("Help us improve your service")}“</b>
                          <p className="text-xl ltr:text-left rtl:text-right relative font-medium leading-loose" dangerouslySetInnerHTML={{ __html: about.ceo.content }} />

                          <div className="flex items-center justify-end flex-wrap w-full mt-8">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`tel:${about.ceo.phone}`}
                              className="px-1 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-7 flex items-center m-1"
                            >
                              {" "}
                              <BiPhoneCall className="" />{" "}
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`mailto:${about.ceo.email}`}
                              className="px-1 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-7 flex items-center m-1"
                            >
                              {" "}
                              <AiOutlineMail className="" />{" "}
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={about.ceo.twitter}
                              className="px-1 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-7 flex items-center m-1"
                            >
                              {" "}
                              <FiTwitter className="" />{" "}
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={about.ceo.instagram}
                              className="px-1 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-7 flex items-center m-1"
                            >
                              {" "}
                              <FiInstagram className="" />{" "}
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={about.ceo.linkedin}
                              className="px-1 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-7 flex items-center m-1"
                            >
                              {" "}
                              <FiLinkedin className="" />{" "}
                            </a>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`https://api.whatsapp.com/send?phone=${about.ceo.whatsapp}`}
                              className="px-1 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-7 flex items-center m-1"
                            >
                              {" "}
                              <BsWhatsapp className="" />{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div> */}
                <div className="bg-black rounded-3xl p-4 md:p-12 mx-auto pb-32 md:pb-12 my-8 md:my-12 relative">
                  <p className="text-lg md:text-2xl ltr:text-left rtl:text-right relative font-medium leading-loose text-white" dangerouslySetInnerHTML={{ __html: about.ceo.content }} />
                  <p className="text-xl md:text-3xl font-bold my-2 text-[#D4B071] font-Butler pt-5">
                    {about.ceo.name}, {about.ceo.position}{" "}
                  </p>
                  <div className="absolute aspect-square max-w-[55%] md:max-w-xs rounded-full overflow-hidden border-[3px] border-[#D4B071] -right-2 -bottom-24 md:-right-[10%] md:-bottom-[15%]">
                    <img src={about.ceo.image} alt="" className="object-cover w-52 md:max-w-xs mx-auto h-full" />
                  </div>
                </div>
              </div>
            )}
          </div>

          {about.our_values.length > 0 && (
            // <section className="bg-[#fff]  py-16">
            //   <div className="container px-6 py-10 mx-auto">
            //     <div className="row flex items-center justify-center flex-wrap">
            //       <div className="w-full">
            //         <h3 className="title font-black mb-8 md:text-3xl relative overflow-hidden pb-8 text-center">
            //           {t("Our Values")}
            //           <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-28 h-2 bg-gold" />
            //         </h3>
            //       </div>

            //       <div className="row flex items-start justify-center flex-wrap w-full">
            //         {about.our_values.map((item, index) => (
            //           <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 relative overflow-hidden p-2" key={index}>
            //             <div className="TeamCard bg-[#E5E5E5] p-4 border-2 border-gold  rounded-lg h-96">
            //               <div className="w-32 h-32 border-2 border-gold rounded-full flex items-center justify-center mx-auto">
            //                 <img className="max-w-[5rem]" src={item.icon} alt={item.title} />
            //               </div>
            //               <div className="pb-5 text-center">
            //                 <h3 className="block text-2xl font-bold text-gray-800 my-6">{item.title}</h3>
            //                 <p className="text-md lines five capitalize text-gray-400 relative overflow-hidden"> {item.content}</p>
            //               </div>
            //             </div>
            //           </div>
            //         ))}
            //       </div>
            //     </div>
            //   </div>
            // </section>
            <section className="px-4 py-16 w-full md:px-24 lg:px-8 lg:py-32 bg-black">
              <div className="container px-6 py-10 mx-auto mt-24 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 grid gap-32 lg:grid-cols-2">
                {about.our_values.map((item, index) => (
                  <div className="relative px-10 py-6 text-center border-4 border-[#D4B071] pt-6 md:pt-28 rounded-3xl lg:px-5 lg:py-8 xl:py-8 flex justify-center items-center flex-col ">
                    <div className="w-20 h-20 md:w-28 md:h-28 absolute bg-black rounded-full flex items-center justify-center -top-16 md:-top-24 border-4 border-[#D4B071]">
                      <img className="w-14" src={item.icon} alt={item.title} />
                    </div>
                    <p className="shrink mb-3 font-black leading-7 py-3 text-lg md:text-3xl px-3 text-white">{item.title}</p>
                    <p className="grow mb-2 text-white text-xl md:text-2xl font-bold">{item.content}</p>
                  </div>
                ))}

                {/* <div className="px-10 py-8 text-center border rounded lg:px-5 lg:py-10 xl:py-20">
                  <p className="mb-2 text-xs font-semibold tracking-wide text-gray-600 uppercase">20 Nov 2020</p>
                  <a
                    href="/"
                    className="inline-block max-w-xs mx-auto mb-3 text-2xl font-extrabold leading-7 transition-colors duration-200 hover:text-deep-purple-accent-400"
                    aria-label="Read article"
                    title="Nori grape silver beet broccoli kombu beet"
                  >
                    Nori grape silver beet broccoli kombu beet
                  </a>
                  <p className="max-w-xs mx-auto mb-2 text-gray-700">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.</p>
                  <a href="/" aria-label="" className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                    Read more
                  </a>
                </div>
                <div className="px-10 py-20 text-center border rounded lg:px-5 lg:py-10 xl:py-20">
                  <p className="mb-2 text-xs font-semibold tracking-wide text-gray-600 uppercase">20 Nov 2020</p>
                  <a
                    href="/"
                    className="inline-block max-w-xs mx-auto mb-3 text-2xl font-extrabold leading-7 transition-colors duration-200 hover:text-deep-purple-accent-400"
                    aria-label="Read article"
                    title="Well, the way they make shows is, they make one"
                  >
                    Well, the way they make shows is, they make one
                  </a>
                  <p className="max-w-xs mx-auto mb-2 text-gray-700">Some pilots get picked and become television programs. Some don't, become nothing.</p>
                  <a href="/" aria-label="" className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                    Read more
                  </a>
                </div>
                <div className="px-10 py-20 text-center border rounded lg:px-5 lg:py-10 xl:py-20">
                  <p className="mb-2 text-xs font-semibold tracking-wide text-gray-600 uppercase">20 Nov 2020</p>
                  <a
                    href="/"
                    className="inline-block max-w-xs mx-auto mb-3 text-2xl font-extrabold leading-7 transition-colors duration-200 hover:text-deep-purple-accent-400"
                    aria-label="Read article"
                    title="Pommy ipsum smeg head whizz morris himer due"
                  >
                    Pommy ipsum smeg head whizz morris himer due
                  </a>
                  <p className="max-w-xs mx-auto mb-2 text-gray-700">Taking the mick middle className bog roll bow ties are cool posh nosh off t'shop, stew and dumps.</p>
                  <a href="/" aria-label="" className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800">
                    Read more
                  </a>
                </div> */}
              </div>
            </section>
          )}

          {about.belief && (
            <section className="-mb-16">
              <div className="container mx-auto px-4 pb-16 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-20">
                <div className="flex items-center justify-center relative w-full">
                  <div className="border-4 border-white bg-black px-6 py-3 rounded-full absolute -top-8 left-0">
                    <p className="text-white font-bold md:text-3xl text-xl">{about.belief.title}</p>
                  </div>
                  <div className="w-full relative py-16 ">
                    {/* <h3 className="title font-black mb-8 md:text-3xl relative overflow-hidden pb-8 text-center">
                      {about.belief.title}
                      <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-28 h-2 bg-gold" />
                    </h3> */}
                    <p className="text-lg md:text-2xl ltr:text-left rtl:text-right relative font-bold leading-loose" dangerouslySetInnerHTML={{ __html: about.belief.content }} />
                  </div>
                </div>
              </div>
            </section>
          )}
          {/*   {about.mission &&
            <section className="bg-[#fff]  py-16">
              <div className="container px-6 py-10 mx-auto">
                <div className="row flex items-center justify-center flex-wrap">
                  <div className="w-full xl:w-8/12 relative">
                    <h3 className="title font-black mb-8 md:text-3xl relative overflow-hidden pb-8 text-center">
                    {about.mission.title}
                      <span className='absolute bottom-0 left-1/2 -translate-x-1/2 w-28 h-2 bg-gold' />
                    </h3>
                  <p className='text-xl ltr:text-left rtl:text-right relative font-medium leading-loose'> {about.mission.content}</p>
                  </div>
                </div>
              </div>
            </section>
          }

           {about.vision &&
            <section className="bg-[#E5E5E5]  py-16">
              <div className="container px-6 py-10 mx-auto">
                <div className="row flex items-center justify-center flex-wrap">
                  <div className="w-full xl:w-8/12 relative">
                    <h3 className="title font-black mb-8 md:text-3xl relative overflow-hidden pb-8 text-center">
                      {about.vision.title}
                      <span className='absolute bottom-0 left-1/2 -translate-x-1/2 w-28 h-2 bg-gold' />
                    </h3>
                    <p className='text-xl ltr:text-left rtl:text-right relative font-medium leading-loose'>{about.vision.content}</p>
                  </div>
                </div>
              </div>
            </section>
          } */}
        </section>
      )}
    </Layout>
  );
};

export default About;
