import { useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../Components/Layout";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import "../Style/Auth.scss";
import axios from "axios";
import { setUserSession, url } from "../Common/Common";
// import SocialMedia from '../Components/SocialMedia/SocialMedia';
import ErrorAlert from "../Components/UI/ErrorAlert";
import { t } from "i18next";
import bgImage from "../img/Hero.webp";

const Sign = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pass, setPass] = useState("");
  const [applyPass, setApplyPass] = useState("");
  const [error, setError] = useState("");
  const [phone, setPhone] = useState("");

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const SignHandler = () => {
    if (pass != applyPass) {
      setError(`Password doesn't match OR At least 8 characters, 1 uppercase letter, 1 number, 1 symbol`);
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    if (email.trim() == " " || email.trim().length < 8 || firstName.trim() == " " || firstName.trim().length < 3 || lastName.trim() == " " || lastName.trim().length < 3) {
      setError(`Please check your data !! `);
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }
    axios
      .post(url + "auth/register", {
        email: email,
        password: pass,
        f_name: firstName,
        l_name: lastName,
        phone: phone,
      })
      .then((response) => {
        setEmail("");
        setPass("");
        setFirstName("");
        setLastName("");
        setPhone("");
        setApplyPass("");
        setUserSession(response.data.data.token, response.data.data.user.name);
        window.location = "/";
      })
      .catch((error) => {
        console.log(error.response.data);
        setError(error.response.data);
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };
  const bgStyles = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <Layout>
      <div style={bgStyles} className={`SignUp bg-no-repeat bg-cover flex items-center justify-center py-8 md:py-24 md:pt-0 px-6 md:px-0 mt-12 md:mt-24 -mb-16 md:-mb-24`}>
        <div className="container mx-auto">
          <div className="row flex items-center justify-center text-center md:mt-12">
            <div className="w-full sm:w-1/2 lg:w-1/3 ">
              <div>
                <p className="text-2xl md:text-4xl font-bold text-white">
                  <span>Asalam, hello!</span>
                  <br /> Register with Keymax
                  <br /> to list your property with us
                </p>
              </div>
              <form action="">
                {/*   <div className="signSocialMedia pb-8 border-mainColor">
                   <SocialMedia /> 
                </div>*/}
                {error && <ErrorAlert error={error} />}

                <div className="mt-6">
                  <input
                    required
                    pattern="^\w+([\w ]*\w)*$"
                    minLength="4"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    type="text"
                    name="FirstName"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white  rounded-full opacity-80"
                    placeholder={t("First Name")}
                  />
                </div>
                <div className="mt-6">
                  <input
                    required
                    pattern="^\w+([\w ]*\w)*$"
                    minLength="4"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    type="text"
                    name="LastName"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white  rounded-full opacity-80"
                    placeholder={t("Last Name")}
                  />
                </div>
                <div className="mt-6">
                  <input
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    name="email"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white  rounded-full opacity-80"
                    placeholder={t("Address Email")}
                  />
                </div>
                <div className="mt-6">
                  <input
                    required
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    type="tel"
                    name="phone"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white  rounded-full opacity-80"
                    placeholder={t("Phone")}
                  />
                </div>
                <div className="mt-6 relative">
                  <input
                    onCut={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    required
                    onChange={(e) => setPass(e.target.value)}
                    value={pass}
                    type={showPassword ? "text" : "password"}
                    maxLength="20"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white rounded-full  opacity-80"
                    placeholder={t("Password")}
                  />
                  <span className="absolute ltr:right-2 ltr:left-auto rtl:right-auto rtl:left-2 top-1/2 -translate-y-1/2 cursor-pointer" onClick={handleShowPassword}>
                    {showPassword ? <AiOutlineEye className="text-2xl text-mainColor" /> : <AiOutlineEyeInvisible className="text-2xl" />}
                  </span>
                </div>
                <div className="mt-6 relative">
                  <input
                    onCut={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    required
                    onChange={(e) => setApplyPass(e.target.value)}
                    value={applyPass}
                    type={showPassword ? "text" : "password"}
                    maxLength="20"
                    autoComplete="true"
                    className="w-full p-4 text-mainColor bg-white rounded-full  opacity-80"
                    placeholder={t("Apply Password")}
                  />
                  <span className="absolute ltr:right-2 ltr:left-auto rtl:right-auto rtl:left-2 top-1/2 -translate-y-1/2 cursor-pointer" onClick={handleShowPassword}>
                    {showPassword ? <AiOutlineEye className="text-2xl text-mainColor" /> : <AiOutlineEyeInvisible className="text-2xl" />}
                  </span>
                </div>
                <div className="mt-6 relative flex justify-center items-center">
                  <label className="checkBox relative cursor-pointer ltr:pl-8 rtl:pr-8 select-none text-md rtl:text-right ltr:text-left flex justify-center items-center flex-col items-center justify-start text-white font-bold ">
                    {t("By registering, you agree to the")}
                    <Link to="/Terms" className="text-blue font-bold mx-1">
                      {" "}
                      {t("Terms of Service and Privacy Policy")}{" "}
                    </Link>
                    <input type="checkbox" className="absolute opacity-0 cursor-pointer h-0 w-0" />
                    <span className="checkmark absolute top-0 ltr:left-0 ltr:right-auto rtl:left-auto rtl:right-0 h-6 w-6 bg-gray"></span>
                  </label>
                </div>

                <div className="mt-6">
                  <button onClick={SignHandler} className=" bg-white px-12 py-3 text-lg font-bold md:text-2xl rounded-full text-center cursor-pointer bg-whtie border-4 border-[#D4B071] text-black" type="button">
                    {" "}
                    {t("Sign up")}{" "}
                  </button>
                  <br />
                  <br />
                </div>
                <Link to="/Login" className="text-mainColor font-bold text-center mt-2 w-full block text-white">
                  {" "}
                  . . . . {t("Login")} . . . .{" "}
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Sign;
