import axios from "axios";
import React, { useEffect, useState } from "react";
import { config, url } from "../Common/Common";
import Layout from "../Components/Layout";
import Loading from "../Components/UI/Loading";
import Nothing from "../Components/UI/Nothing";
import { t } from "i18next";
import { Helmet } from "react-helmet-async";
import ProjectList from "./ProjectList";
import { Link, useLocation } from "react-router-dom";
import { Pagination } from "../Components/Pagination/Pagination";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(queryParams.get("page") || 1);

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchedProjects, setSearchedProjects] = useState([]);
  const [searchProjects, setSearchProjects] = useState([]);
  const [filtersProjects, setFilteredProjects] = useState([]);
  const [isInputFocused, setInputFocused] = useState(false);
  const [total, setTotal] = useState();

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  const displayPageCount = 5; // Number of page numbers to display

  let startPage = Math.max(1, currentPage - 2); // Display 2 pages before the current page
  const endPage = Math.min(startPage + displayPageCount - 1, lastPage);

  // Adjust the start page if there are not enough pages on the right
  if (endPage - startPage < displayPageCount - 1) {
    startPage = Math.max(1, lastPage - displayPageCount + 1);
  }

  const pages = [];
  for (let page = startPage; page <= endPage; page++) {
    pages.push(page);
  }

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth", // Smooth scrolling animation
  //   });
  // };
  // const onPageChange = (page) => {
  //   setCurrentPage(page);
  // };

  useEffect(() => {
    fetchProjects(page);
  }, [page]);

  useEffect(() => {
    axios
      .get(`${url}projects-searched`, config)
      .then(function (response) {
        setSearchedProjects(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setError("Error fetching projects. Please try again.");
      });
  }, [filtersProjects]);

  const fetchProjects = (page) => {
    setLoading(true);
    axios
      .get(`${url}projects?page=${page}`, config)
      .then(function (response) {
        setProjects(response.data.data);
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setTotal(response.data.total);
        setLoading(false);
        setError(null);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setError("Error fetching projects. Please try again.");
      });
  };

  const handleReload = () => {
    setError(null);
    fetchProjects(currentPage);
  };

  const handleSearchedProjects = (e) => {
    const query = e.target.value;

    if (query) {
      setHasSearched(true);
      const searchList = searchedProjects.filter((item) => {
        return item.name_en.toLowerCase().includes(query.toLowerCase());
      });
      setFilteredProjects(searchList);
    } else {
      setHasSearched(false);
      setFilteredProjects([]);
    }
  };

  return (
    <Layout>
      <Helmet>{/* ... (helmet content) */}</Helmet>

      <div className="container mx-auto px-4 md:p-0 mt-20 md:mt-28">
        <h3 className="font-bold text-2xl text-center md:text-4xl w-full my-8">
          <span className="border-b-2 pb-2 border-[#CDAC62] text-black px-4 md:px-4">
            {" "}
            {t("Projects")}{" "}
          </span>{" "}
        </h3>

        <div className="w-full flex justify-center mb-8 md:mb-12 relative">
          <input
            className={`px-6 py-3 border-4 border-black/40 rounded-full placeholder:text-center ${
              isInputFocused ? "placeholder:text-transparent" : ""
            }`}
            type="text"
            placeholder="Search Projects"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleSearchedProjects}
          />
          {filtersProjects.length > 0 && (
            <div className="max-w-lg min-w-[16rem] absolute top-16 border-2 border-black overflow-y-auto max-h-32 z-20 bg-white rounded-xl p-4 overflow-hidden">
              {filtersProjects.slice(0, 12).map((project) => (
                // Render project data here, for example:
                <div key={project.id} className="py-2">
                  <Link to={`/projects/${project.slug}`}>
                    <p>{project.name_en}</p>
                  </Link>
                  {/* Add more project data as needed */}
                </div>
              ))}
            </div>
          )}
        </div>
        {isLoading && (
          <div className="absolute top-0 left-0 h-full w-screen bg-white z-40 flex items-center justify-center">
            <Loading />
          </div>
        )}

        <div className="grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1">
          {projects.length && (
            <>
              {projects.map((project) => (
                <ProjectList project={project} key={project.id} />
              ))}
            </>
          )}
        </div>

        {/* Pagination */}
        <Pagination
          endPage={endPage}
          currentPage={currentPage}
          pages={pages}
          title={"projects"}
          total={total}
          onClick={(val) => {
            setPage(val);
          }}
        />

        {/* Check for error and show reload button */}
        {error && (
          <div className="text-red-500 font-bold text-center h-screen flex justify-center items-center flex-col">
            <p className="text-lg md:text-2xl">{error}</p>
            <button
              onClick={handleReload}
              className="px-6 py-3 rounded-full bg-black text-white mt-12 text-blue-500 hover:text-blue-600 cursor-pointer"
            >
              Reload
            </button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Projects;
