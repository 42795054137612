import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { config, url } from "../Common/Common";
import Layout from "../Components/Layout";
import Support from "../Components/Sections/Support";
import { GoogleMap, LoadScript, OverlayView } from "@react-google-maps/api";
import logo from "../img/logo_keymax.png";
import Title from "../Components/UI/Title";
import Loading from "../Components/UI/Loading";
import BG from "../img/contact_hero.webp";
import { Link } from "react-router-dom";
import { BiPhoneCall } from "react-icons/bi";
import { GiRotaryPhone } from "react-icons/gi";
import { AiOutlineMail } from "react-icons/ai";
import MapTriangle from "../Components/SvgIcons/MapTriangle";

const Contact = () => {
  const [contact, setContact] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(url + `pages/contact_us`, config)
      .then(function (response) {
        const data = response.data.data;
        setContact(data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const containerStyle = {
    width: "100%",
    height: "450px",

    minHeight: "350px",
  };

  const MAP_OPTIONS = {
    zoomControl: false,
    gestureHandling: "cooperative",
  };

  const center = {
    // 25.194905804999397, 55.260731123286945
    lat: +25.1942628,
    lng: +55.2609867,
  };
  const styles = {
    backgroundImage: `url(${BG})`,
  };
  return (
    <Layout>
      <div className="container mx-auto py-8">
        {isLoading ? (
          <div className="pt-8 md:pt-0 md:mt-28 container mx-auto flex justify-center items-center h-screen">
            <Loading />
          </div>
        ) : (
          <div className=" mt-20 md:mt-28 ">
            <div className="h-[60vh] md:h-[75vh] bg-cover rounded-[3.125rem] bg-center" style={styles}></div>
            <div className="w-full md:w-full flex justify-center items-center flex-col md:flex-row">
              <div className="max-w-full md:max-w-4xl mx-auto mb-10 mt-8 md:-mt-16">
                <div className="w-full border-0 md:border-4 border-[#D4B071] rounded-[3.125rem] overflow-hidden">
                  <div className="w-full mx-auto flex flex-col md:flex-row bg-white">
                    <div className="w-full p-5 pl-5 md:pl-5 md:p-28 bg-white md:bg-black border-b-2 md:border-b-0 border-[#D4B071] md:rounded-[2.8rem] md:w-1/2 w-full">
                      <h4 className="pb-12 md:pb-0 text-2xl text-black md:text-left text-center  md:text-white font-black">Keymax Real Estate</h4>
                      <div className="lg:max-w-sm flex flex-col justify-between h-full">
                        <p className="text-lg font-bold pb-12 md:pb-0 text-black md:text-white">
                          <a href="tel:97146052400 " className="flex items-center" target="_blank" rel="noreferrer">
                            <GiRotaryPhone className="rtl:ml-2 ltr:mr-2" />
                            <span className="ltr">+971 4 605 2400</span>{" "}
                          </a>
                          <a href="tel:971505720423" className="flex items-center" target="_blank" rel="noreferrer">
                            <BiPhoneCall className="rtl:ml-2 ltr:mr-2" />
                            <span className="ltr">+971 50 572 0423 </span>{" "}
                          </a>

                          <a href="mailto:info@keymaxrealestate.com" className="flex items-center" target="_blank" rel="noreferrer">
                            <AiOutlineMail className="rtl:ml-2 ltr:mr-2" />
                            info@keymaxrealestate.com
                          </a>
                        </p>
                        <p className="text-lg font-bold pb-12 md:pb-0 text-black md:text-white">
                          <a
                            href="https://www.google.com/maps/place/Key+Max+Real+Estate+Brokers/@25.1941874,55.2609457,15z/data=!4m5!3m4!1s0x0:0x7197e1467d539d59!8m2!3d25.1941874!4d55.2609457"
                            className="flex items-center"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {/* <GoLocation className='rtl:ml-2 ltr:mr-2' />   */}
                            {t("Office 107, \n Sheikh Zayed Rd, Rawdat Al Wasl Building, Dubai, UAE")}
                          </a>
                        </p>
                        <p className="text-lg font-bold pb-12 md:pb-0 text-black md:text-white">
                          <p>{t("Monday to Saturday")}</p>
                          <p>9AM - 6PM</p>
                        </p>
                      </div>
                    </div>
                    <Support />
                  </div>
                </div>
              </div>
              {/* <div style={{ overflowWrap: "anywhere" }} dangerouslySetInnerHTML={{ __html: contact.contents }} /> */}
            </div>
          </div>
        )}
      </div>
      <div className="w-full md:w-full">
        <div className="w-full h-full overflow-hidden text-center">
          <LoadScript googleMapsApiKey="AIzaSyCSu32tihJqyOGNpcNdUnjBbNBoACV2R_M">
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16} options={MAP_OPTIONS}>
              <OverlayView
                position={center}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                  x: -(width / 2),
                  y: -(height / 2),
                })}
              >
                <a href="https://maps.google.com/maps?ll=25.194263,55.260987&z=20&t=m&hl=en-US&gl=US&mapclient=apiv3&cid=8185258540635757913">
                  <div className="mapIcon relative -mt-5 drop-shadow-xl	rounded-full" style={{ backgroundColor: "white", padding: "5px", borderRadius: "5px" }}>
                    <div style={{ position: "relative", width: "100%", height: "100%" }}>
                      {/* Add your image inside the div */}

                      <img className="w-40 px-6 py-2" src={logo} alt="Your Image" style={{ objectFit: "cover" }} />
                    </div>
                    <MapTriangle />
                  </div>
                </a>
              </OverlayView>
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
