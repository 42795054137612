import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { BsWhatsapp, BsTwitter, BsFillSuitHeartFill, BsFillShareFill } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail, AiOutlineCopy } from "react-icons/ai";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { config, url } from "../../Common/Common";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useContext, useState } from "react";
import { FaBusinessTime, FaTiktok, FaFacebookF } from "react-icons/fa";
import { FiDollarSign, FiInstagram, FiLinkedin } from "react-icons/fi";
import { GrLinkedinOption } from "react-icons/gr";
import FavoritesContext from "../../store/store";
import ImgSlider from "../UI/ImgSlider";

const WidthCard = (props) => {
  const [message, setMessage] = useState(false);
  const [SocialMedia, setSocialMedia] = useState(false);
  const ctx = useContext(FavoritesContext);
  const itemIsFavorite = ctx.itemIsFavorite(props.id);
  const navigate = useNavigate();
  function toggleFavoriteStatusHandler() {
    if (!localStorage.KeyMaxToken) {
      navigate("/login");
      return;
    }
    if (itemIsFavorite) {
      ctx.removeFavorite(props.id);
    } else {
      ctx.addFavorite(props.id);
    }
  }

  const handleShare = () => {
    setMessage(true);
    setTimeout(() => {
      setMessage(false);
    }, 2000);
    navigator.clipboard.writeText(`https://keymaxrealestate.com/PropertyDetails/${props.slug}`);
  };

  return (
    <div className="flex flex-wrap w-full mx-auto overflow-hidden bg-white rounded-lg border-2 border-[#D4B071] my-4 relative" onMouseLeave={() => setSocialMedia(false)}>
      <Link to={`/PropertyDetails/${props.slug}`} className="w-full md:w-2/5 h-72 relative">
        <ImgSlider imgs={props.images} heightValue={props.height} widthValue={props.width} />
        {props.status && (
          <div className="absolute bg-green top-4 ltr:left-0 rtl:right-0 px-4 ltr:rounded-r-md rtl:rounded-l-md py-2 z-10 font-bold opacity-90 text-white capitalize">
            <h3> {props.status}</h3>
          </div>
        )}
      </Link>
      <div className="w-full md:w-3/5 px-4 pt-4 md:p-4 text-left">
        <div className="flex justify-between items-center h-full flex-wrap">
          <div className=" flex items-center flex-wrap justify-between text-center w-full md:w-4/5">
            <h1 className="text-2xl font-bold text-gray-800 w-full rtl:text-right ltr:text-left mb-2">{props.name}</h1>
            <div className="flex justify-between items-center mt-2 flex-wrap w-full">
              <Link to={`/PropertyDetails/${props.slug}`} className="flex items-center mb-2 lg:mb-0 lg:w-3/4 text-overflow">
                <div className="w-8">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="26.992" viewBox="0 0 22 26.992">
                    <path
                      id="icons8_home_address"
                      d="M15,2A11,11,0,0,0,4,13c0,7.234,7.153,10.7,8.049,11.5a9.282,9.282,0,0,1,1.975,3.713,1,1,0,0,0,1.953,0A9.289,9.289,0,0,1,17.951,24.5C18.847,23.7,26,20.234,26,13A11,11,0,0,0,15,2Zm0,6a.5.5,0,0,1,.3.1l4,3a.5.5,0,0,1-.3.9v5H16V14H14v3H11V11.99a.489.489,0,0,1-.4-.189.5.5,0,0,1,.1-.7l4-3A.5.5,0,0,1,15,8Z"
                      transform="translate(-4 -2)"
                      fill="#CDAC62"
                    />
                  </svg>
                </div>
                <p className="text-black opacity-50 text-overflow"> {props.location} </p>
              </Link>
            </div>

            <Link to={`/PropertyDetails/${props.slug}`} className="border w-full flex border-gray rounded-lg px-2">
              <div className="flex items-center justify-start py-2 w-1/3 text-overflow ">
                <svg xmlns="http://www.w3.org/2000/svg" width="18.249" height="18.249" viewBox="0 0 18.249 18.249">
                  <path
                    id="icons8_floor_plan"
                    d="M20.59,4H5.659A1.659,1.659,0,0,0,4,5.659V20.59a1.659,1.659,0,0,0,1.659,1.659H20.59a1.659,1.659,0,0,0,1.659-1.659V5.659A1.659,1.659,0,0,0,20.59,4Zm0,15.761a.829.829,0,0,1-.83.83H6.489a.829.829,0,0,1-.83-.83V6.489a.829.829,0,0,1,.83-.83H9.81a.833.833,0,0,1,.5.166l3.977,2.987-1,1.327L9.533,7.318H7.318V18.931h4.148V14.784H9.807V13.125h4.977v1.659H13.125v4.148h5.807V14.784H17.272V13.125h1.659V7.318H16.443V5.659h3.318a.829.829,0,0,1,.83.83Z"
                    transform="translate(-4 -4)"
                    fill="#CDAC62"
                  />
                </svg>
                <span className="ltr:ml-2 rtl:mr-2 whitespace-nowrap capitalize">
                  {props.area} {t("sqft")}
                </span>
              </div>
              <div className="flex items-center justify-center py-2 w-1/3 text-overflow border-r-2 border-l-2 border-gray">
                <svg xmlns="http://www.w3.org/2000/svg" width="22.216" height="18.249" viewBox="0 0 22.216 18.249">
                  <path
                    id="icons8_bed"
                    d="M4.174,4A1.586,1.586,0,0,0,2.587,5.587v5.554H4.174V9.554a.794.794,0,0,1,.793-.793h5.554a.794.794,0,0,1,.793.793v1.587H12.9V9.554a.794.794,0,0,1,.793-.793h5.554a.794.794,0,0,1,.793.793v1.587h1.587V5.587A1.586,1.586,0,0,0,20.043,4ZM1.781,11.924a.794.794,0,0,0-.781.8v8.728a.793.793,0,1,0,1.587,0v-.793H21.629v.793a.793.793,0,1,0,1.587,0V12.728a.794.794,0,1,0-1.587,0H2.587a.794.794,0,0,0-.806-.8Z"
                    transform="translate(-1 -4)"
                    fill="#CDAC62"
                  />
                </svg>
                <span className="ltr:ml-2 rtl:mr-2">{props.bedrooms}</span>
              </div>
              <div className="flex items-center justify-center py-2 w-1/3 text-overflow ">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.439" height="18.249" viewBox="0 0 20.439 18.249">
                  <path
                    id="icons8_bathtub"
                    d="M6.475,0A3.3,3.3,0,0,0,3.19,3.285V8.03H1.73a.73.73,0,1,0,0,1.46H3.8a.73.73,0,0,0,.238,0H20.709a.73.73,0,1,0,0-1.46H4.65V3.285a1.82,1.82,0,0,1,3.627-.224A2.184,2.184,0,0,0,6.84,5.11h4.38A2.182,2.182,0,0,0,9.737,3.047a3.268,3.268,0,0,0-3-2.994A.73.73,0,0,0,6.475,0ZM1.73,10.949l.958,4.423a3.651,3.651,0,0,0,3.567,2.877h9.929a3.65,3.65,0,0,0,3.567-2.877l.958-4.423Z"
                    transform="translate(-1)"
                    fill="#CDAC62"
                  />
                </svg>
                <span className="ltr:ml-2 rtl:mr-2">{props.bathrooms}</span>
              </div>
            </Link>
          </div>
          <div className="flex flex-wrap-reverse md:flex-wrap text-right justify-between md:justify-end item-center w-full md:w-1/5">
            <div className="companyLogo w-full">
              {/* <div className="flex items-center justify-center w-full  md:h-20 my-4 md:my-0 relative overflow-hidden">
                <img src={props.developer} alt="" className="rtl:ml-0 ltr:mr-0 rtl:mr-auto ltr:ml-auto block w-auto max-w-[6rem] h-auto max-h-full" />
              </div> */}
              <div className="flex flex-col justify-end w-full my-2">
                <div className={`relative transition-all duration-1000 ${!SocialMedia ? "opacity-0 w-0 h-0 overflow-hidden" : "opacity-100 w-auto h-auto flex"}`}>
                  <FacebookShareButton url={`https://keymaxrealestate.com/PropertyDetails/${props.id}`} quote={"keymax realestate - World is yours to explore"} hashtag="#Keymax">
                    {/* <FacebookIcon size={34} /> */}
                    <span className="text-center flex-wrap text-black  flex items-center justify-center w-8 h-8 hover:bg-transparent  transition-all duration-1000 shadow-2xl hover:shadow:none mr-2 rounded-full bg-[#CDAC62]">
                      <FaFacebookF />{" "}
                    </span>
                  </FacebookShareButton>
                  <WhatsappShareButton url={`https://keymaxrealestate.com/PropertyDetails/${props.id}`} quote={"keymax realestate - World is yours to explore"} hashtag="#Keymax">
                    {/* <WhatsappIcon size={34} /> */}
                    <span className="text-center flex-wrap text-black  flex items-center justify-center w-8 h-8 hover:bg-transparent  transition-all duration-1000 shadow-2xl hover:shadow:none mr-2 rounded-full bg-[#CDAC62]">
                      <BsWhatsapp />{" "}
                    </span>
                  </WhatsappShareButton>
                  <TwitterShareButton url={`https://keymaxrealestate.com/PropertyDetails/${props.id}`} quote={"keymax realestate - World is yours to explore"} hashtag="#Keymax">
                    {/* <TwitterIcon size={34} /> */}
                    <span className="text-center flex-wrap text-black  flex items-center justify-center w-8 h-8 hover:bg-transparent  transition-all duration-1000 shadow-2xl hover:shadow:none mr-2 rounded-full bg-[#CDAC62]">
                      <BsTwitter />{" "}
                    </span>
                  </TwitterShareButton>
                  <LinkedinShareButton url={`https://keymaxrealestate.com/PropertyDetails/${props.id}`} quote={"keymax realestate - World is yours to explore"} hashtag="#Keymax">
                    {/* <LinkedinIcon size={34} /> */}
                    <span className="text-center flex-wrap text-black  flex items-center justify-center w-8 h-8 hover:bg-transparent  transition-all duration-1000 shadow-2xl hover:shadow:none mr-2 rounded-full bg-[#CDAC62]">
                      <GrLinkedinOption />{" "}
                    </span>
                  </LinkedinShareButton>
                  <button
                    onClick={handleShare}
                    className="text-center flex-wrap text-black  flex items-center justify-center w-8 h-8 hover:bg-transparent  transition-all duration-1000 shadow-2xl hover:shadow:none mr-2 rounded-full bg-[#CDAC62]"
                  >
                    {" "}
                    <AiOutlineCopy className="" />{" "}
                  </button>
                </div>
                <button
                  onClick={toggleFavoriteStatusHandler}
                  className={`mx-1 rounded-lg px-2 py-2 text-lg transition-all duration-1000 cursor-pointer  ${
                    itemIsFavorite && localStorage.KeyMaxToken ? "text-red hover:text-white hover:bg-red border-red border" : "text-light bg-mainColor"
                  }`}
                >
                  {" "}
                  <BsFillSuitHeartFill className="" />{" "}
                </button>
                <button onClick={() => setSocialMedia(!SocialMedia)} className={`mx-1 rounded-lg px-2  py-2 text-lg bg-light cursor-pointer  text-mainColor `}>
                  {" "}
                  <BsFillShareFill className="" />{" "}
                </button>
              </div>
              {message && <p className="font-bold text-green mt-3"> {t("Link copied")} </p>}
            </div>
            {/* <div className="details flex flex-wrap items-end  justify-between md:justify-end w-full pb-2 mt-2 md:mt-0">
              {props.whatsApp && (
                <a
                  href={"https://api.whatsapp.com/send?phone=" + props.whatsApp}
                  className="p-2 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-10 flex items-center mx-1"
                >
                  {" "}
                  <BsWhatsapp className="" />{" "}
                </a>
              )}
              {props.phone && (
                <a href={"tel:" + props.phone} className="p-2 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-10 flex items-center mx-1">
                  {" "}
                  <FiPhoneCall className="" />{" "}
                </a>
              )}
              {props.email && (
                <a href={"mailto:" + props.email} className="p-2 text-lg hover:bg-mainColor hover:text-light bg-light border cursor-pointer border-mainColor text-mainColor rounded-lg h-10 flex items-center mx-1">
                  {" "}
                  <AiOutlineMail className="" />{" "}
                </a>
              )}
            </div> */}
          </div>
        </div>
      </div>
      {(props.price || props.price_year) && (
        <div className="absolute -bottom-5 right-0">
          <h3 className="p-2 mt-2 text-md lg:text-lg bg-mainColor text-white rounded-lg h-10 flex items-center mx-1">
            {" "}
            {props.buy_or_sell !== "rent" ? props.price : props.price_year} {t("AED")}{" "}
          </h3>
        </div>
      )}
    </div>
  );
};

export default WidthCard;
