import React from "react";
import { Link } from "react-router-dom";

const Title = (props) => {
  return (
    <div className="flex  justify-between items-center overflow-hidden w-full border-b-2 border-[#D4B071] pb-2 mb-5">
      <h6 className="bg-white rtl:pl-4 ltr:pr-4 inline-block font-bold text-xl lg:text-2xl"> {props.title} </h6>
      <Link to={`${props.url}`} className="text-md md:text-lg font-bold">
        {props.link}{" "}
      </Link>
    </div>
  );
};

export default Title;
