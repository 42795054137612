import ScreenNav from "./ScreenNav";
import Footer from "./Footer";
import MobileNav from "./MobileNav";
function index(props) {
  return (
    <>
      {window.innerWidth < 1025 ? <MobileNav Blog={props.Blog} HomePage={props.HomePage} /> : <ScreenNav Blog={props.Blog} HomePage={props.HomePage} />}

      {props.children}
      <Footer />
    </>
  );
}

export default index;
