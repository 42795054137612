import { useState, useEffect } from "react";
import DateForm from "../Layout/DateForm";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "sweetalert2/src/sweetalert2.scss";
import { t } from "i18next";
import SuccessAlert from "../UI/SuccessAlert";
import { url, recaptcha_key, recaptcha__secret_key, chechPhone } from "../../Common/Common";
import "./proDetails.scss";

import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ReactRecaptcha3 from "react-google-recaptcha3";

const ProjectDateForm = ({ pojectName }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState();
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  // const [messageError, setMessageError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [bookLoading, setBookLoading] = useState(false);

  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key);
  }, []);


  const contactHandler = (e) => {
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    e.preventDefault();

    if (name.trim() == "") {
      setNameError(true);
      return;
    }
    if (email.trim() == "" || !email.includes("@")) {
      setEmailError(true);
      return;
    }

    if(!chechPhone(phone)){
      setPhoneError(true);
        return;
    }

    setBookLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + "verifyRecaptcha",
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            axios
              .post(url + "contact-us", {
                name: name,
                email: email,
                phone: phone,
                message: message,
                source: `Project Name: ${pojectName}`,
              })
              .then((response) => {
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                setBookLoading(false);
                Swal.fire({
                  timer: 3000,
                  title: `${t("Success")}`,
                  icon: "success",
                  html: `${t("Your message has been sent")}`,
                  confirmButtonText: `${t("ok")}`,
                  showCloseButton: true,
                  timerProgressBar: true,
                });
              })
              .catch((error) => {
                setBookLoading(false);
                setErrToken(true);
              });
          })
          .catch(() => {
            setBookLoading(false);
            setErrToken(true);
          });
      },
      (error) => {
        setBookLoading(false);
        setErrToken(true);
      }
    );
  };

  return (
    <section className="px-3 pt-24 lg:pt-0 lg:mt-[-4rem]">
      {success && (
        <div className="fixed bottom-4 left-4 z-50">
          <SuccessAlert success={success} />
        </div>
      )}
      <div className="relative p-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl h-full border-4 border-[#D4B071] rounded-3xl  bg-white">
        <div className="flex justify-center items-center m-10 md:mt-[-2.5rem] mt-[-2.2rem]">
          <p className="text-white bg-black rounded-full inline-block text-center text-xl md:text-3xl px-5 py-2 font-bold font-Butler border-4 border-[#D4B071]">
            Book your Consultation
          </p>
        </div>
        <form className="relative">
          <div className="w-5/6 mx-auto flex flex-col justify-center items-center">
            <div className="w-full md:max-w-md">
              <DateForm />
            </div>
            <div
              className={`flex flex-col md:flex-row gap-x-0 md:gap-x-2 w-full relative pt-5`}
            >
             <div className="block-feild"> 
              <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  placeholder="Name"
                  required
                  type="text"
                  className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border-b-2 border-black appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                />
                {nameError && (
                  <p className=" text-red text-sm">Please enter your name</p>
                )}
              </div>
              <div className="block-feild"> 
                <input
                  onCut={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Email"
                  required
                  type="text"
                  className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                />
                {emailError && (
                  <p className=" text-red text-sm">Please enter your email</p>
                )}
              </div>
              <div className="block-feild"> 
                <PhoneInput
                  placeholder="Enter phone number"
                  country={"ae"}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-b-black focus:outline-none focus:shadow-outline"
                />
                {phoneError && (<p className=" text-red text-sm"> Please enter valid phone number </p> )}
                </div>
            </div>
            <div className="block-feild end"> 
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                name=""
                value={message}
                id=""
                rows="3"
                placeholder="Message"
                className="flex-grow w-full px-4 transition duration-200 bg-white border-b-2 border-black appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline mb-10"
              ></textarea>
            </div>
            <button
              disabled={bookLoading}
              onClick={contactHandler}
              type="button"
              className="absolute bottom-[-2.1rem] placeholder-center inline-flex items-center justify-center h-12 px-6 text-lg md:text-2xl mr-0 md:mr-6 font-medium tracking-wide text-black font-bold transition duration-200 rounded-full shadow-md bg-[#D4B071] hover:bg-blue-700 focus:shadow-outline focus:outline-none"
            >
              {bookLoading ? (
                <>
                  <span className="inline-flex items-center justify-center h-8 px-6">
                    <span className="lds-dual-ring"></span>
                  </span>
                </>
              ) : (
                t("submit")
              )}
            </button>
            {errToken && (
              <p className="text-red text-sm error-token">
                please verify you are a human
              </p>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default ProjectDateForm;
