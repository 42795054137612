import React from "react";
import { Link } from "react-router-dom";

const List = ({ link, name, total, id, onClicked }) => {
  const clicked = (e) => {
    onClicked(e.target.parentNode.getAttribute("id"))
  }; 
  return (
    <Link id={id} to={`${link}`} className="flex items-center justify-between text-left leading-6 lg:leading-9 hover:text-mainColor" onClick={clicked}>
      <span className="text-md lg:text-lg">{name}</span>
      <span className="font-bold text-mainColor">({total})</span>
    </Link>
  );
};

export default List;
