import {
  Home,
  Blog,
  BlogDetails,
  Login,
  Sign,
  Reset,
  Buy,
  BuyType,
  BuyTypeArea,
  RentType,
  Area,
  Rent,
  Recomend,
  Hot,
  Developers,
  SubProperties,
  PrivacyPolicy,
  Developer,
  MapView,
  Projects,
  Project,
  WishList,
  Properties,
  Services,
  AreaProperties,
  PropertyDetails,
  Contact,
  About,
  Notfound,
  Faqs,
  Careers,
  LongestPayment,
  SingleService,
  OffPlan,
  Terms,
  SiteMap,
  FilteredProjects,
  Test,
} from "./Pages";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import MeetTeam from "./Pages/MeetTeam";
import BuyingOffPlanGuide from "./Pages/BuyingOffPlanGuide";
import SellingGuide from "./Pages/SellingGuide";
import BuyingGuide from "./Pages/BuyingGuide";
import RentingGuide from "./Pages/RentingGuide";
import ListYourPropertyInDubai from "./Pages/ListYourPropertyInDubai";

function App() {
  const buyApartmentMetaData = {
    title: "Apartments for sale in dubai|buy apartment uae|for sale aparments",
    description: `Invest wisely in Dubai's real estate.  exceptional apartments for sale to  enjoy world-class amenities and breathtaking views`,
    link: `apartments-for-sale-dubai-uae`,
  };

  const buyVillaMetaData = {
    title: "Villa for Sale in Dubai | Buy Villa UAE | For Sale Villa",
    description: `Live in grandeur: villas for sale in Dubai , Your dream home is just a click away!`,
    link: `villa-for-sale-in-dubai`,
  };

  const rentApartmentMetaData = {
    title:
      "Apartments for Rent in Dubai | Rent Apartment UAE | For Rent Aparments",
    description: `Top a wide range of apartments for rent in Dubai. Find your ideal home in this vibrant city today!`,
    link: `apartments-for-rent-in-dubai`,
  };

  const rentHotelApartmentMetaData = {
    title:
      "Hotel Apartments for Rent in Dubai | Rent Hotel Apartments UAE | For Rent Hotel Apartments",
    description: `Discover your comfort and stylish hotel apartments for rent in Dubai's superior locations. Book your perfect stay today!`,
    link: `hotel-apartments-for-rent-in-dubai`,
  };

  const rentTownhouseMetaData = {
    title:
      "Townhouses for rent in dubai | rent townhouses uae | For rent townhouses",
    description: `Enjoy a friendly-townhouse for rent in Dubai's safe and welcoming communities. Discover your perfect place to create lasting memories.`,
    link: `townhouses-for-rent-in-dubai`,
  };

  const rentVillaMetaData = {
    title: "Villa for Rent in Dubai | Rent Villa UAE | For Rent Villa",
    description: `Find and book deals on the best villas in Dubai, UAE! Explore all options and book the perfect villa for your repose.`,
    link: `villa-for-rent-in-dubai`,
  };

  //services meta data
  const sellPropertyService = {
    title:
      "Selling property in Dubai | property sell uae |property selling companies",
    description: `Maximize your property's returns with our expert assistance. Sell your Dubai property with confidence. Contact us today.`,
    link: `/sell-property-in-dubai`,
  };

  const propertyManagementService = {
    title:
      "Property management in Dubai | managing property  |property management companies",
    description: `Relax and Let Us Manage Your Dubai Property, Get in touch today.`,
    link: `/property-management-dubai`,
  };

  const rentPropertyService = {
    title: "Renting property in Dubai | property renting uae |dubai rentals ",
    description: `Get the best return on profits from your property  in Dubai with our expert property rental services. Trust us to help you rent your property hassle-free. Contact us today.`,
    link: `/renting-property-in-dubai`,
  };

  const buyPropertyService = {
    title: "Buying property in Dubai | property buying uae |dubai Properties",
    description: `Find your dream property in Dubai with ease. Our expert team is here to guide you through the buying process. Start your journey today.`,
    link: `/buying-property-dubai`,
  };

  //services

  const propertyManagement = {
    title: `Property Management`,
    titleDescription: `If you're aiming for successful investment, property preservation, and growth, excellent property management is essential. Effective management ensures property preservation and sustainable returns. We provide comprehensive property management services to help you achieve your goals in the best possible ways`,
    subHeading: `Our Approach to Property Management`,
    subHeadingDescription: [
      "Develop a marketing strategy to attract suitable tenants and manage leasing operations",
      "Oversee maintenance and necessary repairs for properties, including routine maintenance and emergency fixes",
      "Formulate strategies to maximize property benefits by enhancing returns",
      "Prioritize tenant satisfaction through prompt communication, addressing concerns, and fostering positive renting experiences for better retention",
    ],
    about: `We are committed to achieving successful investment, property preservation, and growth to meet our clients' needs and ensure their complete satisfaction`,
    formHeading: `Interested in this service?`,
    formdescription: `All Our Services are Just One Click Away!<br />Just Fill In Your Details.`,
  };

  const sellWithUs = {
    title: `Sell with Us`,
    titleDescription: `Selling properties in Dubai is a significant and complex process. The importance of property selling lies in its ability to generate financial returns for the owner. It requires time, effort, and expertise to be executed correctly. Our company offers exceptional services to clients throughout this process
    `,
    subHeading: `Our Approach to Assisting You in Property Selling`,
    subHeadingDescription: [
      "We can help you sell your property quickly and efficiently",
      "With our extensive real estate market experience, we ensure exceptional selling opportunities",
      "We promote your properties through the most appropriate channels, guaranteeing you the best deals",
      "Launching effective advertising campaigns for your property can attract more buyers using our expertise in targeting the right audience, professional photography, and social media utilization",
      "We schedule viewings with potential buyers and negotiate to secure the highest returns for you",
    ],
    about: `We are committed to achieving successful investment, property preservation, and growth to meet our clients' needs and ensure their complete satisfaction`,
    formHeading: `Interested in this service?`,
    formdescription: `All Our Services are Just One Click Away!<br />Just Fill In Your Details.`,
  };

  const buyWithUs = {
    title: `Buy with Us`,
    titleDescription: `Purchasing real estate ranks among the most significant decisions an individual makes in their life, whether it's for investment purposes or achieving the dream of living in a distinctive home. It's a strategic step towards stability and personal goals. That's why we're here – to assist you, offering comprehensive services that span from finding the right property to successfully completing the purchasing process`,
    subHeading: `Our Approach to Assisting You in Property Selling`,
    subHeadingDescription: [
      "We help you determine an appropriate budget and define a suitable price range",
      "We negotiate professionally with the seller or real estate agent to reach an agreement on price and terms. Our expertise in negotiation ensures the best possible way",
      "Upon finalizing the deal, we assist you with the legal procedures of property registration and transfer, ensuring proper ownership transition",
      "Launching effective advertising campaigns for your property can attract more buyers using our expertise in targeting the right audience, professional photography, and social media utilization",
    ],
    about: `In the end, we ensure a smooth and successful property buying experience for you`,
    formHeading: `Interested in this service?`,
    formdescription: `All Our Services are Just One Click Away!<br />Just Fill In Your Details.`,
  };

  const RentWithUs = {
    title: `Rent with Us`,
    titleDescription: `People search for rental properties either to secure a comfortable living space or to find a suitable location for their business endeavors. Securing the right property is among the most important services sought by individuals and companies due to the benefits it offers. We offer outstanding rental property services characterized by transparency, honesty in dealings, and a constant effort to provide the best deals`,
    subHeading: `Our Approach to Renting Properties`,
    subHeadingDescription: [
      "Before initiating the search for a rental home, we meticulously identify your needs and requirements for the property, such as the number of rooms, space, and desired amenities",
      "We maintain an extensive database of available rental properties",
      "Our experts negotiate prices, payments, collections, and discounts on your behalf",
      "We diligently oversee every stage to ensure a seamless process, from application to key delivery",
    ],
    about: `We prioritize delivering top-notch services at competitive and suitable prices for all our clients`,
    formHeading: `Interested in this service?`,
    formdescription: `All Our Services are Just One Click Away!<br />Just Fill In Your Details.`,
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/Area" element={<Area />} />
        <Route path="/services" element={<Services />} />
        <Route
          path="/sell-property-in-dubai/"
          element={
            <SingleService
              id={3}
              meta={sellPropertyService}
              details={sellWithUs}
              property_type={"buy"}
            />
          }
        />
        <Route
          path="/renting-property-in-dubai/"
          element={
            <SingleService
              id={4}
              meta={rentPropertyService}
              details={RentWithUs}
              property_type={"rent"}
            />
          }
        />
        <Route
          path="/buying-property-dubai/"
          element={
            <SingleService
              buy={true}
              id={2}
              meta={buyPropertyService}
              details={buyWithUs}
              property_type={"purchase"}
            />
          }
        />
        <Route
          path="/property-management-dubai/"
          element={
            <SingleService
              id={5}
              meta={propertyManagementService}
              details={propertyManagement}
              property_type={"own"}
            />
          }
        />
        <Route
          path="/property-for-sale-in-dubai"
          element={<Buy title={`Exclusive Hot Properties for Sale in Dubai`} />}
        />
        <Route
          path="/apartments-for-sale-dubai-uae"
          element={
            <BuyType
              apartments={1}
              meta={buyApartmentMetaData}
              title={`Luxurious Apartments for Sale in Dubai`}
            />
          }
        />
        {/* <Route path='/apartments-for-sale/:area'>
            <BuyTypeArea />
          </Route> */}
        {/* <Route path="/apartments-for-sale/:area" element={<BuyTypeArea />} /> */}
        <Route
          path="/villa-for-sale-in-dubai"
          element={
            <BuyType
              villa={5}
              meta={buyVillaMetaData}
              title={`Villa for Sale in Dubai's Most Desirable Areas`}
            />
          }
        />
        <Route path="/property-for-rent-in-dubai" element={<Rent />} />
        <Route
          path="/apartments-for-rent-in-dubai"
          element={
            <RentType
              apartments={1}
              meta={rentApartmentMetaData}
              title={`Exclusive Apartments for Rent in Dubai`}
            />
          }
        />
        <Route
          path="/villa-for-rent-in-dubai"
          element={
            <RentType
              villa={5}
              meta={rentVillaMetaData}
              title={`Villa for Rent in Dubai`}
            />
          }
        />
        <Route
          path="/hotel-apartments-for-rent-in-dubai"
          element={
            <RentType
              hotelApartment={4}
              meta={rentHotelApartmentMetaData}
              title={`Stylish Hotel Apartments for Rent in Dubai`}
            />
          }
        />
        <Route
          path="/townhouses-for-rent-in-dubai"
          element={
            <RentType
              townhouse={3}
              meta={rentTownhouseMetaData}
              title={`Dubai's Most Conveniently Townhouses for Rent`}
            />
          }
        />
        <Route path="/off-plan-properties-dubai" element={<OffPlan />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/subProperties/:id" element={<SubProperties />} />
        <Route
          path="/sign-up"
          element={!localStorage.KeyMaxToken ? <Sign /> : <Notfound />}
        />
        <Route
          path="/login"
          element={!localStorage.KeyMaxToken ? <Login /> : <Notfound />}
        />
        <Route path="/reset" element={<Reset />} />
        <Route path="/recomend" element={<Recomend />} />
        <Route path="/hot" element={<Hot />} />
        <Route path="/developers" element={<Developers />} />
        <Route path="/mapView" element={<MapView />} />
        <Route path="/developer/:name" element={<Developer />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:slug" element={<Project />} />
        <Route path="/off-plan-projects/:slug" element={<FilteredProjects />} />
        <Route
          path="/wishList"
          element={localStorage.KeyMaxToken ? <WishList /> : <Login />}
        />
        <Route path="/propertyDetails/:id" element={<PropertyDetails />} />
        <Route path="/areas/:name" element={<AreaProperties />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/buying-off-plan-guide" element={<BuyingOffPlanGuide />} />
        <Route path="/selling-guide" element={<SellingGuide />} />
        <Route path="/buying-guide" element={<BuyingGuide />} />
        <Route path="/renting-guide" element={<RentingGuide />} />
        <Route path="/longest-payment" element={<LongestPayment />} />
        <Route
          path="/list-your-property-in-dubai"
          element={<ListYourPropertyInDubai />}
        />
        <Route path="/singleService/:id" element={<SingleService />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/meet-the-team" element={<MeetTeam />} />
        <Route path="/sitemap" element={<SiteMap />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
