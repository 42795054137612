import React, { useState, useEffect } from "react";
import axios from "axios";
import { t } from "i18next";

import {
  recaptcha__secret_key,
  recaptcha_key,
  url,
  chechPhone,
} from "../../Common/Common";
import { HiCheck } from "react-icons/hi";
import "./FormOptions.scss";
import PhoneInput from "react-phone-input-2";
import { AiOutlineCloudUpload } from "react-icons/ai";

import ReactRecaptcha3 from "react-google-recaptcha3";
import Swal from "sweetalert2/dist/sweetalert2.js";

const FormOptions = (props) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState(Array(7).fill(null));
  const [error, setError] = useState(false);

  const [uploadDeed, setUploadDeed] = useState("");
  const [uploadDeedError, setUploadDeedError] = useState("");

  const [uploadPassport, setUploadPassport] = useState("");
  const [uploadPassportError, setUploadPassportError] = useState("");

  const [mobile, setPhone] = useState();
  const [showResult, setShowResult] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [viewDate, setViewDate] = useState("");
  const [viewTime, setViewTime] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    size: "",
    price: "",
    location: "",
    property_status: "",
    bed_rooms: "",
    payment_type: "",
  });
  const handleSecondValueChange = (newValue) => {
    if (newValue === "Villa" || newValue === "Townhouse") {
      setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[2] = [null, null];
        return updatedAnswers;
      });
    } else {
      setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[2] = userAnswers[2];
        return updatedAnswers;
      });
    }
  };

  const addCommas = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const handleAnswerClick = (index, value) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = value;
    setUserAnswers(updatedAnswers);
  };
  const handleFormDataChange = (number, event) => {
    const { name, value } = event.target;

    // if (number === 6) {
    //   const formattedValue = addCommas(removeNonNumeric(value));
    //   setFormData({
    //     ...formData,
    //     [name]: formattedValue,
    //   });
    // } else {
    // }
    setFormData({
      ...formData,
      [name]: value,
    });
    const newArr = [...userAnswers];
    newArr[number] = event.target.value;
    setUserAnswers(newArr);
  };

  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key);
  }, []);

  const handleSubmitClick = (e) => {
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    e.preventDefault();

    if (formData.name.trim() == "") {
      setNameError(true);
      return;
    }
    if (formData.email.trim() == "" || !formData.email.includes("@")) {
      setEmailError(true);
      return;
    }

    if (!chechPhone(mobile)) {
      setPhoneError(true);
      return;
    }

    setSellLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + "verifyRecaptcha",
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            var submitedData = new FormData();
            Object.keys(formData).forEach((key) => {
              submitedData.append(key, formData[key]);
            });
            submitedData.append("mobile", mobile);
            submitedData.append("property_type", userAnswers[0]);
            submitedData.append(
              "property_for",
              props.property_type.replace("?", "")
            );
            submitedData.append("uploadDeed", uploadDeed);
            submitedData.append("uploadPassport", uploadPassport);

            axios
              .post(url + "list-property", submitedData)
              .then((response) => {
                setSellLoading(false);
              })
              .catch((error) => {
                console.error("API Error:", error);
                setSellLoading(false);
              });
            setShowResult(true);
          })
          .catch(() => {
            setSellLoading(false);
            setErrToken(true);
          });
      },
      () => {
        setSellLoading(false);
        setErrToken(true);
      }
    );
  };

  const handleReplayClick = () => {
    setCurrentQuestion(0);
    setUserAnswers(Array(7).fill(null));
    setPhone("");
    setShowResult(false);
    setFormData({
      name: "",
      email: "",
      size: "",
      price: "",
      location: "",
      property_status: "",
      bed_rooms: "",
    });
    setUploadDeed("");
    setUploadPassport("");
  };
  const handleNextClick = () => {
    if (userAnswers[currentQuestion]) {
      setError(false);
      if (currentQuestion < 6) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setShowResult(true);
      }
    } else {
      setError(true);
    }
  };

  const handlePrevClick = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [error]);

  return (
    <div
      className={`quiz-container flex justify-center items-center text-center w-full max-w-4xl bg-white rounded-3xl p-12 relative`}
    >
      {error && (
        <div
          className={`absolute top-6 left-1/2 transform -translate-x-1/2 p-6 rounded-3xl bg-[#f28f9c] z-10 text-black font-black transition-transform duration-800 ease-in-out ${
            error ? "opacity-1" : "opacity-0"
          }`}
        >
          {currentQuestion < 2 ? "select an option" : "fill the value"}
        </div>
      )}
      {!showResult ? (
        <form className="question-container">
          <div className="mb-10 ">
            {/* Question 1 */}

            {/* <div className={`question  ${currentQuestion === 0 ? "active slide-in-right" : "hidden slide-out-left"}`}>
            <div className="flex flex-col justify-center items-center">

            <p className={`text-lg mb-6 font-bold`}>Do you want to sell or rent?</p>
            <div className="answer-options w-full max-w-sm space-x-0 space-y-3 lg:space-x-3 lg:space-y-0 flex lg:flex-row flex-col  justify-center items-center">
              <label className="flex items-center step1_radio w-full">
                <input type="radio" checked={userAnswers[0] === "Sell"} onChange={() => handleAnswerClick(0, "Sell")} className="mr-2 hidden" />
                <div className={` ${userAnswers[0] === "Sell" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[0] === "Sell" &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  Sell
                </div>
              </label>
              <label className="flex items-center  w-full">
                <input type="radio" checked={userAnswers[0] === "Rent"} onChange={() => handleAnswerClick(0, "Rent")} className="mr-2 hidden" />
                <div className={` ${userAnswers[0] === "Rent" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}>
                  {userAnswers[0] === "Rent" &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  Rent
                </div>
              </label>
            </div>
            </div>
          </div> */}

            {/* Question 2 */}
            <div
              className={`question ${
                currentQuestion === 0
                  ? "active slide-in-right"
                  : "hidden slide-out-left"
              }`}
            >
              <div className="flex flex-col justify-center items-center">
                <p className={`text-lg mb-6 font-black`}>
                  Which type of property do you want to {props.property_type}
                </p>
                <div className="answer-options w-full max-w-xl grid gap-4 grid-cols-1 lg:grid-cols-2">
                  <label className="flex items-center w-full">
                    <input
                      type="radio"
                      checked={userAnswers[0] === "Apartment"}
                      onChange={() => handleAnswerClick(0, "Apartment")}
                      className="mr-2 hidden"
                    />
                    <div
                      className={` ${
                        userAnswers[0] === "Apartment"
                          ? "border-black shadow-lg"
                          : "border-[#b3b3b3]"
                      } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}
                    >
                      {userAnswers[0] === "Apartment" && (
                        <div
                          className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                        >
                          <HiCheck className="text-white" />
                        </div>
                      )}
                      Apartment
                    </div>
                  </label>
                  <label className="flex items-center w-full">
                    <input
                      type="radio"
                      checked={userAnswers[0] === "Villa"}
                      onChange={() => handleAnswerClick(0, "Villa")}
                      className="mr-2 hidden"
                    />
                    <div
                      className={` ${
                        userAnswers[0] === "Villa"
                          ? "border-black shadow-lg"
                          : "border-[#b3b3b3]"
                      } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}
                    >
                      {userAnswers[0] === "Villa" && (
                        <div
                          className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                        >
                          <HiCheck className="text-white" />
                        </div>
                      )}
                      Villa
                    </div>
                  </label>
                  <label className="flex items-center w-full">
                    <input
                      type="radio"
                      checked={userAnswers[0] === "Penthouse"}
                      onChange={() => handleAnswerClick(0, "Penthouse")}
                      className="mr-2 hidden"
                    />
                    <div
                      className={` ${
                        userAnswers[0] === "Penthouse"
                          ? "border-black shadow-lg"
                          : "border-[#b3b3b3]"
                      } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}
                    >
                      {userAnswers[0] === "Penthouse" && (
                        <div
                          className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                        >
                          <HiCheck className="text-white" />
                        </div>
                      )}
                      Penthouse
                    </div>
                  </label>
                  <label className="flex items-center w-full">
                    <input
                      type="radio"
                      checked={userAnswers[0] === "Townhouse"}
                      onChange={() => handleAnswerClick(0, "Townhouse")}
                      className="mr-2 hidden"
                    />
                    <div
                      className={` ${
                        userAnswers[0] === "Townhouse"
                          ? "border-black shadow-lg"
                          : "border-[#b3b3b3]"
                      } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}
                    >
                      {userAnswers[0] === "Townhouse" && (
                        <div
                          className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                        >
                          <HiCheck className="text-white" />
                        </div>
                      )}
                      Townhouse
                    </div>
                  </label>
                </div>
              </div>
            </div>

            {/* Question 3 */}
            <div
              className={`question  ${
                currentQuestion === 1
                  ? "active slide-in-right"
                  : "hidden slide-out-left"
              }`}
            >
              <div className="flex flex-col justify-center items-center">
                <p className={`text-lg mb-6 font-bold`}>
                  How many bedrooms does your {userAnswers[0]} have?
                </p>
                {userAnswers[0] === "Apartment" && (
                  <div className="answer-options w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={0}
                        checked={userAnswers[1] == 0}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 0
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 0 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        Studio
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={1}
                        checked={userAnswers[1] == 1}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 1
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 1 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        1BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={2}
                        checked={userAnswers[1] == 2}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 2
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 2 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        2BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={3}
                        checked={userAnswers[1] == 3}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 3
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 3 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        3BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={4}
                        checked={userAnswers[1] == 4}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 4
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 4 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        4BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={5}
                        checked={userAnswers[1] == 5}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 5
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 5 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        5BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={6}
                        checked={userAnswers[1] == 6}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 6
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 6 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        6BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={7}
                        checked={userAnswers[1] == 7}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 7
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 7 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        7BR
                      </div>
                    </label>
                  </div>
                )}
                {(userAnswers[0] === "Villa" ||
                  userAnswers[0] === "Townhouse") && (
                  <div className="answer-options w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={2}
                        checked={userAnswers[1] == 2}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 2
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 2 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        2BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={3}
                        checked={userAnswers[1] == 3}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 3
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 3 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        3BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={4}
                        checked={userAnswers[1] == 4}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 4
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 4 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        4BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={"4+"}
                        checked={userAnswers[1] == "4+"}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == "4+"
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == "4+" && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        4BR+
                      </div>
                    </label>
                  </div>
                )}
                {userAnswers[0] === "Penthouse" && (
                  <div className="answer-options w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={2}
                        checked={userAnswers[1] == 2}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 2
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 2 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        2BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={3}
                        checked={userAnswers[1] == 3}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 3
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 3 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        3BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={4}
                        checked={userAnswers[1] == 4}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 4
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 4 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        4BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={5}
                        checked={userAnswers[1] == 5}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 5
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 5 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        5BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={6}
                        checked={userAnswers[1] == 6}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 6
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 6 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        6BR
                      </div>
                    </label>
                    <label className="flex items-center step1_radio w-full">
                      <input
                        type="radio"
                        name="bed_rooms"
                        value={7}
                        checked={userAnswers[1] == 7}
                        onChange={(event) => handleFormDataChange(1, event)}
                        className="mr-2 hidden"
                      />
                      <div
                        className={` ${
                          userAnswers[1] == 7
                            ? "border-black shadow-lg"
                            : "border-[#b3b3b3]"
                        } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                      >
                        {userAnswers[1] == 7 && (
                          <div
                            className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                          >
                            <HiCheck className="text-white" />
                          </div>
                        )}
                        7BR
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>

            {/* Question 5 */}
            <div
              className={`question  ${
                currentQuestion === 2
                  ? "active slide-in-right"
                  : "hidden slide-out-left"
              }`}
            >
              <div className="flex flex-col justify-center items-center">
                <p className={`text-lg mb-6 font-bold`}>
                  Is your property vacant or tenanted?
                </p>
                <div className="answer-options w-full max-w-sm space-x-0 space-y-3 lg:space-x-3 lg:space-y-0 flex lg:flex-row flex-col  justify-center items-center">
                  <label className="flex items-center step1_radio w-full">
                    <input
                      type="radio"
                      name="property_status"
                      value={"Vacant"}
                      checked={userAnswers[2] === "Vacant"}
                      onChange={(event) => handleFormDataChange(2, event)}
                      className="mr-2 hidden"
                    />
                    <div
                      className={` ${
                        userAnswers[2] === "Vacant"
                          ? "border-black shadow-lg"
                          : "border-[#b3b3b3]"
                      } border-2 px-10 py-2 rounded-full bg-white relative w-full`}
                    >
                      {userAnswers[2] === "Vacant" && (
                        <div
                          className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                        >
                          <HiCheck className="text-white" />
                        </div>
                      )}
                      Vacant
                    </div>
                  </label>
                  <label className="flex items-center  w-full">
                    <input
                      type="radio"
                      name="property_status"
                      value={"Tenanted"}
                      checked={userAnswers[2] === "Tenanted"}
                      onChange={(event) => handleFormDataChange(2, event)}
                      className="mr-2 hidden"
                    />
                    <div
                      className={` ${
                        userAnswers[2] === "Tenanted"
                          ? "border-black shadow-lg"
                          : "border-[#b3b3b3]"
                      } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}
                    >
                      {userAnswers[2] === "Tenanted" && (
                        <div
                          className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}
                        >
                          <HiCheck className="text-white" />
                        </div>
                      )}
                      Tenanted
                    </div>
                  </label>
                </div>
              </div>
            </div>

            {/* Question 6 */}
            <div
              className={`question ${
                currentQuestion === 3
                  ? "active slide-in-right"
                  : "hidden slide-out-left"
              }`}
            >
              <div className="flex flex-col justify-center items-center">
                <p className={`text-lg mb-6 font-black`}>
                  Where is the property located?
                </p>
                <div className="answer-options w-full max-w-xl flex items-center">
                  <input
                    type="text"
                    placeholder="Enter Address"
                    name="location"
                    value={formData.location}
                    onChange={(event) => handleFormDataChange(3, event)}
                    className="focus:border-black border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full mb-6"
                  />
                </div>
              </div>
            </div>

            {/* Question 7 */}
            <div
              className={`question ${
                currentQuestion === 4
                  ? "active slide-in-right"
                  : "hidden slide-out-left"
              }`}
            >
              <div className="flex flex-col justify-center items-center">
                <p className={`text-lg mb-6 font-black`}>
                  what is the Asking Price?
                </p>
                <div className="answer-options w-full max-w-xl flex items-center">
                  <input
                    type="number"
                    placeholder="Enter Price"
                    name="price"
                    value={formData.price}
                    onChange={(event) => handleFormDataChange(4, event)}
                    className="focus:border-black border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full mb-6"
                  />
                </div>
              </div>
            </div>

            {/* Question 4 */}
            <div
              className={`question  ${
                currentQuestion === 5
                  ? "active slide-in-right"
                  : "hidden slide-out-left"
              }`}
            >
              <div className="flex flex-col md:flex-row md:space-x-12 justify-center items-center">
                <div>
                  <p className={`text-lg mb-6 font-black`}>
                    Upload the Title Deed
                  </p>

                  <div className="w-full my-6  px-6 py-3 text-mainColor bg-white shadow-lg rounded-full flex flex-wrap items-center file-div">
                    <input
                      type="file"
                      id="uploadDeed"
                      hidden
                      onChange={(e) => {
                        setUploadDeedError(false);
                        setUploadDeed(e.target.files[0]);
                        handleFormDataChange(5, e);
                      }}
                      accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
                    />
                    <label
                      htmlFor="uploadDeed"
                      className={` w-full cursor-pointer font-bold flex items-center justify-between gap-2 ${
                        uploadDeedError ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      {" "}
                      {"Upload Title Deed"}{" "}
                      <AiOutlineCloudUpload className="text-2xl" />{" "}
                    </label>
                  </div>
                  {uploadDeed && (
                    <p
                      id="upload_deed_p"
                      className="file-name text-sm text-right flex items-center justify-center w-full text-[#555]"
                    >
                      {"Uploaded file: " + uploadDeed.name}
                    </p>
                  )}
                </div>

                <div>
                  <p className={`text-lg mb-6 font-black`}>
                    Upload the Passport Copy
                  </p>

                  <div className="w-full my-6  px-6 py-3 text-mainColor bg-white shadow-lg rounded-full flex flex-wrap items-center file-div">
                    <input
                      type="file"
                      id="uploadPassport"
                      hidden
                      onChange={(e) => {
                        setUploadPassportError(false);
                        setUploadPassport(e.target.files[0]);
                        handleFormDataChange(5, e);
                      }}
                      accept="image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
                    />
                    <label
                      htmlFor="uploadPassport"
                      className={` w-full cursor-pointer font-bold flex items-center justify-between gap-2 ${
                        uploadPassportError ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      {" "}
                      {"Upload Passport"}{" "}
                      <AiOutlineCloudUpload className="text-2xl" />{" "}
                    </label>
                  </div>
                  {uploadPassport && (
                    <p
                      id="upload_passport_p"
                      className="file-name text-sm text-right flex items-center justify-center w-full text-[#555]"
                    >
                      {"Uploaded file: " + uploadPassport.name}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Question 8 */}
            <div
              className={`question ${
                currentQuestion === 6
                  ? "active slide-in-right"
                  : "hidden slide-out-left"
              }`}
            >
              <div className="flex flex-col justify-center items-center">
                <h2 className="text-xl font-medium mb-4 font-black">
                  One last step
                </h2>
                <div className="answer-options grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
                  <div className="block-div">
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={formData.name}
                      onChange={(event) => handleFormDataChange(6, event)}
                      className="border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full focus:border-black"
                    />
                    {nameError && (
                      <p className="text-sm text-red">
                        {" "}
                        Please enter your name
                      </p>
                    )}
                  </div>

                  <div className="block-div">
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={(event) => handleFormDataChange(6, event)}
                      className="border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full focus:border-black"
                    />
                    {emailError && (
                      <p className="text-sm text-red"> Enter a valid Email </p>
                    )}
                  </div>

                  <div className="block-div end">
                    <PhoneInput
                      placeholder="Enter phone number"
                      country={"ae"}
                      value={mobile}
                      onChange={(mobile) => setPhone(mobile)}
                      className="border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full focus:border-black col-span-2"
                    />
                    {phoneError && (
                      <p className=" text-red text-sm">
                        {" "}
                        Please enter valid phone number{" "}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="flex flex-col lg:flex-row justify-center items-center flex-col-reverse space-y-reverse space-y-4 space-x-0 lg:space-y-0 lg:space-x-3 w-full max-w-lg">
              {currentQuestion > 0 && (
                <button
                  type="button"
                  onClick={handlePrevClick}
                  className="next-prev bg-black text-white"
                >
                  Previous
                </button>
              )}
              {currentQuestion < 6 && (
                <button
                  type="button"
                  onClick={handleNextClick}
                  className={`next-prev bg-[#D4B071] px-4 py-2 mr-2`}
                >
                  Next
                </button>
              )}
              {currentQuestion === 6 && (
                <button
                  disabled={sellLoading}
                  type="button"
                  onClick={handleSubmitClick}
                  className={`${
                    userAnswers.includes(null) ||
                    !formData.location ||
                    !formData.name ||
                    !formData.email ||
                    !formData.mobile
                      ? "cursor-not-allowed"
                      : ""
                  } next-prev bg-[#d4b071]`}
                >
                  {sellLoading ? (
                    <>
                      <span className="inline-flex items-center justify-center h-12 px-6">
                        <span className="lds-dual-ring"></span>
                      </span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              )}
            </div>
          </div>
          {errToken && (
            <p className="text-red text-sm error-token-center">
              please verify you are a human
            </p>
          )}
        </form>
      ) : (
        <div className="result-container">
          <h2 className="text-xl font-medium mb-4">
            Thank you for submitting your request!
          </h2>
          <button
            onClick={handleReplayClick}
            className="bg-blue-500 text-black px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Submit again
          </button>
        </div>
      )}
    </div>
  );
};

export default FormOptions;
