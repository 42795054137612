import React from "react";

const Airport = ({ width }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height="458" viewBox="0 0 704 458" fill="none">
      <path d="M308.618 288.288L269.864 432.429C265.841 447.39 276.549 461.93 287.953 457.032L324.363 441.421C327.257 440.158 329.804 437.747 331.57 434.495L501 128" fill="black" />
      <path
        d="M580.925 97.85L443.275 142.575L243.925 2.32505C240.775 -0.0749533 236.675 -0.574953 232.875 0.675047L194.6 13.1C187 15.6 183.675 24.8 187.975 31.55L288.725 192.8L135.85 242.45C119.925 247.65 102.625 244.6 89.5003 234.125L44.9253 198.925C41.7003 196.325 37.2753 195.625 33.2503 196.95L9.00032 204.8C0.675323 207.525 -2.39968 217.45 3.00032 224.375L51.7003 287.675C84.2003 329.425 139.175 346.525 189.325 330.225L681.025 170.45C698.15 164.875 707.45 146.6 701.875 129.5C698.5 119.075 690.175 111.25 679.675 108.075L638.875 96.6251C619.8 91.2751 599.725 91.75 580.925 97.85Z"
        fill="black"
      />
    </svg>
  );
};

export default Airport;
