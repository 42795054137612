// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./ImgSlider.css";

// import required modules
import { Navigation, Pagination } from "swiper";

const ImgSlider = (props) => {
  const { imgs, title, heightValue, widthValue, slice } = props;

  return (
    <>
      <Swiper
        navigation={true}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper h-full rounded-2xl overflow-hidden scale-[1.005]"
      >
        {slice && imgs
          ? imgs.slice(slice).map((img, index) => (
              <SwiperSlide key={index}>
                <img loading="lazy" src={img.medium} alt={title} height={heightValue} width={widthValue} className="object-cover aspect-[13/9] object-top w-full h-full rounded-2xl overflow-hidden" />
              </SwiperSlide>
            ))
          : imgs &&
            imgs.map((img, index) => (
              <SwiperSlide key={index}>
                <img loading="lazy" src={img.medium} alt={title} height={heightValue} width={widthValue} className="object-cover aspect-[13/9] object-top w-full h-full rounded-2xl  overflow-hidden" />
              </SwiperSlide>
            ))}
      </Swiper>
    </>
  );
};

export default ImgSlider;
