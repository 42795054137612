// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "../UI/Card";
import Slider from "react-slick";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { config, url } from "../../Common/Common";
import Loading from "../UI/Loading";
import Nothing from "../UI/Nothing";
import { t } from "i18next";
import Title from "../UI/Title";

var settings = {
  ltr: true,
  centerPadding: "20px",
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: false,
  autoplay: true,
  speed: 10000,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: false,
  rows: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 1,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: false,
        // rows: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
const Buy = () => {
  const [buy, setBuy] = useState([]);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(url + "properties?target=buy&limit=4", config)
      .then(function (response) {
        // handle success
        setBuy(response.data.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  return (
    <div className="BuyProperties mt-10">
      {isLoading ? (
        <Loading />
      ) : (
        buy.length > 0 && (
          <>
            <Title title={t("Ready Properties for Sale")} link={t("view all")} url="/property-for-sale-in-dubai" />
            <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-cols-1">
              {buy.slice(0, 4).map((item, index) => (
                <Card
                  key={index}
                  id={item.id}
                  slug={item.slug}
                  name={item.title}
                  location={item.address}
                  area={item.size}
                  bedroom={item.bedrooms_number}
                  bathroom={item.bathrooms_number}
                  property_type={item.property_type}
                  price={item.price}
                  img={item.image}
                  imgs={item.images}
                  status={item.launch}
                  buy_or_sell={item.buy_or_sell}
                  price_year={item.price_year}
                  price_hidden={item.price_hidden}
                  description={item.description}
                  height={256}
                  width={380}
                />
              ))}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default Buy;
