// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./ImgSliderAmenities.css";

// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";

const ImgSliderAmenities = (props) => {
  const { imgs, title, heightValue, widthValue, slice, developer } = props;

  return (
    <>
      <Swiper
        navigation={developer ? false : true}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        modules={[Pagination, Navigation, Autoplay]}
        autoplay={{ delay: 4000 }}
        className="mySwiperAmenities rounded-lg lg:rounded-[3rem] overflow-hidden drop-shadow-lg"
      >
        {developer
          ? imgs.map((img, index) => (
              <SwiperSlide key={index}>
                <LazyLoadImage src={img} alt={title} height={heightValue} width={widthValue} effect="blur" className={`object-cover bg-center	${developer ? "md:aspect-[20/9] brightness-[.6] " : "aspect-video"}  object-top w-full h-full`} />
              </SwiperSlide>
            ))
          : imgs.slice(slice).map((img, index) => (
              <SwiperSlide key={index}>
                <LazyLoadImage src={img} alt={title} height={heightValue} width={widthValue} effect="blur" className={`object-cover bg-center	${developer ? "md:aspect-[20/9] brightness-[.6]" : "aspect-video"}  object-top w-full h-full`} />
              </SwiperSlide>
            ))}
      </Swiper>
    </>
  );
};

export default ImgSliderAmenities;
