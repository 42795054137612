import { useEffect, useState } from "react";
import Layout from "../Components/Layout";
import Filter from "../Components/Sections/Filter";
import WidthCard from "../Components/Sections/WidthCard";
import axios from "axios";
import { config, url } from "../Common/Common";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { HiOutlineAdjustments, HiOutlineLocationMarker } from "react-icons/hi";
import List from "../Components/UI/List";
import Loading from "../Components/UI/Loading";
import Nothing from "../Components/UI/Nothing";
import { Link, useParams } from "react-router-dom";
import FilterMobile from "../Components/Sections/FilterMobile";
import { t } from "i18next";
import Card from "../Components/UI/Card";

const Properties = () => {
  const { id } = useParams();
  const [buy, setBuy] = useState([]);
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("featured");
  const [urlPage, setUrlPage] = useState(window.location.search);
  const [isActive, setIsActive] = useState(false);

  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(12);

  const [filters, setFilters] = useState();

  const handleFilter = (filterVal) => {
    setUrlPage("");
    axios
      .get(url + `properties${filterVal}&sort_by=${sortBy}&limit=${100}`, config)
      .then(function (response) {
        // handle success
        setBuy(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(url + `properties${urlPage}&sort_by=${sortBy}&limit=${limit}`, config)
      .then(function (response) {
        // handle success
        setBuy(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [sortBy, urlPage, limit]);

  useEffect(() => {
    const requestUrl = `${url}categories${filters ? `${filters}` : ""}`;
    axios
      .get(requestUrl, config)
      .then(function (response) {
        // handle success
        setList(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [filters]);

  return (
    <Layout>
      {window.innerWidth < 768 ? <FilterMobile onFilterChange={handleFilter} handleCloseFilter={() => setIsActive(false)} className={isActive && "active"} /> : ""}
      <div className="container mx-auto pt-8 md:pt-20">
        <div className="row flex items-start justify-start text-center flex-wrap">
          <h3 className="font-bold text-2xl text-center md:text-4xl w-full mb-4  md:mt-8">
            {" "}
            <span className="border-b-2 border-[#CDAC62] text-black px-4 md:px-4"> {t("search results page")} </span>{" "}
          </h3>

          <div className="w-full">{window.innerWidth < 768 ? "" : <Filter onFilterChange={handleFilter} />}</div>
          <div className="w-full flex items-center justify-between flex-wrap border-b border-mainColor pb-4">
            <div className="btns flex items-center justify-between md:justify-start mb-4 md:mb-0 w-full md:w-1/2">
              <Link to="/MapView" className="text-sm md:text-lg w-1/3 md:w-auto px-1 md:px-4 py-2 rounded-full mx-1 md:mx-2  flex items-center relative overflow-hidden cursor-pointer  border-2 font-black border-[#D4B071]">
                <HiOutlineLocationMarker className="mx-1 md:mx-2 text-mainColor" /> {t("Map View")}{" "}
              </Link>
              <button
                onClick={() => setIsActive(true)}
                className="w-1/3 md:w-auto  px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex md:hidden items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-mainColor text-gray"
              >
                <HiOutlineAdjustments className="mx-1 md:mx-2 text-gray" /> {t("Filter")}{" "}
              </button>
            </div>
            <div className="btns flex justify-between md:justify-end items-center  w-full md:w-1/2">
              <label className="mx-4" htmlFor="SortBy">
                {t("Sort By:")}
              </label>
              <select id="SortBy" className="border border-gray py-2 px-4" onChange={(e) => setSortBy(e.target.value)}>
                {/*<option value="featured">{t("Featured")}</option>*/}
                <option value="newest">{t("Newest")}</option>
                <option value="price_low">{t("Price (low)")}</option>
                <option value="price_high">{t("Price (high)")}</option>
                <option value="beds_low">{t("Beds (least)")}</option>
                <option value="beds_high">{t("Beds (most)")}</option>
                <option value="delivery_date_earliest">{t("Delivery date (earliest)")}</option>
                <option value="delivery_date_latest">{t("Delivery date (latest)")}</option>
              </select>
            </div>
          </div>

          {/* <div className="w-full flex justify-center items-center mt-12 px-4">
            {list && list.length > 0 && (
              <div className="flex space-x-10 px-4 py-4 rounded-lg">
                {list.map((item, index) => (
                  <List key={index} id={item.id} status={"target=rent"} name={item.name} total={item.total} />
                ))}
              </div>
            )}
          </div> */}

          {isLoading ? (
            <div className="h-screen w-screen bg-white z-40 flex items-center justify-center">
              <Loading />
            </div>
          ) : (
            <>
              {!isLoading && buy.length === 0 ? (
                <Nothing />
              ) : (
                <div className="flex flex-col md:flex-row">
                  <div className=" w-full flex flex-col justify-center items-center">
                    <div className=" relative grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 md:pt-8">
                      {buy.map((item, index) => (
                        <>
                          <Card
                            id={item.id}
                            slug={item.slug}
                            name={item.title}
                            location={item.address}
                            area={item.size}
                            bedroom={item.bedrooms_number}
                            bathroom={item.bathrooms_number}
                            property_type={item.property_type}
                            price={item.price}
                            img={item.image}
                            imgs={item.images}
                            status={item.launch}
                            buy_or_sell={item.buy_or_sell}
                            price_year={item.price_year}
                            price_hidden={item.price_hidden}
                            description={item.description}
                            height={256}
                            width={380}
                          />
                        </>
                      ))}
                    </div>
                    <div className="w-full flex justify-center items-center">
                      {total > 12 && (
                        <div className="flex gap-4 mt-8">
                          {total > 12 && total > limit && (
                            <button
                              onClick={() => setLimit(limit + 12)}
                              className="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl"
                            >
                              {t("Show more")}
                            </button>
                          )}

                          {limit > 12 && (
                            <button
                              onClick={() => setLimit(limit - 12)}
                              className="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl"
                            >
                              {t("Show Less")}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Properties;
