import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const ImgLightbox = ({ images }) => {
  const [open, setOpen] = React.useState(false);

  const slides = images.map((el) => ({ src: el.original }));

  const [index, setIndex] = React.useState(-1);
  const handleClick = (index) => {
    setIndex(index);
  };

  return (
    <>
      <div>
        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="md:p-2 p-1 w-full md:w-4/6 ">
            <img loading="lazy" className="w-full aspect-[4/3] object-cover h-full object-right block  border-black rounded-xl md:rounded-3xl" src={images[0].medium} onClick={() => handleClick(0)} alt="" />
          </div>
          <div className="relative w-full md:w-2/6 flex flex-row md:flex-col justify-center items-center">
            <div className="md:p-2 p-1  ">
              <img loading="lazy" className="w-full aspect-[4/3]  object-cover h-full object-center block  border-black rounded-xl md:rounded-3xl" src={images[1].medium} onClick={() => handleClick(1)} alt="" />
            </div>
            <div className="md:p-2 p-1  ">
              <img loading="lazy" className="w-full aspect-[4/3]  object-cover h-full object-center block  border-black rounded-xl md:rounded-3xl" src={images[2].medium} onClick={() => handleClick(2)} alt="" />
            </div>
            <button
              className="top-[0] text-md md:text-lg w-4/5 md:w-5/6 absolute md:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 lg:px-4 lg:py-0.5 tracking-wider   font-black bg-black rounded-full border-[3px] border-[#D4B071] text-white"
              onClick={() => handleClick(0)}
            >
              Show {images.length - 3} Photos
            </button>
          </div>
        </div>
      </div>

      <Lightbox open={index >= 0} index={index} close={() => setIndex(-1)} slides={slides} plugins={[Zoom]} />
    </>
  );
};

export default ImgLightbox;
