import axios from "axios";
import { t } from "i18next";
import React, { useState } from "react";
import { useEffect } from "react";

import { useCallback } from "react";
import {
  recaptcha__secret_key,
  recaptcha_key,
  url,
  config,
  chechPhone,
} from "../../../Common/Common";
import Button from "../../UI/Button";
import ErrorAlert from "../../UI/ErrorAlert";
import SuccessAlert from "../../UI/SuccessAlert";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Title from "../../UI/Title";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactRecaptcha3 from "react-google-recaptcha3";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const ListSellYourProperty = () => {
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [location, setlocation] = useState("");
  const [property_type, setproperty_type] = useState("");
  const [property_for, setproperty_for] = useState("");
  const [bed_rooms, setbed_rooms] = useState("");
  const [size_sqft, setsize_sqft] = useState("");
  const [price, setprice] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const [success, setSuccess] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [locationErr, setLocationErr] = useState(false);
  const [propertyTypeErr, setPropertyTypeErr] = useState(false);
  const [propertyForErr, setPropertyForErr] = useState(false);
  const [bedroomError, setBedroomError] = useState(false);
  const [sizeErr, setSizeErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);

  let data = new FormData();
  data.append("name", name);
  data.append("email", email);
  data.append("mobile", mobile);
  data.append("location", location);
  data.append("property_type", property_type);
  data.append("property_for", property_for);
  data.append("bed_rooms", bed_rooms);
  data.append("size_sqft", size_sqft);
  data.append("price", price);

  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key);
  }, []);
  const ListSellHandler = (e) => {
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    setLocationErr(false);
    setPropertyTypeErr(false);
    setPropertyForErr(false);
    setBedroomError(false);
    setSizeErr(false);
    setPriceErr(false);
    e.preventDefault();

    if (name.trim() == "") {
      setNameError(true);
      return;
    }
    if (email.trim() == "" || !email.includes("@")) {
      setEmailError(true);
      return;
    }
    if (!chechPhone(mobile)) {
      setPhoneError(true);
      return;
    }
    if (location.trim() == "") {
      setLocationErr(true);
      return;
    }
    if (!property_type) {
      setPropertyTypeErr(true);
      return;
    }
    if (!property_for) {
      setPropertyForErr(true);
      return;
    }
    if (!bed_rooms) {
      setBedroomError(true);
      return;
    }
    if (size_sqft.trim() == "") {
      setSizeErr(true);
      return;
    }
    if (price.trim() == "") {
      setPriceErr(true);
      return;
    }

    setSellLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + "verifyRecaptcha",
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            axios
              .post(url + "list-property", data, config)
              .then((response) => {
                setName("");
                setemail("");
                setmobile("");
                setlocation("");
                setproperty_type("");
                setproperty_for("");
                setbed_rooms("");
                setsize_sqft("");
                setprice("");
                setSellLoading(false);
                Swal.fire({
                  timer: 3000,
                  title: `${t("Success")}`,
                  icon: "success",
                  html: `${t("Your message has been sent")}`,
                  confirmButtonText: `${t("ok")}`,
                  showCloseButton: true,
                  timerProgressBar: true,
                });
              })
              .catch((error) => {
                setError(error.response.data);
                setTimeout(() => {
                  setError("");
                }, 2000);
              });
          })
          .catch(() => {
            setSellLoading(false);
            setErrToken(true);
          });
      },
      () => {
        setSellLoading(false);
        setErrToken(true);
      }
    );
  };

  return (
    <div className="flex items-center justify-center flex-wrap my-2 mt-10">
      <Title title={t("List or Sell your property in Dubai")} />

      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <input
          autoComplete="true"
          type="text"
          className={`border border-[#D4B071] w-full py-2 px-4 rounded-full ${
            nameError ? "border-red" : "border-[#D4B071]"
          }`}
          placeholder={t("Name")}
          required="required"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {nameError && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <input
          autoComplete="true"
          type="email"
          className={`border border-[#D4B071] w-full py-2 px-4 rounded-full ${
            emailError ? "border-red" : "border-[#D4B071]"
          }`}
          placeholder={t("E-mail")}
          required="required"
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
        {emailError && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <PhoneInput
          placeholder={t("Mobile")}
          country={"ae"}
          value={mobile}
          onChange={(mobile) => setmobile(mobile)}
          className={`border border-[#D4B071] w-full py-2 px-4 transition duration-200 appearance-none rounded-full shadow-md focus:border-b-black focus:outline-none focus:shadow-outline ${
            phoneError ? "border-red" : "border-[#D4B071]"
          }`}
          required="required"
        />
        {phoneError && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <input
          autoComplete="true"
          type="text"
          className={`border border-[#D4B071] w-full py-2 px-4 rounded-full ${
            locationErr ? "border-red" : "border-[#D4B071]"
          }`}
          placeholder={t("Location")}
          required="required"
          value={location}
          onChange={(e) => setlocation(e.target.value)}
        />
        {locationErr && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <select
          className={`border border-[#D4B071] w-full py-2 px-4 rounded-full ${
            propertyTypeErr ? "border-red" : "border-[#D4B071]"
          }`}
          required="required"
          value={property_type}
          onChange={(e) => setproperty_type(e.target.value)}
        >
          <option> {t("Property Type")} </option>
          <option value="Apartment">{t("Apartment")}</option>
          <option value="Villa">{t("Villa")}</option>
          <option value="Commercial">{t("Commercial")}</option>
          <option value="Townhouse">{t("Townhouse")}</option>
        </select>
        {propertyTypeErr && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <select
          className={`border border-[#D4B071] w-full py-2 px-4 rounded-full ${
            propertyForErr ? "border-red" : "border-[#D4B071]"
          }`}
          required="required"
          value={property_for}
          onChange={(e) => setproperty_for(e.target.value)}
        >
          <option> {t("Property For")} </option>
          <option value="sell">{t("Sell")}</option>
          <option value="rent">{t("Rent")}</option>
        </select>
        {propertyForErr && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <select
          className={`border border-[#D4B071] w-full py-2 px-4 rounded-full ${
            bedroomError ? "border-red" : "border-[#D4B071]"
          }`}
          required="required"
          value={bed_rooms}
          onChange={(e) => setbed_rooms(e.target.value)}
        >
          <option> {t("Bed Rooms")} </option>
          <option value="studio">{t("Studio")}</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10+</option>
        </select>
        {bedroomError && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <input
          autoComplete="true"
          type="number"
          className={`border border-[#D4B071] w-full py-2 px-4 rounded-full ${
            sizeErr ? "border-red" : "border-[#D4B071]"
          }`}
          placeholder={t("Size Sqft")}
          required="required"
          value={size_sqft}
          onChange={(e) => setsize_sqft(e.target.value)}
        />
        {sizeErr && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <div className="form-group w-full lg:w-1/3 relative px-2 my-2">
        <input
          autoComplete="true"
          type="number"
          className={`border border-[#D4B071] w-full py-2 px-4 rounded-full ${
            priceErr ? "border-red" : "border-[#D4B071]"
          }`}
          placeholder={t("Price")}
          required="required"
          value={price}
          onChange={(e) => setprice(e.target.value)}
        />
         {priceErr && (
          <div className="absolute text-red top-1/2 ltr:right-4 rtl:left-4 -translate-y-1/2">
            <AiOutlineCloseCircle />
          </div>
        )}
      </div>
      <button
        type="submit"
        disabled={sellLoading}
        className="mt-8 GoldClass text-lg md:text-xl px-4 py-2 rounded-full"
        onClick={ListSellHandler}
      >
        {sellLoading ? (
          <>
            <span className="inline-flex items-center justify-center h-12 px-6">
              <span className="lds-dual-ring"></span>
            </span>
          </>
        ) : (
          "Send"
        )}
      </button>
      <div className="w-full mt-4">
        {success && <SuccessAlert success={success} />}
        {error && <ErrorAlert error={error} />}
      </div>
      {errToken && (
        <p className="text-red text-sm error-token">
          please verify you are a human
        </p>
      )}
    </div>
  );
};

export default ListSellYourProperty;
