import { t } from 'i18next'
import { BsCheck2Circle } from 'react-icons/bs'

const SuccessAlert = (props) => {
  return (
    <div className="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md  mb-8">
      <div className="flex items-center justify-center w-12 bg-green">
        <BsCheck2Circle className='text-3xl text-white' />
      </div>

      <div className="px-4 py-2 -mx-3">
        <div className="mx-3 text-left">
          <span className="font-semibold text-green ">{t('Success')}</span>
          <p className="text-sm text-gray-600  ">{props.success}</p>
        </div>
      </div>
    </div>
  )
}

export default SuccessAlert