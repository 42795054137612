import "./Filter.scss";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import { useEffect, useState } from "react";
import axios from "axios";
import { config, url } from "../../Common/Common";
import { t } from "i18next";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

const bedroomsCounter = [
  "studio",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];
const bathRoomsCounter = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const Filter = (props) => {
  const { homepage, typeChange } = props;
  const [toggleFilter, setToggleFilter] = useState(props.filterType);

  const navigate = useNavigate();

  // Location
  const [location, setLocation] = useState("");
  //  Buy -- Rent
  const [filterType, setFilterType] = useState("");
  // Type Apartment
  const [filterKind, setFilterKind] = useState("");
  //  BathRoom Number
  const [bathRoom, setBathRoom] = useState("");
  // keywords input
  // const [keywords, setkeywords] = useState('')
  //  From Api 'Categories'
  const [list, setList] = useState([]);
  //  From Api 'Aminities'
  // const [aminities, setAminities] = useState([])
  // Rental Period
  const [Period, setPeriod] = useState("");

  // Min Price
  const [MinPrice, setMinPrice] = useState("");

  // Max Price
  const [MaxPrice, setMaxPrice] = useState("");

  // Min Area
  const [MinArea, setMinArea] = useState("");

  // Max Area
  const [MaxArea, setMaxArea] = useState("");

  // Show more search options
  const [dropFilter, setDropFilter] = useState(false);

  // Price dropdown
  const [priceDrop, setPriceDrop] = useState(false);

  // Area dropdown
  const [areaDrop, setAreaDrop] = useState(false);

  const [AminitiesDrop, setAminitiesDrop] = useState(false);

  // bath and bedroom dropdown
  const [BathBedDrop, setBathBedDrop] = useState(false);

  const [status, setStatus] = useState("");

  const [propType, setPropType] = useState(typeChange);

  const [paymentPlan, setPaymentPlan] = useState("");
  const [offPlan, setOffPlan] = useState("");
  const [MaxPriceDrop, setMaxPriceDrop] = useState(false);
  const [MinPriceDrop, setMinPriceDrop] = useState(false);

  const [minAreaDrop, setMinAreaDrop] = useState(false);
  const [maxAreaDrop, setMaxAreaDrop] = useState(false);

  const name = useRef();
  // const x = name.current.value
  // State with list of all checked item
  const [bedRoomArray, setBedRoomArray] = useState([]);
  const [bathRoomArray, setBathRoomArray] = useState([]);
  const [aminities, setAminities] = useState([]);
  const [aminitiesValue, setAminitiesValue] = useState([]);

  // Add/Remove checked item from list
  const handleBedRoomArrayCheck = (event) => {
    var updatedList = [...bedRoomArray];
    if (event.target.checked) {
      updatedList = [...bedRoomArray, event.target.value];
    } else {
      updatedList.splice(bedRoomArray.indexOf(event.target.value), 1);
    }
    setBedRoomArray(updatedList);
  };

  // Generate string of checked items
  const bedRoomArrayItems = bedRoomArray.length
    ? bedRoomArray.reduce((total, item) => {
        return total + "&" + item;
      })
    : "";

  const handleBathRoomArrayCheck = (event) => {
    var updatedList = [...bathRoomArray];
    if (event.target.checked) {
      updatedList = [...bathRoomArray, event.target.value];
    } else {
      updatedList.splice(bathRoomArray.indexOf(event.target.value), 1);
    }
    setBathRoomArray(updatedList);
  };

  const bathRoomArrayItems = bathRoomArray.length
    ? bathRoomArray.reduce((total, item) => {
        return total + "&" + item;
      })
    : "";

  const handleAminitiesCheck = (event) => {
    var aminitiesList = [...aminitiesValue];
    if (event.target.checked) {
      aminitiesList = [...aminitiesValue, event.target.value];
    } else {
      aminitiesList.splice(aminitiesValue.indexOf(event.target.value), 1);
    }
    setAminitiesValue(aminitiesList);
  };
  const aminitiesArrayItems = aminitiesValue.length
    ? aminitiesValue.reduce((total, item) => {
        return total + "&" + item;
      })
    : "";

  const handleFilter = (event) => {
    event.preventDefault();
    //?min_price_aed=5000&max_price_aed=50000000&min_area_ft=200&max_area_ft=30000&class[]=Luxury&category[]=1&category[]=2&category[]=3&category[]=4&category[]=5&category[]=6&bedrooms[]=1&bedrooms[]=2&location=Al%20Furjan&type=sell&aminities[]=5&aminities[]=6&aminities[]=7&start=0
    let filterVal = "?";
    if (location) {
      filterVal += `&term=${location}`;
    }

    if (filterType) {
      filterVal += `&target=${filterType}`;
    }

    if (filterType === "buy") {
      filterVal += "&target=buy";
    }

    if (paymentPlan) {
      filterVal += `&payment_plan=${paymentPlan}`;
    }

    if (offPlan) {
      filterVal += `&offPlan=${offPlan}`;
    }

    if (filterKind) {
      filterVal += `&category[]=${filterKind}`;
    }

    if (bedRoomArrayItems) {
      filterVal += `&${bedRoomArrayItems}`;
    }

    if (bathRoomArrayItems) {
      filterVal += `&${bathRoomArrayItems}`;
    }

    if (MinPrice) {
      filterVal += `&min_price_aed=${MinPrice}`;
    }

    if (MaxPrice) {
      filterVal += `&max_price_aed=${MaxPrice}`;
    }

    if (propType) {
      filterVal += `&target=${propType}`;
    }

    if (status) {
      filterVal += `&status=${status}`;
    }

    if (MinArea) {
      filterVal += `&min_area_ft=${MinArea}`;
    }

    if (MaxArea) {
      filterVal += `&max_area_ft=${MaxArea}`;
    }

    if (aminitiesArrayItems) {
      filterVal += `&${aminitiesArrayItems}`;
    }

    props.onFilterChange(filterVal);
  };

  useEffect(() => {
    axios
      .get(url + "aminities", config)
      .then(function (response) {
        // handle success
        setAminities(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    axios
      .get(url + "categories?target=" + typeChange, config)
      .then(function (response) {
        // handle success
        setList(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  const toggleOnChange = (event) => {
    setPropType(event.target.value);
    setToggleFilter(event.target.value);

    // if (propType === 'buy') {
    //   navigate(`/property-for-sale-in-dubai`);
    // } else if( propType === 'rent') {
    //   navigate(`/property-for-rent-in-dubai`);
    // }
  };

  const advancedFilterToggle = (event) => {
    event.preventDefault();
    setDropFilter(!dropFilter);
  };

  return (
    <form name="" className={` w-full relative  z-10 rounded-xl p-2 lg:p-4 `}>
      {/* ${props.homepage ? "mb-24" : ""} */}
      {props.homepage && (
        <div className="bg-white/30 absolute left-0 top-0 w-full h-full rounded-[3rem] -z-10" />
      )}
      <div className="flex items-center justify-between my-2">
        <div className="tags flex items-center">
          {homepage ? (
            <>
              <span
                className={`buy px-4 py-px rounded-full mr-2 text-[#222] inline-flex relative overflow-hidden text-lg hover:cursor-pointer font-bold capitalize ${
                  props.buyClass
                } ${
                  propType === "buy" || toggleFilter === "buy"
                    ? "GoldClass"
                    : "bg-gray"
                }`}
              >
                <input
                  onChange={toggleOnChange}
                  checked={propType === "buy"}
                  value="buy"
                  type="radio"
                  name="type"
                  id=""
                  className="hover:cursor-pointer absolute w-full h-full left-0 top-0 opacity-0"
                />
                {t("buy")}
              </span>

              <span
                className={`rent px-4 py-px rounded-full mx-2 text-[#222] inline-flex relative overflow-hidden text-lg hover:cursor-pointer font-bold capitalize ${
                  props.rentClass
                } ${propType === "rent" ? "GoldClass" : "bg-gray"} ${
                  toggleFilter === "rent" ? "GoldClass" : "bg-gray"
                }`}
              >
                <input
                  onChange={toggleOnChange}
                  checked={propType === "rent"}
                  value="rent"
                  type="radio"
                  name="type"
                  id=""
                  className="hover:cursor-pointer absolute w-full h-full left-0 top-0 opacity-0"
                />
                {t("rent")}
              </span>
            </>
          ) : (
            <>
              <Link to={`/property-for-sale-in-dubai`}>
                <span
                  className={`buy px-4 py-px rounded-full mr-2 text-[#222] inline-flex relative overflow-hidden text-lg hover:cursor-pointer font-bold capitalize ${
                    props.buyClass
                  } ${
                    propType === "buy" || toggleFilter === "buy"
                      ? "GoldClass"
                      : "bg-gray"
                  }`}
                >
                  <input
                    onChange={toggleOnChange}
                    checked={propType === "buy"}
                    value="buy"
                    type="radio"
                    name="type"
                    id=""
                    className="hover:cursor-pointer absolute w-full h-full left-0 top-0 opacity-0"
                  />
                  {t("buy")}
                </span>
              </Link>

              <Link to={`/property-for-rent-in-dubai`}>
                <span
                  className={`rent px-4 py-px rounded-full mx-2 text-[#222] inline-flex relative overflow-hidden text-lg hover:cursor-pointer font-bold capitalize ${
                    props.rentClass
                  } ${propType === "rent" ? "GoldClass" : "bg-gray"} ${
                    toggleFilter === "rent" ? "GoldClass" : "bg-gray"
                  }`}
                >
                  <input
                    onChange={toggleOnChange}
                    checked={propType === "rent"}
                    value="rent"
                    type="radio"
                    name="type"
                    id=""
                    className="hover:cursor-pointer absolute w-full h-full left-0 top-0 opacity-0"
                  />
                  {t("rent")}
                </span>
              </Link>
            </>
          )}

          <Link
            to={`/off-plan-properties-dubai`}
            className="capitalize px-4 py-px rounded-full mx-2  inline-flex relative overflow-hidden cursor-pointer font-bold text-lg  text-[#000] bg-gray"
          >
            {" "}
            {t("Off Plan properties")}{" "}
          </Link>

          {/* <label className="checkBox relative cursor-pointer rtl:pr-8 ltr:pl-8 select-none text-lg ">{t('Commercial properties')} <b>{t('only')}</b>
                              <input type="checkbox" className='absolute opacity-0 cursor-pointer h-0 w-0' />
                              <span className="checkmark absolute top-0 rtl:right-0 ltr:left-0 h-6 w-6 bg-gray rounded-full"></span>
                         </label> */}
        </div>
      </div>
      <div className="flex items-center justify-between gap-2  my-2">
        <div className="form-group w-full lg:w-1/2 relative">
          <BsSearch className="absolute top-1/2 left-2 -translate-y-1/2" />
          <input
            autoComplete="true"
            type="text"
            className={`border border-gray w-full py-2 px-4 pl-8 rounded-full ${
              homepage ? "" : "shadow-lg"
            } `}
            placeholder={t("City, Community or building")}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            ref={name}
          />
        </div>

        <div className="flex justify-between w-full lg:w-1/2 items-center gap-2">
          <div className="form-group w-1/3">
            <select
              className={`border border-gray w-full py-2 px-4 rounded-full  ${
                homepage ? "" : "shadow-lg"
              } `}
              onChange={(e) => setFilterKind(e.target.value)}
            >
              <option> {t("Type")} </option>
              {list.map((item, index) => (
                <option key={item.id} value={item.id}>
                  {" "}
                  {item.name}{" "}
                </option>
              ))}
            </select>
          </div>
          <div
            className="form-group w-1/3 relative bedRoom"
            onMouseLeave={() =>
              setTimeout(() => {
                setBathBedDrop(false);
              }, 300)
            }
          >
            <div
              onClick={() => setBathBedDrop(true)}
              className={`border border-gray w-full py-2 px-2 cursor-pointer rtl:text-sm rounded-full bg-white ${
                homepage ? "" : "shadow-lg"
              }`}
            >
              {" "}
              {t("Bed & Baths")}{" "}
            </div>
            <div
              className={`border border-gray w-auto absolute rtl:left-0 ltr:right-0 bg-white  text-dark z-30 leading-7 rounded-lg shadow-xl ${
                BathBedDrop
                  ? "h-42 overflow-y-scroll px-2 py-4  block"
                  : " h-0 overflow-hidden hidden"
              }`}
            >
              <div className="flex w-full flex-wrap">
                <h3 className="font-bold">{t("Bedrooms")}</h3>
                <ul className="w-full flex items-center justify-between">
                  {bedroomsCounter.map((item, index) => (
                    <label
                      key={index}
                      className={`checkBox text-center h-6 text-overflow mx-1 w-6 relative overflow-hidden bg-gray text-dark rounded-full ${
                        item === "studio" ? "w-16" : "w-6"
                      }  ${item === "studio" ? "w-16" : "w-6"}`}
                    >
                      <input
                        value={`bedrooms[]=${item}`}
                        type="checkbox"
                        onChange={handleBedRoomArrayCheck}
                        className={`absolute opacity-0 cursor-pointer h-0 w-0 ${
                          homepage ? "" : "shadow-lg"
                        }`}
                      />
                      <span
                        className={`checkmark absolute top-0 left-0 h-6 w-full bg-gray {isChecked(item)}`}
                      >
                        {item}
                      </span>
                    </label>
                  ))}
                </ul>
              </div>
              <div className="flex w-full flex-wrap mt-4">
                <h3 className="font-bold">{t("Bathrooms")}</h3>
                <ul className="w-full flex items-center justify-between">
                  {bathRoomsCounter.map((item, index) => (
                    <label
                      key={index}
                      className={`checkBox text-center h-6 text-overflow w-6 relative overflow-hidden bg-gray text-dark rounded-full ${
                        item === "studio" ? "w-16" : "w-6"
                      }  ${item === "studio" ? "w-16" : "w-6"}`}
                    >
                      <input
                        value={`bathroms[]=${item}`}
                        type="checkbox"
                        onChange={handleBathRoomArrayCheck}
                        className={`absolute opacity-0 cursor-pointer h-0 w-0 ${
                          homepage ? "" : "shadow-lg"
                        }`}
                      />
                      <span
                        className={`checkmark absolute top-0 left-0 h-6 w-full bg-gray {isChecked(item)}`}
                      >
                        {item}
                      </span>
                    </label>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div
            className="form-group w-1/3 relative"
            onMouseLeave={() =>
              setTimeout(() => {
                setMaxPriceDrop(false);
                setMinPriceDrop(false);
                setPriceDrop(false);
              }, 300)
            }
            >
            <div
              onClick={() => setPriceDrop(true)}
              className={`border border-gray w-full py-2 px-2 cursor-pointer rounded-full bg-white ${
                homepage ? "" : "shadow-lg"
              }`}
            >
              {" "}
              {t("Price")}{" "}
            </div>
            {(filterType != "buy" && props.filterType != "buy") ||
            props.offPlan === false ? (
              <div
                className={` w-auto absolute rtl:left-0 ltr:right-0  rounded-lg text-dark leading-7 h-48 ${
                  priceDrop
                    ? "px-2 py-4 block z-30 "
                    : " h-0 overflow-hidden hidden -z-30"
                }`}
              >
                <div
                  onClick={() => {
                    setMinPriceDrop(false);
                    setMaxPriceDrop(false);
                  }}
                  className={`bg-white  absolute left-0 top-0 w-full -z-50  shadow-2xl border border-gray rounded-lg ${
                    filterType === "rent" ? "h-40" : "h-20"
                  }`}
                ></div>
                <div
                  className="flex w-full gap-2 items-start "
                  onMouseOver={() => {
                    setPriceDrop(true);
                  }}
                >
                  <div className="w-full flex flex-wrap">
                    <input
                      onFocus={() => {
                        setMinPriceDrop(true);
                        setMaxPriceDrop(false);
                      }}
                      className="rounded-lg border border-mainColor p-2 "
                      type="number"
                      value={MinPrice}
                      onChange={(e) => setMinPrice(e.target.value)}
                      placeholder={t("Min. Price (AED) ")}
                    />
                    `{ typeChange != "rent" ? 
                      <ul
                      className={`w-full overflow-y-auto overflow-x-hidden mt-2 bg-white border border-[#999c]  rounded-lg px-1 shadow-xl z-20 ${
                        MinPriceDrop ? "h-60 py-2 border" : "border-0 h-0 py-0"
                      }`}
                    >
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("300000");
                          setMinPriceDrop(false);
                        }}
                      >
                        300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("400000");
                          setMinPriceDrop(false);
                        }}
                      >
                        400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("500000");
                          setMinPriceDrop(false);
                        }}
                      >
                        500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("600000");
                          setMinPriceDrop(false);
                        }}
                      >
                        600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("700000");
                          setMinPriceDrop(false);
                        }}
                      >
                        700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("800000");
                          setMinPriceDrop(false);
                        }}
                      >
                        800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("900000");
                          setMinPriceDrop(false);
                        }}
                      >
                        900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1000000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1100000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.100.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1200000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.200.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1300000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1400000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1500000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1600000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1700000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1800000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1900000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2000000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2100000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.100.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2200000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.200.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2300000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2400000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2500000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2600000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2700000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2800000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2900000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("3000000");
                          setMinPriceDrop(false);
                        }}
                      >
                        3.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("3250000");
                          setMinPriceDrop(false);
                        }}
                      >
                        3.250.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("3500000");
                          setMinPriceDrop(false);
                        }}
                      >
                        3.500.000 {t("AED")}{" "}
                      </li>
                      <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("3750000");
                        setMinPriceDrop(false);
                      }}
                    >
                      3.750.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("4000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      4.000.000 {t("AED")}{" "}
                    </li>

                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("4250000");
                      setMinPriceDrop(false);
                    }}
                    >
                      4.250.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("4500000");
                      setMinPriceDrop(false);
                    }}
                    >
                      4.500.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("5000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      5.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("6000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      6.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("7000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      7.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("8000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      8.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("9000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      9.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("10000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      10.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("25000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      25.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("50000000");
                      setMinPriceDrop(false);
                    }}
                    >
                      50.000.000 {t("AED")}{" "}
                    </li>
                    </ul>
                   : 
                    <ul
                    className={`w-full overflow-y-auto overflow-x-hidden mt-2 bg-white border border-[#999c]  rounded-lg px-1 shadow-xl z-20 ${
                      MinPriceDrop ? "h-60 py-2 border" : "border-0 h-0 py-0"
                    }`}
                  >
                    
                    
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("20000");
                        setMinPriceDrop(false);
                      }}
                    >
                      20.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("30000");
                        setMinPriceDrop(false);
                      }}
                    >
                      30.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("40000");
                        setMinPriceDrop(false);
                      }}
                    >
                     40.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("50000");
                        setMinPriceDrop(false);
                      }}
                    >
                      50.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("60000");
                        setMinPriceDrop(false);
                      }}
                    >
                      60.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("70000");
                        setMinPriceDrop(false);
                      }}
                    >
                      70.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("80000");
                        setMinPriceDrop(false);
                      }}
                    >
                      80.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMinPrice("90000");
                        setMinPriceDrop(false);
                      }}
                    >
                      90.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMinPrice("100000");
                      setMinPriceDrop(false);
                    }}
                  >
                    100.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("110000");
                    setMinPriceDrop(false);
                  }}
                  >
                    110.000 {t("AED")}{" "}
                  </li>

                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("120000");
                    setMinPriceDrop(false);
                  }}
                  >
                  120.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("130000");
                    setMinPriceDrop(false);
                  }}
                  >
                    130.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("140000");
                    setMinPriceDrop(false);
                  }}
                  >
                    140.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("150000");
                    setMinPriceDrop(false);
                  }}
                  >
                    150.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("160000");
                    setMinPriceDrop(false);
                  }}
                  >
                    160.000 {t("AED")}{" "}
                  </li>

                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("170000");
                    setMinPriceDrop(false);
                  }}
                  >
                    170.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("180000");
                    setMinPriceDrop(false);
                  }}
                  >
                    180.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("190000");
                    setMinPriceDrop(false);
                  }}
                  >
                    190.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("200000");
                    setMinPriceDrop(false);
                  }}
                  >
                    200.000 {t("AED")}{" "}
                  </li>

                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("225000");
                    setMinPriceDrop(false);
                  }}
                  >
                    225.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("250000");
                    setMinPriceDrop(false);
                  }}
                  >
                    250.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("275000");
                    setMinPriceDrop(false);
                  }}
                  >
                    275.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("300000");
                    setMinPriceDrop(false);
                  }}
                  >
                    300.000 {t("AED")}{" "}
                  </li>
                  
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("350000");
                    setMinPriceDrop(false);
                  }}
                  >
                    350.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("400000");
                    setMinPriceDrop(false);
                  }}
                  >
                    400.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("500000");
                    setMinPriceDrop(false);
                  }}
                  >
                    500.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("750000");
                    setMinPriceDrop(false);
                  }}
                  >
                    750.000 {t("AED")}{" "}
                  </li>
                  <li
                  className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                  onClick={() => {
                    setMinPrice("1000000");
                    setMinPriceDrop(false);
                  }}
                  >
                    1.000.000 {t("AED")}{" "}
                  </li>
                
                    </ul>
                  }`

                  </div>
                  <span> - </span>
                  <div className="w-full flex flex-wrap">
                    <input
                      onFocus={() => {
                        setMaxPriceDrop(true);
                        setMinPriceDrop(false);
                      }}
                      className="rounded-lg border border-mainColor p-2 "
                      type="number"
                      value={MaxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                      placeholder={t("Max. Price (AED)")}
                    />
                    `{ typeChange != "rent" ? 
                    <ul
                      className={`w-full overflow-y-auto overflow-x-hidden mt-2 bg-white border border-[#999c]  rounded-lg px-1 shadow-xl z-20 ${
                        MaxPriceDrop ? "h-60 py-2 border" : "border-0 h-0 py-0"
                      }`}
                    >
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("300000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("400000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("500000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("600000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("700000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("800000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("900000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1000000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1100000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.100.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1200000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.200.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1300000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1400000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1500000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1600000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1700000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1800000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1900000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2000000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2100000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.100.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2200000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.200.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2300000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2400000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2500000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2600000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2700000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2800000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2900000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("3000000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        3.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("3250000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        3.250.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("3500000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        3.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("3750000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        3.750.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("4000000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        4.000.000 {t("AED")}{" "}
                      </li>

                      <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("4250000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      4.250.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("4500000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      4.500.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("5000000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      5.000.000 {t("AED")}{" "}
                    </li>

                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("6000000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      6.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("7000000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      7.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("8000000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      8.000.000 {t("AED")}{" "}
                    </li>

                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("9000000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      9.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("10000000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      10.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("25000000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      25.000.000 {t("AED")}{" "}
                    </li>
                    <li
                    className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                    onClick={() => {
                      setMaxPrice("50000000");
                      setMaxPriceDrop(false);
                    }}
                    >
                      50.000.000 {t("AED")}{" "}
                    </li>
                  
                    </ul>
                    :
                    <ul
                    className={`w-full overflow-y-auto overflow-x-hidden mt-2 bg-white border border-[#999c]  rounded-lg px-1 shadow-xl z-20 ${
                      MaxPriceDrop ? "h-60 py-2 border" : "border-0 h-0 py-0"
                    }`}
                  >
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("20000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      20.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("30000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      30.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("40000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      40.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("50000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      50.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("60000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      60.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("70000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      70.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("80000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      80.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("90000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      90.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("100000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      100.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("110000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      110.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("120000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      120.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("130000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      130.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("140000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      140.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("150000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      150.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("160000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      160.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("170000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      170.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("180000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      180.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("190000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      190.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("200000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      200.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("225000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      225.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("250000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      250.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("275000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      275.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("300000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      300.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("350000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      350.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("400000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      400.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("500000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      500.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("750000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      750.000 {t("AED")}{" "}
                    </li>
                    <li
                      className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                      onClick={() => {
                        setMaxPrice("1000000");
                        setMaxPriceDrop(false);
                      }}
                    >
                      1.000.000 {t("AED")}{" "}
                    </li>
                   
                   
               
                 
                
                
                  </ul>
                  }`
                  </div>
                </div>
                <div
                  className="flex w-full flex-wrap mt-4 absolute  top-16"
                  onClick={() => {
                    setMinPriceDrop(false);
                    setMaxPriceDrop(false);
                  }}
                >
                  <h3 className="font-bold w-full rtl:text-right ltr:text-left">
                    {t("Rental Period")}
                  </h3>
                  <span
                    className={`rounded-full my-1 mr-2 w-auto px-3 cursor-pointer text-overflow  ${
                      Period === "Yearly"
                        ? "bg-mainColor text-white"
                        : "text-dark bg-gray"
                    }`}
                    onClick={() => setPeriod("Yearly")}
                  >
                    {t("Yearly")}
                  </span>
                  <span
                    className={`rounded-full my-1 mr-2 w-auto px-3 cursor-pointer text-overflow  ${
                      Period === "Monthly"
                        ? "bg-mainColor text-white"
                        : "text-dark bg-gray"
                    }`}
                    onClick={() => setPeriod("Monthly")}
                  >
                    {t("Monthly")}
                  </span>
                  {/* <span className={`rounded-full my-1 mr-2 w-auto px-3 cursor-pointer text-overflow  ${Period === 'Weekly' ? 'bg-mainColor text-white' : 'text-dark bg-gray'}`} onClick={() => setPeriod('Weekly')}>{t('Weekly')}</span> */}
                  {/* <span className={`rounded-full my-1 mr-2 w-auto px-3 cursor-pointer text-overflow  ${Period === 'Daily' ? 'bg-mainColor text-white' : 'text-dark bg-gray'}`} onClick={() => setPeriod('Daily')}>{t('Daily')}</span> */}
                </div>
              </div>
            ) : (
              <div
                className={` w-auto absolute rtl:left-0 ltr:right-0  rounded-lg text-dark leading-7 h-48 ${
                  priceDrop
                    ? "px-2 py-4 block z-30 "
                    : " h-0 overflow-hidden hidden -z-30"
                }`}
              >
                <div
                  className={`bg-white  absolute left-0 top-0 w-full -z-50  shadow-2xl border border-gray rounded-lg ${
                    filterType === "rent" ? "h-40" : "h-20"
                  }`}
                ></div>
                <div
                  className="flex w-full gap-2 items-start "
                  onMouseOver={() => {
                    setPriceDrop(true);
                  }}
                >
                  <div className="w-full flex flex-wrap">
                    <input
                      onFocus={() => {
                        setMinPriceDrop(true);
                        setMaxPriceDrop(false);
                      }}
                      className="rounded-lg border border-mainColor p-2 "
                      type="number"
                      value={MinPrice}
                      onChange={(e) => setMinPrice(e.target.value)}
                      placeholder={t("Min. Price (AED) ")}
                    />
                    <ul
                      className={`w-full overflow-y-auto overflow-x-hidden mt-2 bg-white border border-[#999c]  rounded-lg px-1 shadow-xl z-20 ${
                        MinPriceDrop ? "h-48 py-2 border" : "border-0 h-0 py-0"
                      }`}
                    >
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("300.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("400.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("500.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("600.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("700.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("800.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("900.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1.000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1100.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.100.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1200.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.200.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1300.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1400.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1500.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1600.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1700.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1800.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("1900.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        1.900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.000.000 {t("AED")}{" "}
                      </li>

                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2100.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.100.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2200.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.200.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2300.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2400.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2500.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2600.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2700.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2800.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("2900.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        2.900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("3000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        3.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("3250.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        3.250.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("3500.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        3.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("3750.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        3.750.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("4000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        4.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("4250.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        4.250.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("4500.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        4.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("5000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        5.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("6000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        6.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("7000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        7.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("8000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        8.00.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("9000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        9.00.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("10000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        10.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("25000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        25.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinPrice("50000.000");
                          setMinPriceDrop(false);
                        }}
                      >
                        50.000.000 {t("AED")}{" "}
                      </li>
                    </ul>
                  </div>
                  <span> - </span>
                  <div className="w-full flex flex-wrap">
                    <input
                      onFocus={() => {
                        setMaxPriceDrop(true);
                        setMinPriceDrop(false);
                      }}
                      className="rounded-lg border border-mainColor p-2 "
                      type="number"
                      value={MaxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                      placeholder={t("Max. Price (AED)")}
                    />
                    <ul
                      className={`w-full overflow-y-auto overflow-x-hidden mt-2 bg-white border border-[#999c]  rounded-lg px-1 shadow-xl z-20 ${
                        MaxPriceDrop ? "h-48 py-2 border" : "border-0 h-0 py-0"
                      }`}
                    >
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("300.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("400.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("500.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("600.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("700.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("800.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("900.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1.000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1100.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.100.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1200.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.200.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1300.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1400.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1500.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1600.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1700.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1800.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("1900.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        1.900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2100.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.100.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2200.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.200.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2300.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.300.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2400.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.400.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2500.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2600.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.600.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2700.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.700.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2800.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.800.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("2900.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        2.900.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("3000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        3.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("3250.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        3.250.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("3500.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        3.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("3750.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        3.750.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("4000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        4.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("4250.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        4.250.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("4500.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        4.500.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("5000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        5.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("6000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        6.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("7000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        7.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("8000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        8.00.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("9000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        9.00.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("10000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        10.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("25000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        25.000.000 {t("AED")}{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxPrice("50000.000");
                          setMaxPriceDrop(false);
                        }}
                      >
                        50.000.000 {t("AED")}{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            className={`GoldClass text-[#222] py-3 px-3 rounded-full ${
              homepage ? "" : "drop-shadow-lg"
            }`}
            onClick={handleFilter}
          >
            <BsSearch />
          </button>
          <button
            onClick={advancedFilterToggle}
            className={`absolute top-6 right-4 GoldClass py-px px-2 rounded-full text-black cursor-pointer flex flex-wrap items-center ${
              homepage ? "" : "drop-shadow-lg"
            }`}
          >
            {dropFilter
              ? `${t("Hide Advanced Search")}`
              : `${t("Advanced Search")}`}
            {dropFilter ? (
              <IoIosArrowUp className="mx-2 text-xl" />
            ) : (
              <IoIosArrowDown className="mx-2 text-xl" />
            )}
          </button>
        </div>
      </div>
      <div
        className={`flex items-center justify-start gap-2 relative  ${
          dropFilter === true ? " h-auto my-2 " : "h-0 overflow-hidden"
        }`}
      >
        {/* {filterType != "rent" && props.filterType != "rent" && (
          <>
            <div className="form-group w-1/5">
              <select className="border border-gray w-full py-2 px-4 rounded-full bg-white" onChange={(e) => setPaymentPlan(e.target.value)}>
                <option value="">{t("Payment Plan")}</option>
                <option value="1">{t("Year")} </option>
                <option value="2">{t("twoYears")} </option>
                <option value="3"> 3 {t("Years")} </option>
                <option value="4"> 4 {t("Years")} </option>
                <option value="5"> 5 {t("Years")} </option>
                <option value="6"> 6 {t("Years")} </option>
                <option value="7"> 7 {t("Years")} </option>
                <option value="8"> 8 {t("Years")} </option>
                <option value="9"> 9 {t("Years")} </option>
                <option value="10"> +10 {t("Years")} </option>
              </select>
            </div>
            <div className="form-group w-1/5">
              <select className="border border-gray w-full py-2 px-4 rounded-full bg-white" onChange={(e) => setOffPlan(e.target.value)}>
                <option value="">{t("Off Plan")}</option>
                <option value="1"> {t("Year")} </option>
                <option value="2"> {t("twoYears")} </option>
                <option value="3"> 3 {t("Years")} </option>
                <option value="4"> 4 {t("Years")} </option>
                <option value="5"> 5 {t("Years")} </option>
                <option value="6"> 6 {t("Years")} </option>
                <option value="7"> 7 {t("Years")} </option>
                <option value="8"> 8 {t("Years")} </option>
                <option value="9"> 9 {t("Years")} </option>
                <option value="10"> +10 {t("Years")} </option>
              </select>
            </div>
          </>
        )} */}
        <div className="flex justify-start w-full lg:w-1/2 items-center gap-2">
          {filterType != "rent" && props.filterType != "rent" && (
            <div
              className={`form-group w-1/3 completion-status ${
                homepage ? "" : "drop-shadow-lg"
              }`}
            >
              <select
                className="border border-gray w-full py-2 px-2 rounded-full bg-white"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">
                  {t("Completion Status")}
                </option>
                <option value="offplan">{t("Off-plan")} </option>
                <option value="ready">{t("Ready")} </option>
              </select>
            </div>
          )}
          <div
            className={`form-group w-1/3 relative overflow-hidden  ${
              areaDrop === true ? " overflow-visible " : " overflow-hidden"
            } ${homepage ? "" : "drop-shadow-lg"}`}
            onMouseLeave={() =>
              setTimeout(() => {
                setAreaDrop(false);
              }, 1000)
            }
          >
            <div
              onClick={() => setAreaDrop((prevState) => !prevState)}
              className={`border border-gray w-full py-2 px-2 cursor-pointer ltr:text-left rtl:text-right rounded-full bg-white `}
            >
              {" "}
              {t("Area")}{" "}
            </div>

            <div
              className={` w-auto absolute rounded-lg text-dark z-20 leading-7  ${
                areaDrop
                  ? "h-42 overflow-y-scroll px-2 py-4 "
                  : " h-0 overflow-hidden"
              }`}
            >
              <div
                className={`bg-white  absolute left-0 top-0 w-full -z-50  shadow-2xl border border-gray rounded-lg h-20`}
              ></div>

              <div className="flex w-full gap-2 items-start ">
                <div
                  className="flex w-full gap-2 items-start "
                  onMouseOver={() => {
                    setAreaDrop(true);
                  }}
                >
                  <div className="w-full flex flex-wrap">
                    <input
                      onFocus={() => {
                        setMinAreaDrop(true);
                        setMaxAreaDrop(false);
                      }}
                      value={MinArea}
                      className="rounded-lg border border-mainColor p-2 rtl:text-sm"
                      type="number"
                      onChange={(e) => setMinArea(e.target.value)}
                      placeholder={t("Min. Area (Sqft)")}
                    />
                    <ul
                      className={`w-full overflow-y-auto overflow-x-hidden mt-2 bg-white border border-[#999c]  rounded-lg px-1 shadow-xl z-20 ${
                        minAreaDrop ? "h-48 py-2 border" : "border-0 h-0 py-0"
                      }`}
                    >
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("500");
                        }}
                      >
                        500 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("600");
                        }}
                      >
                        600 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("700");
                        }}
                      >
                        700 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("800");
                        }}
                      >
                        800 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("900");
                        }}
                      >
                        900 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("1000");
                        }}
                      >
                        1000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("2000");
                        }}
                      >
                        2000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("3000");
                        }}
                      >
                        3000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("4000");
                        }}
                      >
                        4000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("5000");
                        }}
                      >
                        5000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("6000");
                        }}
                      >
                        6000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("7000");
                        }}
                      >
                        7000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("8000");
                        }}
                      >
                        8000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMinArea("9000");
                        }}
                      >
                        9000 Sqft{" "}
                      </li>
                    </ul>
                  </div>
                </div>

                <span> - </span>
                <div
                  className="flex w-full gap-2 items-start "
                  onMouseOver={() => {
                    setAreaDrop(true);
                  }}
                >
                  <div className="w-full flex flex-wrap">
                    <input
                      onFocus={() => {
                        setMinAreaDrop(false);
                        setMaxAreaDrop(true);
                      }}
                      value={MaxArea}
                      className="rounded-lg border border-mainColor p-2 rtl:text-sm"
                      type="number"
                      onChange={(e) => setMaxArea(e.target.value)}
                      placeholder={t("Max. Area (Sqft)")}
                    />
                    <ul
                      className={`w-full overflow-y-auto overflow-x-hidden mt-2 bg-white border border-[#999c]  rounded-lg px-1 shadow-xl z-20 ${
                        maxAreaDrop ? "h-48 py-2 border" : "border-0 h-0 py-0"
                      }`}
                    >
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("500");
                        }}
                      >
                        500 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("600");
                        }}
                      >
                        600 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("700");
                        }}
                      >
                        700 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("800");
                        }}
                      >
                        800 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("900");
                        }}
                      >
                        900 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("1000");
                        }}
                      >
                        1000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("2000");
                        }}
                      >
                        2000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("3000");
                        }}
                      >
                        3000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("4000");
                        }}
                      >
                        4000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("5000");
                        }}
                      >
                        5000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("6000");
                        }}
                      >
                        6000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("7000");
                        }}
                      >
                        7000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("8000");
                        }}
                      >
                        8000 Sqft{" "}
                      </li>
                      <li
                        className="px-2 font-bold text-lg  text-[#555] my-1 cursor-pointer hover:bg-[#f5f5f5]"
                        onClick={() => {
                          setMaxArea("9000");
                        }}
                      >
                        9000 Sqft{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`form-group w-1/3 relative overflow-hidden  ${
              AminitiesDrop === true ? " overflow-visible " : " overflow-hidden"
            } ${homepage ? "" : "drop-shadow-lg"}`}
          >
            <div
              onClick={() =>
                setAminitiesDrop((prevAminitiesDrop) => !prevAminitiesDrop)
              }
              className=" w-full py-2 px-2 cursor-pointer rtl:text-right ltr:text-left rounded-full bg-white relative"
            >
              {" "}
              {t("Aminities")}{" "}
            </div>
            <div
              className={` aminities border border-gray w-auto absolute bg-white shadow-lg rounded-lg text-dark z-30 leading-7  ${
                AminitiesDrop
                  ? "h-32 overflow-y-scroll px-2 py-4 AmenitiesActive"
                  : "h-0 overflow-hidden"
              }`}
            >
              <div
                className=" flex flex-col w-full items-center "
                onMouseLeave={() =>
                  setTimeout(() => {
                    setAminitiesDrop(false);
                  }, 1000)
                }
              >
                {aminities.map((item, index) => (
                  <label
                    className="checkBox aminitiesCheckbox mb-2 w-full flex items-center relative cursor-pointer pl-6 select-none text-md h-4 text-overflow"
                    key={index}
                  >
                    {item.name}
                    <input
                      value={`aminities[]=${item.id}`}
                      onChange={handleAminitiesCheck}
                      type="checkbox"
                      className="absolute opacity-0 cursor-pointer h-0 w-0"
                    />
                    <span className="checkmark absolute top-0 left-0 h-4 w-4 bg-gray"></span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Filter;
