import React, { useState, useEffect } from "react";
import axios from "axios";
import { t } from "i18next";

import { recaptcha__secret_key, recaptcha_key, url, chechPhone } from "../../Common/Common";
import { HiCheck } from "react-icons/hi";
import "./FormOptions.scss";
import PhoneInput from "react-phone-input-2";
import ReactRecaptcha3 from "react-google-recaptcha3";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { AiOutlineCloudUpload } from "react-icons/ai";


const FormOptionsBuy = (props) => {

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState(Array(8).fill(null));  
  const [error, setError] = useState(false);

  
  const [phone, setPhone] = useState();
  const [showResult, setShowResult] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [viewDate, setViewDate] = useState("");
  const [viewTime, setViewTime] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    plotSize: "",
    buildSize: "",
    size: "",
    price: "",
    address: "",
    property_status: "",
    planing_to_purchase: "",
    payment_type: "",
  });
  const handleSecondValueChange = (newValue) => {
    if (newValue === "Villa" || newValue === "Townhouse") {
      setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[2] = [null, null];
        return updatedAnswers;
      });
    } else {
      setUserAnswers((prevAnswers) => {
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[2] = userAnswers[2];
        return updatedAnswers;
      });
    }
  };

  const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");

  const handleAnswerClick = (index, value) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = value;
    setUserAnswers(updatedAnswers);
  };
  const handleFormDataChange = (number, event) => {
    
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if ( userAnswers[0] === "Villa" && currentQuestion === 2) {
      const newArr = [...userAnswers];
      newArr[2][number] = value;
      setUserAnswers(newArr); 
      return
    }
      const newArr = [...userAnswers];
      newArr[number] = event.target.value;
      setUserAnswers(newArr); 
  };

  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key)    
  }, [])


  const handleSubmitClick = (e) => {
    setError(false);
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    e.preventDefault();

    if (formData.name.trim() == "") {
      setNameError(true);
      return;
    }
    if (formData.email.trim() == "" || !formData.email.includes("@")) {
      setEmailError(true);
      return;
    }

    if(!chechPhone(phone)){
      setPhoneError(true);
        return;
    }

    setSellLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + "verifyRecaptcha",
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
            )
            .then((response) => {
              const combinedData = {
              property_type: userAnswers[0],
              bed_rooms: userAnswers[1],
              mobile: phone,
              property_for:'buy',
              ...formData,
            }; 
        
            axios
              .post(url + "list-property", combinedData)
              .then((response) => {
                setSellLoading(false);
              })
              .catch((error) => {
                console.error("API Error:", error);
              });
            setShowResult(true);
          })
          .catch(() => {
            setSellLoading(false);
            setErrToken(true);
          });
      },
      () => {
        setSellLoading(false);
        setErrToken(true);
      }
    );
  };

  const handleReplayClick = () => {
    setCurrentQuestion( 0);
    setUserAnswers(Array(8).fill(null));
    setPhone('');
    setShowResult(false);
    setFormData({
      name: "",
      email: "",
      plotSize: "",
      buildSize: "",
      size: "",
      price: "",
      location	: "",
      property_status: "",
      planing_to_purchase: "",
    });
  };
  const handleNextClick = () => {
    if (userAnswers[currentQuestion] ) {
      if (userAnswers[0]) {
        handleSecondValueChange(userAnswers[0]);
      }
     
      if (Array.isArray(userAnswers[2]) && typeof userAnswers[2][0] !== 'undefined' ) {
          if ((userAnswers[0] === "Villa" || userAnswers[0] === "Townhouse") && currentQuestion === 2 && !(formData.plotSize && formData.buildSize)) {
            setError(true);
            return;
          }
        
      }
      
      setError(false);
      if (currentQuestion < 8) {
        setCurrentQuestion(currentQuestion + 1);

      } else {
        setShowResult(true);
      }
    } else {
      setError(true)
    }
  };

  const handlePrevClick = () => {
    
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [error]);

  return (
    <div className={`quiz-container flex justify-center items-center text-center w-full max-w-4xl bg-white rounded-3xl p-12 relative`}>
      {error &&

      <div className={`absolute top-6 left-1/2 transform -translate-x-1/2 p-6 rounded-3xl bg-[#f28f9c] z-10 text-black font-black transition-transform duration-800 ease-in-out ${error ? 'opacity-1' : 'opacity-0'}`}>{currentQuestion < 3 ? "select an option" : "fill the value"}
        </div>

      }
      {!showResult ? (
        <form className="question-container w-full max-w-2xl">
          <div className="mb-10 ">

          {/* Question 1 */}
          
          <div className={`question ${currentQuestion === 0 ? "active slide-in-right" : "hidden slide-out-left"}`}>
          <div className="flex flex-col justify-center items-center">
          
            <p className={`text-lg mb-6 font-black`}>what type of property do you want to purchase?</p>
            <div className="answer-options w-full max-w-xl grid gap-4 grid-cols-1 lg:grid-cols-2">
              <label className="flex items-center w-full">
                <input type="radio" checked={userAnswers[0] === "Apartment"} onChange={() => handleAnswerClick(0, "Apartment")} className="mr-2 hidden" />
                <div  className={` ${userAnswers[0] === "Apartment" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}>
                  {userAnswers[0] === "Apartment" &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  Apartment
                </div>
              </label>
              <label className="flex items-center w-full">
                <input type="radio" checked={userAnswers[0] === "Villa"} onChange={() => handleAnswerClick(0, "Villa")} className="mr-2 hidden" />
                <div className={` ${userAnswers[0] === "Villa" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}>
                  {userAnswers[0] === "Villa" &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  Villa
                </div>
              </label>
              <label className="flex items-center w-full">
                <input type="radio" checked={userAnswers[0] === "Penthouse"} onChange={() => handleAnswerClick(0, "Penthouse")} className="mr-2 hidden" />
                <div className={` ${userAnswers[0] === "Penthouse" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}>
                  {userAnswers[0] === "Penthouse" &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  Penthouse
                </div>
              </label>
              <label className="flex items-center w-full">
                <input type="radio" checked={userAnswers[0] === "Townhouse"} onChange={() => handleAnswerClick(0, "Townhouse")} className="mr-2 hidden" />
                <div className={` ${userAnswers[0] === "Townhouse" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}>
                  {userAnswers[0] === "Townhouse" &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  Townhouse
                </div>
              </label>
            </div>
          </div>
          </div>

          {/* Question 2 */}
          <div className={`question  ${currentQuestion === 1 ? "active slide-in-right" : "hidden slide-out-left"}`}>
            <div className="flex flex-col justify-center items-center">

            <p className={`text-lg mb-6 font-bold`}>How many bedrooms does your {userAnswers[0]} have?</p>
            { userAnswers[0] === "Apartment" &&

            <div className="answer-options w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <label className="flex items-center step1_radio w-full">
                <input type="radio" name="bed_rooms" value={0} checked={userAnswers[1] == 0} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                <div className={` ${userAnswers[1] == 0 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[1] == 0 &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  Studio
                </div>
              </label>
              <label className="flex items-center step1_radio w-full">
                <input type="radio" name="bed_rooms" value={1} checked={userAnswers[1] == 1} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                <div className={` ${userAnswers[1] == 1 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[1] == 1 &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  1BR
                </div>
              </label>
              <label className="flex items-center step1_radio w-full">
                <input type="radio" name="bed_rooms" value={2} checked={userAnswers[1] == 2} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                <div className={` ${userAnswers[1] == 2 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[1] == 2 &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  2BR
                </div>
              </label><label className="flex items-center step1_radio w-full">
                <input type="radio" name="bed_rooms" value={3} checked={userAnswers[1] == 3} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                <div className={` ${userAnswers[1] == 3 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[1] == 3 &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  3BR
                </div>
              </label><label className="flex items-center step1_radio w-full">
                <input type="radio" name="bed_rooms" value={4} checked={userAnswers[1] == 4} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                <div className={` ${userAnswers[1] == 4 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[1] == 4 &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  4BR
                </div>
              </label><label className="flex items-center step1_radio w-full">
                <input type="radio" name="bed_rooms" value={5} checked={userAnswers[1] == 5} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                <div className={` ${userAnswers[1] == 5 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[1] == 5 &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  5BR
                </div>
              </label><label className="flex items-center step1_radio w-full">
                <input type="radio" name="bed_rooms" value={6} checked={userAnswers[1] == 6} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                <div className={` ${userAnswers[1] == 6 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[1] == 6 &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  6BR
                </div>
              </label><label className="flex items-center step1_radio w-full">
                <input type="radio" name="bed_rooms" value={7} checked={userAnswers[1] == 7} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                <div className={` ${userAnswers[1] == 7 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                {userAnswers[1] == 7 &&
                  <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                    <HiCheck className="text-white"/>
                  </div> }
                  7BR
                </div>
              </label>
            </div>
            }
            { (userAnswers[0] == "Villa" || userAnswers[0] == "Townhouse") &&
              <div className="answer-options w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={2} checked={userAnswers[1] == 2} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 2 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 2 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    2BR
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={3} checked={userAnswers[1] == 3} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 3 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 3 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    3BR
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={4} checked={userAnswers[1] == 4} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 4 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 4 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    4BR
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={"4+"} checked={userAnswers[1] == "4+"} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == "4+" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == "4+" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    4BR+
                  </div>
                </label>
              </div>
            }
            { userAnswers[0] == "Penthouse" &&
              <div className="answer-options w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                <label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={2} checked={userAnswers[1] == 2} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 2 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 2 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    2BR
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={3} checked={userAnswers[1] == 3} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 3 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 3 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    3BR
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={4} checked={userAnswers[1] == 4} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 4 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 4 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    4BR
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={5} checked={userAnswers[1] == 5} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 5 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 5 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    5BR
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="bed_rooms" value={6} checked={userAnswers[1] == 6} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 6 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 6 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    6BR
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio"  name="bed_rooms" value={7} checked={userAnswers[1] == 7} onChange={(event) => handleFormDataChange(1, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[1] == 7 ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[1] == 7 &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    7BR
                  </div>
                </label>
              </div>
            }
            </div>
          </div>

          {/* Question 3
          <div className={`question  ${currentQuestion === 2 ? "active slide-in-right" : "hidden slide-out-left"}`}>

            <div className="flex flex-col justify-center items-center">

              <p className={`text-lg mb-6 font-black`}>What is the size of your property?</p>
              { 
                (userAnswers[0] === "Villa" || userAnswers[0] === "Townhouse") &&
                <div className="answer-options max-w-sm w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <input type="text" placeholder="Plot Size" name="plotSize" value={formData.plotSize} onChange={(event) => handleFormDataChange(0, event)} className="focus:border-black border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full mb-6" />
                  <input type="text" placeholder="Build Size" name="buildSize" value={formData.buildSize} onChange={(event) => handleFormDataChange(1, event)} className="focus:border-black border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full mb-6" />
                </div>
              }
              { 
              (userAnswers[0] === "Penthouse" || userAnswers[0] === "Apartment") &&
              <div className="answer-options w-full max-w-sm flex items-center">
                <input type="text" placeholder="Plot Size" name="size" value={formData.size} onChange={(event) => handleFormDataChange(2, event)} className="focus:border-black border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full mb-6" />
              </div>
              }
            </div>
          </div> */}

          {/* Question 4 */}
          <div className={`question  ${currentQuestion === 2 ? "active slide-in-right" : "hidden slide-out-left"}`}>
            <div className="flex flex-col justify-center items-center">

            <p className={`text-lg mb-6 font-bold`}>What kind of property your looking?</p>
                <div className="answer-options w-full max-w-md space-x-0 space-y-3 lg:space-x-3 lg:space-y-0 flex lg:flex-row flex-col  justify-center items-center">
                <label className="flex items-center step1_radio w-full">
                  <input type="radio" name="property_status" value={"Ready to Move in"} checked={userAnswers[2] === "Ready to Move in"} onChange={(event) => handleFormDataChange(2, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[2] === "Ready to Move in" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[2] === "Ready to Move in" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    Ready to Move in
                  </div>
                </label>
                <label className="flex items-center  w-full">
                  <input type="radio" name="property_status" value={'Off Plan'} checked={userAnswers[2] === "Off Plan"} onChange={(event) => handleFormDataChange(2, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[2] === "Off Plan" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}>
                    {userAnswers[2] === "Off Plan" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    Off Plan
                  </div>
                </label>
              </div>
            
            </div>
          </div>

          {/* Question 5 */}
          <div className={`question ${currentQuestion === 3 ? "active slide-in-right" : "hidden slide-out-left"}`}>
            <div className="flex flex-col justify-center items-center">

              <p className={`text-lg mb-6 font-black`}>What is the prefered location?</p>
              <div className="answer-options w-full max-w-xl flex items-center">

              <input type="text" placeholder="Enter Address" name="location" value={formData.location} onChange={(event) => handleFormDataChange(3, event)} className="focus:border-black border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full mb-6" />
              </div>
            </div>
          </div>

          {/* Question 7 */}
          <div className={`question ${currentQuestion === 4 ? "active slide-in-right" : "hidden slide-out-left"}`}>
            <div className="flex flex-col justify-center items-center">

              <p className={`text-lg mb-6 font-black`}>What is the your budget?</p>
              <div className="answer-options w-full max-w-xl flex items-center">

              <input type="number" placeholder="Enter Price" name="price" value={formData.price} onChange={(event) => handleFormDataChange(4, event)} className="focus:border-black border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full mb-6" />
              </div>
            </div>
          </div>
          <div className={`question  ${currentQuestion === 5 ? "active slide-in-right" : "hidden slide-out-left"}`}>
            <div className="flex flex-col justify-center items-center">

            <p className={`text-lg mb-6 font-bold`}>Payment Method?</p>
                <div className="answer-options w-full max-w-sm space-x-0 space-y-3 lg:space-x-3 lg:space-y-0 flex lg:flex-row flex-col  justify-center items-center">
                <label className="flex items-center step1_radio w-full">
                  <input type="radio" name="payment_type" value={"Mortage"} checked={userAnswers[5] === "Mortage"} onChange={(event) => handleFormDataChange(5, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[5] === "Mortage" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[5] === "Mortage" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    Mortage
                  </div>
                </label>
                <label className="flex items-center  w-full">
                  <input type="radio" name="payment_type" value={"Cash"} checked={userAnswers[5] === "Cash"} onChange={(event) => handleFormDataChange(5, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[5] === "Cash" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative  w-full`}>
                    {userAnswers[5] === "Cash" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    Cash
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className={`question  ${currentQuestion === 6 ? "active slide-in-right" : "hidden slide-out-left"}`}>
            <div className="flex flex-col justify-center items-center">

            <p className={`text-lg mb-6 font-bold`}>when your planing to purchase?</p>
            <div className="answer-options w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <label className="flex items-center step1_radio w-full">
                  <input type="radio" name="planing_to_purchase" value={"Immediate"} checked={userAnswers[6] === "Immediate"} onChange={(event) => handleFormDataChange(6, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[6] === "Immediate" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[6] === "Immediate" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    Immediate
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="planing_to_purchase" value={"3 to 6 months"} checked={userAnswers[6] === "3 to 6 months"} onChange={(event) => handleFormDataChange(6, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[6] === "3 to 6 months" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[6] === "3 to 6 months" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    3 to 6 months
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="planing_to_purchase" value={"6 to 12 months"} checked={userAnswers[6] === "6 to 12 months"} onChange={(event) => handleFormDataChange(6, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[6] === "6 to 12 months" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[6] === "6 to 12 months" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    6 to 12 months
                  </div>
                </label><label className="flex items-center step1_radio w-full">
                  <input type="radio" name="planing_to_purchase" value={"more than 12 months"} checked={userAnswers[6] === "more than 12 months"} onChange={(event) => handleFormDataChange(6, event)} className="mr-2 hidden" />
                  <div className={` ${userAnswers[6] === "more than 12 months" ? 'border-black shadow-lg' : 'border-[#b3b3b3]' } border-2 px-10 py-2 rounded-full bg-white relative w-full`}>
                  {userAnswers[6] === "more than 12 months" &&
                    <div className={`text-black absolute top-1/2 transform left-2 -translate-y-1/2 rounded-full bg-black w-6 h-6 flex justify-center items-center  border-double`}  >
                      <HiCheck className="text-white"/>
                    </div> }
                    more than 12 months
                  </div>
                </label>
              </div>
            </div>
          </div>

          {/* Question 8 */}
          <div className={`question ${currentQuestion === 7 ? "active slide-in-right" : "hidden slide-out-left"}`}>
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-xl font-medium mb-4 font-black">One last step</h2>
            
            <div className="answer-options grid myGrid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4">
            <div className="block-div">
            <input type="text" placeholder="Name" name="name" value={formData.name} onChange={(event) => handleFormDataChange(7, event)} className="border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full focus:border-black" />
            {nameError && ( <p className="text-sm text-red">{" "} Please enter your name</p>)}
            </div>

            <div className="block-div">
            <input type="email" placeholder="Email" name="email" value={formData.email} onChange={(event) => handleFormDataChange(7, event)} className="border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full focus:border-black" />
            {emailError && ( <p className="text-sm text-red"> {" "} Enter a valid Email </p> )}
            </div>

            <div className="block-div end">
              <PhoneInput
                placeholder="Enter phone number"
                country={"ae"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                className="border-[#b3b3b3] border-2 bg-gray-100 p-2 rounded-full w-full focus:border-black col-span-2"
              />
              {phoneError && (<p className=" text-red text-sm"> Please enter valid phone number </p> )}
              </div>

              </div>
          </div>
          </div>
          </div>

          <div className="flex justify-center">
          <div className="flex flex-col lg:flex-row justify-center items-center flex-col-reverse space-y-reverse space-y-4 space-x-0 lg:space-y-0 lg:space-x-3 w-full max-w-lg">

            {currentQuestion > 0 && (
              <button type="button" onClick={handlePrevClick} className="next-prev bg-black text-white">
                Previous
              </button>
            )}
            
            {currentQuestion < 7 && (
              <button type="button" onClick={handleNextClick} className={`next-prev bg-[#D4B071] px-4 py-2 mr-2`}>
                Next
              </button>
            )}
            {currentQuestion === 7 && (
              <button   disabled={sellLoading} type="button" onClick={handleSubmitClick}  className={`${userAnswers.includes(null) || !formData.address || !formData.name || !formData.email || !formData.phone ? "cursor-not-allowed" : ""} next-prev bg-[#d4b071]`}>
              {sellLoading ? (
                <>
                  <span className="inline-flex items-center justify-center h-12 px-6">
                    <span className="lds-dual-ring"></span>
                  </span>
                </>
              ) : (
                "Submit"
              )}
              </button>
            )}
          </div>
          </div>
          {errToken && (
              <p className="text-red text-sm error-token-center">
                please verify you are a human
              </p>
             )}
        </form>
      ) : (
        <div className="result-container">
          <h2 className="text-xl font-medium mb-4">Thank you for submitting your request!</h2>
          <button onClick={handleReplayClick} className="bg-blue-500 text-black px-4 py-2 rounded-md hover:bg-blue-600">
            Submit again
          </button>
        </div>
      )}
    </div>
  );
};

export default FormOptionsBuy;