import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { config, url } from "../Common/Common";
import Layout from "../Components/Layout";
import Loading from "../Components/UI/Loading";
import Nothing from "../Components/UI/Nothing";
import Title from "../Components/UI/Title";
import border from "../img/border.png";
import Hero from "../img/Developers_hero.jpg";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Skeleton from "react-loading-skeleton";

const Developers = () => {
  const inputRef = useRef(null);

  const [developers, setDevelopers] = useState([]);
  const [topDevelopers, setTopDevelopers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(12);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchDeveloper, setSearchDeveloper] = useState([]);

  useEffect(() => {
    axios
      .get(url + `developers`)
      .then(function (response) {
        // handle success
        setDevelopers(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });

    axios
      .get(url + `developers?sort=order`)
      .then(function (response) {
        // handle success
        setTopDevelopers(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  const handleInputChange = (event) => {
    setIsInputFocused(true);

    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      setHasSearched(true);
      const searchList = developers.filter((item) => {
        return item.name.toLowerCase().includes(query.toLowerCase());
      });
      setSearchDeveloper(searchList);
      setTotal(searchList.length);
    } else {
      setHasSearched(false);
      setDevelopers(developers); // No need to fetch data again
      setTotal(developers.length); // Update the total based on the original list
    }
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    if (searchQuery === "") {
      setIsInputFocused(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      //  areaCardsRef.current.scrollIntoView({ behavior: "smooth" });
      inputRef.current.blur();
    }
  };

  //   //Search list of objects
  //   const handleSearch = (event) => {
  //     const query = event.target.value;
  //     setSearchQuery(query);

  //     if (query) {
  //       const searchList = developers.filter((item) => {
  //         return query.toLowerCase() === "" ? item : item.name.toLowerCase().includes(query.toLowerCase());
  //       });
  //       setDevelopers(searchList);
  //       setTotal(searchList.length);

  //       if (searchList.length <= 0) {
  //         setLoading(true);
  //       }
  //     } else {
  //       setDevelopers(query);
  //     }
  //   };

  const styles = {
    backgroundImage: `linear-gradient(to right bottom, rgb(0 0 0 / 20%), rgba(0 0 0 / 20%)),url("${Hero}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    height: "60vh",
  };

  return (
    <Layout>
      <div className="container mx-auto px-4 md:px-0 mt-20 md:mt-28">
        {/* <Title title="Developers" /> */}
        <div className="hero1 w-full rounded-[3rem] " style={styles}>
          <div className="h-full relative flex flex-col justify-center items-center px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-0 lg:py-20">
            <h2 className="max-w-lg mb-6 text-center md:text-left text-5xl font-bold tracking-tight text-white md:text-6xl leading-none textShadowBlack">{t("Developers")}</h2>
            <div className="w-full max-w-2xl flex items-start justify-center">
              <input
                type="text"
                className="text-center py-4 rounded-full opacity-80 px-4 border border-black/10 w-full md:w-8/12 lg:w-6/12 placeholder-black placeholder-opacity-75 placeholder-black text-lg"
                // placeholder="Search Location"
                // value={searchQuery}
                // onChange={handleSearch}
                placeholder={isInputFocused ? "" : "Search"}
                value={searchQuery}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                ref={inputRef}
              />
            </div>
          </div>
        </div>
        <div className={`topDevelopers hidden md:flex flex-col justify-center items-center space-x-6 px-24 pb-12 mt-14 ${isInputFocused ? "invisible opacity-0 transform duration-500" : "opacity-100 transform duration-500 delay-100"}`}>
          {/* {isLoading ? (
            // Skeleton loading state
            <div className="aspect-square relative flex rounded-full  flex-col justify-center w-full overflow-hidden ">
              <div className="scale-[1.1] brightness-50 object-cover w-full h-56 cursor-pointer transition-all duration-700 hover:shadow-lg">
                <Skeleton height={56} />
              </div>
              <div className="absolute left-1/2 transform -translate-x-1/2 text-md xl:text-2xl font-black text-white font-Butler text-center">
                <Skeleton width={120} />
              </div>
              Loading....
            </div>
          ) : ( */}
          <h1 className="font-Butler w-full font-bold text-4xl text-center pb-14">Leading Developers in Dubai</h1>
          <div className="w-full flex flex-row">
            {
              // Actual data rendering

              topDevelopers.slice(0, 6).map((item, index) => (
                <Link key={index} to={`/Developer/${item.slug}`} className="aspect-square relative flex flex-col space-x-6 justify-center w-full overflow-hidden ">
                  <img id="DeveloperSwiperImg" className={`border-black ${index == 5 ? "" : "border-r"}`} src={item.icon} alt={item.name} />
                </Link>
              ))
            }
          </div>
          {/* )} */}
        </div>
        {/* <h3 className="font-bold text-2xl text-center md:text-4xl w-full my-8">
          {" "}
          <span className="border-b-2 border-[#CDAC62] text-black px-4 md:px-4"> {t("Developers")} </span>{" "}
        </h3>
        <div className="w-full flex items-start justify-center">
          <input type="text" className="text-center mb-4 py-2 px-4 border border-black/10 w-full md:w-8/12 lg:w-6/12" placeholder="Search Devlopers" value={searchQuery} onChange={handleSearch} />
        </div> */}

        <div className={`DevelopersCards relative  mt-12 ${isInputFocused && window.innerWidth > 1024 ? " transition-all duration-500 transform -translate-y-96" : "transition-all duration-500 transform translate-y-0 delay-100"} `}>
          <div className="grid gap-10 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-4 grid-cols-1 md:px-32 px-3">
            {isLoading ? (
              <div className="w-full flex justify-center items-center">
                <h1>Loading...</h1>
              </div>
            ) : (
              // <div className='absolute top-0 left-0 h-screen w-screen bg-white z-40 flex items-center justify-center'><Loading /></div>
              <>
                {searchDeveloper.length === 0 && hasSearched ? (
                  <div className="w-full flex justify-center items-center">
                    <h1>No developers found</h1>
                  </div>
                ) : (
                  <>
                    {(hasSearched ? searchDeveloper : developers).slice(0, `${limit}`).map((developer, index) => (
                      <div className="aspect-square max-w-xs w-full my-1 px-1 relative mx-auto" key={index}>
                        <Link to={`/Developer/${developer.slug}`} className="relative overflow-hidden">
                          <img src={developer.icon} alt={developer.name} className="object-contain w-full h-full rounded-3xl shadow-xl md:p-6 p-4" />
                        </Link>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
          <div className={`w-full flex justify-center items-center ${searchDeveloper.length === 0 && hasSearched ? "hidden" : ""}`}>
            {total > 12 && (
              <div className="flex gap-4 mt-8">
                {total > 12 && total > limit && (
                  <button
                    onClick={() => setLimit(limit + 12)}
                    className="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl"
                  >
                    {t("Show more")}
                  </button>
                )}

                {limit > 12 && (
                  <button
                    onClick={() => setLimit(limit - 12)}
                    className="flex mx-auto relative items-center justify-center bg-white border-2 border-[#D4B071]  text-black font-bold px-2 xl:px-6 py-2 rounded-full drop-shadow-lg hover:drop-shadow-2xl"
                  >
                    {t("Show Less")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Developers;
