import { Link } from "react-router-dom";

const ProjectList = ({ project }) => {

  return (
    <Link to={`/Projects/${project.slug}`} class="inline-block overflow-hidden bg-white rounded ">
      <div class="flex flex-col h-full">
        <div className=" relative">
          <img src={project.image} class="object-cover rounded-3xl w-full h-64 lg:h-80" alt="" />
          {project.other_payment_plan ? (
            <div className="absolute top-2 left-4">
              <p className="bg-[#D4B071] px-4 py-2 rounded-full font-bold">{project.other_payment_plan} Payment Plan</p>
            </div>
          ) : (
            <></>
          )}
          {project.project_type && (
            <div className="absolute bottom-14 left-4">
              <p className=" bg-white px-4 py-2 rounded-full font-bold">{project.project_type.name}</p>
            </div>
          )}
        </div>

        <div class="flex-grow border-2 border-[#D4B071] rounded-3xl mx-3 -mt-12 bg-white z-10">
          <div class="p-5 text-left">
            {project.name && <h3 class="mb-2 font-bold leading-5 text-2xl md:text-3xl">{project.name}</h3>}
            {project.developer_name && <p class="text-sm text-gray-500">Developer</p>}
            {project.developer_name && <h3 class="mb-2 font-bold leading-5 text-lg md:text-xl">{project.developer_name}</h3>}
            {project.address && <p class="text-sm text-gray-500">Location</p>}
            {project.address && <h3 class="mb-2 font-bold leading-5 text-lg md:text-xl">{project.address}</h3>}
            <div className="flex flex-wrap items-center justify-between">
              {/* <p class="text-sm text-gray-500">Payment Plan</p>
<h3 class="mb-2 font-bold leading-5 text-lg md:text-xl">
{`${Number(project.on_booking) + Number(project.during_construction)}/${project.on_completion}`}
</h3> */}
              {project.post_handover ? (
                <div>
                  <p class="text-sm text-gray-500">Post Hand Over</p>
                  <h3 class="mb-2 font-bold leading-5 text-lg lg:text-xl">{project.post_handover}% <span className="text-sm lg:text-md">in {project.post_handover_period} {project.post_handover_type}</span></h3>
                </div>
              ) : (
                <></>
              )}
              {project.delivery_date && (
                <div>
                  <p class="text-sm text-gray-500">Completion Year</p>
                  <h3 class="mb-2 font-bold leading-5 text-lg md:text-xl">{project.delivery_date.substring(0, project.delivery_date.indexOf("-"))}</h3>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <LazyLoadImage src={project.image} alt="" className=' h-full w-full object-cover rounded-lg' style={{ height: '25rem' }} effect='blur'/> */}
      </div>

      {/* <LazyLoadImage src={project.image} alt="" className=' h-full w-full object-cover rounded-lg' style={{ height: '25rem' }} effect='blur'/> */}
    </Link>
  );
};

export default ProjectList;
