import React from "react";
import Layout from "../Components/Layout";
import Lottie from "react-lottie";
import nothing from "../Components/Lottie/NotFound.json";
import { Link } from "react-router-dom";

const Notfound = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: nothing,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Layout>
      <div className="container mx-auto">
        <div className="row flex items-center justify-center text-center mt-32">
          <div className="w-full sm:w-1/2 md:w-1/3 ">
            <Lottie options={defaultOptions} />
            <h3 className="font-bold text-xl">Page in not found</h3>
            <Link to="/" className="px-4 py-2 bg-mainColor text-white my-4 flex justify-center items-center  rounded-lg">
              Home
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Notfound;
