import React from "react";

const Pragraph = (props) => {
  return (
    <div className="item grid grid-col-1 items-center  md:items-start justify-start flex-wrap ltr:text-left rtl:text-right w-full mb-4 md:mb-8 ">
      <div className="data flex justify-start items-center w-full">
        <h3 className={`w-12 h-12 bg-[#D4B071] rounded-lg flex items-center justify-center rtl:ml-4 ltr:mr-4 shadow-xl font-bold text-xl md:text-3xl text-black `}>
          <p className="w-full text-center ">{props.index + 1}</p>
        </h3>
        <h3 className="font-bold mb-2 w-full text-2xl">{props.title}</h3>
      </div>
      <p className="leading-loose  md:ml-[3.8rem]">{props.content}</p>
    </div>
  );
};

export default Pragraph;
