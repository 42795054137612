import Layout from "../Components/Layout";

const SiteMap = () => {
  const containerStyle = {
    margin: "auto",
    listStyle: "circle",
  };
  return (
    <Layout>
      <section className="container mx-auto mt-20 md:mt-28">
        <h1 className="text-center md:text-2xl text-5xl font-black capitalize">Sitemap</h1>
        <ul className="text-2xl font-medium" style={containerStyle}>
          <li>
            <a href="/property-for-sale-in-dubai">Buy</a>
            <ol className="pl-14" style={containerStyle}>
              <li>
                <a href="/apartments-for-sale-dubai-uae">Apartment for sale</a>
              </li>
              <li>
                <a href="/villa-for-sale-in-dubai">Villa for sale</a>
              </li>
            </ol>
          </li>
        </ul>
        <ul className="text-2xl font-medium" style={containerStyle}>
          <li>
            <a href="/property-for-rent-in-dubai">Rent</a>
            <ol className="pl-14" style={containerStyle}>
              <li>
                <a href="/apartments-for-rent-in-dubai">Apartment for rent</a>
              </li>
              <li>
                <a href="/villa-for-rent-in-dubai">Villa for rent</a>
              </li>
              <li>
                <a href="/hotel-apartments-for-rent-in-dubai">Hotel Apartments for rent</a>
              </li>
              <li>
                <a href="/townhouses-for-rent-in-dubai">Townhouses for rent</a>
              </li>
            </ol>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default SiteMap;
