import axios from "axios";
import React, { useReducer, useRef, useState, useEffect } from "react";
import { BsTelephoneForward, BsPhoneVibrate } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import { recaptcha__secret_key, recaptcha_key, url, chechPhone } from "../../Common/Common";
import Button from "../UI/Button";
import SuccessAlert from "../UI/SuccessAlert";
import ErrorAlert from "../UI/ErrorAlert";
import { t } from "i18next";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import PhoneInput from "react-phone-input-2";
import ReactRecaptcha3 from "react-google-recaptcha3";
import contact from "./contact.css";

const Support = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("971");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState();

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key);
  }, []);


  const contactHandler = (e) => {
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    setMessageError(false);
    e.preventDefault();

    if (name.trim() == "") {
      setNameError(true);
      return;
    }
    if (email.trim() == "" || !email.includes("@")) {
      setEmailError(true);
      return;
    }
    if(!chechPhone(phone)){
      setPhoneError(true);
        return;
    }
    if (message.trim() == "") {
      setMessageError(true);
      return;
    }

    setLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + 'verifyRecaptcha',
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            axios
              .post(url + "contact-us", {
                name: name,
                email: email,
                phone: phone,
                message: message,
                source: `contact us`,
              })
              .then((response) => {
                setName("");
                setEmail("");
                setPhone("971");
                setMessage("");
                Swal.fire({
                  timer: 3000,
                  title: `${t("Success")}`,
                  icon: "success",
                  html: `${t("Your message has been sent")}`,
                  confirmButtonText: `${t("ok")}`,
                  showCloseButton: true,
                  timerProgressBar: true,
                });
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                setErrToken(true);
              });
          })
          .catch(() => {
            setLoading(false);
            setErrToken(true);
          });
      },
      (error) => {
        setLoading(false);
        setErrToken(true);
      }
    );
  };

  return (
    <div className="SupportSection rounded-xl md:w-1/2 w-full">
      {success && (
        <div className="fixed bottom-4 left-4 z-50">
          <SuccessAlert success={success} />
        </div>
      )}
      {/* {error && <div className='fixed bottom-4 left-4 z-50'><ErrorAlert error={error} /></div>} */}
      <div className="px-4 relative">
        <div className="row flex flex-wrap justify-between items-center py-16 ">
          {/* <div className="w-full md:w-1/3  lg:w-2/4 ">
                              <h3 className='font-medium text-2xl mb-4'>{t('Contact Details')}</h3>

                              <ul className=' leading-10 font-medium text-lg md:text-xl'>

                                   <a href='tel:971505720423' className='flex items-center mb-2' target='_blank' rel="noreferrer">
                                        <BsPhoneVibrate className='text-mainColor' />
                                        <span className='mx-4 ltr'>+971 50 572 0423</span>
                                   </a>
                                   <a href='tel:97146052400' className='flex items-center mb-2' target='_blank' rel="noreferrer">
                                        <BsTelephoneForward className='text-mainColor' />
                                        <span className='mx-4 ltr'>+971 4 605 2400</span>
                                   </a>
                                   <a href='https://www.google.com/maps/place/Key+Max+Real+Estate+Brokers/@25.1941874,55.2609457,15z/data=!4m5!3m4!1s0x0:0x7197e1467d539d59!8m2!3d25.1941874!4d55.2609457' target='_blank' rel="noreferrer" className='flex items-center mb-2'>
                                        <GoLocation className='text-mainColor' />
                                        <span className='mx-4'>{t('Office 107 - Sheikh Zayed Rd - Rawdat Al Wasl - Dubai')}</span>
                                   </a>
                              </ul>
                         </div> */}
          <div className="w-full flex flex-col">
            <p className="text-xl md:text-4xl font-bold mb-4 text-center">
              Submit your enquiry
            </p>
            <form>
              <div className="form-group text-right px-5 md:px-10">
                <div className="flex flex-col items-center justify-between ">
                  <div className="w-full ">
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white rounded-full drop-shadow-md appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      type="text"
                      pattern="^\w+([\w ]*\w)*$"
                      placeholder={t("Your Name")}
                      name=""
                    />
                    {nameError && (
                      <p className=" text-red text-sm">
                        Please enter your name
                      </p>
                    )}
                  </div>

                  <div className="w-full mb-2 ">
                    <input
                      onCut={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      onBlur={() => console.log("BLur")}
                      className={`flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white rounded-full drop-shadow-md appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline ${
                        emailError ? "border-red" : " border-mainColor"
                      }`}
                      onFocus={() => setEmailError(false)}
                      type="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                      placeholder={t("Your Email")}
                      name="Email"
                    />
                    {emailError && (
                      <p className=" text-red text-sm">
                        Please enter your email
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-2 ">
                  {/* <input
                    onFocus={() => setPhoneError(false)}
                    onChange={(e) => setPhone(e.target.value)}
                    className={`flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white rounded-full drop-shadow-md appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline ${
                      phoneError ? " border-red" : " border-mainColor"
                    }`}
                    value={phone}
                    type="tel"
                    pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                    maxLength="12"
                    placeholder={t("Your Phone")}
                    name="Phone"
                    required
                  /> */}
                  <PhoneInput
                    placeholder="Enter phone number"
                    country={"ae"}
                    value={phone}
                    onChange={(value) => setPhone(value)}
                    className={`flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white rounded-full drop-shadow-md appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline ${
                      phoneError ? " border-red" : " border-mainColor"
                    }`}
                  />
                  {phoneError && (<p className=" text-red text-sm"> Please enter valid phone number </p> )}
                </div>
                
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  className="flex-grow w-full px-4 transition duration-200 bg-white rounded-2xl drop-shadow-md appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline "
                  name=""
                  placeholder={t("Message")}
                  id=""
                 
                ></textarea>
                <div className="w-full flex  mb-4">
                  {messageError && (
                    <p className=" text-red text-sm">
                      Please enter your message
                    </p>
                  )}
                </div>
                <div className="w-full flex justify-center">
                  {errToken && (
                    <p className="text-red text-sm error-token">
                      please verify you are a human
                    </p>
                  )}
                </div>
                <div className="w-full flex justify-center">
                  <button
                    disabled={loading}
                    className=" px-6 py-3 text-lg md:text-xl font-bold bg-[#D4B071] text-black rounded-full text-center "
                    onClick={contactHandler}
                  >
                    {loading ? (
                      <>
                        <span className="inline-flex items-center justify-center h-6 px-6">
                          <span className="lds-dual-ring"></span>
                        </span>
                      </>
                    ) : (
                      t("Send Message")
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
