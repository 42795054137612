import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { t } from "i18next";
import { Fragment, useState } from "react";
import bitcoin from "../../img/bitcoin-2.svg";
import cheque from "../../img/cheque.svg";
import creditCard from "../../img/credit-card.svg";
import cash from "../../img/dollar.svg";
import bank from "../../img/bank.svg";
import SwiperThumb from "../Layout/SwiperThumb";
import "./projectDetails.scss";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import React from "react";
// import ReactModal from 'react-modal';
import ModalPopup from "../ModalPopup/ModalPopup";
import ProjectForm from "../Layout/ProjectForm";
import ProjectDateForm from "../Sections/ProjectDateForm";
import Modal from "../Modal/Modal";

const containerStyle = {
  width: "100%",
  height: "inherit",
};

const MAP_OPTIONS = {
  zoomControl: true,
  gestureHandling: "cooperative",
  streetViewControl: false,
};

const MAP_THEME = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        color: "#878787",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f9f5ed",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#aee0f4",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

// var MoreAvailable = {
//   ltr: true,
//   dots: false,
//   infinite: false,
//   autoPlay: true,
//   speed: 500,
//   slidesToShow: 1,
//   arrows: false,
//   rows: 1,
//   centerPadding: "20px",
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 3,
//         rows: 1,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         dots: true,
//         infinite: true,
//         autoPlay: true,
//         speed: 500,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

const ProjectDetails = (props) => {
  const { Gallery } = props;

  // Calculate the number of columns based on the length of props.Payments
  const numColumns = Math.min(props.Payments.length, 4);

  const [activeTap, setActiveTap] = useState(`${props.Starting[0].title} ${props.Starting[0].type}`);
  const [activeObject, setActiveObject] = useState(props.Starting[0]);
  const [modal, setModal] = useState(false);

  const slides = Gallery.map((el, index) => ({
    src: el.image,
    key: index,
  }));

  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white mt-16 dark:bg-gray-900 relative">
      <div className="">
        <div>
          {modal && (
            <>
              <ModalPopup youtube={props.youtubeVideo} title={t("")} btnText={t("")} closeModal={() => setModal(false)} />
            </>
          )}
        </div>
        <section>
          <div className="relative py-4 px-4 pb-36">
            <img src={props.Gallery[0].image} className="absolute inset-0 object-cover w-full h-full" alt="" />
            <div class="projectHero relative md:px-4 px-0 h-full lg:h-screen">
              <div class="relative bg-opacity-75 bg-deep-purple-accent-700 h-full">
                <div class="relative mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl h-full">
                  <div class="flex flex-col space-y-7 md:space-y-0 items-center justify-between lg:flex-row w-full h-full">
                    <div class="w-full lg:w-4/12 flex flex-col space-y-2 p-5 items-start justify-between py-16 px-6 lg:px-6 lg:py-12 bg-white/70 rounded-3xl">
                      <div class="w-full">
                        <h1 class="w-full font-Butler mt-4 mx-2 text-2xl xl:text-4xl font-extrabold shadow-black rounded-md inline-block drop-shadow-lg text-black text-center rtl:md:text-right w-full text-[calc(20px+6vw)] md:text-[calc(20px+4vw)] lg:text-[calc(20px+1vw)]">
                          {props.project_name}
                        </h1>
                        <h3 class="mb-4 mx-2 text-lg lg:text-xl drop-shadow-md shadow-black/20 font-black text-black tracking-wide font-semibold text-center rtl:md:text-right w-full">Located at {props.location.address}</h3>
                      </div>

                      <div class="grid row-gap-5  lg:grid-cols-2 w-full flex justify-center items-center">
                        <div class="w-full sm:mx-auto sm:text-center p-2 md:p-5 flex flex-col justify-center items-center text-black broder-none lg:border-r-2 border-gray-800">
                          {props.Payment.payment_bref ? 
                          <>
                          <p class="grow mb-3 text-2xl md:text-3xl font-black leading-5 font-Butler">{props.Payment.payment_bref}</p>
                          <p className="font-black text-lg md:text-xl text-center">
                            Payment <br />
                            Plan
                          </p>
                          </>
                        
                      :
                      <>
                          <p class="grow mb-3 text-2xl md:text-3xl font-black leading-5 font-Butler">{props.Banner.developer_name}</p>
                          <p className="font-black text-lg md:text-xl text-center">
                            Developer
                          </p>
                          </>
                      
                      }
                          
                        </div>
                        <div class="w-full sm:mx-auto sm:text-center p-5 flex flex-col justify-center items-center text-black">
                          <p class="grow mb-3 text-2xl md:text-3xl font-black leading-5 font-Butler">{props.Full.delivery_date && props.Full.delivery_date.substring(0, props.Full.delivery_date.indexOf("-"))}</p>
                          <p className="font-black text-lg md:text-xl text-center">
                            Completion <br />
                            Year
                          </p>
                        </div>
                        {/* <div class="w-full sm:mx-auto sm:text-center bg-white p-5 rounded-2xl flex flex-col justify-center items-center">
                            <h6 class="grow mb-3 text-3xl font-bold leading-5 font-Butler">2025</h6>
                            <p className="font-black">Completion</p>
                          </div> */}
                      </div>

                      <div class="flex flex-col items-center lg:items-start col-start-1 col-end-3 lg:col-span-1 w-full px-5 text-black">
                        <p class="text-lg lg:text-2xl font-bold text-center drop-shadow-lg w-full">Starting Price</p>
                        <p class="text-3xl lg:text-4xl font-black text-center font-Butler w-full">{(props.About.starting_price_formatted === '0' ) ? 'Update Soon' : `AED ${props.About.starting_price_formatted}` } </p>
                      </div>
                    </div>
                    {/* <!-- <div><img class="-mt-24" src="./images/building.webp" /></div> --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ProjectDateForm pojectName={props.project_name} />

        <section id="AboutSection" className="mt-8">
          <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
              <h2 className="max-w-lg mb-4 font-sans text-center text-4xl md:text-6xl font-black leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto font-Butler">{props.Banner.project_name}</h2>
              <p className="text-xl md:text-2xl font-semibold text-center" dangerouslySetInnerHTML={{ __html: props.About.about }} />
            </div>
            <div className="grid gap-16 row-gap-5 md:row-gap-10 lg:grid-cols-3">
              {props.Banner.developer_name && (
                <div className="bg-white flex flex-col justify-end w-full">
                  <div className="flex items-center justify-center mb-2">
                    <p className="text-3xl font-extrabold text-center lg:text-4xl font-Butler">{props.Banner.developer_name}</p>
                  </div>
                  <p className="text-xl md:text-2xl text-gray-200 text-center  border-t-4 border-[#D4B071]">Developer</p>
                </div>
              )}
              <div className="bg-white flex flex-col justify-end w-full">
                <div className="flex items-center justify-center mb-2">
                  <p className="text-3xl font-extrabold text-center lg:text-4xl font-Butler">{props.location.address}</p>
                </div>
                <p className="text-xl md:text-2xl text-gray-200 text-center  border-t-4 border-[#D4B071]">Location</p>
              </div>
              <div className="bg-white flex flex-col justify-end w-full">
                <div className="flex items-between justify-center mb-2 ">
                  <p className="text-3xl font-extrabold text-center lg:text-4xl font-Butler">{props.Full.delivery_date && props.Full.delivery_date.substring(0, props.Full.delivery_date.indexOf("-"))}</p>
                </div>
                <p className="text-xl md:text-2xl text-gray-200 text-center border-t-4 border-[#D4B071]">Completion Year</p>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="VideoSection" className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="relative flex flex-col-reverse px-0 md:px-4 py-0 mx-auto lg:block lg:flex-col lg:py-32 md:px-8 sm:max-w-xl md:max-w-full h-full">
            <div className="hidden md:flex justify-end max-w-xl mx-auto lg:max-w-screen-xl relative h-[33rem]">
              <div className="mb-0 lg:mb-16 lg:pr-5 lg:max-w-lg lg:mb-0 mr-0 ltr:lg:mr-[-7rem] rtl:lg:ml-[-7rem]">
                <div className="max-w-full mb-6 ml-0 mt-5 lg:mt-0 lg:ml-24 bg-black text-white p-10 border-4 border-[#D4B071] rounded-[3rem] relative z-10">
                  <h2 className="max-w-lg text-xl font-bold tracking-wide md:leading-[3.5rem] text-gray-900 md:text-3xl leading-none">{props.Banner.banner_bref}</h2>
                </div>
              </div>
            </div>
            <div className="relative z-0 mx-0 md:-mx-24 lg:pt-6 lg:pb-2 lg:pr-8 xl:pr-0 lg:absolute lg:justify-end lg:bottom-0  ltr:lg:-left-[30%] rtl:lg:-right-[30%]">
              <img src={props.Gallery[1].image} className="rounded-2xl md:rounded-[3rem] object-cover object-right w-full h-auto lg:w-auto lg:h-full" alt="" />
              <div className="w-full h-full">
                <img
                  onClick={() => {
                    setModal(true);
                  }}
                  src="https://anya.keymaxrealestate.com/images/play.webp"
                  className="z-50 hover:cursor-pointer w-24 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section> */}

        <section className="Floor_Plans">
          <div className="grid md:grid-cols-12 gird-cols-1 w-full">
            <div className="md:col-span-8 relative">
              <img src={props.Gallery[1].image} className="aspect-video rounded-r-2xl md:rounded-r-[3rem] object-cover object-right w-full h-auto lg:h-full " alt="" />
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                {/* <img
                  onClick={() => {
                    setModal(true);
                  }}
                  src="https://anya.keymaxrealestate.com/images/play.webp"
                  className="z-10 hover:cursor-pointer w-24 play-button"
                  alt=""
                /> */}

                <div class="playContainer">
                  <a
                    class="button is-play"
                    onClick={() => {
                      setModal(true);
                    }}
                  >
                    <div class="button-outer-circle has-scale-animation"></div>
                    <div class="button-outer-circle has-scale-animation has-delay-short"></div>
                    <div class="button-icon is-play">
                      <svg className="w-24 h-24 hover:cursor-pointer" fill="#000000">
                        <polygon class="triangle" points="5,0 30,15 5,30" viewBox="0 0 30 15"></polygon>
                        <path class="path" d="M5,0 L30,15 L5,30z" fill="none" stroke="#000000" stroke-width="1"></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full h-full md:col-span-4 flex justify-center md:justify-start items-center z-10 mt-4 md:mt-0">
              <h2 className="max-w-sm text-xl font-bold tracking-wide md:leading-[2.5rem] text-gray-900 md:text-2xl leading-none text-white bg-black px-10 py-8 rounded-2xl md:rounded-[2rem] border-4 border-[#D4B071]  md:-ml-[8%]">
                <span className="font-Butler text-2xl md:text-4xl pb-4">{props.project_name}</span>
                <br />
                <span>
                  {props.Starting.map((item, index) => (index === props.Starting.length - 1 ? item.title : `${item.title}, `))} Bedroom {props.Starting[0].type} <br />
                  Starting from AED {props.About.starting_price_formatted}
                </span>
              </h2>
            </div>
          </div>
        </section>

        <section>
          <div className="relative flex flex-col-reverse py-8 lg:py-0 lg:flex-col">
            <div className="w-full max-w-full md px-4 md:px-4 mx-auto md:px-4 lg:px-8 lg:py-16 lg:max-w-screen-xl">
              <div className="flex justify-center mb-[-1.4rem] md:mb-[-1.9rem] z-10 relative">
                <p className="text-white bg-black rounded-full inline-block text-center text-xl md:text-4xl px-5 py-2 font-bold font-Butler border-4 border-[#D4B071]">{t("Gallery")} </p>
              </div>
              <SwiperThumb Gallery={Gallery} stateChanger={setIndex} title={props.project_name} />
              <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
                zoom={{
                  maxZoomPixelRatio: 4,
                }}
                plugins={[Zoom]}
              />
            </div>
          </div>
        </section>

        {props.Starting && props.Starting.length > 0 && (
          <section className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 lg:pb-20">
            <div className="text-center  w-full flex justify-center items-center">
              <p className="max-w-lg mt-2 py-4 font-sans text-center text-4xl md:text-5xl font-black leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto font-Butler">Floor Plan</p>
            </div>
            <div className="grid gird-col-1 md:grid-cols-3 gap-10 items-center pb-2 md:py-4 relative">
              {activeObject && (
                <div className="md:px-4 lg:px-0 w-full col-start-1 col-end-3">
                  <h4 className="hidden capitalize md:block my-5 mx-auto px-4 py-2 md:text-lg lg:text-2xl font-bold w-full text-center border-4 border-[#D4B071] transition-all duration-500 rounded-[3rem] text-[#D4B071] text-mainColor bg-black font-Butler">
                    {`${activeObject.title} Bed ${activeObject.type}`} {Number(activeObject.price) <= 0 ? "| Price On Request" : `| Starting Price ${activeObject.price}`}
                  </h4>
                  <div className="flex items-center justify-center flex-wrap w-full border-2 md:border-4 border-[#D4B071] rounded-[3rem] p-4 md:p-8 aspect-video">
                    <img src={activeObject.image} alt="" className="rounded-lg h-full w-auto mx-auto" onClick={() => setOpen(true)} />
                    <Lightbox
                      open={open}
                      closeOnBackdropClick={true}
                      controller={{ closeOnBackdropClick: true }}
                      render={{
                        buttonPrev: () => null,
                        buttonNext: () => null,
                      }}
                      slideConfig={{
                        showControls: false,
                      }}
                      close={() => setOpen(false)}
                      slides={[{ src: activeObject.image }]}
                      zoom={{
                        maxZoomPixelRatio: 4,
                      }}
                      plugins={[Zoom]}
                    />
                  </div>
                </div>
              )}

              {props.Starting && props.Starting.length > 0 && (
                <div className="flex-none w-full text-center order-first col-span-2 md:col-span-1 md:order-none">
                  <ul className="flex flex-col items-center space-y-2 md:space-y-5 justify-center flex-wrap xl:w-full mx-auto">
                    {props.Starting.map((item, index) => (
                      <li
                        onClick={() => {
                          setActiveObject(item);
                          setActiveTap(`${item.title} ${item.type}`);
                        }}
                        key={index}
                        className={` px-4 py-2 text-lg lg:text-2xl font-bold w-full border-4 border-[#D4B071] transition-all capitalize duration-500 cursor-pointer rounded-[3rem]  ${
                          activeTap === `${item.title} ${item.type}` ? "text-[#D4B071] text-mainColor bg-black" : ""
                        } `}
                      >
                        {item.title === "Studio" ? `${item.title} ${item.type}` : `${item.title} Bed ${item.type}`}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </section>
        )}

        <ProjectForm project_name={props.project_name} heading="Download Floorplans" subHeading="We will email you the floor plans" topSpace={0} phoneAg={'+971505720423'} />

        <section className="py-8 md:py-20">
          <div className="relative flex flex-col rtl:flex-col-reverse py-8 lg:py-0 lg:flex-col">
            <div className="w-full px-6 mx-auto md:px-0 lg:px-8 lg:py-8 lg:max-w-screen-xl">
              <div className="mb-0 lg:max-w-lg ">
                <p className="max-w-lg mt-2 py-4 font-sans text-center text-4xl md:text-5xl font-black leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto font-Butler">Location</p>
                <p className="text-lg md:text-xl font-semibold text-center md:text-left rtl:md:text-right" dangerouslySetInnerHTML={{ __html: props.location.description }} />
              </div>
              <div className="flex flex-col rtl:flex-col-reverse items-start w=full py-8 md:w-1/2  pt-4 md:pt-10">
                {props.location.points.map((el, index) => (
                  <div key={index}>
                    <p className="text-xl md:text-xl font-semibold text-left">✦ {el.value}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="inset-y-0 top-0 ltr:right-0 rtl:left-0 w-full h-[20rem] md:h-[35rem] lg:h-full max-w-xl px-0 mx-auto mb-6 lg:px-0 md:pr-0 md:mb-0 md:mx-0 lg:w-1/2 md:max-w-full lg:absolute xl:px-0 ltr:rounded-l-[3rem] rtl:rounded-r-[3rem] overflow-hidden ml-5 lg:ml-0">
              <LoadScript googleMapsApiKey="AIzaSyCSu32tihJqyOGNpcNdUnjBbNBoACV2R_M">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{ lat: +props.lat, lng: +props.lng }}
                  zoom={12}
                  options={{
                    ...MAP_OPTIONS,
                    styles: MAP_THEME,
                  }}
                >
                  <Marker position={{ lat: +props.lat, lng: +props.lng }} />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </section>

        <section id="paymentPlan" className="bg-gray-100">
          <div className="relative px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-16 lg:px-8">
            <div className="flex flex-col items-center mt-0">
              <div className="max-w-xl md:mx-auto text-center lg:max-w-2xl md:mb-10">
                <p className="max-w-lg mt-2 py-4 font-sans text-center text-4xl md:text-5xl font-black leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto font-Butler">{t("Payment Plan")}</p>
              </div>

              <div className={`grid md:grid-cols-${numColumns} grid-cols-1`}>
                {props.Payments.map((item, index) => (
                  <Fragment key={index}>
                    {item.value > 0 && (
                      <div className="flex justify-items-stretch flex-1 mt-3 sm:mt-0 w-full md:w-auto md:flex-col flex-col-reverse px-10">
                        <div className="block md:px-6 md:py-10 px-5 py-6 font-bold text-center rounded-3xl lg:px-10 tracking-tight border-4 border-[#D4B071] font-extrabold font-Butler bg-black text-white px-10">
                          <p className="text-3xl lg:text-4xl">

                          {item.months ? `${item.value * item.months}%` : `${item.value}%`}
                          </p>
                        </div>
                        <p className="text-center py-5 text-2xl font-bold">{item.title}</p>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </section>

        <ProjectForm project_name={props.project_name} heading="Get in touch" subHeading="Let us help you tailor your payment plan" topSpace={0} phoneAg={'+971505720423'} />

        <section>
          <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
              <p className="max-w-lg mt-2 py-4 font-sans text-center text-4xl lg:text-5xl font-black leading-none tracking-tight text-gray-900 sm:text-3xl md:mx-auto font-Butler">{t("Amenities")}</p>
              <p className="text-xl md:text-2xl font-semibold text-center" dangerouslySetInnerHTML={{ __html: props.AmenitiesDesc }} />
            </div>

            {/* <div className="max-w-4xl mx-auto">
              <ImgSliderAmenities slice={-6} imgs={Gallery.map((el) => el.image)} />
            </div> */}
            <div className="relative grid gap-5 grid-cols-2 lg:grid-cols-3 max-w-4xl mx-auto pt-5">
              {props.Full.amenity_data.map((el, index) => (
                <div className="flex flex-col justify-between text-left transition-shadow duration-200 bg-white shadow-2xl group hover:shadow-4xl rounded-3xl" key={index}>
                  <div className="p-10 flex flex-col justify-between items-center">
                    <div className="flex items-center justify-center mb-4 rounded-full ">
                      <img src={el.selected_image} className="w-16 drop-shadow" alt={el.name} />
                    </div>
                    <p className="mb-2 font-bold text-black text-center">{el.selected_name}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section>
          <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div className="mb-10 md:mx-auto text-center md:mb-12">
              <h2 className="max-w-screen-full mb-6 font-sans text-4xl lg:text-5xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto font-Butler">{t("We accept all types of payments")}</h2>
            </div>
            <ul className="w-full mt-8 grid gap-5 grid-cols-2 lg:grid-cols-5">
              <li className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4">
                <img src={bitcoin} alt="" className="md:w-12" />
                <h3 className="text-sm md:text-lg mx-2  text-black font-bold">{t("Bitcoin")}</h3>
              </li>
              <li className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4">
                <img src={cheque} alt="" className="md:w-12" />
                <h3 className="text-sm md:text-lg mx-2  text-black font-bold ">{t("Cheques")}</h3>
              </li>
              <li className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4">
                <img src={creditCard} alt="" className="md:w-12" />
                <h3 className="text-sm md:text-lg mx-2  text-black font-bold">{t("Credit Card")}</h3>
              </li>
              <li className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4">
                <img src={bank} alt="" className="md:w-12" />
                <h3 className="text-sm md:text-lg mx-2  text-black font-bold ">{t("Bank Transfer")}</h3>
              </li>
              <li className="flex flex-col space-y-3 md:space-y-5 justify-center items-center text-white mb-4 p-4 col-span-2 md:col-span-1">
                <img src={cash} alt="" className="md:w-12" />
                <h3 className="text-sm md:text-lg mx-2  text-black font-bold">{t("Cash")}</h3>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProjectDetails;
