import { useEffect, useState, useRef } from "react";
import Layout from "../Components/Layout";
import Filter from "../Components/Sections/Filter";
import axios from "axios";
import { config, url } from "../Common/Common";
import { HiOutlineAdjustments, HiOutlineLocationMarker } from "react-icons/hi";
import List from "../Components/UI/List";
import Loading from "../Components/UI/Loading";
import { Link, useParams, useLocation } from "react-router-dom";
import FilterMobile from "../Components/Sections/FilterMobile";
import { t } from "i18next";
import "./OffPlan.scss";
import { Helmet } from "react-helmet-async";
import Card from "../Components/UI/Card";
import { Pagination } from "../Components/Pagination/Pagination";

const OffPlan = () => {
  const { id } = useParams();
  const [buy, setBuy] = useState([]);
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("newest");
  const [isActive, setIsActive] = useState(false);
  const [filters, setFilters] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(12);
  const [lastPage, setLastPage] = useState();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [page, setPage] = useState(queryParams.get("page") || 1);

  const handleFilter = (filterVal) => {
    if(!isNaN(filterVal)){
      var array = filters ? filters.replace("target=offplan", "").replace(/\?/g,"").split("&"):[];
      var temp =[];
      array.forEach(arr => {
        var tt = arr.split("=");
        if(tt[0] != "category[]" && arr != "target=offplan" && isNaN(arr)){
          temp.push(arr);
        }
      });
      temp.push("category[]=" + filterVal);
      filterVal = "?target=offplan&" + temp.join("&");
    }
    setFilters(filterVal);
    setLoading(true);
    const val = !filterVal ? "" : filterVal ;
    setPage(1);
    
    window.history.pushState(
      {},
      "",
      document.location.href.substring(
        0,
        document.location.href.lastIndexOf("?page")
      )
    );

    axios.get(url +`properties${val}&sort_by=${sortBy}&limit=${limit}&page=${1}`,config)
      .then(function (response) {
        setBuy(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
        setCurrentPage(+response.data.page.current_page);
        setLastPage(response.data.page.last_page);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    setBuy([]);
    axios
      .get(
        url +
          `properties?target=offplan&${
            filters ? filters.substring("1") : ""
          }${
            !filters || !filters.includes("sort_by") ? "&sort_by=" + sortBy : ""
          }&limit=${limit}&page=${page}`,
        config
      )
      .then(function (response) {
        setBuy(response.data.data);
        setLoading(false);
        setTotal(response.data.total);
        setCurrentPage(+response.data.page.current_page);
        setLastPage(response.data.page.last_page);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [sortBy, page]);

  useEffect(() => {
    const requestUrl = `${url}categories?target=offplan&${filters ? `${filters}` : ""}&limit=${limit}`;
    axios
      .get(requestUrl, config)
      .then(function (response) {
        // handle success
        setList(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, [filters]);

  const displayPageCount = 5; // Number of page numbers to display

  let startPage = Math.max(1, currentPage - 2); // Display 2 pages before the current page
  const endPage = Math.min(startPage + displayPageCount - 1, lastPage);

  // Adjust the start page if there are not enough pages on the right
  if (endPage - startPage < displayPageCount - 1) {
    startPage = Math.max(1, lastPage - displayPageCount + 1);
  }

  const pages = [];
  for (let page = startPage; page <= endPage; page++) {
    pages.push(page);
  }

  return (
    <div id="OffPlan">
      <Helmet>
        <title>
          Off-Plan Properties in Dubai, UAE | Off-Plan Oroperties For Sale |
          Real Estate company
        </title>
        <meta
          name="description"
          content="Invest smartly in Dubai's off-plan properties. Secure your future with pre-construction real estate opportunities. Maximize ROI in Dubai's thriving market"
        />
        <link
          href="https://keymaxrealestate.com/off-plan-properties-dubai"
          rel="canonical"
        />
      </Helmet>

      <Layout>
        {window.innerWidth < 768 ? (
          <FilterMobile
            onFilterChange={handleFilter}
            handleCloseFilter={() => setIsActive(false)}
            className={isActive && "active"}
            typeChange={"offplan"}
          />
        ) : (
          ""
        )}
        <div className="container mx-auto">
          <div className="mt-8 lg:mt-28">
            <div className="row flex items-start justify-start text-center flex-wrap">
              <div className="w-full">
                {window.innerWidth < 768 ? (
                  ""
                ) : (
                  <Filter offPlan={true} onFilterChange={handleFilter} typeChange={"offplan"} />
                )}
              </div>
              <div className="w-full flex items-center justify-between flex-wrap border-b border-mainColor pb-4">
                <div className="btns flex items-center justify-between md:justify-start mb-4 md:mb-0 w-full md:w-1/2">
                  <Link
                    to="/MapView"
                    className="text-sm md:text-lg w-1/3 md:w-auto px-1 md:px-4 py-2 rounded-full mx-1 md:mx-2  flex items-center relative overflow-hidden cursor-pointer  border-2 font-black border-[#D4B071]"
                  >
                    <HiOutlineLocationMarker className="mx-1 md:mx-2 text-mainColor" />{" "}
                    {t("Map View")}{" "}
                  </Link>
                  <button
                    onClick={() => setIsActive(true)}
                    className="w-1/3 md:w-auto  px-2 md:px-4 py-2 rounded-md mx-1 md:mx-2  flex md:hidden items-center relative overflow-hidden cursor-pointer border border-mainColor  bg-mainColor text-gray"
                  >
                    <HiOutlineAdjustments className="mx-1 md:mx-2 text-gray" />{" "}
                    {t("Filter")}{" "}
                  </button>
                </div>
                <div className="btns flex justify-between md:justify-end items-center  w-full md:w-1/2">
                  <label className="mx-4" htmlFor="SortBy">
                    {t("Sort By:")}
                  </label>
                  <select
                    id="SortBy"
                    className="border border-gray py-2 px-4"
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    {/* <option value="featured">{t("Featured")}</option> */}
                    <option value="newest">{t("Newest")}</option>
                    <option value="price_low">{t("Price (low)")}</option>
                    <option value="price_high">{t("Price (high)")}</option>
                    <option value="beds_low">{t("Beds (least)")}</option>
                    <option value="beds_high">{t("Beds (most)")}</option>
                    <option value="delivery_date_earliest">
                      {t("Delivery date (earliest)")}
                    </option>
                    <option value="delivery_date_latest">
                      {t("Delivery date (latest)")}
                    </option>
                  </select>
                </div>
              </div>
              <h1 className="font-bold text-2xl text-center md:text-4xl w-full mb-4  md:mt-8">
                <span className="border-b-2 border-[#CDAC62] text-black px-4 md:px-4">
                  Off-Plan properties in Dubai
                </span>
              </h1>
              <div className="w-full flex justify-center items-center px-4">
              {
              <div className="w-full flex justify-center items-center px-4">
                  {list && list.length > 0 && (
                  <div className="flex space-x-10 px-4 py-4 rounded-lg overflow-x-auto">
                    {
                      list.map((item)=>( item && ( 
                      <List
                          link="/off-plan-properties-dubai"
                          name={item.name}
                          total={item.total}
                          id={item.id}
                          onClicked={handleFilter}
                        />)
                      ))
                    }
                  </div>
                )}            

              </div>
            }

              </div>

              {isLoading ? (
                <div className="h-screen w-screen bg-white z-40 flex items-center justify-center">
                  <Loading />
                </div>
              ) : (
                <>
                  <div className="flex flex-col md:flex-row">
                    <div className="w-full flex flex-col justify-center items-center">
                      <div className="relative grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 md:pt-8">
                        {buy.map((item, index) => (
                          <>
                            <Card
                              id={item.id}
                              slug={item.slug}
                              name={item.title}
                              location={item.address}
                              area={item.size}
                              bedroom={item.bedrooms_number}
                              bathroom={item.bathrooms_number}
                              property_type={item.property_type}
                              price={item.price}
                              img={item.image}
                              imgs={item.images}
                              status={item.launch}
                              buy_or_sell={item.buy_or_sell}
                              price_year={item.price_year}
                              price_hidden={item.price_hidden}
                              description={item.description}
                              height={256}
                              width={380}
                            />
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Pagination */}
                  <Pagination
                    endPage={endPage}
                    currentPage={currentPage}
                    pages={pages}
                    title={"off-plan-properties-dubai"}
                    total={total}
                    onClick={(val)=>{
                      setPage(val);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default OffPlan;
