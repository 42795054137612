import Button from "../UI/Button";

import "../ModalPopup/ModalPopup.css";

const ModalPopup = (props) => {
  return (
    <>
      <div className="fixed top-0 left-0 bg-black opacity-75 w-screen h-screen z-50 bkDrop" onClick={() => props.closeModal()}></div>
      <div className="Modal w-full md:w-3/6 h-auto fixed top-1/2 left-1/2 bg-white -translate-x-1/2 -translate-y-1/2">
        <div className="video-container">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${props.youtube}?&autoplay=1`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        {/* <div className="flex gap-4 pt-5 justify-center">
                          <Button onClick={() => props.closeModal()}>Close</Button>
                </div> */}
      </div>
    </>
  );
};

export default ModalPopup;
