// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect } from "react";
import axios from "axios";
import { useState, useRef } from "react";
import { config, url } from "../../Common/Common";
import { Link } from "react-router-dom";
import Loading from "../UI/Loading";
import Nothing from "../UI/Nothing";
import Title from "../UI/Title";
import { t } from "i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "./Developers.scss";

var settings = {
  ltr: true,
  centerPadding: "20px",
  slidesToShow: 6,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  speed: 5000,
  // autoplaySpeed: 0,
  // cssEase: 'linear',
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,
        autoPlay: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoPlay: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        arrows: false,
        autoPlay: true,
        slidesToScroll: 1,
      },
    },
  ],
};
const Developers = () => {
  const [developers, setDevelopers] = useState([]);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(url + "developers?start=0", config)
      .then(function (response) {
        // handle success
        setDevelopers(response.data.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  return (
    <div className="Developers mt-10">
      {isLoading ? (
        <Loading />
      ) : (
        developers.length > 0 && (
          <>
            <Title title={t("Developers")} link={t("view all")} url="/Developers" />
            <Swiper
              slidesPerView={2}
              modules={[Autoplay]}
              spaceBetween={10}
              autoplay={{ delay: 1500 }}
              loop={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 1,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 2,
                },
                1024: {
                  slidesPerView: 6,
                  spaceBetween: 2,
                },
              }}
              className="mySwiperDeveloper"
            >
              {developers.map((developer, index) => (
                <div className="mx-5">
                  <SwiperSlide key={index}>
                    <Link to={`/Developer/${developer.slug}`} className="developer outline-none h-full w-full flex items-center border-r border-black justify-center">
                      <img id="DeveloperSwiperImg" src={developer.icon} alt={developer.name} />
                    </Link>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
          </>
        )
      )}
    </div>
  );
};

export default Developers;
