import React, { useEffect } from 'react'
import Layout from '../Components/Layout'
import img from '../img/bk.jpg'
import img1 from '../img/1.png'
import img2 from '../img/2.png'
import img3 from '../img/3d.png'
// import svg1 from '../img/1.svg'
// import svg2 from '../img/2.svg'
// import svg3 from '../img/3.svg'
// import svg4 from '../img/4.svg'
import Title from '../Components/UI/Title';
import { BiPhoneCall } from 'react-icons/bi'
import { AiOutlineMail } from 'react-icons/ai'
import { BsWhatsapp } from 'react-icons/bs'
import { FiTwitter, FiInstagram, FiLinkedin } from 'react-icons/fi'
import { config, url } from '../Common/Common'
import axios from 'axios'
import { useState } from 'react'
import { t } from 'i18next';

const Terms = () => {
  const [page, setPages] = useState()

  useEffect(() => {
    axios.get(url + `pages/terms`, config)
      .then(function (response) {
        const data = response.data.data
        setPages(data)
      })
      .catch(function (error) {
        console.log(error);
      })
  }, []);


  return (
    <Layout>
      <div className="container mx-auto mt-12 px-4 md:px-0">
        {page &&
          <div className="row flex items-start justify-start  flex-wrap">
            <div className="w-full md:w-1/2">
              <Title title={page.title} />
              <div style={{ overflowWrap: 'anywhere' }} dangerouslySetInnerHTML={{ __html: page.contents }} />
            </div>
            <div className="w-full md:w-1/2 md:p-8">
              <div className='relative overflow-hidden rounded-xl'>
                <img src={page.img_url} alt={page.title} title={page.title} className='w-full h-full object-cover' />
              </div>
            </div>

          </div>
        }
      </div>
    </Layout>
  )
}

export default Terms