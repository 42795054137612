import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import './index.css';
import './Style/App.scss';
import App from './App';
import {FavoritesContextProvider} from './store/store';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HelmetProvider } from 'react-helmet-async';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector )
  .use(HttpApi)
  .init({
    supportedLangs: ['en', 'ar'],
     fallbackLng: "ar", 
    detection:{
      order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage'],
      lookupLocalStorage : 'KeyMaxLang'
    },
    backend:{ 
      loadPath: '/assets/local/{{lng}}/translation.json',
    },
  });

 

  export default i18n;

  // console.log = console.warn = console.error = () => {};
  // console.error('Something bad happened.');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  <FavoritesContextProvider >
  <HelmetProvider>

    <App />
  </HelmetProvider>
  </FavoritesContextProvider>
); 