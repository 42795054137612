import '../Style/Auth.scss'
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Layout from '../Components/Layout'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import axios from 'axios';
import { url } from '../Common/Common';
const Reset = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [pass, setPass] = useState('');
  const [applyPass, setApplyPass] = useState('');
  const [otp] = useSearchParams();
  const [email] = useSearchParams();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const ResetPassword = () => {
    axios.post(url + 'auth/reset-password',
      {
        identity: email.get('email'),
        otp: otp.get('otp'),
        password: pass,
        confirm_password: applyPass
      }
    ).then(response => {
      setApplyPass('')
      setPass('')
      window.location = "/";
    }).catch(error => {
      console.log(error.response.data.message)
    })
  }

  return (

    <Layout>
      <div className="auth flex items-center justify-center">
        <div className="container mx-auto">
          <div className="row flex items-center justify-center text-center">
            <div className="w-full sm:w-1/2 md:w-1/3 ">
              <h3 className='font-bold text-mainColor  capitalize text-xl'>Password recovery</h3>
              <form action="">
                <div className="mt-6 relative">
                  <input onChange={(e) => setPass(e.target.value)} value={pass} type={showPassword ? 'text' : 'password'} maxLength='20' autoComplete="true" className="w-full p-4 text-mainColor bg-white border-mainColor border  rounded-md " placeholder='Password ' />
                  <span className='absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer' onClick={handleShowPassword} >
                    {showPassword ? <AiOutlineEye className='text-2xl text-mainColor' /> : <AiOutlineEyeInvisible className='text-2xl' />}
                  </span>
                </div>
                <div className="mt-6 relative">
                  <input onChange={(e) => setApplyPass(e.target.value)} value={applyPass} type={showPassword ? 'text' : 'password'} maxLength='20' autoComplete="true" className="w-full p-4 text-mainColor bg-white border-mainColor border  rounded-md " placeholder='Apply Password ' />
                  <span className='absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer' onClick={handleShowPassword} >
                    {showPassword ? <AiOutlineEye className='text-2xl text-mainColor' /> : <AiOutlineEyeInvisible className='text-2xl' />}
                  </span>
                  <span className='flex text-right items-center justify-end w-full text-xs'>At least 8 characters, 1 uppercase letter, 1 number, 1 symbol</span>
                </div>


                <div className="mt-6">
                  <button onClick={ResetPassword} className='w-full p-4 text-xl rounded-lg text-center cursor-pointer bg-mainColor text-white' type="button" > Reset Password  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Reset