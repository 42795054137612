import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/white-logo.png";
import { BsBuilding } from "react-icons/bs";
import { BiPhoneCall, BiBuildingHouse } from "react-icons/bi";
import { GiCrane, GiReceiveMoney } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import { SiAboutdotme } from "react-icons/si";
import { BsWhatsapp } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { TbCrane } from "react-icons/tb";
import { t } from "i18next";
import Modal from "../Modal/Modal";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const Sidebar = (props) => {
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [servicesDropDown, setServicesDropDown] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  return (
    <>
      {modal && (
        <>
          <Modal title={t(" Take Appontment")} btnText={t("Send Message")} closeModal={() => setModal(false)} />
        </>
      )}
      <div
        className={`fixed top-0  w-full h-screen z-50 bg-white flex flex-col items-start justify-start pt-8 opacity-80 transition-all duration-500 ease-in-out  ${
          props.isOpen == true ? "ltr:left-0 ltr:right-auto rtl:right-0 rtl:left-auto" : "ltr:-left-full ltr:right-auto rtl:-right-full rtl:left-auto "
        }`}
        onClick={() => props.toggleSidebar()}
      ></div>
      <sidebar
        className={`fixed top-0 w-9/12 h-screen z-50 bg-black flex flex-col items-start justify-start pt-8 shadow-lg transition-all  duration-1000 ease-in-out overflow-y-scroll ${
          props.isOpen == true ? "ltr:left-0 ltr:right-auto rtl:right-0 rtl:left-auto" : "ltr:-left-full ltr:right-auto rtl:-right-full rtl:left-auto "
        }`}
      >
        <ul className="w-full flex flex-col items-center justify-start text-left pl-8 font-bold text-lg pt-16 capitalize text-white">
          <Link to="/property-for-sale-in-dubai" aria-label="property for sale in dubai" title="property for sale in dubai" className="mb-4 w-full text-left flex items-center justify-start">
            {t("Buy")}
          </Link>
          <Link to="/property-for-rent-in-dubai" className="mb-4 w-full text-left flex items-center justify-start">
            {t("Rent")}
          </Link>
          <Link to={`/off-plan-properties-dubai`} className="mb-4 w-full text-left flex items-center justify-start">
            {t("Off Plan")}
          </Link>
          <Link to="/projects" className="mb-4 w-full text-left flex items-center justify-start">
            {t("Projects")}
          </Link>
          <Link to="/area" className="mb-4 w-full text-left flex items-center justify-start">
            {t("Areas")}
          </Link>
          <Link to="/developers" className="mb-4 w-full text-left flex items-center justify-start">
            {t("Developers")}
          </Link>
          <Link to="/contact" className="mb-4 w-full text-left flex items-center justify-start">
            {t("Contact Us")}
          </Link>
          <Link to="/list-your-property-in-dubai" className="mb-4 w-full text-left flex items-center justify-start">
            {t("List Property")}
          </Link>
          <li className="mb-4 w-full text-left flex flex-col items-center justify-start">
            <button className="flex w-full items-center md:text-sm xl:text-lg justify-start hover:bg-opacity-10 hover:bg-black" onClick={() => setIsOpen(!isOpen)}>
              {" "}
              {t("Guides")}
              {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </button>
            <ul className={`opacity-75 relative overflow-hidden ${isOpen ? "h-auto" : "h-0"} w-full text-left flex flex-col items-start justify-start`}>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/buying-off-plan-guide" className="py-2 ltr:pl-6 rtl:pr-6 flex items-center md:text-[1rem]">
                  {t("Buying Off Plan Guide")}{" "}
                </Link>
              </li>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/selling-guide" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                  {t("Selling Guide")}{" "}
                </Link>
              </li>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/buying-guide" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                  {t("Buying Guide")}{" "}
                </Link>
              </li>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/renting-guide" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                  {t("Renting Guide")}{" "}
                </Link>
              </li>
            </ul>
          </li>
          <li className="mb-4 w-full text-left flex flex-col items-start justify-start relative">
            <button
              aria-label="About us"
              title="About us"
              class={`flex flex-row items-center gap-x-2 tracking-wide text-white`}
              onClick={() => {
                setServicesDropDown(false);
                setDropDown(!dropDown);
              }}
            >
              {t("Others")}
              {dropDown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </button>
            <ul className={`opacity-75 relative overflow-hidden ${dropDown ? "h-auto" : "h-0"} w-full text-left flex flex-col items-start justify-start`}>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/about" className="py-2 ltr:pl-6 rtl:pr-6 flex items-center md:text-[1rem]">
                  {t("About Us")}{" "}
                </Link>
              </li>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/meet-the-team" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                  {t("Meet the Team")}{" "}
                </Link>
              </li>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/faqs" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                  {t("FAQ's")}{" "}
                </Link>
              </li>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/blogs" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                  {t("Blog")}{" "}
                </Link>
              </li>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/careers" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                  {t("Carrers")}{" "}
                </Link>
              </li>
              <li className="hover:bg-opacity-10 hover:bg-black">
                <Link to="/contact" className="py-2  ltr:pl-6 rtl:pr-6  flex items-center md:text-[1rem]">
                  {t("Contact Us")}{" "}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        <div className="btns flex items-center gap-6 px-8 pt-12">
          <a onClick={() => setModal(true)} className="cursor-pointer ltr:flex-row-reverse flex flex-row justify-between	 items-center text-black bg-white rounded-full border-2 border-[#D4B071] px-3 py-2 text-md font-bold">
            <p>Book your consultation</p>
          </a>
        </div>
      </sidebar>
    </>
  );
};

export default Sidebar;
