import axios from "axios";
import React, { useEffect, useState } from "react";
import { config, url } from "../Common/Common";
import { useParams, Link, useLocation } from "react-router-dom";
import ProjectList from "./ProjectList";
import Layout from "../Components/Layout";
import { Helmet } from "react-helmet-async";
import { t } from "i18next";
import Loading from "../Components/UI/Loading";


const FilteredProjects = () => {
    const { slug } = useParams();
    const [projectsList, setProjectsList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') || 1;

    // const handleFocus = () => {
    //   setInputFocused(true);
    // };
  
    // const handleBlur = () => {
    //   setInputFocused(false);
    // };
  
    const displayPageCount = 5; // Number of page numbers to display
  
    let startPage = Math.max(1, currentPage - 2); // Display 2 pages before the current page
    const endPage = Math.min(startPage + displayPageCount - 1, lastPage);
  
    // Adjust the start page if there are not enough pages on the right
    if (endPage - startPage < displayPageCount - 1) {
      startPage = Math.max(1, lastPage - displayPageCount + 1);
    }
  
    const pages = [];
    for (let page = startPage; page <= endPage; page++) {
      pages.push(page);
    }
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scrolling animation
      });
    };
    useEffect(() => {
      fetchProjects(page);
    }, [page]);

    const fetchProjects = () => {
      setLoading(true);
        // Fetch project details and set loading state
        axios
          .get(url + `projects?area_id=${slug}&page=${page}`, config)
          .then(function (response) {
            setProjectsList(response.data.data);
            setCurrentPage(response.data.current_page);
            setLastPage(response.data.last_page);
            setLoading(false);
            setError(null);
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false);
          });
    }

    const handleReload = () => {
      setError(null);
      fetchProjects(currentPage);
    };
    return(
        <Layout>
      <Helmet>{/* ... (helmet content) */}</Helmet>

      <div className="container mx-auto px-4 md:p-0 mt-20 md:mt-28">
        <h3 className="font-bold text-2xl text-center md:text-4xl w-full my-8">
          <span className="border-b-2 pb-2 border-[#CDAC62] text-black px-4 md:px-4"> {t("Projects")} </span>{" "}
        </h3>

        {/* <div className="w-full flex justify-center mb-8 md:mb-12 relative">
          <input
            className={`px-6 py-3 border-4 border-black/40 rounded-full placeholder:text-center ${isInputFocused ? "placeholder:text-transparent" : ""}`}
            type="text"
            placeholder="Search Projects"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleSearchedProjects}
          />
            {filtersProjects.length > 0 &&
          <div className="max-w-lg min-w-[16rem] absolute top-16 border-2 border-black overflow-y-auto max-h-32 z-20 bg-white rounded-xl p-4 overflow-hidden">
          {filtersProjects.slice(0, 12).map((project) => (
            // Render project data here, for example:
            <div key={project.id} className="py-2">
              <Link to={`/project/${project.slug}`}>
                <p>{project.name_en}</p>
              </Link>
            </div>
          ))}
        </div>
        
          }
        </div> */}
        {isLoading && (
          <div className="absolute top-0 left-0 h-full w-screen bg-white z-40 flex items-center justify-center">
            <Loading />
          </div>
        )}

        <div className="w-full">
          {projectsList.length ?  (
            <div className="grid gap-5 row-gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1 w-full">
              {projectsList.map((project) => (
                <ProjectList project={project} key={project.id} />
              ))}
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center h-[60vh]">
              <p className="text-xl md:text-2xl lg:text-3xl">Not Project Found</p>
              <Link to={-1} className='text-md md:text-lg lg:text-xl py-2 my-6 px-6 text-white bg-black rounded-full'>Prev Page</Link>
            </div>
          )}
        </div>

        {/* Pagination */}
        {!error && projectsList.length &&
        
        <nav className="pagination flex items-center justify-center space-x-2">
          <Link to={`/off-plan-projects/${slug}?page=1`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
            &lt;&lt; {/* First Page */}
          </Link>
          <Link to={`/off-plan-projects/${slug}?page=${currentPage - 1}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
            &lt; {/* Previous Page */}
          </Link>
          {pages.map((page) => (
            <Link
              to={`/off-plan-projects/${slug}?page=${page}`}
              onClick={scrollToTop}
              key={page}
              className={`rounded-full black-bg px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 ${page === currentPage ? 'text-white bg-black' : 'text-black'}`}
            >
              {page}
            </Link>
          ))}
          <Link to={`/off-plan-projects/${slug}?page=${currentPage + 1}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
            &gt; {/* Next Page */}
          </Link>
          <Link to={`/off-plan-projects/${slug}?page=${lastPage}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
            &gt;&gt; {/* Last Page */}
          </Link>
        </nav>
        }

        {/* Check for error and show reload button */}
        {error && (
          <div className="text-red-500 font-bold text-center h-screen flex justify-center items-center flex-col">
            <p className="text-lg md:text-2xl">{error}</p>
            <button onClick={handleReload} className="px-6 py-3 rounded-full bg-black text-white mt-12 text-blue-500 hover:text-blue-600 cursor-pointer">
              Reload
            </button>
          </div>
        )}
      </div>
    </Layout>
    )
}

export default FilteredProjects