import React from "react";
import Layout from "../Components/Layout";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Pragraph from "../Components/UI/Pragraph";
import { t } from "i18next";
import DateFormProperty from "../Components/Layout/DateFormProperty/DateFormProperty";
import PhoneInput from "react-phone-input-2";
import BG from "../img/Buy-Guide.webp";
import "./formStyle.css";

import "react-phone-input-2/lib/style.css";
import ReactRecaptcha3 from "react-google-recaptcha3";
import {
  recaptcha__secret_key,
  recaptcha_key,
  url,
  config,
  chechPhone
} from "../Common/Common";
import Swal from "sweetalert2/dist/sweetalert2.js";

const BuyingGuide = () => {
  const [Buying, setBuying] = useState([]);
  const [phone, setPhone] = useState("");

  const [Selling, setSelling] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [viewDate, setViewDate] = useState("");
  const [viewTime, setViewTime] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [errToken, setErrToken] = useState(false);
  const [sellLoading, setSellLoading] = useState(false);

  useEffect(() => {
    axios
      .get(url + `guides/Buying%20Guide`, config)
      // Response
      .then((response) => {
        const data = response.data.data;
        setBuying(data);
      })
      // Check Error
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key);
  }, []);

  const styles = {
    backgroundImage: `url(${BG})`,
  };


  const buyHandler = (e) => {
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    setEmailError(false);
    e.preventDefault();

    if (name.trim() == "") {
      setNameError(true);
      return;
    }
    if (email.trim() == "" || !email.includes("@")) {
      setEmailError(true);
      return;
    }
    if(!chechPhone(phone)){
      setPhoneError(true);
        return;
    }
    if (message.trim() == "") {
      setMessageError(true);
      return;
    }

    setSellLoading(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + "verifyRecaptcha",
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            axios
              .post(url + "contact-us", {
                name: name,
                email: email,
                phone: phone,
                message: message,
                viewDate: viewDate,
                viewTime: viewTime,
                source: "sell guide",
              })
              .then((response) => {
                setName("");
                setEmail("");
                setPhone("");
                setMessage("");
                setSellLoading(false);
                Swal.fire({
                  timer: 3000,
                  title: `${t("Success")}`,
                  icon: "success",
                  html: `${t("Your message has been sent")}`,
                  confirmButtonText: `${t("ok")}`,
                  showCloseButton: true,
                  timerProgressBar: true,
                });
              })
              .catch(() => {
                setSellLoading(false);
                setErrToken(true);
              });
          })
          .catch(() => {
            setSellLoading(false);
            setErrToken(true);
          });
      },
      () => {
        setSellLoading(false);
        setErrToken(true);
      }
    );
  };

  return (
    <Layout>
      <div className="container mx-auto mt-20 md:mt-28 px-4 md:px-0">
        <div className="mx-auto w-full relative flex justify-center items-center">
          <div className="w-full h-[50vh] md:h-[75vh] bg-cover rounded-3xl md:rounded-[3.25rem] bg-bottom brightness-[0.60]" style={styles}></div>
          <p className="absolute text-4xl md:text-6xl text-white font-black text-center">
            Dubai Real Estate:
            <br />A Buyer's Guide
          </p>
        </div>
        <div className="flex flex-col lg:flex-row items-start justify-start text-center mt-14">
          <div className="w-full lg:w-3/4 mx-auto ">
            {/* <h3 className="font-bold text-2xl text-center md:text-4xl w-full my-8">
            {" "}
            <span className="border-b-2 border-[#CDAC62] text-black px-4 md:px-4"> {t("Buying Guide")} </span>{" "}
          </h3> */}
            <div className="row flex items-start justify-center text-center flex-wrap  ">
              {Buying.length > 0 && (
                <div className="w-full mx-2 md:mx-8  bg-mainColorLight bg-opacity-5 p-2 md:p-8 rounded-2xl shadow-2xl ">
                  {Buying.map((item, index) => (
                    <Pragraph index={index} key={index} title={item.title} content={item.content} />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="sticky top-32 w-full lg:w-1/4 ">
            <div className=" mx-4 pb-5 max-h-full">
              <div className="relative p-2 w-full h-auto border-4 border-[#D4B071] rounded-3xl  bg-white">
                <div className="flex flex-col justify-center items-center w-full my-8 mt-[-2rem]">
                  <h1 className="text-white bg-black rounded-full inline-block text-center text-md lg:text-2xl px-5 py-2 font-bold font-Butler">Need Help Buying?</h1>
                  <p className="text-md md:text-2xl pt-4">Schedule a call with us</p>
                </div>
                <form className="relative">
                  <div className="max-w-5/6 mx-auto flex flex-col justify-center items-center">
                    <div className="max-w-sm">
                      <DateFormProperty mobile={true} />
                    </div>
                    <div className={`flex flex-col gap-x-0 md:gap-x-5 w-full relative pt-5`}>
                      <input
                      placeholder="Name"
                      required
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                        className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border-b-2 border-black appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      />
                      {nameError && (
                        <span className="text-sm text-red">
                          {" "}
                          Please enter your name
                        </span>
                      )}
                      <input
                      placeholder="Email"
                      required
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                        className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                      />
                      {emailError && (
                        <span className="text-sm text-red">
                          {" "}
                          Enter a valid Email
                        </span>
                        )}
                      <PhoneInput
                      placeholder="Enter phone number"
                      country={"ae"}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                        className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-b-black focus:outline-none focus:shadow-outline"
                      />
                      {phoneError && (<p className=" text-red text-sm"> Please enter valid phone number </p> )}
                    </div>


                    <textarea
                    name=""
                    id=""
                    rows="5"
                    value={message}
                    placeholder="Message"
                    onChange={(e) => setMessage(e.target.value)}
                      className="flex-grow w-full px-4 transition duration-200 bg-white border-b-2 border-black appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline mb-10"
                    ></textarea>
                    {errToken && (
                      <p className="text-red text-sm error-token">
                        please verify you are a human
                      </p>
                    )}


                    <button
                    onClick={buyHandler}
                    type="submit"
                    disabled={sellLoading}
                      className="absolute bottom-[-1.9rem] placeholder-center inline-flex items-center justify-center  px-7 py-2 text-md font-medium tracking-wide text-black font-bold transition duration-200 rounded-full shadow-md bg-[#D4B071] hover:bg-blue-700 focus:shadow-outline focus:outline-none"
                    >
                    {sellLoading ? (
                      <>
                        <span className="inline-flex items-center justify-center h-12 px-6">
                          <span className="lds-dual-ring"></span>
                        </span>
                      </>
                    ) : (
                     " Book now"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BuyingGuide;
