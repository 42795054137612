import "./Mobile.scss";
import { BsJustifyLeft, BsFillSuitHeartFill } from "react-icons/bs";
import { AiOutlineLogin } from "react-icons/ai";
// import {} from 'react-icons/'
import Lang from "../lang/Lang";
import { Link } from "react-router-dom";
import logo from "../../img/logo_keymax.png";
import Sidebar from "./Sidebar";
import { useState, useEffect } from "react";
import { FiDollarSign } from "react-icons/fi";
import { TbCurrencyDirham } from "react-icons/tb";
import LangSvgIcons from "../SvgIcons/LangSvgIcons";

const MobileNav = (props) => {
  const { HomePage } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [scrollColor, setScrollColor] = useState(true);

  const handleScroll = () => {
    window.pageYOffset >= 150 ? setScrollColor(false) : setScrollColor(true);
    window.pageYOffset <= 150 ? setScrollColor(true) : setScrollColor(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.addEventListener("scroll", handleScroll);
  });

  return (
    <>
      <div className="fixed top-0 left-0 w-full z-50">
        <div className={` ${HomePage ? (scrollColor ? "bg-white/[.14] backdrop-blur-sm" : "bg-white shadow-lg") : "bg-white shadow-lg"} relative z-50`}>
          <div className="container mx-auto px-4">
            <ul className="flex items-center justify-center text-center py-4  h-16 ">
              <li className="w-1/3 flex items-center justify-start gap-2">
                <BsJustifyLeft className="text-3xl text-black" onClick={() => setIsOpen(true)} />
                {/* <Link to='/WishList'><BsFillSuitHeartFill className='text-white' /></Link> */}
                {/* <Lang /> */}
              </li>
              <Link to="/" className="w-1/3">
                <img src={logo} alt="" srcSet="" className="mx-auto" width="100" />
              </Link>
              {/* <li className="w-1/3 flex items-center justify-end gap-2">
                                        <LangSvgIcons />
                                        {localStorage.name ?
                                             <h3 className='font-bold whitespace-nowrap text-white'>{localStorage.name}</h3>
                                             :
                                             <Link to='/Login' className='flex gap-2 items-center text-white text-lg'><AiOutlineLogin className='' /> Login </Link>
                                        }
                                   </li> */}
              <li className="w-1/3 flex items-center justify-end gap-2 relative langBtns">
                <div>
                  <LangSvgIcons />
                </div>
                <div className="btn z-10 absolute top-0 right-[-5rem]  opacity-0 w-full flex flex-col items-center justify-center mx-2">
                  <Lang />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Sidebar isOpen={isOpen} toggleSidebar={() => setIsOpen(false)} />
    </>
  );
};

export default MobileNav;
