import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { config, url } from "../Common/Common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Layout from "../Components/Layout";
import Loading from "../Components/UI/Loading";
import Hero from "../img/Area_Hero.jpeg";
import { Helmet } from "react-helmet-async";
import Skeleton from "react-loading-skeleton";

const Area = () => {
  // Inside your component
  const areaCardsRef = useRef(null);
  const inputRef = useRef(null);

  const [areas, setAreas] = useState([]);
  const [topAreas, setTopAreas] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [limit, setLimit] = useState(18);
  const [total, setTotal] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = queryParams.get('page') || 1;

  useEffect(() => {
    axios
    .get(url + `areas?sort=order`, config)
    .then(function (response) {
      // handle success
      setTopAreas(response.data.data);
      setLoading(false)
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .then(function () {
      // always executed
    });
  }, [])



  useEffect(() => {
      axios
        .get(url + `areas?page=${page}`, config)
        .then(function (response) {
          // handle success
          setAreas(response.data.data);
          setCurrentPage(response.data.current_page);
          setLastPage(response.data.last_page);
  
          // Store the data in localStorage
          const areasDataToStore = {
            areas: response.data.data,
          };
          localStorage.setItem('areasData', JSON.stringify(areasDataToStore));
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
  }, [page]);
  
  

  const handleInputChange = (event) => {
    setIsInputFocused(true);

    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      const searchList = areas.filter((item) => {
        return query.toLowerCase() === "" ? item : item.name.toLowerCase().includes(query.toLowerCase());
      });
      setAreas(searchList);
      setTotal(searchList.length);

      if (searchList.length <= 0) {
        setLoading(true);
      }
    } else {
      // Remove this part to prevent fetching again when input is empty
      axios
        .get(url + `areas`, config)
        .then(function (response) {
          // handle success
          setAreas(response.data.data);
          setTotal(response.data.total);
          setLoading(false);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    if (searchQuery === "") {
      setIsInputFocused(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      areaCardsRef.current.scrollIntoView({ behavior: "smooth" });
      inputRef.current.blur();
    }
  };

  const styles = {
    backgroundImage: `url("${Hero}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "60vh",
  };

  const displayPageCount = 5; // Number of page numbers to display

  let startPage = Math.max(1, currentPage - 2); // Display 2 pages before the current page
  const endPage = Math.min(startPage + displayPageCount - 1, lastPage);

  // Adjust the start page if there are not enough pages on the right
  if (endPage - startPage < displayPageCount - 1) {
    startPage = Math.max(1, lastPage - displayPageCount + 1);
  }

  const pages = [];
  for (let page = startPage; page <= endPage; page++) {
    pages.push(page);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
  };

  return (
    <>
      <Helmet>
        <title>Areas</title>
        <meta name="description" content="List of Dubai's greatest neighborhoods that will always be popular with renters and buyers. Go through our neighborhood guides to find the best areas to live in Dubai." />
        <link href="https://keymaxrealestate.com/area" rel="canonical" />
      </Helmet>

      <Layout>
        <div className="Area mt-24 md:mt-32" id="Area">
          <div className="container mx-auto">
            <div className="hero1 w-full rounded-[3rem]" style={styles}>
              <div className="h-full relative flex flex-col justify-center items-center px-4 py-16 mx-auto overflow-hidden sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-0 lg:py-20">
                <h2 className="max-w-lg mb-6 text-center md:text-left text-5xl font-bold tracking-tight text-white md:text-6xl leading-none textShadowBlack">{t("Areas")}</h2>
                <div className="w-full max-w-xl flex items-start justify-center">
                  <input
                    type="text"
                    className="text-center py-4 rounded-full opacity-80 px-4 border border-black/10 w-full md:w-8/12 lg:w-6/12 placeholder-black placeholder-opacity-75 placeholder-black text-lg"
                    placeholder={isInputFocused ? "" : "Search Location"}
                    value={searchQuery}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                  />
                </div>
              </div>
            </div>
            <div className={`topArea hidden md:flex justify-center items-center space-x-6 px-24 pb-12 -mt-14 ${isInputFocused ? "invisible opacity-0 transform duration-1000" : "opacity-100 transform duration-1000 delay-100"}`}>
              {isLoading ? (
                // Skeleton loading state
                <div className="flex justify-center">
                  {[...Array(6)].map((_, index) => (
                    <div key={index} className="aspect-square rounded-full overflow-hidden m-2">
                      <div className="aspect-square bg-gray-300 animate-pulse">
                        <Skeleton height={56} circle />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                // Actual data rendering
                topAreas.slice(0, 6).map((item, index) => (
                  <Link key={index} to={`/Areas/${item.slug}`} className="aspect-square relative flex rounded-full flex-col justify-center w-full overflow-hidden">
                    <div className="aspect-square">
                      <img className="object-cover w-full h-full brightness-[.60]" src={item.img_url} alt={item.name} />
                    </div>
                    <span className="absolute left-1/2 transform -translate-x-1/2 text-md xl:text-2xl font-black text-white font-Butler text-center">{item.name}</span>
                  </Link>
                ))
              )}
            </div>
            
              {isLoading ? (
                <div>
                  <h1>Loading...</h1>
                </div>
              ) : (
                <>
                  <div className={`AreaCards relative grid gap-5 row-gap-5 mb-8 sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 mt-12 ${isInputFocused && window.innerWidth > 1024 ? " transition-all duration-1000 transform -translate-y-32" : "transition-all duration-1000 transform translate-y-0 delay-100"} `} ref={areaCardsRef}>
                    {areas.slice(0, limit).map((item, index) => (
                      <Link key={index} to={`/Areas/${item.slug}`} className="AreaCard flex flex-col justify-center w-full overflow-hidden bg-white p-4 bg-transparent">
                        <img className="object-cover w-full h-56 md:h-full cursor-pointer transition-all duration-700 hover:shadow-lg rounded-[0.8rem] md:rounded-[2.8rem] aspect-video" src={item.thumbnail_url} alt={item.name} />
                        <span className="text-xl font-bold text-gray-700 text-center bg-white border-2 border-[#D4B071] rounded-full py-px -mt-5 z-20">{item.name}</span>
                      </Link>
                    ))}
                  </div>
                  

                  <div className="w-full flex justify-center items-center">
                    {/* Pagination */}
                    <nav className="pagination flex items-center justify-center space-x-2 w-full">
                      <Link to={`/area?page=1`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
                        &lt;&lt; {/* First Page */}
                      </Link>
                      <Link to={`/area?page=${currentPage - 1}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
                        &lt; {/* Previous Page */}
                      </Link>
                      {pages.map((page) => (
                        <Link
                          to={`/area?page=${page}`}
                          onClick={scrollToTop}
                          key={page}
                          className={`rounded-full black-bg px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 ${page === currentPage ? 'text-white bg-black' : 'text-black'}`}
                        >
                          {page}
                        </Link>
                      ))}
                      <Link to={`/area?page=${currentPage + 1}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
                        &gt; {/* Next Page */}
                      </Link>
                      <Link to={`/area?page=${lastPage}`} onClick={scrollToTop} className="rounded-full black-bg text-black px-3 py-2 transition duration-300 ease-in-out transform hover:scale-110 hover:text-blue-600">
                        &gt;&gt; {/* Last Page */}
                      </Link>
                    </nav>
                  </div>
                </>
              )}
            
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Area;