import React from "react";
// import Button from "./Button";
import { Link } from "react-router-dom";
// import { IoLogoWhatsapp } from "react-icons/io";
import { HiLocationMarker } from "react-icons/hi";
import { FaBath } from "react-icons/fa";
import { t } from "i18next";
// import Floor from "../Icon/Floor";
// import Bed from "../Icon/Bed";
import { FaRulerCombined } from "react-icons/fa";
import { IoIosBed } from "react-icons/io";
// import HomeAddress from "../Icon/HomeAddress";
import ImgSlider from "./ImgSlider";
// import { SiLeaflet } from "react-icons/si";
const Card = (props) => {

  let selectedPrice = null;
  let priceSuffix = "";

  if (props.price_month !== "0" && props.price_month) {
    selectedPrice = props.price_month;
    priceSuffix = " / Month";
  } else if (props.price_year !== "0" && props.price_year) {
    selectedPrice = props.price_year;
    priceSuffix = " / Year";
  } else {
    selectedPrice = props.price;
  }

  return (
    <>
      <div className="propertyCard flex flex-col items-stretch rounded-2xl px-px h-full w-full">
        <div className="mb-[-1.2rem] z-[2] px-2 text-white flex flex-col items-center justify-center w-full font-bold">
          {(props.price || props.price_year || props.price_month) && props.price_hidden === "0" && (
            <div className="bg-black border-2 border-[#D4B071] px-5 py-px rounded-full text-xl w-10/12 text-center">AED {`${selectedPrice} ${priceSuffix}`}</div>
          )}
          {props.price_hidden === "1" && (
            <div href="" className="bg-black border-2 border-[#D4B071] px-5 py-px rounded-full text-xl w-10/12 text-center">
              {" "}
              {t("Ask for price")}{" "}
            </div>
          )}
        </div>
        <Link to={`/PropertyDetails/${props.slug}`} className={`flex flex-col ${props.map ? "h-auto mb-5" : "h-full"} border-2 border-[#D4B071]  space-y-between rounded-[1.25rem] overflow-hidden`}>
          <div className="h-auto bg-gray  relative">
            <ImgSlider imgs={props.imgs} heightValue={props.height} widthValue={props.width} />
            {props.map && (
              <div className="absolute bottom-2 left-4 z-[2]">
                <p className=" bg-white px-4 py-2 rounded-full font-bold capitalize">{props.buy_or_sell}</p>
              </div>
            )}
            {props.property_type.name && (
              <div className="absolute bottom-2 z-[1] left-4">
                <p className=" bg-white px-4 py-2 rounded-full font-bold">{props.property_type.name}</p>
              </div>
            )}
          </div>
          <div className=" PropertyDetails h-full relative flex flex-col space-y-between items-center pt-6 px-4 ltr:text-left rtl:text-right ">
            <p className="grow text-2xl font-bold w-full ltr:text-left rtl:text-right font-Butler">{props.name}</p>

            <div className="shrink flex flex-col items-center w-full mt-2">
              <div className="grow flex justify-start items-center w-full">
                <HiLocationMarker className="w-1/12 text-3xl text-black" />
                <p className="w-11/12 text-black font-medium capitalize pl-2">{props.location ? props.location : "Not selected"}</p>
              </div>
              <div className="flex-1 w-full flex items-center flex-wrap justify-start ltr:ml-5 rtl:mr-5 space-x-4 md:space-x-2 lg:space-x-4 rtl:space-x-reverse text-center">
                <div className="flex items-center justify-center py-2 text-overflow ">
                  <div className="w-4">
                    <FaRulerCombined className="text-lg text-black" />
                  </div>
                  <span className="rtl:mr-2 ltr:ml-2 text-overflow text-bold">
                    {props.area} {t(".sqft")}
                  </span>
                </div>
                <div className="flex items-center justify-center py-2 text-overflow">
                  <div className="w-4">
                    <IoIosBed className="text-xl text-black" />
                  </div>
                  <span className="rtl:mr-2 ltr:ml-2 text-bold">{props.bedroom == 0 ? "Studio" : props.bedroom}</span>
                </div>
                <div className="flex items-center justify-center py-2 text-overflow ">
                  <div className="w-4">
                    <FaBath className="text-lg text-black" />
                  </div>
                  <span className="rtl:mr-2 ltr:ml-2 text-bold">{props.bathroom}</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Card;
