import { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import uae from '../../img/uae.png'
import en from '../../img/en.png'

const languages = [
          {
                    img: en,
                    code: 'en',
                    name: 'English',
                    country_code: 'gb',
          },
          {
                    img: uae,
                    code: 'ar',
                    name: 'العربية',
                    dir: 'rtl',
                    country_code: 'sa',
                    lang: 'ar',
          },
]

const Lang = ({stickyNav, HomePage}) => {
          const { t } = useTranslation()
          const currentLanguageCode = localStorage.getItem('KeyMaxLang') || 'ar'
          const currentLanguage = languages.find(l => l.code === currentLanguageCode)
          useEffect(() => {
                    document.documentElement.dir = currentLanguage.dir || 'ltr'
                    document.documentElement.lang = i18next.language;
          }, [currentLanguage, t])

          const textColorClass = HomePage ? (stickyNav ? "text-black" : "text-white") : stickyNav ? "text-black" : "text-black";

          return (
                    <>
                              {
                                        languages.map(({ code, name, country_code, img }) => (
                                                  <div key={country_code}>
                                                            <button className={`w-full text-white md:text-black langButton flex items-center ${stickyNav ? "text-black border-black  bg-white" : ""} ${stickyNav ? "" : "text-white bg-white/[.14] backdrop-blur-sm border-white"}  rounded-full `} onClick={() => { window.location.reload(true); setTimeout(i18next.changeLanguage(code), 1000); }} style={{ display: code === currentLanguageCode ? 'none' : 'flex' }} disabled={code === currentLanguageCode}>
                                                                      {/* <button className='text-black langButton flex items-center cursor-not-allowed' onClick={() => { window.location.reload(true);  setTimeout(i18next.changeLanguage(code), 1000);} } style={{ display: code === currentLanguageCode ? 'none' : 'flex' }} disabled='disabled'> */}
                                                                      {/* <img src={img} alt="" className='w-4 lg:w-6' /> */}
                                                                      <p className={`md:mx-2 px-2 py-pxtext-md ${textColorClass} text-2xl`}>{name}</p>
                                                            </button>
                                                  </div>
                                        ))
                              }
                    </>
          )
}

export default Lang