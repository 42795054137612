import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { config, recaptcha_key, url } from "../Common/Common";
import Layout from "../Components/Layout";
import Slider from "react-slick";
import { BsCameraVideo, BsWhatsapp } from "react-icons/bs";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { MdOutlineApartment } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import Modal from "../Components/Modal/Modal";
import Swal from "sweetalert2/dist/sweetalert2.js";
import bitcoin from "../img/bitcoin-2.svg";
import cheque from "../img/cheque.svg";
import creditCard from "../img/credit-card.svg";
import bank from "../img/bank.svg";
import cash from "../img/dollar.svg";

import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import Title from "../Components/UI/Title";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Card from "../Components/UI/Card";
import { t } from "i18next";
import "./PropertyDetails.scss";
import img from "../img/Dubai.jpg";
import icon from "../img/house.png";
import img2 from "../img/3d.png";
import map from "../img/map.png";

import logo from "../img/logo_keymax.png";

import { useLocation } from "react-router-dom";
import ImgLightbox from "../Components/ImgLightbox/ImgLightbox";
import DateForm from "../Components/Layout/DateForm";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import MortgageCalculator from "../Components/Layout/MortgageCalculator";
import Loading from "../Components/UI/Loading";
import ProjectForm from "../Components/Layout/ProjectForm";
import DateFormProperty from "../Components/Layout/DateFormProperty/DateFormProperty";
import ReactRecaptcha3 from "react-google-recaptcha3";
import { recaptcha__secret_key, recaptcha_key, url, config, chechPhone } from "../Common/Common";

const moreAvailable = {
  ltr: true,
  centerPadding: "20px",
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: false,
  autoplay: true,
  speed: 10000,
  autoplaySpeed: 0,
  cssEase: "linear",
  arrows: false,
  rows: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: false,
        infinite: false,
        autoPlay: false,
        speed: 500,
        slidesToScroll: 1,
      },
    },
  ],
};

const alsoHave = {
  ltr: true,
  dots: false,
  infinite: true,
  autoPlay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: "linear",
};

const settings = {
  ltr: true,
  dots: true,
  infinite: true,
  autoPlay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
      },
    },
  ],
};

const PropertyDetails = () => {
  const { id } = useParams();
  // const [project, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [lat, setLat] = useState(24.52);
  const [lng, setLng] = useState(55.05555555555);
  const [chartY, setChartY] = useState([]);
  const [chartX, setChartX] = useState([]);
  const navigate = useNavigate();
  const [success, setSuccess] = useState();
  const [message, setMessage] = useState(false);
  const [SocialMedia, setSocialMedia] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [modal, setModal] = useState(false);
  const [loadingBook, setLoadingBook] = useState(false);
  const [propertyDetails, setpropertyDetails] = useState(false);
  const [phone, setPhone] = useState("971");
  const [name, setName] = useState("");

  const [viewName, setViewName] = useState("");
  const [viewEmail, setViewEmail] = useState("");
  const [viewPhone, setViewPhone] = useState("");
  const [viewMessage, setViewMessage] = useState("");

  useEffect(() => {
    ReactRecaptcha3.init(recaptcha_key)    
  }, [])

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    axios
      .get(url + `properties/${id}`, config)
      .then(function (response) {
        const data = response.data.data;
        setpropertyDetails(data);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      })
      .then(function () {
        // always executed
      });
  }, [id, success]);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errToken, setErrToken] = useState(false);


  const contactHandler = (e) => {
    setEmailError(false);
    setPhoneError(false);
    setErrToken(false);
    setNameError(false);
    e.preventDefault();
    if (name.trim() == "") {
      setNameError(true);
      return;
    }
    if (viewEmail.trim() == "" || !viewEmail.includes("@")) {
      setEmailError(true);
      return;
    }

    if(!chechPhone(phone)){
      setPhoneError(true);
        return;
    }

    setLoadingBook(true);
    ReactRecaptcha3.getToken().then(
      (token) => {
        axios
          .post(
            url + 'verifyRecaptcha',
            {
              secret: recaptcha__secret_key,
              token: token,
            },
            { "Content-Type": "application/json", Accept: "application/json" }
          )
          .then((response) => {
            axios
              .post(url + "contact-us", {
                name: name,
                email: viewEmail,
                phone: phone,
                message: viewMessage != "" ? viewMessage : `None`,
                source: `Project Name: ${propertyDetails.project_number} / Book your viewing`,
                agent_id: propertyDetails?.agent?.id,
              })
              .then((response) => {
                setLoadingBook(false);
                setName("");
                setViewEmail("");
                setPhone("");
                setEmailError(false);
                setPhoneError(false);
                setViewMessage("");
                Swal.fire({
                  timer: 3000,
                  title: `${t("Success")}`,
                  icon: "success",
                  html: `${t("Your message has been sent")}`,
                  confirmButtonText: `${t("ok")}`,
                  showCloseButton: true,
                  timerProgressBar: true,
                });
              })
              .catch((error) => {
                setLoadingBook(false);
                setErrToken(true);
              });
          })
          .catch(() => {
            setLoadingBook(false);
            setErrToken(true);
          });
      },
      (error) => {
        setLoadingBook(false);
        setErrToken(true);
      }
    );

  };

  const AddToWishList = (id) => {
    if (!localStorage.KeyMaxToken) {
      navigate("/Login");
    }

    if (!localStorage.KeyMaxToken) return;

    let data = new FormData();
    data.append("property_id", id);

    axios
      .post(url + `add-wishlist`, data, config)
      .then(function (response) {
        // handle success
        setSuccess(response.data.message);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  const state = {
    labels: chartY, // Y
    datasets: [
      {
        label: "Keymax",
        fill: false,
        lineTension: 1,
        backgroundColor: "rgb(177 155 88)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 1,
        data: chartX, // X
      },
    ],
  };

  const center = {
    lat: propertyDetails && +propertyDetails.lat,
    lng: propertyDetails && +propertyDetails.lng,
  };

  const containerStyle = {
    width: "100%",
    height: "450px",

    minHeight: "350px",
  };

  const MAP_OPTIONS = {
    zoomControl: true,
    gestureHandling: "cooperative",
  };

  let selectedPrice = null;
  let priceSuffix = "";

  if (propertyDetails.price_month !== "0") {
    selectedPrice = propertyDetails.price_month;
    priceSuffix = " / Month";
  } else if (propertyDetails.price_year !== "0") {
    selectedPrice = propertyDetails.price_year;
    priceSuffix = " / Year";
  } else {
    selectedPrice = propertyDetails.price;
  }

  return (
    <Layout>
      {modal && (
        <>
          <Modal
            title={t(" Take Appontment")}
            btnText={t("Send Message")}
            closeModal={() => setModal(false)}
          />
        </>
      )}

      <div className="PropertyDetails pt-8 md:pt-0 mt-16 md:mt-32">
        {propertyDetails ? (
          <>
            <div className="container mx-auto relative">
              <div className="flex items-start justify-start flex-col md:flex-row relative space-x-3">
                <div className=" w-full md:w-8/12 lg:w-9/12  relative overflow-hidden overflow-y-auto">
                  <div className="flex flex-wrap">
                    <p className="md:p-2 p-1 capitalize text-lg md:text-xl font-bold">
                      {propertyDetails.city.name} |
                    </p>
                    <p className="md:p-2 p-1 capitalize  text-lg md:text-xl font-bold">
                      {propertyDetails.buy_or_sell} |
                    </p>
                    <p className="md:p-2 p-1 capitalize  text-lg md:text-xl font-bold">
                      {propertyDetails.property_type.name} |
                    </p>
                    <p className="md:p-2 p-1 capitalize  text-lg md:text-xl font-bold">
                      {propertyDetails.location_area.name}
                    </p>
                  </div>

                  <div className="mainPropertySlider relative overflow-hidden">
                    <ImgLightbox images={propertyDetails.images} />

                    {/* <div className="absolute z-50 top-8 right-8 flex items-center gap-4">
                      <button className='p-2 px-4 text-black bg-gold border-white/20 border rounded-sm text-sm flex items-center justify-center font-bold gap-2'> <BsCameraVideo />  {t('Video')}  </button>
                      <button className='p-2 px-4 text-black bg-gold border-white/20 border rounded-sm text-sm flex items-center justify-center font-bold gap-2' onClick={() => setOpenMap(true)}> <GoLocation />  {t('View on map')}  </button>
                    </div> */}
                    {/* {(propertyDetails.images && propertyDetails.images.length > 0) &&
                      <Slider  {...settings} >
                        {
                          propertyDetails.images &&
                          propertyDetails.images.map((item, index) => (
                            <img src={item} key={index} alt={`project.name`} className='object-cover min-w-full max-w-full w-full h-[85vh]' />
                          ))

                        }
                      </Slider>
                    } */}
                    {/* <div className="absolute min-h-24 w-full left-0 bottom-0 bg-black/60 text-white 'flex items-center justify-center  py-2">
                      <ul className='flex items-center justify-center flex-wrap p-0 gap-4  h-full'>
                        {((propertyDetails.price || propertyDetails.price_year) && propertyDetails.price_hidden === '0') && <li className='text-gold font-black text-2xl'> {propertyDetails.buy_or_sell === "rent" ? propertyDetails.price_year : propertyDetails.price} {t('AED')} </li>}
                        {propertyDetails.price_hidden === '1' && <li className='text-gold font-black text-2xl'> {t('Ask fo price')} </li>}
                        {propertyDetails.property_type && <li className='text-white/70 ltr:border-l rtl:border-r  ltr:pl-4 rtl:pr-4 uppercase'>{propertyDetails.property_type.name} </li>}
                        {propertyDetails.bedrooms_number && <li className='text-white/70 ltr:border-l rtl:border-r  ltr:pl-4 rtl:pr-4 uppercase'>{propertyDetails.bedrooms_number} {t('Bedroom')}</li>}
                        {propertyDetails.bathrooms_number && <li className='text-white/70 ltr:border-l rtl:border-r  ltr:pl-4 rtl:pr-4 uppercase'>{propertyDetails.bathrooms_number}  {t('Bathroom')}</li>}
                        {propertyDetails.size && <li className='text-white/70 ltr:border-l rtl:border-r  ltr:pl-4 rtl:pr-4 uppercase'>{t('Size')}: {propertyDetails.size} {t('Sqft')}</li>}

                      </ul>
                    </div> */}
                  </div>

                  <div className="flex flex-col md:flex-row justify-between space-y-2 md:space-y-0 space-x-0 md:space-x-5">
                    <div className="text-xl md:text-lg lg:text-2xl border-2 lg:border-4 py-px lg:py-2 font-black border-[#D4B071] rounded-full w-full item-center  text-center">
                      {propertyDetails.property_type.name}
                    </div>
                    <div className="text-xl md:text-lg lg:text-2xl border-2 lg:border-4 py-px lg:py-2 font-black border-[#D4B071] rounded-full w-full item-center  text-center">
                      {propertyDetails.bedrooms_number == 0
                        ? "Studio"
                        : `${propertyDetails.bedrooms_number} Bedroom`}
                    </div>
                    <div className="text-xl md:text-lg lg:text-2xl border-2 lg:border-4 py-px lg:py-2 font-black border-[#D4B071] rounded-full w-full item-center  text-center">
                      {propertyDetails.bathrooms_number} Bathroom
                    </div>
                    <div className="text-xl md:text-lg lg:text-2xl border-2 lg:border-4 py-px lg:py-2 font-black border-[#D4B071] rounded-full w-full item-center  text-center">
                      {propertyDetails.size} sq ft
                    </div>
                  </div>

                  <div className="w-full lg:w-10/12 3xl:w-10/12 mx-auto">
                    <div className="FindYourHome flex items-center flex-col py-4 lg:py-8">
                      <h1 className="text-md lg:text-lg text-black/80  my-px lg:my-2 font-bold">
                        {propertyDetails.bedrooms_number == 0
                          ? "Studio"
                          : `${propertyDetails.bedrooms_number} Bed `}
                        {propertyDetails.property_type.name} for{" "}
                        <span className="capitalize">
                          {propertyDetails.buy_or_sell}
                        </span>{" "}
                        at {propertyDetails.address}
                      </h1>
                      <h3 className="font-bold text-xl lg:text-2xl text-gold my-px lg:my-2">
                        {propertyDetails.title}
                      </h3>
                    </div>

                    {window.innerWidth < 768 && (
                      <div className="w-full">
                        <div className="w-full px-4">
                          <div>
                            <div className="border-black w-full flex items-start justify-center flex-wrap w-full text-black bg-[#D4B071] rounded-full border-[3px]">
                              {(propertyDetails.price ||
                                propertyDetails.price_year ||
                                propertyDetails.price_month) &&
                                propertyDetails.price_hidden === "0" && (
                                  <div className="w-full py-2">
                                    <h3 className="title font-black text-lg md:text-xl lg:text-3xl text-center ">
                                      AED {`${selectedPrice} ${priceSuffix}`}
                                    </h3>
                                    {/* <p className="Description text-white text-center leading-loose text-lg 3xl:text-2xl mt-4 capitalize"> {`price year`}</p> */}
                                  </div>
                                )}
                            </div>
                          </div>
                          {propertyDetails.agent && (
                            <div className="w-full flex justify-center">
                              <div className="w-full md:max-w-md flex flex-col justify-center items-center items-center bg-black border-4 border-[#D4B071] rounded-[3rem]  px-2 mt-28 md:mt-4 py-4 w-full">
                                <div className="aspect-square rounded-full bg-black  relative overflow-hidden imgAvatar items-center -mt-[5.5rem] border-4 border-[#D4B071]">
                                  <img
                                    className="w-32"
                                    src={propertyDetails.agent.img_url}
                                    alt="Keymax Real Estate CEO"
                                  />
                                </div>
                                <div className="flex justity-between items-center col-span-2">
                                  <div>
                                    <h3 className="w-full text-white inline-block font-black font-Butler text-2xl lg:text-3xl text-center pb-2 mt-2 mb-0 lg:mb-2">
                                      {propertyDetails.agent.first_name}
                                    </h3>
                                    <ul className="text-left pb-2 mb-2">
                                      {/* <li className="text-white font-black font-Butler text-md lg:text-lg text-center">Property Consultant</li> */}
                                      <li className="text-white font-black text-md lg:text-lg text-center">
                                        Speaks:{" "}
                                        {propertyDetails.agent.languages}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="btns flex flex-col items-center justify-center flex-row -mt-6">
                                    {propertyDetails.agent.phone != null && (
                                      <a
                                        href={`https://wa.me/${propertyDetails.agent.phone.replace(
                                          "+",
                                          ""
                                        )}?text=Hi, I am interested in your property on Keymax  https://keymaxrealestate.com/PropertyDetails/${
                                          propertyDetails?.slug
                                        }`}
                                        className="p-2 duration-300 transform hover:-translate-y-2 hover:opacity-90 text-xl md:text-3xl cursor-pointer text-white lg:h-12  flex items-center"
                                      >
                                        {" "}
                                        <BsWhatsapp className="w-14 " />
                                      </a>
                                    )}
                                    {propertyDetails?.agent?.phone != null && (
                                      <a
                                        href={
                                          "tel:" + propertyDetails.agent.phone
                                        }
                                        className="p-2 duration-300 transform hover:-translate-y-2 hover:opacity-90  text-xl md:text-3xl cursor-pointer text-white lg:h-12  flex items-center"
                                      >
                                        <FiPhoneCall className="w-14 " />
                                      </a>
                                    )}
                                    {propertyDetails.agent.email != null && (
                                      <a
                                        href={
                                          "mailto:" +
                                          propertyDetails.agent.email
                                        }
                                        className="p-2 duration-300 transform hover:-translate-y-2 hover:opacity-90 text-xl md:text-3xl cursor-pointer text-white lg:h-12  flex items-center"
                                      >
                                        <AiOutlineMail className="w-14 " />
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="mt-4 pt-4 alsoHave">
                            <div className="relative p-2 max-w-full h-full border-4 border-[#D4B071] rounded-3xl  bg-white">
                              <div className="flex justify-center items-center w-full mx-2 my-5 mt-[-2rem]">
                                <p className="text-white bg-black rounded-full inline-block text-center text-2xl px-5 py-2 font-bold font-Butler">
                                  Book your viewing
                                </p>
                              </div>
                              <form className="relative" onSubmit={contactHandler}>
                                <div className="w-5/6 mx-auto flex flex-col justify-center items-center">
                                  <div className="max-w-full mx-10">
                                    <DateForm />
                                  </div>
                                  <div
                                    className={`flex flex-col gap-x-0 md:gap-x-5 w-full relative pt-5`}
                                  >
                                    <input
                                      placeholder="Name"
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                      type="text"
                                      className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border-b-2 border-black appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                    />
                                    {nameError && (
                                      <span className="text-sm text-red">
                                        {" "}
                                        Please enter your name
                                      </span>
                                    )}
                                    <input
                                      placeholder="Email"
                                      value={viewEmail}
                                      onChange={(e) => setViewEmail(e.target.value)}
                                      type="text"
                                      className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                                    />
                                    {emailError && (
                                      <span className="text-sm text-red">
                                        {" "}
                                        Enter a valid Email
                                      </span>
                                    )}
                                    <PhoneInput
                                      placeholder="Enter phone number"
                                      country={"ae"}
                                      value={phone}
                                      onChange={(phone) => setPhone(phone)}
                                      className="height3 flex-grow w-full px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-b-black focus:outline-none focus:shadow-outline"
                                    />
                                    {phoneError && (<p className=" text-red text-sm"> Please enter valid phone number </p> )}

                                  </div>
                                  <textarea
                                    name=""
                                    id=""
                                    rows="5"
                                    value={viewMessage}

                                    placeholder="Message"
                                    onChange={(e) => setViewMessage(e.target.value)}

                                    className="flex-grow w-full px-4 transition duration-200 bg-white border-b-2 border-black appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline mb-10"
                                  ></textarea>
                                  {errToken && (
                                    <p className="text-red text-sm error-token">
                                      please verify you are a human
                                    </p>
                                  )}
                                  <button
                                  onClick={contactHandler}
                                  type="submit"
                                  disabled={loadingBook}
                                    className="absolute bottom-[-2.1rem] placeholder-center inline-flex items-center justify-center h-12 px-6 text-lg md:text-2xl mr-0 md:mr-6 font-medium tracking-wide text-black font-bold transition duration-200 rounded-full shadow-md bg-[#D4B071] hover:bg-blue-700 focus:shadow-outline focus:outline-none"
                                  >
                                  {loadingBook ? (
                                    <>
                                      <span className="inline-flex items-center justify-center h-12 px-6">
                                        <span className="lds-dual-ring"></span>
                                      </span>
                                    </>
                                  ) : (
                                    "Submit"
                                  )}
                                    
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {propertyDetails.description && (
                      <div className="Description row flex items-center justify-between flex-wrap mt-8 pb-8">
                        <div className="w-full">
                          <p
                            className="text-md leading-loose mb-4 lg:text-xl px-6"
                            dangerouslySetInnerHTML={{
                              __html: propertyDetails.description,
                            }}
                          />
                        </div>
                        {propertyDetails.video && (
                          <div className="relative Video row flex items-center justify-center flex-wrap mt-12 my-12">
                            <div className="absolute top-[-3.8rem] flex justify-center items-center m-10 z-10">
                              <p className="text-white bg-black rounded-full border border-4 border-[#D4B071] inline-block text-center text-xl lg:text-4xl px-5 py-2 font-bold font-Butler">
                                Video
                              </p>
                            </div>
                            <div className="w-full h-2/4 rounded-[3rem] border-2 overflow-hidden text-center">
                              <img
                                src={propertyDetails.images[0]}
                                className="w-full object-cover h-full object-center block aspect-video"
                              />
                              <div className="w-full h-full">
                                <img
                                  onClick={() => {
                                    setModal(true);
                                  }}
                                  src="https://anya.keymaxrealestate.com/images/play.webp"
                                  className="z-50 hover:cursor-pointer w-24 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {propertyDetails.amenities &&
                      propertyDetails.amenities.length > 0 && (
                        <div className=" row flex items-center justify-between flex-wrap mt-8 rounded-lg">
                          {/* <div className="w-full text-center pb-8">
                          <h3 className="title text-2xl relative overflow-hidden pb-4 uppercase font-black text-black/70">
                            {t('Amenities')}
                            <span className='absolute bottom-0 left-1/2 -translate-x-1/2 w-28 h-2 bg-gold' />
                          </h3>
                        </div> */}

                          <div className="grid grid-cols-2 rounded-lg relative overflow-hidden w-full">
                            <img
                              src={img2}
                              alt=""
                              className="absolute top-0 left-0 object-cover min-w-full min-h-full brightness-50 z-[-1]"
                            />

                            {propertyDetails.amenities.length > 0 &&
                              propertyDetails.amenities.map((item, index) => (
                                <div className="" key={index}>
                                  <div className="p-4 flex items-center space-x-5 flex-row">
                                    <img
                                      src={item.icon}
                                      alt=""
                                      className="w-8"
                                    />
                                    <h3 className="text-white capitalize text-xl">
                                      {item.name}
                                    </h3>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    {propertyDetails.location_area && (
                      <div className="relative Location row flex items-center justify-center flex-wrap mt-12 my-12">
                        <div className="absolute top-[-3.7rem] lg:top-[-4.2rem] flex justify-center items-center m-10 z-10">
                          <p className="text-white bg-black rounded-full border border-[3px] lg:border-4 border-[#D4B071] inline-block text-center text-lg md:text-xl lg:text-2xl px-5 py-px lg:py-2 font-bold font-Butler">
                            View on map
                          </p>
                        </div>
                        <div className="w-full h-full rounded-[3rem] border-2 overflow-hidden text-center">
                          <LoadScript googleMapsApiKey="AIzaSyCSu32tihJqyOGNpcNdUnjBbNBoACV2R_M">
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={center}
                              zoom={12}
                              options={MAP_OPTIONS}
                            >
                              <Marker position={center} />
                            </GoogleMap>
                          </LoadScript>
                        </div>
                      </div>
                    )}

                    <section>
                      <div className="px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl lg:px-8">
                        <div className="mb-10 md:mx-auto text-center md:mb-12">
                          <h2 className="max-w-screen-full mb-6 font-sans text-4xl md:text-4xl lg:text-5xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto font-Butler">
                            {t("We accept all types of payments")}
                          </h2>
                        </div>
                        <ul className="w-full flex flex-wrap items-center justify-center mt-8">
                          <li className="flex flex-col lg:flex-row space-x-0 md:space-x-4 space-y-3 md:space-y-0 justify-center items-center text-white md:mb-4 mb-0 p-4">
                            <img src={bitcoin} alt="" className="lg:w-16 w-9" />
                            <h3 className="text-sm lg:text-lg mx-0 lg:mx-2 text-black font-bold">
                              {t("Bitcoin")}
                            </h3>
                          </li>
                          <li className="flex flex-col lg:flex-row space-x-0 md:space-x-4 space-y-3 md:space-y-0 justify-center items-center text-white md:mb-4 mb-0 p-4">
                            <img src={cheque} alt="" className="lg:w-16 w-9" />
                            <h3 className="text-sm lg:text-lg mx-0 lg:mx-2   text-black font-bold">
                              {t("Cheques")}
                            </h3>
                          </li>
                          <li className="flex flex-col lg:flex-row space-x-0 md:space-x-4 space-y-3 md:space-y-0 justify-center items-center text-white md:mb-4 mb-0 p-4">
                            <img
                              src={creditCard}
                              alt=""
                              className="lg:w-16 w-9"
                            />
                            <h3 className="text-sm lg:text-lg mx-0 lg:mx-2   text-black font-bold">
                              {t("Credit Card")}
                            </h3>
                          </li>
                          <li className="flex flex-col lg:flex-row space-x-0 md:space-x-4 space-y-3 md:space-y-0 justify-center items-center text-white md:mb-4 mb-0 p-4">
                            <img src={bank} alt="" className="lg:w-16 w-9" />
                            <h3 className="text-sm lg:text-lg mx-0 lg:mx-2   text-black font-bold ">
                              {t("Bank Transfer")}
                            </h3>
                          </li>
                          <li className="flex flex-col lg:flex-row space-x-0 md:space-x-4 space-y-3 md:space-y-0 justify-center items-center text-white md:mb-4 mb-0 p-4">
                            <img src={cash} alt="" className="lg:w-16 w-9" />
                            <h3 className="text-sm lg:text-lg mx-0 lg:mx-2   text-black font-bold">
                              {t("Cash")}
                            </h3>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>

                {window.innerWidth > 767 && (
                  <div
                    className={`sticky top-[8rem] sidebar w-full md:w-4/12 lg:w-3/12 rtl:md:left-0 ltr:md:right-0 md:z-10 overflow-y-auto h-screen max-h-[calc(100vh-8rem)] scrollable-container pb-16`}
                  >
                    <div className="price">
                      <div className="border-black w-full flex items-start justify-center flex-wrap w-full text-black bg-[#D4B071] rounded-full border-[3px]">
                        {(propertyDetails.price ||
                          propertyDetails.price_year ||
                          propertyDetails.price_month) &&
                          propertyDetails.price_hidden === "0" && (
                            <div className="w-full py-2">
                              <h3 className="title font-black text-lg md:text-xl lg:text-3xl text-center ">
                                AED {`${selectedPrice} ${priceSuffix}`}
                              </h3>
                              {/* <p className="Description text-white text-center leading-loose text-lg 3xl:text-2xl mt-4 capitalize"> {`price year`}</p> */}
                            </div>
                          )}
                      </div>
                    </div>
                    {propertyDetails.agent && (
                      <div className="w-full flex justify-center mt-16">
                        <div className="w-full md:max-w-md flex flex-col justify-center items-center items-center bg-black border-4 border-[#D4B071] rounded-[3rem]  px-2 mt-28 md:mt-4 py-4 w-full">
                          <div className="aspect-square rounded-full bg-black  relative overflow-hidden imgAvatar items-center -mt-[5.5rem] border-4 border-[#D4B071]">
                            <img
                              className="w-32"
                              src={propertyDetails.agent.img_url}
                              alt={`Real Estate Agent ${propertyDetails.agent.first_name}"`}
                            />
                          </div>
                          <div className="flex justity-between items-center col-span-2">
                            <div>
                              <h3 className="w-full text-white inline-block font-black font-Butler text-2xl lg:text-3xl text-center pb-2 mt-2 mb-0 lg:mb-2 ">
                                {propertyDetails.agent.first_name}
                              </h3>
                              <ul className="text-left pb-2 mb-2">
                                {/* <li className="text-white font-black font-Butler text-md lg:text-lg text-center">Property Consultant</li> */}
                                <li className="text-white font-black text-md lg:text-lg text-center">
                                  Speaks: {propertyDetails.agent.languages}
                                </li>
                              </ul>
                            </div>
                            <div className="btns flex flex-col items-center justify-center flex-row -mt-6">
                              {propertyDetails.agent.phone != null && (
                                <>
                                  <a
                                    href={`https://wa.me/${propertyDetails.agent.phone.replace(
                                      "+",
                                      ""
                                    )}?text=Hi, I am interested in your property on Keymax  https://keymaxrealestate.com/PropertyDetails/${
                                      propertyDetails?.slug
                                    }`}
                                    className="p-2 duration-300 transform hover:-translate-y-2 hover:opacity-90 text-xl md:text-3xl cursor-pointer text-white lg:h-12  flex items-center"
                                  >
                                    <BsWhatsapp className="w-14 " />
                                  </a>
                                  <a
                                    href={"tel:" + propertyDetails.agent.phone}
                                    className="p-2 duration-300 transform hover:-translate-y-2 hover:opacity-90  text-xl md:text-3xl cursor-pointer text-white lg:h-12  flex items-center"
                                  >
                                    <FiPhoneCall className="w-14 " />
                                  </a>
                                </>
                              )}
                              {propertyDetails.agent.email != null && (
                                <a
                                  href={"mailto:" + propertyDetails.agent.email}
                                  className="p-2 duration-300 transform hover:-translate-y-2 hover:opacity-90 text-xl md:text-3xl cursor-pointer text-white lg:h-12  flex items-center"
                                >
                                  <AiOutlineMail className="w-14 " />
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mt-4 pt-4 max-h-full">
                      <div className="relative p-2 w-full h-auto border-4 border-[#D4B071] rounded-3xl  bg-white">
                        <div className="flex justify-center items-center w-full my-8 mt-[-2rem]">
                          <p className="text-white bg-black rounded-full inline-block text-center text-md lg:text-2xl px-5 py-2 font-bold font-Butler">
                            Book your viewing
                          </p>
                        </div>
                        <form className="relative" onSubmit={contactHandler}>
                          <div className="max-w-5/6 mx-auto flex flex-col justify-center items-center">
                            <DateFormProperty mobile={true} />
                            <div
                              className={`flex flex-col gap-x-0 md:gap-x-5 w-full relative pt-5`}
                            >
                              <input
                                placeholder="Name"
                                
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                              />
                              {nameError && (
                                <span className="text-sm text-red">
                                  {" "}
                                  Please enter your name
                                </span>
                              )}
                              <input
                                placeholder="Email"
                                
                                type="text"
                                value={viewEmail}
                                onChange={(e) => setViewEmail(e.target.value)}
                                className="flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                              />
                              {emailError && (
                                <span className="text-sm text-red">
                                  {" "}
                                  Enter a valid Email
                                </span>
                              )}
                              <PhoneInput
                              
                                placeholder="Enter phone number"
                                country={"ae"}
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                                className="height-more2  flex-grow w-full h-12 px-4 mb-3 transition duration-200 bg-white border-b-2 border-black appearance-none md:mb-0 focus:border-b-black focus:outline-none focus:shadow-outline"
                              />
                              {phoneError && (<p className=" text-red text-sm"> Please enter valid phone number </p> )}
                            </div>
                            <textarea
                              name=""
                              id=""
                              rows="5"
                              value={viewMessage}
                              placeholder="Message"
                              onChange={(e) => setViewMessage(e.target.value)}
                              className="flex-grow w-full px-4 transition duration-200 bg-white border-b-2 border-black appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline mb-10"
                            ></textarea>

                            {errToken && (
                              <p className="text-red text-sm error-token">
                                please verify you are a human
                              </p>
                            )}
                            <button
                              onClick={contactHandler}
                              type="submit"
                              disabled={loadingBook}
                              className="absolute bottom-[-1.9rem] placeholder-center inline-flex items-center justify-center  px-7 py-2 text-md font-medium tracking-wide text-black font-bold transition duration-200 rounded-full shadow-md bg-[#D4B071] hover:bg-blue-700 focus:shadow-outline focus:outline-none"
                            >
                              {loadingBook ? (
                                <>
                                  <span className="inline-flex items-center justify-center h-12 px-6">
                                    <span className="lds-dual-ring"></span>
                                  </span>
                                </>
                              ) : (
                                "Book now"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                      {/* <Slider {...alsoHave} >
                        {
                          propertyDetails.more_same_area.map((item, index) => (
                            <Card key={index}
                              id={item.id}
                              name={item.title}
                              location={item.address}
                              whatsapp={item.whatsapp}
                              area={item.size}
                              bedroom={item.bedrooms_number}
                              bathroom={item.bathrooms_number}
                              price={item.price}
                              img={item.image}
                              imgs={item.images}
                              status={item.launch}
                              buy_or_sell={item.buy_or_sell}
                              price_year={item.price_year}
                              price_hidden={item.price_hidden}
                              description={item.description}
                              width='100%'
                              height='100%' />
                          ))
                        }
                      </Slider> */}
                    </div>
                  </div>
                )}
              </div>
              <ProjectForm
                project_name={propertyDetails.project_number}
                heading="Book your Consultation"
                subHeading="and our agent will show you property"
                topSpace={0}
                slug={propertyDetails?.slug}
                agent_id={propertyDetails?.agent?.id}
                phoneAg={propertyDetails?.agent?.phone}
              />
            </div>
            <div className="moreProperties container mx-auto row bg-white mt-16 py-8 relative z-10">
              <div className="mt-4 alsoHave">
                {/* <div className="w-full text-center pb-8">
                  <h3 className="title font-bold mb-4 text-2xl relative overflow-hidden pb-8">
                    {t("More available in the same area")}
                    <span className="absolute bottom-0 left-1/2 -translate-x-1/2 w-28 h-2 bg-gold" />
                  </h3>
                </div> */}
                <Title
                  title={t("Similar Properties")}
                  link={t("view all")}
                  url={`/SubProperties/area_id=${propertyDetails.location_area.id}`}
                />
                <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-4 grid-cols-1">
                  {propertyDetails.more_same_area
                    .slice(0, 4)
                    .map((item, index) => (
                      <Card
                        key={index}
                        id={item.id}
                        slug={item.slug}
                        name={item.title}
                        location={item.address}
                        whatsapp={item.whatsapp}
                        area={item.size}
                        bedroom={item.bedrooms_number}
                        bathroom={item.bathrooms_number}
                        property_type={item.property_type}
                        price={item.price}
                        img={item.image}
                        imgs={item.images}
                        status={item.launch}
                        buy_or_sell={item.buy_or_sell}
                        price_year={item.price_year}
                        price_hidden={item.price_hidden}
                        description={item.description}
                        height={256}
                        width={380}
                      />
                    ))}
                </div>
              </div>
            </div>

            {/* {openMap && (
              <>
                <div className="overlay fixed top-0 left-0 h-screen w-screen bg-black/80 z-50" onClick={() => setOpenMap(false)} />
                <div className="md:p-8 map fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-1/2 md:h-full w-10/12 z-[9999]  bg-white rounded-xl overflow-hidden">
                  <button onClick={() => setOpenMap(false)} className="absolute top-4 right-4 z-50 font-bold bg-[#CDAC62] rounded-full w-8 h-8 text-white">
                    x
                  </button>
                  <LoadScript googleMapsApiKey="AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc">
                    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10} options={MAP_OPTIONS}>
                      <Marker position={center} />
                    </GoogleMap>
                  </LoadScript>
                </div>
              </>
            )} */}
          </>
        ) : isLoading ? (
          <div className="pt-8 md:pt-0 md:mt-28 container mx-auto flex justify-center items-center h-screen">
            <Loading />
          </div>
        ) : (
          <div className="h-[50vh] flex items-center justify-center capitalize font-bold flex-col gap-4 ">
            <img src={logo} alt="" className="w-48" />
            <p>this property is not available anymore</p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PropertyDetails;
