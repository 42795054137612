import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./SwiperThumb.css";

// import required modules
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper";

export default function SwiperThumb({ Gallery, stateChanger, title }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Autoplay]}
        autoplay={{ delay: 4000 }}
        className="mySwiper2"
      >
        {Gallery.map((img, index) => (
          <SwiperSlide key={index}>
            <img loading="lazy" src={img.image} alt={title} className="object-cover aspect-video  object-top w-full h-full" onClick={() => stateChanger(index)} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Swiper onSwiper={setThumbsSwiper} spaceBetween={15} slidesPerView={4} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]} className="mySwiperThumb">
        {Gallery.map((img, index) => (
          <SwiperSlide key={index}>
            <img loading="lazy" src={img.image} alt={title} className="object-cover aspect-video object-top w-full h-full" />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
